import React from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { SET_MEMBERSHIP } from 'api/mutations'
import { useForm } from 'react-hook-form'
//import { useHistory } from 'react-router-dom'
import validate from './validate'
import View from './view'

const AddMembershipForm = ({ ...props }) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  //const history = useHistory()
  const mutateLogin = useMutation(SET_MEMBERSHIP, {
    onSuccess: () => {
      enqueueSnackbar(t('login.success'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = data => {
    console.log(data)
    mutateLogin.mutate(data)
  }

  return <View {...props} onSubmit={handleSubmit(onSubmit)} register={register} control={control} errors={errors} />
}

export default AddMembershipForm
