import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { setNewChecklistItem, updateChecklistItem, deleteChecklistItem } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import React, { useRef, useState } from 'react'
import { CloseIcon, Col2, Col, ModalCard, ModalHeader, ModalItem, ModalTitle, SubmitBtn } from '../bookings/modalStyles'
// eslint-disable-next-line import/named

function ModalCreateChecklistItem(props) {
  // const { t } = useTranslation()
  const { checklist, checkListItem } = props
  const { toClose } = props
  console.log(checklist, checkListItem)
  const [checkListItemData, setChecklistData] = useState({
    name: checkListItem?.name ? checkListItem?.name : '',
    status: checkListItem?.status ? checkListItem?.status : 'active',
    description: checkListItem?.description ? checkListItem?.description : '',
  })

  const [updating, setUpdating] = useState(false)

  const validated = useRef(false)

  const [errors, setErrors] = useState({
    name: null,
    status: null,
  })

  function validation(data) {
    console.log(data)
    const textErrors = {
      name: 'Name is required',
      status: 'Status is required',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const statusRes = data?.status === undefined || data?.status === 0 ? textErrors.status : null

    setErrors({
      name: nameRes,
      status: statusRes,
    })

    validated.current = nameRes === null && statusRes === null
  }

  function createNewLine() {
    setUpdating(true)
    if (validated.current) {
      // eslint-disable-next-line camelcase
      const data = {
        ...checkListItemData,
        check_list: checklist.cl_slug,
      }
      console.log(data)
      if (checkListItem === null) {
        setNewChecklistItem(data).then(() => {
          setUpdating(false)
          toClose()
          //window.location.reload()
        })
      } else {
        console.log('Update ', checkListItem.slug)
        updateChecklistItem(checkListItemData, checkListItem.slug).then(() => {
          toClose()
          setUpdating(false)
          //window.location.reload()
        })
      }
    }
  }

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{checkListItem ? 'Update Line' : 'Add Line'}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col2>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='checklist-item-name-id'
            name='name'
            value={checkListItemData?.name}
            onChange={value => setChecklistData({ ...checkListItemData, name: value.target.value })}
            error={errors.name !== null}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl error={errors.status !== null}>
            <InputLabel style={{ marginLeft: '15px' }}>Status</InputLabel>
            <Select
              label='Status'
              variant='outlined'
              defaultValue={checkListItem?.status}
              value={checkListItemData?.status}
              onChange={value => {
                if (value.target.value) {
                  setChecklistData({ ...checkListItemData, status: value.target.value })
                }
              }}
            >
              <MenuItem value='active'>active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
            </Select>
            {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
          </FormControl>
        </ModalItem>
      </Col2>
      <Col>
        <ModalItem>
          <FormControl>
            <TextField
              label='Description'
              variant='outlined'
              id='cl_description'
              name='description'
              multiline
              maxRows={5}
              value={checkListItemData?.description}
              rows={5}
              onChange={value => setChecklistData({ ...checkListItemData, description: value.target.value })}
              style={{ width: '100%' }}
            />
          </FormControl>
        </ModalItem>
      </Col>
      {updating ? (
        <CircularProgress color='secondary' />
      ) : (
        <SubmitBtn
          onClick={() => {
            validation(checkListItemData)
            createNewLine()
          }}
        >
          {checkListItem ? 'Update Line' : 'Add Line'}
        </SubmitBtn>
      )}
    </ModalCard>
  )
}

ModalCreateChecklistItem.propTypes = {
  toClose: PropTypes.func,
  checklist: PropTypes.object,
  checkListItem: PropTypes.object,
}

ModalCreateChecklistItem.defaultProps = {
  toClose: () => {},
  checklist: null,
  checkListItem: null,
}

export default ModalCreateChecklistItem
