import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import TextInput from 'components/atoms/textInput'
import TextInputHidden from 'components/atoms/textInputHidden'
import SelectInput from 'components/atoms/selectInput'
import SelectInputMultiple from 'components/atoms/selectInputMultiple'
import DateInput from 'components/atoms/dateInput'
import { getOffices } from 'api'
import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { Col, Col2, Col3, Col4, Container, TimerInput, Title, Toolbar } from './styled'

const LoginFormView = ({ onSubmit, control, errors }) => {
  const { t } = useTranslation()

  const params = useLocation()
  const offer = params.state
  const [offices, setOffices] = useState([])

  function getDataOffice() {
    getOffices().then(results => {
      const options = []
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const i in results) {
        const option = results[i]
        options.push({ id: option.id, text: option.name })
      }
      setOffices(options)
    })
  }

  useEffect(() => {
    getDataOffice()
  }, [])

  return (
    <Container onSubmit={onSubmit}>
      <Toolbar>
        <Button variant='contained' color='secondary' type='submit'>
          {t(offer ? 'marketing.updateOffer' : 'marketing.addOffer')}
        </Button>
      </Toolbar>
      <Title>{t(offer ? 'marketing.form.updateOffer' : 'marketing.form.addOffer')}</Title>
      <Col>
        <TextInputHidden value={offer?.slug} name='slug' control={control} />
      </Col>
      <Col3>
        <TextInput
          label={t('marketing.form.code')}
          value={offer?.code}
          name='code'
          control={control}
          error={errors?.code}
        />
        <TextInput
          label={t('marketing.form.title')}
          value={offer?.title}
          name='title'
          control={control}
          error={errors?.title}
        />
        <TextInput
          label={t('marketing.form.subtitle')}
          name='subtitle'
          value={offer?.subtitle}
          control={control}
          error={errors?.subtitle}
        />
      </Col3>

      <Col>
        <TextInput
          label={t('marketing.form.description')}
          name='description'
          control={control}
          value={offer?.description}
          error={errors?.description}
        />
      </Col>
      <Col3>
        <SelectInputMultiple
          searchProp='text'
          label={t('marketing.form.office')}
          name='offices'
          multiple
          value={offer?.offices ? offer?.offices : []}
          control={control}
          error={errors?.offices}
          options={offices}
        />
        <SelectInput
          searchProp='text'
          label={t('marketing.form.type')}
          name='offer_type'
          control={control}
          value={offer?.offer_type}
          error={errors?.offer_type}
          options={[
            { id: 'hot_desk', text: 'Hot Desk' },
            { id: 'work_area', text: 'Work Area' },
            { id: 'full_membership', text: 'Full Membership' },
            { id: 'bar', text: 'Bar' },
            { id: 'services', text: 'Services' },
            { id: 'events', text: 'Events' },
            { id: 'informative', text: 'Informative' },
          ]}
        />
        <SelectInput
          searchProp='text'
          label={t('marketing.form.screenAfterAccept')}
          name='screen_after_accept'
          control={control}
          value={offer?.screen_after_accept}
          error={errors?.screen_after_accept}
          options={[
            { id: 'Feed', text: 'Feed' },
            { id: 'BookingsHome', text: 'Bookings' },
            { id: 'MembershipList', text: 'Membership Plans' },
            { id: 'Workazon', text: 'Bar' },
            { id: 'Coliving', text: 'Events' },
            { id: 'Profile', text: 'Profile' },
          ]}
        />
      </Col3>
      <Col2>
        <TextInput
          label={t('marketing.form.price')}
          name='price'
          type='number'
          value={offer?.price ? parseFloat(offer?.price) : ''}
          control={control}
          error={errors?.price}
        />
        <TextInput
          label={t('marketing.form.discount')}
          name='discount'
          type='number'
          value={offer?.discount ? parseFloat(offer?.discount) : ''}
          control={control}
          error={errors?.discount}
        />
      </Col2>
      <Col4>
        <DateInput
          label={t('marketing.form.dateStart')}
          name='offer_start_0'
          control={control}
          value={offer?.date_start ? offer?.date_start : ''}
          error={errors?.offer_start_0}
        />
        <TimerInput
          label={t('marketing.form.timeStart')}
          name='offer_start_1'
          control={control}
          placeholder='09:00 AM'
          mask='__:__ _M'
          maxDateMessage='06:00 PM'
          minDateMessage='09:00 AM'
          minutesStep={1}
          variant='outlined'
          value={offer?.date_start ? offer?.date_start : ''}
          error={errors?.offer_start_1}
        />
        <DateInput
          label={t('marketing.form.dateEnd')}
          name='offer_end_0'
          value={offer?.date_end ? offer?.date_end : ''}
          control={control}
          error={errors?.offer_end_0}
        />
        <TimerInput
          label={t('marketing.form.timeEnd')}
          name='offer_end_1'
          control={control}
          placeholder='09:00 AM'
          mask='__:__ _M'
          maxDateMessage='06:00 PM'
          minDateMessage='09:00 AM'
          minutesStep={1}
          variant='outlined'
          value={offer?.date_end ? offer?.date_end : ''}
          error={errors?.offer_end_1}
        />
      </Col4>
      <Col3>
        <TextInput
          label={t('marketing.form.limitPerCampaign')}
          name='limit_per_campaign'
          type='number'
          control={control}
          value={offer?.limit_per_campaign ? parseFloat(offer?.limit_per_campaign) : ''}
          error={errors?.limit_per_campaign}
        />
        <TextInput
          label={t('marketing.form.limitPerTeam')}
          name='limit_per_team'
          type='number'
          control={control}
          value={offer?.limit_per_team ? parseFloat(offer?.limit_per_team) : ''}
          error={errors?.limit_per_team}
        />
        <TextInput
          label={t('marketing.form.limitPerMember')}
          name='limit_per_member'
          type='number'
          control={control}
          value={offer?.limit_per_member ? parseFloat(offer?.limit_per_member) : ''}
          error={errors?.limit_per_member}
        />
      </Col3>

      <Col4>
        <Controller
          control={control}
          render={({ onChange, ...props }) => (
            <FormControlLabel
              {...props}
              checked={offer?.work_only}
              onChange={(e, data) => {
                // eslint-disable-next-line react/prop-types
                props.field.onChange(data)
              }}
              control={<Checkbox name='work_only' />}
              label={t('marketing.form.workOnly')}
            />
          )}
          name='send_notificacion'
        />
        <Controller
          control={control}
          render={({ onChange, ...props }) => (
            <FormControlLabel
              {...props}
              checked={offer?.only_team_admin}
              onChange={(e, data) => {
                // eslint-disable-next-line react/prop-types
                props.field.onChange(data)
              }}
              control={<Checkbox name='only_team_admin' />}
              label={t('marketing.form.onlyTeamAdmin')}
            />
          )}
          name='send_notificacion'
        />
      </Col4>
    </Container>
  )
}

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  // office: PropTypes.object,
}

LoginFormView.defaultProps = {
  errors: null,
  // office: {},
}

export default LoginFormView
