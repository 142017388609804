import { useEffect, useState } from 'react'
import { createProduct, getProductCategories } from 'api'
import PropTypes from 'prop-types'
import { Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { CloseIcon, ModalCard, ModalHeader, ModalTitle, ModalItem, SubmitBtn } from '../bookings/modalStyles'

function ModalCreateProduct(props) {
  const { toClose } = props
  const [theProduct, setProduct] = useState({
    vat: 20,
    price: 0,
  })
  const [errors, setErrors] = useState({
    name: null,
    category: null,
    price: null,
    vat: null,
  })
  const [categories, setCategories] = useState()

  function validation(data) {
    const textErrors = {
      name: 'Product Name is required',
      category: 'Product Category is required',
      price: 'Product Price is required',
      vat: 'VAT  is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const catRes = data?.category === undefined || data?.category === '' ? textErrors.category : null
    const priceRes = data?.price === undefined || data?.price === '' ? textErrors.price : null
    const vatRes = data?.vat === undefined || data?.vat === '' ? textErrors.vat : null

    setErrors({
      name: nameRes,
      category: catRes,
      price: priceRes,
      vat: vatRes,
    })
  }

  function createNewProduct() {
    if (errors.name === null && errors.category === null && errors.price === null && errors.vat === null) createProduct(theProduct).then(setTimeout(() => window.location.reload(), 250))
  }

  function getCategoriesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getProductCategories(query).then(r => setCategories(r))
  }

  useEffect(() => {
    if (categories === undefined) getCategoriesData()
  }, [theProduct, categories])

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle>Add Product</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Grid container direction='column'>
        <Grid item>
          <ModalItem>
            <TextField
              label='Name'
              variant='outlined'
              id='product-name'
              name='name'
              onChange={e => setProduct({ ...theProduct, name: e.target.value })}
              error={errors.name !== null}
            />
            {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
          </ModalItem>
        </Grid>
        <Grid item>
          <ModalItem>
            <TextField
              label='Description'
              variant='outlined'
              id='product-name'
              name='description'
              onChange={e => setProduct({ ...theProduct, description: e.target.value })}
              //error={errors.name !== null}
            />
            {/*errors.name === null ? '' : <SmallError>{errors.name}</SmallError>*/}
          </ModalItem>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around'>
            <Grid item xs={4}>
              <ModalItem>
                <FormControl variant='outlined'>
                  <InputLabel id='filter-label-role'>Status</InputLabel>
                  <Select
                    label='Status'
                    onChange={value => setProduct({ ...theProduct, status: value.target.value })}
                    //error={errors.role !== null}
                  >
                    <MenuItem value='available'>Available</MenuItem>
                    <MenuItem value='unavailable'>Unavailable</MenuItem>
                  </Select>
                </FormControl>
                {/*errors.role === null ? '' : <SmallError>{errors.role}</SmallError>*/}
              </ModalItem>
            </Grid>
            <Grid item xs={4}>
              <ModalItem>
                <FormControl variant='outlined'>
                  <InputLabel id='filter-label-role'>Category</InputLabel>
                  <Select
                    label='Category'
                    onChange={value => setProduct({ ...theProduct, category: value.target.value })}
                    error={errors.category !== null}
                  >
                    {categories?.categories.map(cat => (
                      <MenuItem key={cat.id} value={cat.id}>
                        {cat.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {errors.category === null ? '' : <SmallError>{errors.category}</SmallError>}
              </ModalItem>
            </Grid>
            <Grid item xs={4}>
              <ModalItem>
                <TextField
                  label='Quantity'
                  type='number'
                  variant='outlined'
                  name='quantity'
                  onChange={e => setProduct({ ...theProduct, quantity: parseInt(e.target.value, 10) })}
                />
              </ModalItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around'>
            <Grid item xs={4}>
              <ModalItem>
                <TextField
                  label='Price'
                  value={theProduct.price}
                  type='number'
                  variant='outlined'
                  name='price'
                  onChange={e => setProduct({ ...theProduct, price: parseInt(e.target.value, 10) })}
                  error={errors.price !== null}
                />
                {errors.price === null ? '' : <SmallError>{errors.price}</SmallError>}
              </ModalItem>
            </Grid>
            <Grid item xs={4}>
              <ModalItem>
                <TextField
                  label='Price Happy Hour'
                  type='number'
                  variant='outlined'
                  name='price_hh'
                  onChange={e => setProduct({ ...theProduct, price_hh: parseInt(e.target.value, 10) })}
                />
              </ModalItem>
            </Grid>
            <Grid item xs={4}>
              <ModalItem>
                <TextField
                  label='VAT type'
                  type='number'
                  variant='outlined'
                  value={theProduct.vat}
                  name='vat'
                  onChange={e => setProduct({ ...theProduct, vat: e.target.value })}
                  error={errors.vat !== null}
                />
                {errors.vat === null ? '' : <SmallError>{errors.vat}</SmallError>}
              </ModalItem>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around'>
            <Grid item>
              <SubmitBtn
                onClick={() => {
                  validation(theProduct)
                  createNewProduct()
                }}
              >
                Create Product
              </SubmitBtn>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateProduct.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateProduct.defaultProps = {
  toClose: () => {},
}

export default ModalCreateProduct
