import { getMember, resetPassword, sendInvitation } from 'api'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Button, Checkbox, CircularProgress, FormControlLabel, Modal, Grid } from '@material-ui/core'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { useTranslation } from 'react-i18next'
import DefaultAvatar from 'components/molecules/defaultAvatar/defaultAvatar'
import { Link, useLocation } from 'react-router-dom'
import ModalCreateMember from 'components/organisms/members/modalCreateMember'
import ModalSchedulerBooking from 'components/organisms/bookings/modalSchedulerBooking'
import ModalCreateMS from 'components/organisms/members/modalCreateMS'
import { format } from 'date-fns'
import { useSnackbar } from 'notistack'
import formatDates from 'components/atoms/formatDates/formatDates'
import ProfileTeamMemberList from './singleTeamMemberList'
import ModalCreateDesk from '../bookings/modalCreateDesk'
import ProfileMembershipList from './singleMembershipList'
import {
  AvatarWrapper,
  BorderBoxColumn,
  BorderBoxRow,
  Column,
  ProfileContainer,
  ProfileDataContent,
  ProfileFirstContent,
  ProfileHeaderContent,
  ProfileImgContent,
  ProfileInfoContent,
  ProfileInput,
  ProfileInputBold,
  ProfileInputBoldExtra,
  ProfileInputBoldHeader,
  ProfileInputDer,
  ProfileMembershipContent,
  ProfileTeamContent,
  ProfileTitle,
  ResendInvitationButton,
  ResetButton,
  Row,
  StatusOff,
  StatusOn,
} from './profileStyle'
import SingleOrdersList from './singleOrdersList'
import SingleBookingList from '../bookings/singleBookingsList'

function MemberProfile({ office }) {
  const params = useLocation()
  const member = params.state
  const [memberData, setDataMember] = useState()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  function getDataMember(slug) {
    getMember(slug).then(aMember => setDataMember(aMember))
  }

  useEffect(() => {
    console.log(params.pathname)
    const slugArray = params.pathname.split('/')
    console.log(member)
    const slug = slugArray[2]
    console.log('Slug is ', slug)
    getDataMember(slug)
  }, [])

  const noStyle = {
    textDecoration: 'none',
    color: 'white',
  }
  const [modalMember, setModalMember] = useState(false)

  function handleMemberClose() {
    setModalMember(false)
  }

  async function sendResetPassword() {
    const response = await resetPassword(memberData?.member?.user?.email)
    if (response.status === 201) {
      enqueueSnackbar(t('memberProfile.resetPassSuccess'), {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(t('memberProfile.resetPassError'), {
        variant: 'error',
      })
    }
  }

  async function resendInvitation() {
    const response = await sendInvitation(memberData.member?.slug)
    if (response.status === 201) {
      enqueueSnackbar(t('memberProfile.resendInvitationSuccess'), {
        variant: 'success',
      })
    } else {
      enqueueSnackbar(t('memberProfile.resendInvitationError'), {
        variant: 'error',
      })
    }
  }

  const [modalMS, setMSModal] = useState(false)
  const [modalDesk, setDeskModal] = useState(false)
  const [modalRoom, setRoomModal] = useState(false)

  function handleMSClose() {
    setMSModal(false)
  }

  function handleDeskClose() {
    setDeskModal(false)
  }

  function handleRoomClose() {
    setRoomModal(false)
  }

  const resourceData = {
    uploadContent: {
      member,
      team: member ? member?.team : memberData?.team,
    },
    start: formatDates(new Date(), 'start'),
    end: formatDates(new Date()),
  }

  const deskData = {
    theTeam: memberData?.member.team,
    theMember: memberData?.member,
    theOffice: memberData?.member.office,
  }
  console.log('Member info ', memberData)
  if (memberData !== undefined) {
    const m = memberData.member
    const u = memberData.member.user
    let roleName = '-'
    switch (m?.role) {
      case 'team_admin':
        roleName = 'Team Admin'
        break
      case 'team_member':
        roleName = 'Team Member'
        break
      case 'referral':
        roleName = 'Referral'
        break
      case 'pending':
        roleName = 'Activation Pending'
        break
      default:
        roleName = 'Invalid role'
        break
    }
    return (
      <ProfileContainer>
        <Modal open={modalMS === true}>
          <ModalCreateMS toClose={handleMSClose} m={m} t={memberData?.member?.team} office={office} />
        </Modal>
        <Modal open={modalRoom === true}>
          <ModalSchedulerBooking
            closeModal={handleRoomClose}
            office={getDataMember?.member?.office}
            resourceData={resourceData}
          />
        </Modal>
        <Modal open={modalDesk === true}>
          <ModalCreateDesk closeModal={handleDeskClose} deskData={deskData} office={getDataMember?.member?.office} />
        </Modal>
        <Modal open={modalMember} onClose={handleMemberClose}>
          <ModalCreateMember member={m} toClose={handleMemberClose} office={office} />
        </Modal>
        <ProfileFirstContent>
          <ProfileInfoContent>
            <AvatarWrapper>
              <ProfileImgContent>
                {m?.image ? (
                  <img src={m?.image} alt={`${m?.first_name}`} />
                ) : (
                  <DefaultAvatar first={u?.first_name} last={u?.last_name} />
                )}
              </ProfileImgContent>
              <Button onClick={() => setModalMember(true)} variant='outlined' size='small'>
                Edit {u?.is_active}
              </Button>
            </AvatarWrapper>
            <ProfileDataContent>
              <ProfileTitle>
                {u?.first_name} {u?.last_name}
              </ProfileTitle>
              <ProfileInputBold>
                {u?.email} {u?.phone ? `/ ${u?.phone}` : ''}
              </ProfileInputBold>
              <ProfileInput>
                {m?.team?.name}
                {m?.job_title ? `, ${m?.job_title}` : ''}
                {` - ${roleName}`} {m?.role === 'pending' || u?.is_active === false ? <StatusOff /> : <StatusOn />}
              </ProfileInput>
              <ProfileInput>Presence: {m?.presence}</ProfileInput>
              <ProfileInput>
                Last access: {u?.last_login ? format(new Date(u?.last_login), 'dd MMMM hh:ii:ss') : '-'}
              </ProfileInput>
            </ProfileDataContent>
          </ProfileInfoContent>
          <BorderBoxRow>
            <ResetButton onClick={() => sendResetPassword()} variant='outlined' color='secondary' size='small'>
              {t('memberProfile.resetPassword')}
            </ResetButton>
            {!memberData?.user?.validate && (
              <ResendInvitationButton
                onClick={() => resendInvitation()}
                variant='outlined'
                color='secondary'
                size='small'
              >
                {t('memberProfile.resendInvitation')}
              </ResendInvitationButton>
            )}
            <Column>
              <ProfileInputBoldHeader>Application Config</ProfileInputBoldHeader>
              <FormControlLabel
                control={<Checkbox size='small' checked={m?.allow_email === 'YES'} />}
                label={<ProfileInputDer>{t('memberProfile.allowMail')}</ProfileInputDer>}
              />
              <FormControlLabel
                control={<Checkbox size='small' checked={m?.allow_push === 'YES'} />}
                label={<ProfileInputDer>{t('memberProfile.allowPush')}</ProfileInputDer>}
              />
              <FormControlLabel
                control={<Checkbox size='small' checked={m?.auto_assign === 'YES'} />}
                label={<ProfileInputDer>{t('memberProfile.autoAsignDesk')}</ProfileInputDer>}
              />
              <FormControlLabel
                control={<Checkbox size='small' checked={m?.auto_checking === 'YES'} />}
                label={<ProfileInputDer>{t('memberProfile.autoCheckin')}</ProfileInputDer>}
              />
            </Column>
            <BorderBoxColumn>
              <Row>
                <ProfileInputBold>{t('memberProfile.validationLevel')}</ProfileInputBold>
              </Row>
              <Row>
                <ProfileInputBoldExtra>{u?.validated}</ProfileInputBoldExtra>
              </Row>
            </BorderBoxColumn>
            <BorderBoxColumn>
              <Row>
                <ProfileInputBold>{t('memberProfile.onboardingLevel')}</ProfileInputBold>
              </Row>
              <Row>
                <ProfileInputBoldExtra>{u?.onboarding}</ProfileInputBoldExtra>
              </Row>
            </BorderBoxColumn>
          </BorderBoxRow>
        </ProfileFirstContent>
        <ProfileTeamContent>
          <ProfileInputBoldExtra>Team</ProfileInputBoldExtra>
          <Link to={`/members/companies/${m?.team?.slug}`} key={m?.team?.slug} style={noStyle}>
            <ProfileInput>{m?.team?.name}</ProfileInput>
          </Link>
          <ProfileInput>Status:&nbsp;&nbsp;{m?.team?.status === 'active' ? <StatusOn /> : <StatusOff />}</ProfileInput>
          <ProfileTeamMemberList teamId={memberData?.team_mates} />
        </ProfileTeamContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <ProfileInputBoldExtra>Memberships</ProfileInputBoldExtra>
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction='row'>
                  <Grid item>
                    <Button variant='contained' color='secondary' onClick={() => setMSModal(true)}>
                      Add Membership
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary' onClick={() => {}}>
                      <a href={`/members/memberships/member/${getDataMember?.member?.slug}`} style={noStyle}>
                        View All
                      </a>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ProfileHeaderContent>
          <ProfileMembershipList memberships={m?.memberships.slice(0, 5)} showViewAll={m?.memberships.length > 5} />
        </ProfileMembershipContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <ProfileInputBoldExtra>Bookings</ProfileInputBoldExtra>
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction='row'>
                  <Grid item>
                    <Button
                      variant='contained'
                      color='secondary'
                      onClick={() => {
                        setDeskModal(true)
                      }}
                    >
                      Add Booking
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary'>
                      <Link
                        style={noStyle}
                        to={{
                          pathname: '/bookings/list',
                          state: {
                            fromNotifications: true,
                            slug: getDataMember?.member?.slug,
                          },
                        }}
                      >
                        View All
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ProfileHeaderContent>
          <SingleBookingList
            bookings={memberData?.bookings.slice(0, 5)}
            showViewAll={memberData?.bookings.length > 5}
            type='noHead'
          />
        </ProfileMembershipContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <Grid container direction='row' justify='space-between' alignItems='center'>
              <Grid item>
                <ProfileInputBoldExtra>Orders</ProfileInputBoldExtra>
              </Grid>
              <Grid item>
                <Grid container spacing={2} direction='row'>
                  <Grid item>
                    <Button variant='contained' color='secondary' onClick={() => {}} disabled>
                      Add Order
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button variant='contained' color='secondary'>
                      <Link to='/food-and-drinks/orders' style={noStyle}>
                        View All
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </ProfileHeaderContent>
          <SingleOrdersList orders={memberData.orders} type='noHead' />
        </ProfileMembershipContent>
      </ProfileContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

MemberProfile.propTypes = {
  office: PropTypes.object,
}

MemberProfile.defaultProps = {
  office: {},
}

export default MemberProfile
