import { useEffect, useState } from 'react'
import { setInvoiceData, updateInvoiceData } from 'api'
import PropTypes from 'prop-types'
import { Grid, Button, FormControl, TextField, Checkbox } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard } from '../bookings/modalStyles'

function ModalInvoiceData({ toClose, team, invoiceData }) {
  function closeModal() {
    toClose()
  }
  const [theInv, setInv] = useState({
    slug: invoiceData !== null ? invoiceData.slug : null,
    team: invoiceData !== null ? invoiceData.team.slug : team,
    name: invoiceData !== null ? invoiceData.name : '',
    town: invoiceData !== null ? invoiceData.town : '',
    post_code: invoiceData !== null ? invoiceData.post_code : '',
    address_1: invoiceData !== null ? invoiceData.address_1 : '',
    address_2: invoiceData !== null ? invoiceData.address_2 : '',
    vat_number: invoiceData !== null ? invoiceData.vat_number : '',
    company_number: invoiceData !== null ? invoiceData.company_number : '',
    email_contact: invoiceData !== null ? invoiceData.email_contact : '',
    phone_contact: invoiceData !== null ? invoiceData.phone_contact : '',
    person_contact: invoiceData !== null ? invoiceData.person_contact : '',
    default: invoiceData !== null ? invoiceData.default : false,
    xero_id: invoiceData !== null ? invoiceData.xero_id : '',
  })

  useEffect(() => {
    console.log(theInv)
  }, [theInv])

  function createInvData() {
    console.log(theInv)
    setInvoiceData(theInv)
      .then(
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      )
      .catch(e => console.log(e))
  }

  function updateInvData() {
    console.log(theInv)
    updateInvoiceData(theInv, theInv.slug)
      .then(
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      )
      .catch(e => console.log(e))
  }

  function cancelInvoiceData() {
    console.log('set status to inactive')
    updateInvoiceData({ ...theInv, status: 'inactive' }, theInv.slug)
      .then(
        setTimeout(() => {
          window.location.reload()
        }, 2000)
      )
      .catch(e => console.log(e))
  }

  const [errors, setErrors] = useState({
    name: null,
    town: null,
    address_1: null,
    post_code: null,
    email_contact: null,
  })

  useEffect(() => {}, [errors])

  function validateInvData(create) {
    let canCreate = false
    const textErrors = {
      name: 'Name is required',
      town: 'Town is required',
      address_1: 'Address 1 is required',
      post_code: 'Post Code is required',
      email_contact: 'Email Contact is required',
      badMail: 'Must be a valid mail (example@mail.com)',
    }
    const nameRes = theInv.name === null || theInv.name === undefined || theInv.name === '' ? textErrors.name : null
    const townRes = theInv.town === null || theInv.town === undefined || theInv.town === '' ? textErrors.town : null
    const addressRes =
      theInv.address_1 === null || theInv.address_1 === undefined || theInv.address_1 === ''
        ? textErrors.address_1
        : null
    const postCodeRes =
      theInv.post_code === null || theInv.post_code === undefined || theInv.post_code === ''
        ? textErrors.post_code
        : null

    let emailRes =
      theInv.email_contact === null || theInv.email_contact === undefined || theInv.email_contact === ''
        ? textErrors.email_contact
        : null
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const testMail = regex.test(theInv.email_contact)
    emailRes = testMail === false ? textErrors.badMail : null

    setErrors({
      name: nameRes,
      town: townRes,
      address_1: addressRes,
      post_code: postCodeRes,
      email_contact: emailRes,
    })

    if (nameRes === null && townRes === null && addressRes === null && postCodeRes === null && emailRes === null) canCreate = true

    console.log('Create invoice is ', create)
    if (canCreate === true) {
      if (create) createInvData()
      else updateInvData()
    }
  }

  return (
    <ModalCard>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item style={{ fontSize: '20px', fontWeight: 500 }}>
              {invoiceData !== null ? 'Update Invoice Data' : 'Create Invoice Data'}
            </Grid>
            <Grid item>
              <Button variant='outlined' onClick={() => closeModal()}>
                X
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>Address Info</Grid>
        <Grid item>
          <Grid container justify='space-between' alignItems='center' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Name'
                  value={theInv.name}
                  onChange={e => setInv({ ...theInv, name: e.target.value })}
                  error={errors.name !== null}
                />
                {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Town'
                  value={theInv.town}
                  onChange={e => setInv({ ...theInv, town: e.target.value })}
                  error={errors.town !== null}
                />
                {errors.town !== null ? <SmallError>{errors.town}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Post Code'
                  value={theInv.post_code}
                  onChange={e => setInv({ ...theInv, post_code: e.target.value })}
                  error={errors.post_code !== null}
                />
                {errors.post_code !== null ? <SmallError>{errors.post_code}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label='Address 1'
              value={theInv.address_1}
              variant='outlined'
              onChange={e => setInv({ ...theInv, address_1: e.target.value })}
              error={errors.address_1 !== null}
            />
            {errors.address_1 !== null ? <SmallError>{errors.address_1}</SmallError> : ''}
          </FormControl>
        </Grid>
        <Grid item style={{ marginBottom: '25px' }}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label='Address 2'
              value={theInv.address_2}
              variant='outlined'
              onChange={e => setInv({ ...theInv, address_2: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>Contact Info</Grid>
        <Grid item style={{ marginBottom: '25px' }}>
          <Grid container justify='space-between' alignItems='center' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Contact person'
                  value={theInv.person_contact}
                  onChange={e => setInv({ ...theInv, person_contact: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Contact Email'
                  value={theInv.email_contact}
                  onChange={e => setInv({ ...theInv, email_contact: e.target.value })}
                  error={errors.email_contact !== null}
                />
                {errors.email_contact !== null ? <SmallError>{errors.email_contact}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Contact phone'
                  value={theInv.phone_contact}
                  onChange={e => setInv({ ...theInv, phone_contact: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={1}>
              <Grid container direction='column' justify='center' alignItems='center'>
                <Grid item>
                  <Checkbox
                    checked={theInv.default === true}
                    onClick={() =>
                      theInv.default === true
                        ? setInv({ ...theInv, default: false })
                        : setInv({ ...theInv, default: true })
                    }
                  />
                </Grid>
                <Grid item style={{ fontSize: '11px' }}>
                  Default
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>Invoice Info</Grid>
        <Grid item style={{ marginBottom: '25px' }}>
          <Grid container justify='space-between' alignItems='center' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Company Number'
                  value={theInv.company_number}
                  onChange={e => setInv({ ...theInv, company_number: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='VAT Number'
                  value={theInv.vat_number}
                  onChange={e => setInv({ ...theInv, vat_number: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  variant='outlined'
                  label='Xero'
                  value={theInv.xero_id}
                  onChange={e => setInv({ ...theInv, xero_id: e.target.value })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          {invoiceData !== null ? (
            <Grid container justify='space-between'>
              <Grid item style={{ marginTop: '25px' }}>
                <Button
                  variant='contained'
                  onClick={() => cancelInvoiceData()}
                  style={{ color: 'white', backgroundColor: 'red' }}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item style={{ marginTop: '25px' }}>
                <Button variant='contained' color='secondary' onClick={() => validateInvData(false)}>
                  Update Invoice Data
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justify='center'>
              <Grid item style={{ marginTop: '25px' }}>
                <Button variant='contained' color='secondary' onClick={() => validateInvData(true)}>
                  Add Invoice Data
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalInvoiceData.propTypes = {
  toClose: PropTypes.func,
  team: PropTypes.string,
  invoiceData: PropTypes.object,
}

ModalInvoiceData.defaultProps = {
  toClose: () => {},
  team: '',
  invoiceData: {},
}

export default ModalInvoiceData
