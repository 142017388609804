import Typography from '@material-ui/core/Typography'
import styled from 'styled-components'

export const TextStyled = styled(Typography)`
  && {
    font-family: Lato;
    ${({ type }) => {
      if (type === 'title') {
        return `
          font-size: 24px;
          line-heigth: 32px;
          font-weight: 700;
        `
      }
      return `
      font-size: 14px;
      `
    }}
  }
`
