import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getDashboardStaff } from 'api'
import PropTypes from 'prop-types'
import formatWords from 'components/atoms/formatWords/formatWords'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import { BoxItem, BoxItemTitle } from '../invoices/scrollListStyles'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'

function ProfileStaffList({ reloadData }) {
  const [staffData, setStaffData] = useState()

  function getStaffData() {
    console.log('getStaffData')
    getDashboardStaff().then(r => setStaffData(r))
  }

  useEffect(() => {
    getStaffData()
  }, [])

  useEffect(() => {
    console.log('A Change in staff data ', reloadData)
    if (reloadData === true) {
      setStaffData(null)
      getStaffData()
    }
  }, [reloadData])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      {staffData !== undefined && staffData !== null ? (
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxItemTitle>
                <ListItem>Name</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Location</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Email address</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Phone number</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Job Title</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Role</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Status</ListItem>
              </BoxItemTitle>
            </ListHeader>
            {staffData.staff.map((s, i) => (
              <Link to={`/users/staff/${s.slug}`} key={i} style={{ textDecoration: 'none' }}>
                <ListRow>
                  <BoxItem>{`${s.user?.first_name} ${s.user?.last_name}`}</BoxItem>
                  <BoxItem>{s.offices > 0 ? s.offices[0].name : '-'}</BoxItem>
                  <BoxItem>{s.user?.email}</BoxItem>
                  <BoxItem>{s.user?.phone}</BoxItem>
                  <BoxItem>-</BoxItem>
                  <BoxItem>{formatWords(s.role)}</BoxItem>
                  <BoxItem>-</BoxItem>
                </ListRow>
              </Link>
            ))}
          </ListContainer>
        </ScrollView>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </ListContainer>
  )
}

ProfileStaffList.propTypes = {
  reloadData: PropTypes.object.isRequired,
}

export default ProfileStaffList
