import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Button, TextField } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { getOrders, updateOrder, getDashboardOffices } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import routes from 'config/routes'
import { Pagination, Autocomplete } from '@material-ui/lab'
import { DatePicker } from '@material-ui/pickers'
import { ScrollView } from 'devextreme-react'
import { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { colors } from '../../../config/colors'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListSmallItem,
  SpinnerContainer,
  ListItemStatus,
  PaginationContainer,
  PaginationNumbers,
  FilterSelect,
  StateBtn,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemLG, BoxItemTitle, BoxItemTitleLG, BoxRow } from '../invoices/scrollListStyles'

const now = new Date()
function FADOrders({ office }) {
  const [ordersData, setOrdersData] = useState()
  const [paginate, setPaginate] = useState(true)
  const [officesData, setOfficesData] = useState({ offices: [] })
  const [downloadOrders, setDownloadOrders] = useState([])
  const [prepareDownload, setPrepareDownload] = useState(false)
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 15,
    thePage: 1,
    pagesTotal: '',
  })
  const [theFilter, setFilter] = useState({
    name: '',
    order: '-init_date',
    status: '',
    layoutOffice: office.slug,
    office: office?.slug,
    start: new Date(now.getFullYear(), now.getMonth(), 1).toISOString(),
    end: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString(),
  })
  const [changeText, setText] = useState({
    text: null,
    id: null,
  })

  function formatCSV(rawDataInvoice) {
    const proccessedData = []
    rawDataInvoice.map(item => {
      item.order_lines.map(product => {
        proccessedData.push({
          product: product.product.name,
          quantity: product.quantity,
          member: `${item.member?.user?.first_name} ${item.member?.user?.last_name}`,
          resource: item.booking ? item.booking?.resource?.name : '-',
          status: item.status,
          bar: item.bar ? item.bar?.name : '-',
          office: item.bar?.office ? item.bar?.office?.name : '-',
          order_date: `${new Date(item.open_date).toLocaleDateString('en-GB')}  ${new Date(
            item.open_date
          ).toLocaleTimeString('en-GB')}`,
          received_date: `${new Date(item.received_date).toLocaleDateString('en-GB')}  ${new Date(
            item.received_date
          ).toLocaleTimeString('en-GB')}`,
          service_date: `${new Date(item.service_date).toLocaleDateString('en-GB')}  ${new Date(
            item.service_date
          ).toLocaleTimeString('en-GB')}`,
          price_inc_vat: item.price,
        })
        return true
      })
      return true
    })
    return proccessedData
  }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getOrders(query).then(o => {
      setOrdersData(o)
      getPagination(o)
      setPaginate(true)
      setDownloadOrders([])
    })
  }

  function downloadData(filterData) {
    setPrepareDownload(true)
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: 0,
        currentPage: 1,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getOrders(query).then(o => {
      setOrdersData(o)
      getPagination(o)
      setPaginate(true)
      console.log(formatCSV(o.orders))
      setDownloadOrders(formatCSV(o.orders))
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  function filterByBtn(filterData) {
    if (filterData !== undefined) {
      const newValue = filterData
      if (theFilter.status === newValue) {
        setFilter({ ...theFilter, status: '' })
        const query = {
          pageSize: pagination.resultsByPage,
          currentPage: pagination.thePage,
          filter: { ...theFilter, status: '' },
        }
        getData(query)
      } else {
        setFilter({ ...theFilter, status: newValue })
        const query = {
          pageSize: pagination.resultsByPage,
          currentPage: pagination.thePage,
          filter: { ...theFilter, status: newValue },
        }
        getData(query)
      }
    }
  }

  function filterByStart(date) {
    const startValue = new Date(date)
    console.log(startValue.toISOString())
    if (startValue !== '') {
      setFilter({ ...theFilter, start: startValue.toISOString() })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, start: startValue.toISOString() },
      }
      getData(query)
    } else {
      setFilter({ ...theFilter, start: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, start: '' },
      }
      getData(query)
    }
  }

  function filterByEnd(date) {
    const endValue = new Date(date)
    console.log(endValue.toISOString())
    if (endValue !== '') {
      setFilter({ ...theFilter, end: endValue.toISOString() })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, end: endValue.toISOString() },
      }
      getData(query)
    } else {
      setFilter({ ...theFilter, end: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, end: '' },
      }
      getData(query)
    }
  }

  function filterByOffice(event) {
    const typeValue = event
    if (typeValue !== 'all') {
      setFilter({ ...theFilter, office: typeValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, office: typeValue },
      }
      getData(query)
    } else {
      setFilter({ ...theFilter, office: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, office: '' },
      }
      getData(query)
    }
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = ordersData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter, order: theOrder },
    }
    getData(query)
  }

  function changeOrderState(order, value) {
    console.log(order, value)
    const theOrder = order
    const theState = value.toLowerCase()
    theOrder.status = theState
    updateOrder(theOrder.slug, theOrder).then(setTimeout(() => getData(), 500))
  }

  function changeBtnText(i, e, o) {
    if (e.type === 'click') changeOrderState(o, e.target.innerText)
    else if (e.target.innerText === 'Pending' && e.type === 'mouseover' && e.type !== 'click') setText({ ...changeText, text: 'Preparing', id: i })
    else if (e.target.innerText === 'Preparing' && e.type === 'mouseover' && e.type !== 'click') setText({ ...changeText, text: 'Ready', id: i })
    else if (e.target.innerText === 'Ready' && e.type === 'mouseover' && e.type !== 'click') setText({ ...changeText, text: 'Delivered', id: i })
    else if (e.type === 'mouseleave') setText({ ...changeText, text: null, id: null })
    return ''
  }

  useEffect(() => {}, [pagination, changeText])

  useEffect(() => {
    getData()
  }, [theFilter])

  useEffect(() => {
    if (ordersData === undefined) getData()
  }, [ordersData])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: office.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
    getOfficesData()
  }, [office])

  useEffect(() => {
    console.log('Download orders changed ')
    if (downloadOrders.length > 0) setPrepareDownload(false)
    //return () => { <CSVDownload data={downloadOrders} target='_blank' /> }
  }, [downloadOrders])

  const slash = '-'
  const space = ' '
  const noLinkStyle = { textDecoration: 'none' }

  const [btnAllow, setBtnAllow] = useState(false)
  const [btnData, setBtnData] = useState({
    counter: 0,
    allow: false,
    i: null,
    o: null,
  })

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    if (btnAllow === true) {
      const count = btnData.counter + 1
      setBtnData({ ...btnData, counter: count })
    }
    if (btnData.counter > 33) {
      setText({ ...changeText, text: 'Delivered', i: btnData.counter.i })
      changeOrderState(btnData.o, 'Delivered')
      setBtnAllow(false)
      setBtnData({
        counter: 0,
        i: null,
        o: null,
      })
    }
  }, [btnData, btnAllow])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  if (ordersData !== undefined) {
    return (
      <ListContainer>
        <BoxRow style={{ marginTop: '25px' }}>
          <FormControl variant='outlined' style={{ width: '49%', marginRight: '1%' }}>
            <SearchInput
              placeholder='Search by member or team...'
              onChange={value => setSearchFilter({ filter: value.target.value })}
            />
          </FormControl>
          <FormControl variant='outlined' style={{ width: '19%', marginRight: '1%' }}>
            <InputLabel id='select-status'>Status</InputLabel>
            <FilterSelect
              labelId='select-status'
              id='select-status'
              label='Status'
              autoWidth
              variant='outlined'
              onChange={e => filterByBtn(e.target.value)}
            >
              <MenuItem value=''>Active</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
              <MenuItem value='preparing'>Preparing</MenuItem>
              <MenuItem value='ready'>Ready</MenuItem>
              <MenuItem value='paid'>Paid</MenuItem>
              <MenuItem value='cancelled'>Cancelled</MenuItem>
              <MenuItem value='finished'>Finished</MenuItem>
              <MenuItem value='delivered'>Delivered</MenuItem>
              <MenuItem value='paid_and_delivered'>Paid & Delivered</MenuItem>
              <MenuItem value='all'>All</MenuItem>
            </FilterSelect>
          </FormControl>
          <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
            <DatePicker
              value={theFilter?.start}
              onChange={value => {
                if (value !== undefined) {
                  filterByStart(value)
                }
              }}
              name='start'
              variant='inline'
              inputVariant='outlined'
              helperText={null}
              format='dd-MM-yyyy'
              label='From'
              autoOk
            />
          </FormControl>
          <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
            <DatePicker
              value={theFilter?.end}
              onChange={value => {
                if (value !== undefined) {
                  filterByEnd(value)
                }
              }}
              name='to'
              variant='inline'
              inputVariant='outlined'
              helperText={null}
              format='dd-MM-yyyy'
              label='To'
              autoOk
            />
          </FormControl>
          <FormControl style={{ width: '29%', marginRight: '1%' }}>
            <Autocomplete
              name='office'
              options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
              onChange={(e, data) => {
                if (data?.slug) {
                  filterByOffice(data?.slug)
                } else {
                  filterByOffice('all')
                }
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
              defaultValue={office}
            />
          </FormControl>
        </BoxRow>
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitleLG>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('init_date')}>Products</Button>
                    {ordersData?.order === 'products' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitleLG>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('member')}>Member</Button>
                    {ordersData?.order === 'member' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('resource')}>Resource</Button>
                    {ordersData?.order === 'resource' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('resource')}>Club</Button>
                    {ordersData?.order === 'resource' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('init_date')}>Ordered</Button>
                    {ordersData?.order === 'init_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('items')}>Items</Button>
                    {ordersData?.order === 'items' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('price')}>Amount</Button>
                    {ordersData?.order === 'price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button onClick={() => setOrderData('init_date')}>Action</Button>
                    {ordersData?.order === 'init_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {ordersData.orders.map((o, i) => {
              let qty = 0
              o.order_lines.map(ol => {
                qty += ol.quantity
                return ''
              })
              const theDate = o.init_date?.split('T')
              const points = ':'
              const zero = '0'
              let am = 'am'
              const time = theDate[1]?.split('+')
              let theTime = time[0]?.slice(0, 5)
              let hoursPM = parseInt(theTime.slice(0, 2), 10)
              if (hoursPM >= 12) {
                hoursPM -= 12
                am = 'pm'
                theTime = zero + hoursPM.toString() + points + time[0].slice(3, 5)
              }
              let theColor = colors.softgray
              let typeColor = colors.white
              let theName = '-'
              switch (o.status) {
                case 'pending':
                  theColor = colors.orange
                  typeColor = colors.white
                  theName = 'Pending'
                  break
                case 'preparing':
                  theColor = colors.orange
                  typeColor = colors.white
                  theName = 'Preparing'
                  break
                case 'ready':
                  theColor = colors.green
                  typeColor = colors.white
                  theName = 'Ready'
                  break
                case 'paid':
                  theColor = colors.green
                  typeColor = colors.white
                  theName = 'Paid'
                  break
                case 'delivered':
                  theColor = colors.green
                  typeColor = colors.white
                  theName = 'Delivered'
                  break
                case 'finished':
                  theColor = colors.softgray
                  typeColor = colors.gray
                  theName = 'Finished'
                  break
                case 'cancelled':
                  theColor = 'red'
                  typeColor = colors.white
                  theName = 'Cancelled'
                  break
                case 'paid_and_delivered':
                  theColor = colors.green
                  typeColor = colors.white
                  theName = 'Paid&Delivered'
                  break
                case 'over':
                  theName = 'over'
                  break
                default:
                  theColor = colors.softgray
                  typeColor = colors.grey
              }
              return (
                <Link
                  to={`${routes.foodAndDrinks}/${o.slug}`}
                  key={i}
                  style={noLinkStyle}
                  onClick={linkEvent => (linkEvent.target.localName === 'button' ? linkEvent.preventDefault() : '')}
                >
                  <ListRow>
                    <BoxRow>
                      <BoxItemLG>
                        <ListItem>
                          {o.order_lines.map(product => `${product.product.name} (x${product.quantity}), `)}
                        </ListItem>
                      </BoxItemLG>
                      <BoxItem>
                        <ListItem>{`${o.member?.user?.first_name} ${o.member?.user?.last_name}`}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{o.booking ? o.booking?.resource?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{o.bar?.office ? o.bar?.office?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{`${theDate[0]} ${theTime} ${am}`}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{qty}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>£{o.price} </ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItemStatus style={{ backgroundColor: theColor }}>
                          <Grid container justify='center'>
                            <Grid item>
                              <StateBtn
                                style={{ color: typeColor }}
                                onClick={e => {
                                  if (btnData.counter < 33) {
                                    changeBtnText(i, e, o)
                                    setBtnData({ ...btnData, counter: 0 })
                                  }
                                }}
                                onMouseDown={() => {
                                  setBtnAllow(true)
                                  setBtnData({ ...btnData, i, o })
                                }}
                                onMouseUp={() => {
                                  setBtnAllow(false)
                                  setBtnData({ ...btnData, i: null, o: null, counter: 0 })
                                }}
                                onMouseOver={e => changeBtnText(i, e)}
                                onMouseLeave={e => changeBtnText(i, e)}
                              >
                                {changeText.text !== null && changeText.id === i ? changeText.text : theName}
                              </StateBtn>
                            </Grid>
                          </Grid>
                        </ListItemStatus>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })}
          </ListContainer>
        </ScrollView>
        {pagination.thePage === undefined || paginate === false ? (
          <PaginationContainer>
            <CircularProgress color='secondary' />
          </PaginationContainer>
        ) : (
          <PaginationContainer>
            <Pagination
              color='secondary'
              size='small'
              onClick={value => setPagePagination(value)}
              count={pagination.resultsTotal < pagination.resultsByPage ? 1 : pagination.pagesTotal}
              page={parseInt(pagination.thePage, 10)}
            />
            <PaginationNumbers>
              {pagination.resultsByPage * pagination.thePage -
                pagination.resultsByPage +
                slash +
                (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                  ? pagination.resultsByPage * pagination.thePage
                  : pagination.resultsTotal) +
                space}
              of {pagination.resultsTotal}
            </PaginationNumbers>
            {!prepareDownload && downloadOrders.length === 0 && (
              <Button onClick={() => downloadData()}>Export CSV</Button>
            )}
            {prepareDownload && <CircularProgress color='secondary' />}
            {!prepareDownload && downloadOrders.length > 0 && <CSVLink data={downloadOrders}>Download CSV</CSVLink>}
          </PaginationContainer>
        )}
      </ListContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

FADOrders.propTypes = {
  office: PropTypes.object,
}

FADOrders.defaultProps = {
  office: {},
}

export default FADOrders
