import { colors } from 'config/colors'
import styled from 'styled-components'

export const TransferListContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  margin-top: 25px;
  padding: 25px;
  background-color: ${colors.gray};
`
