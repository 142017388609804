import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { colors } from 'config/colors'
import { getTheme } from 'config/theme'
import VectorMap, { Layer, Label, Tooltip, Size } from 'devextreme-react/vector-map'
import { mapDeskItem, mapMRItem, mapSuiteItem, maxDimensios } from '../../../utils/floorMap'

function FloorMap({
  spaces,
  resources,
  workAreas,
  getSelectedDesk,
  setSelectedDesk,
  selectedDesk,
  getPanelInfo,
  asignResToBooking,
  setModalBooking,
  floorMap,
  state,
  mapDimension,
  setUpdateModal,
}) {
  const theme = getTheme()
  const MIN_WIDTH = 720

  function formatDesk() {
    const features = []
    const usedSpaces = spaces !== undefined ? spaces?.bookings : []
    if (resources !== undefined) {
      resources?.resources?.map(res => {
        if (res.resource_type === 'hot_desk' || res.resource_type === 'desk' || res.resource_type === 'bar_table') {
          let nextStatus = 'active'
          usedSpaces?.map(used => {
            res.status = ''
            if (used?.resource?.slug === res?.slug) nextStatus = 'booked'
            return res.status
          })
          selectedDesk?.map(selected => {
            res.status = ''
            if (selected === res?.slug) nextStatus = 'selected'
            return res.status
          })
          res.status = nextStatus
          if (res.resource_type === 'desk') res.status = 'inactive'
          const feature = mapDeskItem(res)
          return features.push(feature)
        }
        if (res.resource_type === 'meeting_room') {
          let nextStatus = 'active'
          selectedDesk?.map(selected => {
            res.status = ''
            if (selected === res?.slug) nextStatus = 'selected'
            return res.status
          })
          res.status = nextStatus
          const feature = mapMRItem(res)
          return features.push(feature)
        }
        return ''
      })
    }
    if (workAreas !== undefined) {
      workAreas?.work_areas?.map(res => {
        if (res.area_type === 'suite') {
          let nextStatus = 'active'
          selectedDesk?.map(selected => {
            res.status = ''
            if (selected === res?.slug) nextStatus = 'selected'
            return res.status
          })
          res.status = nextStatus
          const feature = mapSuiteItem(res)
          return features.push(feature)
        }
        return ''
      })
    }
    const desks = {
      type: 'deskData',
      features,
    }
    return desks
  }

  const [theDesks, setDesks] = useState(formatDesk)

  function customizeLayer(elements) {
    console.log('Cutomize pedro ', elements)
    elements.forEach(element => {
      const status = element.attribute('status')
      const slug = element.attribute('slug')
      selectedDesk.map(resSlug => {
        if (slug === resSlug) element.selected(true)
        return ''
      })
      let theColor = `${colors.white}`
      if (status === 'booked') theColor = theme.palette.secondary.main
      if (status === 'inactive') theColor = `${colors.gray}`
      if (status === 'selected') theColor = `${colors.green}`
      element.applySettings({
        color: theColor,
        hoveredColor: `${colors.orange}`,
        selectedColor: `${colors.green}`,
        fontSize: '10px',
      })
    })
  }

  function fillPanelInfo(target) {
    console.log('Rellena el panel pedro ', target, target?.attribute('type'))
    if (target?.attribute('type') === 'desk') {
      getPanelInfo(target?.attribute('slug'), 'resourceInfo')
    }
    if (target?.attribute('type') === 'meeting_room') {
      console.log('Selecting meeting roon')
    }
  }

  function clickArea(event) {
    const { target } = event
    const localSelected = selectedDesk
    console.log('Selected pedro ', localSelected)
    const theName = target?.attribute('name')
    console.log('Click area pedro ', target?.attribute('name'))
    resources?.resources?.map(res => {
      if (res.name === theName && res.status === 'active') target?.selected(!target?.selected())
      return ''
    })
    if (target?.attribute('type') === 'desk') {
      if (target?.attribute('status') === 'booked') {
        console.log('Filling panel pedro booked')
        spaces.bookings.map(booking => {
          if (booking.resource?.slug === target?.attribute('slug')) {
            setUpdateModal(booking.code)
            console.log('SLug found pedro ', booking.code)
          }
          return null
        })
        return
      }
      if (target?.attribute('status') === 'active') {
        asignResToBooking(target?.attribute('slug'))
        fillPanelInfo(target)
      }
      if (target?.attribute('status') === 'selected') {
        setSelectedDesk(localSelected.filter(item => item !== target?.attribute('slug')))
      }
    }
    if (target?.attribute('type') === 'meeting_room') {
      if (target?.attribute('status') === 'active') {
        asignResToBooking(target?.attribute('slug'))
        //fillPanelInfo(target)
      }
      if (target?.attribute('status') === 'selected') {
        setSelectedDesk(localSelected.filter(item => item !== target?.attribute('slug')))
      }
    }
  }

  function deskSelection(event) {
    if (event?.target?.attribute('status') === 'booked') {
      return
    }
    const selected = event?.target?.selected()
    const deskSlug = event?.target?.attribute('slug')
    if (state === 'selecting') getSelectedDesk(deskSlug, selected)
  }

  useEffect(() => {
    setModalBooking(false)
  }, [spaces])

  useEffect(() => {
    console.log('Change in resources ', resources, selectedDesk)
    setDesks(formatDesk)
  }, [selectedDesk, resources])

  function deskPopOver(arg) {
    const slug = arg?.attribute('slug')
    if (slug !== undefined && spaces.bookings) {
      const theDesk = resources.resources.filter(res => res?.slug === slug)
      const theBooking = spaces.bookings.filter(b => b?.resource?.slug === slug || b?.work_area?.slug === slug)
      const theWa = workAreas.work_areas.filter(res => res?.slug === slug)
      const bookingType = theBooking[0]?.booking_type
      const titleLine = theDesk[0] ? theDesk[0]?.name : theWa[0]?.name
      const memberName =
        theBooking[0]?.member !== null
          ? `${theBooking[0]?.member.user.first_name} ${theBooking[0]?.member.user.last_name}`
          : ''
      let firstLine = ''
      let secondLine = ''
      let thirdLine = ''
      let fourthLine = theBooking[0]?.invoice_line === null ? 'PRE-PAID' : theBooking[0]?.invoice_line.line_price
      let fourthLineColor = colors.grey
      if (theBooking[0]?.invoice_line === null) {
        fourthLineColor = theBooking[0]?.invoice_line === null ? colors.grey : colors.red
      } else {
        fourthLineColor = theBooking[0]?.invoice_line.status === 'paid' ? colors.green : colors.red
      }
      if (theBooking[0]?.membership !== null) {
        if (theBooking[0]?.membership) {
          fourthLineColor = theBooking[0]?.invoice_line?.status === 'paid' ? colors.green : colors.red
          fourthLine = 'PRE-PAID'
        }
      }

      if (bookingType === 'hot_desk') {
        firstLine = theBooking[0]?.team?.name
        secondLine = memberName
        thirdLine = ''
      }
      if (bookingType === 'suite') {
        firstLine = theBooking[0]?.team?.name
        secondLine = memberName
        thirdLine = ''
      }
      return (
        <Grid container direction='column' spacing={1}>
          <Grid item style={{ fontWeight: 'bold' }}>
            {titleLine}
          </Grid>
          {theBooking?.length === 0 ? (
            ''
          ) : (
            <Grid item>
              <Grid container direction='column'>
                <Grid item>
                  <h6 style={{ color: colors.gray }}>{firstLine}</h6>
                </Grid>
                <Grid item>
                  <h6 style={{ color: colors.gray }}>{secondLine}</h6>
                  <h6 style={{ color: colors.gray }}>{thirdLine}</h6>
                </Grid>
                <Grid item>
                  <h6 style={{ color: fourthLineColor }}>{fourthLine}</h6>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      )
    }
    return null
  }
  const maxDimensions = maxDimensios(floorMap)
  const maxDimension = maxDimensions.maxX > maxDimensions.maxY ? maxDimensions.maxX : maxDimensions.maxY
  const mapRatio = mapDimension.width > MIN_WIDTH ? MIN_WIDTH / mapDimension.width : 1
  const mapConversion = maxDimension * mapRatio //(mapDimension.height > mapDimension.width ? mapDimension.height : mapDimension.width) * 2.5
  return (
    <VectorMap
      id='vector-map'
      maxZoomFactor={20}
      onClick={event => clickArea(event)}
      controlBar={{ enabled: true }}
      onSelectionChanged={event => deskSelection(event)}
      redrawOnResize
      wheelEnabled={false}
      projection={{
        to: ([l, lt]) => [l / mapConversion, lt / mapConversion],
        from: ([x, y]) => [x * mapConversion, y * mapConversion],
      }}
    >
      <Tooltip enabled contentRender={deskPopOver} />
      <Size height={mapDimension.height} width={mapDimension.width} />
      <Layer
        dataSource={floorMap}
        hoverEnabled={false}
        name='room'
        borderWidth={2}
        color={colors.softgray}
        borderColor={colors.gray}
      />
      {/*<Layer dataSource={waData} name='rooms' borderWidth={1} borderColor={colors.gray} color='transparent' enabled>
        <Label enabled dataField='name' />
      </Layer>*/}
      <Layer
        dataSource={theDesks}
        name='desks'
        borderWidth={1}
        borderColor={colors.gray}
        customize={customizeLayer}
        selectionMode='multiple'
      >
        <Label enabled dataField='name' />
      </Layer>
    </VectorMap>
  )
}

FloorMap.propTypes = {
  spaces: PropTypes.object,
  resources: PropTypes.object,
  workAreas: PropTypes.object,
  setSelectedDesk: PropTypes.func,
  getSelectedDesk: PropTypes.func,
  asignResToBooking: PropTypes.func,
  selectedDesk: PropTypes.array,
  getPanelInfo: PropTypes.func,
  setModalBooking: PropTypes.bool,
  floorMap: PropTypes.object,
  state: PropTypes.string,
  mapDimension: PropTypes.object,
  setUpdateModal: PropTypes.string,
}

FloorMap.defaultProps = {
  spaces: {},
  resources: {},
  workAreas: {},
  setSelectedDesk: () => {},
  getSelectedDesk: () => {},
  selectedDesk: [],
  getPanelInfo: () => {},
  asignResToBooking: () => {},
  setModalBooking: false,
  floorMap: {},
  state: 'selecting',
  mapDimension: {},
  setUpdateModal: '',
}

export default FloorMap
