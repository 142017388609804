import React, { useEffect, useState } from 'react'
import { FormControl, InputLabel, ListItem, MenuItem, Modal, Select, TextField } from '@material-ui/core'
import {
  bookingSpaceAvailable,
  calculateBookingPrice,
  getDashboardGuests,
  getDashboardOffices,
  getDashboardResources,
  getDashboardWorkAreas,
  getSpaces,
  newGetMembers,
  newGetTeams,
  setBooking,
} from 'api'
import { Autocomplete } from '@material-ui/lab'
import {
  FormCard,
  FormContainer,
  FormContainerRow,
  FormContainerRowStart,
  FormContainerStyledColumn,
  FormContainerStyledRow,
  FormItemColumn,
  PayButton,
  SmallError,
} from 'components/pages/resourcesAdd/formStyle'
import { useHistory } from 'react-router-dom'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { ListRowNoStyle, ListSmallItem, ListTitle } from '../members/newListStyle'
import { ColumnCenter, ModalCard, ModalTitle } from './modalStyles'

function BookingCreate() {
  const [infoPrices, setInfoPrices] = useState()
  const [infoBooking, setInfo] = useState()
  const [theBooking, setBookingData] = useState([])
  const [prices, setPrices] = useState()
  const [showBookings, show] = useState()
  const [showModalConfirmation, showModal] = useState(false)
  const [createBooking, setCreate] = useState()
  const [availableSpaces, setSpaces] = useState()

  const [errors, setErrors] = useState({
    bookingSize: null,
    team: null,
    office: null,
    work_area: null,
    resource: null,
    origin: null,
    start: null,
    finish: null,
    day: null,
  })

  const [teamsData, setTeamsData] = useState()
  const [officesData, setOfficesData] = useState()
  //const [membershipsData, setMemberships] = useState()
  const [dateData, setDate] = useState()
  const [membersData, setMembers] = useState()
  const [workAreaData, setWorkArea] = useState()
  const [guestData, setGuest] = useState()
  const [resourcesByOffice, setResOffice] = useState()

  const [membersRows, setMembersRows] = useState()
  const [resourcesRows, setResourcesRows] = useState()
  const [prepaidsRows, setPrepaidsRows] = useState()
  const [guestsRows, setGuestsRows] = useState()
  const history = useHistory()

  function getSpaceAvailable() {
    const date = infoBooking?.day?.toString().split(' ')
    const t = 'T'
    const secs = ':00'
    const slash = '-'
    if (date[1] === 'Jan') date[1] = '01'
    if (date[1] === 'Feb') date[1] = '02'
    if (date[1] === 'Mar') date[1] = '03'
    if (date[1] === 'Apr') date[1] = '04'
    if (date[1] === 'May') date[1] = '05'
    if (date[1] === 'Jun') date[1] = '06'
    if (date[1] === 'Jul') date[1] = '07'
    if (date[1] === 'Aug') date[1] = '08'
    if (date[1] === 'Sep') date[1] = '09'
    if (date[1] === 'Oct') date[1] = '10'
    if (date[1] === 'Nov') date[1] = '11'
    if (date[1] === 'Dec') date[1] = '12'
    const params = {
      waSlug: infoBooking.work_area,
      dateStart: date[3] + slash + date[1] + slash + date[2] + t + infoBooking.start + secs,
      dateEnd: date[3] + slash + date[1] + slash + date[2] + t + infoBooking.end + secs,
    }
    bookingSpaceAvailable(params).then(response => setSpaces(response))
  }

  function getTeamData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: {
        name: '',
        order: 'name',
      },
    }
    if (info !== undefined) query.filter.name = info
    newGetTeams(query)
      .then(response => setTeamsData(response))
      .catch(error => console.log(error))
  }

  function getOfficesData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: '' },
    }
    if (info !== undefined) query.filter.name = info
    getDashboardOffices(query)
      .then(response => setOfficesData(response.offices))
      .catch(error => console.log(error))
  }
  function getGuestData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: '' },
    }
    if (info !== undefined) query.filter.name = info
    getDashboardGuests(query)
      .then(response => {
        setGuest(response.guests)
        setGuestsRows(response.guests)
      })
      .catch(error => console.log(error))
  }

  function getData() {
    getOfficesData()
    getGuestData()
    if (teamsData === undefined) getTeamData()
  }

  function getMembersData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: '' },
    }
    if (info !== undefined) query.filter.name = info

    newGetMembers(query)
      .then(response => {
        setMembers(response)
        setMembersRows(response.members)
      })
      .catch(error => console.log(error))
  }
  /*
  function getMemberships(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: '' },
    }
    if (info !== undefined) query.filter.name = info
    getDashboardMemberships(query)
      .then(response => setMemberships(response.memberships))
      .catch(error => console.log(error))
  }
  */
  function getResourcesData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: '' },
    }
    if (info !== undefined) query.filter.name = info
    getDashboardResources(query)
      .then(response => {
        setResourcesRows(response.resources)
        //setResource(response.resources)
      })
      .catch(error => console.log(error))
  }

  function getResourcesByOffice() {
    if (resourcesByOffice === undefined) {
      let startData = ''
      let endData = ''
      if (dateData !== undefined && dateData?.day !== undefined) {
        const date = dateData.day?.toString()
        const dateSplit = date?.split(' ')
        const space = '-'
        const t = 'T'
        const secs = ':00'
        if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
        if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
        if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
        if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
        if (dateSplit[1] === 'May') dateSplit[1] = '05'
        if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
        if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
        if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
        if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
        if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
        if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
        if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
        const dateStartData = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + dateData.start + secs
        const dateFinishData = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + dateData.end + secs
        startData = dateStartData
        endData = dateFinishData
      }
      const query = {
        booking_type: infoBooking?.bookingType,
        start: startData,
        end: endData,
        office: infoBooking?.office,
      }
      getSpaces(query)
        .then(response => {
          setResOffice(response)
          setResourcesRows(response.office_resources)
        })
        .catch(error => console.log(error))
    }
  }

  function getWAData(info) {
    const query = {
      pageSize: '100',
      currentPage: 1,
      filter: { name: info },
    }
    getDashboardWorkAreas(query)
      .then(response => setWorkArea(response.work_areas))
      .catch(error => console.log(error))
  }

  function getPrices(data) {
    if (data === 'deference') {
      setPrices({ ...prices, total: 0, pending: 0 })
    }
    if (prices?.prePaids?.length > 0) console.log('hola')
    if (infoPrices !== undefined) {
      let unitPrice = 0
      calculateBookingPrice(infoPrices).then(response => {
        unitPrice = response.full_price / infoPrices.bookingSize
        if (prepaidsRows === undefined) setPrepaidsRows(response.selected_prepaids)
        if (prices === undefined) {
          setPrices({
            prePaids: [],
            total: unitPrice * infoPrices.bookingSize,
            pending: unitPrice * infoPrices.bookingSize,
          })
        }
        if (prices?.prePaids?.length > 0) {
          setPrices({
            ...prices,
            total: unitPrice * infoPrices.bookingSize,
            pending: unitPrice * infoPrices.bookingSize - unitPrice * prices.prePaids.length,
          })
        }
      })
    }
  }

  function SendBooking() {
    setCreate(null)
    let create = false
    const theData = []
    let startData = ''
    let endData = ''
    if (dateData !== undefined) {
      const date = dateData?.day ? dateData.day.toString() : new Date()
      const dateSplit = date?.split(' ')
      const space = '-'
      const t = 'T'
      const secs = ':00'
      if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
      if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
      if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
      if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
      if (dateSplit[1] === 'May') dateSplit[1] = '05'
      if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
      if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
      if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
      if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
      if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
      if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
      if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
      const dateStartData = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + dateData.start + secs
      const dateFinishData = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + dateData.end + secs
      startData = dateStartData
      endData = dateFinishData
    }

    const priceUnit = prices.pending / theBooking.length
    theBooking.map(booking => {
      const deskBooking = {
        start: { dateTime: startData },
        end: { dateTime: endData },
        office: infoPrices.office,
        price: infoBooking.origin === 'deference' ? 0 : priceUnit,
        payNow: false,
        member: booking.member,
        booking_type: 'hot_desk',
        resource: booking.resource,
        pre_paid: booking.prepaid,
        work_area: infoBooking.work_area,
      }
      theData.push(deskBooking)
      return ''
    })

    if (
      errors.bookingSize === null &&
      errors.team === null &&
      errors.office === null &&
      errors.work_area === null &&
      errors.origin === null &&
      errors.day === null
    ) {
      create = true
      showModal(true)
    } else {
      create = false
      showModal(false)
    }
    console.log(create)
    if (create) {
      setBooking(theData)
        .then(response => console.log(response))
        .then(
          setTimeout(() => {
            history.push('/bookings')
          }, 1000)
        )
        .catch(error => console.log(error))
    }
  }

  function setBookingsRowsData(value) {
    const rowsArr = [...theBooking]
    const deskBook = {
      id: value,
      member: '',
      resource: '',
    }
    if (value > 0 && value > rowsArr.length) {
      rowsArr.push(deskBook)
      setBookingData(rowsArr)
    } else {
      rowsArr.pop()
      setBookingData(rowsArr)
    }
  }

  function setDataBookings(id, slug, field) {
    const arr = [...theBooking]

    arr.map((booking, i) => {
      if (booking.id === id && field === 'member') {
        const newBooking = { ...booking, member: slug }
        arr[i] = newBooking
        let members = []
        membersRows.map(m => {
          if (m.slug === slug) {
            members = membersRows.filter(mem => mem.slug !== m.slug)
            setMembersRows(members)
          }
          return ''
        })
      }

      if (booking.id === id && field === 'resource') {
        const newBooking = { ...booking, resource: slug }
        arr[i] = newBooking
        let resources = []
        resourcesRows.map(res => {
          if (res.slug === slug) {
            resources = resourcesRows.filter(r => r.slug !== res.slug)
            setResourcesRows(resources)
          }
          return ''
        })
      }

      if (booking.id === id && field === 'prepaid') {
        const newBooking = { ...booking, prepaid: slug }
        arr[i] = newBooking
        let prepaidsArr = []
        prepaidsRows.map(pre => {
          if (pre.code === slug) {
            prepaidsArr = prepaidsRows.filter(p => p.code !== pre.code)
            setPrepaidsRows(prepaidsArr)
          }
          return ''
        })
      }

      if (booking.id === id && field === 'guest') {
        const newBooking = { ...booking, guest: slug }
        arr[i] = newBooking
        let guestsArr = []
        guestsRows.map(g => {
          if (g.slug === slug) {
            guestsArr = guestsRows.filter(p => p.slug !== g.slug)
            setGuestsRows(guestsArr)
          }
          return ''
        })
      }
      return ''
    })
    setBookingData(arr)
  }

  function validateBooking(data) {
    const textErrors = {
      bookingSize: 'Must be a positive number',
      team: 'Team is required',
      office: 'Office is required',
      work_area: 'Work Area is required',
      resource: 'Resource is required',
      origin: 'Origin is required',
      start: 'Start date is required',
      end: 'Finish date is required',
      day: 'Booking day is required',
    }

    const sizeRes =
      data?.bookingSize === undefined || data?.bookingSize === '' || data?.bookingSize <= 0
        ? textErrors.bookingSize
        : null
    const teamRes = data?.team === undefined || data?.team === '' ? textErrors.team : null
    const officeRes = data?.office === undefined || data?.office === '' ? textErrors.office : null
    const waRes = data?.work_area === undefined || data?.work_area === '' ? textErrors.work_area : null
    const resRes = data?.resource === undefined || data?.resource === '' ? textErrors.resource : null
    const originRes = data?.origin === undefined || data?.origin === '' ? textErrors.origin : null
    const startRes = data?.start === undefined || data?.start === '' ? textErrors.start : null
    const endRes = data?.end === undefined || data?.end === '' ? textErrors.end : null
    const dayRes = data?.day === undefined || data?.day === '' ? textErrors.day : null

    setErrors({
      bookingSize: sizeRes,
      team: teamRes,
      office: officeRes,
      work_area: waRes,
      resource: resRes,
      origin: originRes,
      start: startRes,
      end: endRes,
      day: dayRes,
    })
  }

  const handleClose = () => {
    showModal(false)
  }

  function checkFields() {
    if (
      infoBooking?.work_area !== undefined &&
      infoBooking.start !== undefined &&
      infoBooking.end !== undefined &&
      infoBooking.day !== undefined &&
      availableSpaces === undefined
    ) {
      getSpaceAvailable()
    }
    if (teamsData === undefined) getData()
    if (infoPrices?.team !== undefined && infoPrices?.office !== undefined && infoPrices?.bookingType !== undefined) {
      getPrices()
    }
    if (
      theBooking.length > 0 &&
      errors.bookingSize === null &&
      errors.team === null &&
      errors.office === null &&
      errors.work_area === null &&
      errors.origin === null
    ) {
      show(null)
      getResourcesByOffice()
    }
    if (infoBooking?.team !== undefined && infoBooking?.origin !== undefined && infoBooking?.office !== undefined) {
      show(null)
      getResourcesByOffice()
      getPrices()
    }
  }

  useEffect(() => {
    console.log(theBooking)
    console.log(infoBooking)
    console.log(infoPrices)
    checkFields()
  }, [
    infoPrices,
    teamsData,
    guestData,
    officesData,
    membersData,
    theBooking,
    infoBooking,
    resourcesRows,
    prepaidsRows,
    dateData,
    errors,
    guestsRows,
    resourcesByOffice,
    showBookings,
    membersRows,
    availableSpaces,
  ])

  const styledRow = {
    maxWidth: '300px',
    padding: '0 auto',
    width: '250px',
  }
  const styledNarrowRow = {
    width: '300px',
    margin: '5px 15px',
  }
  const styledSmallNarrowRow = {
    width: '100%',
    minWidth: '200px',
    margin: '5px 15px',
  }
  const formControl = {
    marginTop: '25px',
    marginRight: '15px',
  }
  const confirmModal = (
    <ModalCard>
      <ColumnCenter>
        <ModalTitle>Booking Successfull</ModalTitle>
      </ColumnCenter>
    </ModalCard>
  )

  return (
    <FormContainer>
      {showModalConfirmation === false ? (
        ''
      ) : (
        <Modal open={confirmModal} onClose={handleClose}>
          {confirmModal}
        </Modal>
      )}
      <FormContainerRowStart>
        <ListTitle style={{ width: 'auto' }}>Create Booking</ListTitle>
      </FormContainerRowStart>
      <FormContainerStyledRow style={{ paddingBottom: '25px' }}>
        <FormControl style={formControl}>
          <Autocomplete
            name='autocompleteTeam'
            id='autocomplete_team'
            options={teamsData?.teams}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
            onChange={value => getTeamData(value.target.innerText)}
            onInputChange={value => {
              teamsData?.teams.map(team => {
                if (team.name === value.target.innerText) {
                  setInfoPrices({ ...infoPrices, team: team.slug })
                  setInfo({ ...infoBooking, team: team.slug, teamName: team.name })
                  validateBooking({ ...infoBooking, team: team.slug })
                  getMembersData()
                  //getMemberships()
                  getResourcesData()
                }
                return ''
              })
              if (value.target.innerText === undefined) {
                setInfo({ ...infoBooking, team: '', teamName: '' })
                setInfoPrices({ ...infoPrices, team: '' })
                validateBooking({ ...infoBooking, team: '' })
              }
            }}
            style={styledRow}
          />
          {createBooking === undefined || errors.team === null ? '' : <SmallError>{errors.team}</SmallError>}
        </FormControl>
        <FormControl style={formControl}>
          <TextField
            id='number_desk_id'
            name='numberDesk'
            label='Number of Desk'
            variant='outlined'
            type='number'
            min={0}
            style={styledRow}
            onChange={value => {
              setBookingsRowsData(value.target.value)
              setInfoPrices({ ...infoPrices, bookingSize: value.target.value })
              setInfo({ ...infoBooking, bookingSize: value.target.value })
              validateBooking({ ...infoBooking, bookingSize: value.target.value })
              setPrices()
            }}
            onWheel={e => e.target.blur()}
          />
          {createBooking === undefined || errors.bookingSize === null ? (
            ''
          ) : (
            <SmallError>{errors.bookingSize}</SmallError>
          )}
        </FormControl>

        <FormControl style={formControl}>
          <Autocomplete
            disabled={officesData === undefined ? true : ''}
            name='autocompleteOffice'
            id='autocomplete_office'
            options={officesData}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
            onChange={event => getOfficesData(event.target.innerText)}
            onInputChange={value => {
              officesData?.map(office => {
                if (office.name === value.target.innerText) {
                  getWAData(office.name)
                  setInfoPrices({ ...infoPrices, office: office.slug })
                  setInfo({ ...infoBooking, office: office.slug, officeName: value.target.innerText })
                  validateBooking({ ...infoBooking, office: office.slug })
                }
                return ''
              })
              if (value.target.innerText === undefined) {
                setInfoPrices({ ...infoPrices, office: '' })
                setInfo({ ...infoBooking, office: '', officeName: '' })
                validateBooking({ ...infoBooking, office: '' })
              }
            }}
            style={styledRow}
          />
          {createBooking === undefined || errors.office === null ? '' : <SmallError>{errors.office}</SmallError>}
        </FormControl>
        <FormControl style={formControl}>
          <Autocomplete
            disabled={workAreaData === undefined || infoBooking?.bookingType === 'meeting_room' ? true : ''}
            name='autocompleteWA'
            id='autocomplete_wa'
            options={workAreaData}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Work Area' variant='outlined' />}
            onChange={value => {
              workAreaData?.map(wa => {
                getWAData(value.target.innerText)
                if (wa.name === value.target.innerText) {
                  setInfo({ ...infoBooking, work_area: wa.slug, waName: wa.name })
                  validateBooking({ ...infoBooking, work_area: wa.slug })
                }
                return ''
              })
              if (value.target.innerText === undefined) {
                setInfo({ ...infoBooking, work_area: '', waName: '' })
                validateBooking({ ...infoBooking, work_area: '' })
              }
            }}
            style={styledRow}
          />
          {createBooking === undefined || errors.work_area === null ? '' : <SmallError>{errors.work_area}</SmallError>}
        </FormControl>
        <FormControl style={formControl}>
          <InputLabel style={{ marginLeft: '15px' }}>Origin</InputLabel>
          <Select
            id='origin_select_id'
            label='Origin'
            variant='outlined'
            style={styledRow}
            onChange={value => {
              if (value.target.value) {
                if (value.target.value === 'Deference') setPrices(value.target.value)
                setInfo({ ...infoBooking, origin: value.target.value })
                validateBooking({ ...infoBooking, origin: value.target.value })
              }
            }}
          >
            <MenuItem value='regular'>Regular</MenuItem>
            <MenuItem value='deference'>Deference</MenuItem>
            <MenuItem value='referral'>Referral</MenuItem>
            <MenuItem value='trial'>Trial</MenuItem>
          </Select>
          {createBooking === undefined || errors.origin === null ? '' : <SmallError>{errors.origin}</SmallError>}
        </FormControl>
        <FormControl style={formControl}>
          <KeyboardDatePicker
            inputVariant='outlined'
            label='Date'
            value={infoBooking?.day}
            format='dd-MM-yyyy'
            onChange={value => {
              setDate({ ...dateData, day: value })
              setInfo({ ...infoBooking, day: value })
              validateBooking({ ...infoBooking, day: value })
            }}
          />
          {createBooking === undefined || errors.day === null ? '' : <SmallError>{errors.day}</SmallError>}
        </FormControl>
        <FormControl style={formControl}>
          <TextField
            label='Start Date'
            disabled={infoBooking?.day === undefined}
            type='time'
            value={infoPrices?.bookingType === 'hot_desk' ? '09:00' : dateData?.start}
            variant='outlined'
            style={styledRow}
            onChange={value => {
              if (value !== undefined) {
                setInfo({ ...infoBooking, start: value.target.value })
                validateBooking({ ...infoBooking, start: value.target.value })
                setDate({ ...dateData, start: value.target.value })
              }
            }}
          />
          {createBooking === undefined || errors.start === null || infoPrices?.bookingType === 'hot_desk' ? (
            ''
          ) : (
            <SmallError>{errors.start}</SmallError>
          )}
        </FormControl>
        <FormControl style={formControl}>
          <TextField
            label='Finish Date'
            disabled={infoBooking?.day === undefined}
            type='time'
            value={infoPrices?.bookingType === 'hot_desk' ? '18:00' : dateData?.end}
            variant='outlined'
            style={styledRow}
            onChange={value => {
              if (value !== undefined) {
                setInfo({ ...infoBooking, end: value.target.value })
                validateBooking({ ...infoBooking, end: value.target.value })
                setDate({ ...dateData, end: value.target.value })
              }
            }}
          />
          {createBooking === undefined || errors.end === null || infoPrices?.bookingType === 'hot_desk' ? (
            ''
          ) : (
            <SmallError>{errors.end}</SmallError>
          )}
        </FormControl>
      </FormContainerStyledRow>
      {showBookings === undefined ? (
        ''
      ) : (
        <FormContainerStyledColumn>
          <FormCard>
            <FormContainerRow>
              <FormItemColumn>
                <PayButton onClick={() => SendBooking()}>Book for {prices?.pending} £</PayButton>
              </FormItemColumn>
              <FormItemColumn>
                <PayButton onClick={() => SendBooking()}>Book it!</PayButton>
              </FormItemColumn>
            </FormContainerRow>
          </FormCard>
          {infoPrices?.bookingType !== 'hot_desk' ? (
            <FormContainerRowStart>
              <ListTitle style={{ width: 'auto' }}>Meeting Room Booking</ListTitle>
            </FormContainerRowStart>
          ) : (
            <FormContainerRowStart>
              <ListTitle style={{ width: '100%' }}>Desks Bookings</ListTitle>
              <ListItem>Free Desks: {availableSpaces?.office_resources?.length}</ListItem>
            </FormContainerRowStart>
          )}
          {infoPrices?.bookingType === 'meeting_room' ? (
            <ListRowNoStyle style={{ height: '75px' }}>
              <ListSmallItem>
                {teamsData.teams.map(t => (t.slug === infoBooking.team ? t.name : ''))} Meeting Room
              </ListSmallItem>
              <ListSmallItem>Space: {infoPrices?.bookingSize}</ListSmallItem>
              <ListSmallItem>
                {workAreaData?.map(wa => (wa.slug === infoBooking.work_area ? wa.name : ''))}
              </ListSmallItem>
              <FormControl>
                <Autocomplete
                  disabled={resourcesRows === undefined ? true : ''}
                  name='autocompleteResource'
                  id='autocomplete_res'
                  options={resourcesRows?.filter(
                    res =>
                      res.resource_type === 'meeting_room' &&
                      res.office.slug === infoBooking.office &&
                      res.work_area.slug === infoBooking.work_area
                  )}
                  getOptionLabel={option => `${option.name} ${option.resource_type}             ${option.slug}`}
                  renderOption={option => <h5>{`${option.name}`}</h5>}
                  renderInput={params => <TextField {...params} label='Resource' variant='outlined' />}
                  onChange={(event, option) => {
                    if (event !== undefined && option !== undefined) {
                      setInfo({ ...infoBooking, resource: option?.slug })
                    } else {
                      setInfo({ ...infoBooking, resource: '' })
                    }
                  }}
                  style={styledNarrowRow}
                />
                {/*createBooking === undefined || errors.resource === null ? '' : <SmallError>{errors.resource}</SmallError>*/}
              </FormControl>
            </ListRowNoStyle>
          ) : (
            theBooking.map((booking, i) => (
              <ListRowNoStyle key={i}>
                <ListItem style={{ width: '25px' }}>{booking.id}</ListItem>
                <FormControl>
                  <Autocomplete
                    disabled={membersRows === undefined ? 'true' : ''}
                    name='autocompleteMember'
                    id='autocomplete_members'
                    options={membersRows.filter(m => m.team.slug === infoBooking.team)}
                    getOptionLabel={option =>
                      `${option.user.first_name} ${option.user.last_name}                 ${option.slug}`
                    }
                    renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                    renderOption={option => <h5>{`${option.user.first_name} ${option.user.last_name}`}</h5>}
                    onInputChange={(event, option) => {
                      if (event.target.innerText !== undefined) {
                        const format = option?.split(' ')
                        setDataBookings(booking.id, format[format.length - 1], 'member')
                      }
                    }}
                    style={styledSmallNarrowRow}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    name='autocompleteGuest'
                    disabled={guestData === undefined ? true : ''}
                    id='autocomplete_guest'
                    options={guestsRows}
                    getOptionLabel={option => option.name}
                    renderInput={params => <TextField {...params} label='Guest' variant='outlined' />}
                    onChange={(event, option) => {
                      if (event !== undefined && option !== undefined) {
                        setDataBookings(booking.id, option?.slug, 'guest')
                      }
                    }}
                    style={styledSmallNarrowRow}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    disabled={resourcesRows === undefined ? true : ''}
                    name='autocompleteResource'
                    id='autocomplete_res'
                    options={resourcesRows?.filter(
                      res =>
                        res.resource_type === 'hot_desk' &&
                        res.office.slug === infoBooking.office &&
                        res.work_area.slug === infoBooking.work_area
                    )}
                    getOptionLabel={option => `${option.name} Desk                      ${option.slug}`}
                    renderOption={option => <h5>{`${option.name}`}</h5>}
                    renderInput={params => <TextField {...params} label='Resource' variant='outlined' />}
                    onChange={(event, option) => {
                      if (event !== undefined && option !== undefined && option !== null) {
                        setDataBookings(booking.id, option.slug, 'resource')
                      }
                    }}
                    style={styledSmallNarrowRow}
                  />
                </FormControl>
                <FormControl>
                  <Autocomplete
                    disabled={prepaidsRows === undefined ? true : ''}
                    name='autocompletePrepaids'
                    id='autocomplete_prepaids'
                    options={prepaidsRows === undefined ? '' : prepaidsRows}
                    getOptionLabel={option => `${option.single_price} Pre Paid Desk             ${option.code}`}
                    renderOption={option => <h5>{`Desk: ${option.single_price}`}</h5>}
                    renderInput={params => <TextField {...params} label='Price' variant='outlined' />}
                    onChange={(event, option) => {
                      if (event !== undefined && option !== undefined && option !== null) {
                        setDataBookings(booking.id, option.code, 'prepaid')
                        const arr = prices.prePaids
                        arr.push(option.code)
                        setPrices({ ...prices, prePaids: arr })
                        getPrices()
                      }
                    }}
                    style={styledSmallNarrowRow}
                  />
                </FormControl>
              </ListRowNoStyle>
            ))
          )}
        </FormContainerStyledColumn>
      )}
    </FormContainer>
  )
}

export default BookingCreate
