import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import StatItem from 'components/atoms/statItem'

export const Container = styled(Paper)`
  && {
    display: grid;
    padding: 16px 24px;
    border-radius: 8px;
    border: solid 1px ${({ theme }) => theme.palette.grey[300]};
    min-height: 300px;
    -webkit-box-shadow: 0 0 5px 0 ${({ theme }) => theme.palette.grey[300]};
    box-shadow: 0 0 5px 0 ${({ theme }) => theme.palette.grey[300]};
  }
`

export const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: 40px;
`
export const Title = styled(Typography)`
  && {
    font-size: 16px;
    font-weight: bold;
  }
`
export const Content = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-areas:
    'main main'
    'first delta'
    'second second-delta';
  column-gap: 20px;
  row-gap: 20px;
`
export const MainContent = styled(StatItem)`
  grid-area: main;
`

export const FirstContent = styled(StatItem)`
  grid-area: first;
`
export const DeltaItem = styled(StatItem)`
  grid-area: delta;
`
export const SecondContent = styled(StatItem)`
  grid-area: second;
`
export const SecondDeltaItem = styled(StatItem)`
  grid-area: second-delta;
`

export const Footer = styled.div`
  margin-top: 39px;
  display: flex;
  flex-direction: row-reverse;
`
export const GoToSectionLink = styled(Link)`
  && {
    text-decoration: none;
  }
`

export const ArrowGoTo = styled(ArrowForwardIosIcon)`
  && {
    font-size: 8px;
  }
`

export const GoToSection = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-weight: 700;
    font-size: 14px;
    text-decoration: none;
  }
`
