import styled from 'styled-components'
import FormLabel from '@material-ui/core/FormLabel'
import FormControl from '@material-ui/core/FormControl'
import Text from 'components/atoms/text'

export const ThumbsContainer = styled.aside`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 16px;
  padding: 20px;
`

export const FormControlStyled = styled(FormControl)`
  display: flex;
  flex-direction: column;
`

export const Thumb = styled.div`
  position: relative;
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: border-box;
`

export const ThumbInner = styled.div`
  display: flex;
  min-width: 0;
  overflow: hidden;
`

export const ThumbImage = styled.img`
  display: block;
  width: auto;
  height: 100%;
`

export const FormLabelStyled = styled(FormLabel)`
  && {
    margin-bottom: 16px;
    font-size: 14px;
  }
`

export const DropArea = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: dashed 1px ${({ theme }) => theme.palette.grey[500]};
  padding: ${({ oneImage }) => (oneImage ? '0px' : '25px')};
  text-align: center;
  cursor: pointer;
  border-radius: 4px;
`

export const BgImage = styled.img`
  max-width: 100%;
`

export const PlaceholderTextStyled = styled(Text)`
  && {
    font-size: 12px;
    margin-top: 11px;
  }
`
