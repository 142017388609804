import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createWorkArea, getDashboardOffices } from 'api'
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem, Checkbox, Modal } from '@material-ui/core'
import { ModalTitle, SubmitBtn } from 'components/organisms/bookings/modalStyles'
import { EndContainer } from 'components/organisms/members/profileStyle'
import { Autocomplete } from '@material-ui/lab'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import DropzoneInput from 'components/atoms/dropzonetInput'
import Text from 'components/atoms/text/view'
import { PlaceholderIcon } from 'components/organisms/themeForm/styled'
import { SmallError } from './formStyle'

function ResourcesAddWA() {
  const { t } = useTranslation()
  const history = useHistory()
  const { control } = useForm()
  const [theWA, setWAData] = useState({
    bookable: false,
    visible_app: false,
    visible_web: false,
    visible_space: false,
    office: null,
  })
  const [theOffices, setOffices] = useState()
  const [errors, setErrors] = useState({
    name: null,
    size: null,
    status: null,
    office: null,
    floor: null,
    area_type: null,
    item_price_day: null,
    full_price_day: null,
    item_price_month: null,
    full_price_month: null,
  })

  const [feedback, setFeedback] = useState(false)

  function createNewWA() {
    setFeedback(true)
    createWorkArea(theWA).then(setTimeout(() => history.push('../space/work_areas'), 2000))
  }

  function validation(data) {
    let canCreate = false
    const textErrors = {
      name: 'Work Area name is required',
      size: 'Work Area size is required',
      status: 'Work Area status is required',
      office: 'Work Area office is required',
      floor: 'Work Area floor is required',
      area_type: 'Work Area type is required',
      item_price_day: 'Work Area item price for day is required',
      full_price_day: 'Work Area full price for day is required',
      item_price_month: 'Work Area item price for month is required',
      full_price_month: 'Work Area full price for month is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const sizeRes = data?.size === undefined || data?.size === '' ? textErrors.size : null
    const statusRes = data?.status === undefined || data?.status === '' ? textErrors.status : null
    const officeRes =
      data?.office === undefined || data?.office === '' || data?.office === null ? textErrors.office : null
    const floorRes = data?.floor === undefined || data?.floor === '' ? textErrors.floor : null
    const typeRes = data?.area_type === undefined || data?.area_type === '' ? textErrors.area_type : null
    const ipdRes = data?.item_price_day === undefined || data?.item_price_day === '' ? textErrors.item_price_day : null
    const fpdRes = data?.full_price_day === undefined || data?.full_price_day === '' ? textErrors.full_price_day : null
    const ipmRes =
      data?.item_price_month === undefined || data?.item_price_month === '' ? textErrors.item_price_month : null
    const fpmRes =
      data?.full_price_month === undefined || data?.full_price_month === '' ? textErrors.full_price_month : null

    setErrors({
      name: nameRes,
      size: sizeRes,
      status: statusRes,
      office: officeRes,
      floor: floorRes,
      area_type: typeRes,
      item_price_day: ipdRes,
      full_price_day: fpdRes,
      item_price_month: ipmRes,
      full_price_month: fpmRes,
    })

    if (
      nameRes === null &&
      sizeRes === null &&
      statusRes === null &&
      officeRes === null &&
      floorRes === null &&
      typeRes === null &&
      fpdRes === null &&
      fpmRes === null &&
      ipdRes === null &&
      ipmRes === null
    ) canCreate = true

    if (canCreate === true) createNewWA()
  }

  function setChecks(value) {
    switch (value) {
      case 'bookable':
        return theWA.bookable === false
          ? setWAData({ ...theWA, bookable: true })
          : setWAData({ ...theWA, bookable: false })
      case 'visible_app':
        return theWA.visible_app === false
          ? setWAData({ ...theWA, visible_app: true })
          : setWAData({ ...theWA, visible_app: false })
      case 'visible_web':
        return theWA.visible_web === false
          ? setWAData({ ...theWA, visible_web: true })
          : setWAData({ ...theWA, visible_web: false })
      case 'visible_space':
        return theWA.visible_space === false
          ? setWAData({ ...theWA, visible_space: true })
          : setWAData({ ...theWA, visible_space: false })
      default:
        return ''
    }
  }

  function getOfficesData() {
    const query = { pageSize: 15, currentPage: 1, filter: {} }
    getDashboardOffices(query).then(r => setOffices(r))
  }

  useEffect(() => {
    if (theOffices === undefined) getOfficesData()
    console.log(theWA)
  }, [theWA, errors])

  const officeFloors = theOffices?.offices?.filter(o => o.slug === theWA.office)
  console.log(control)
  return (
    <Grid container direction='column' spacing={3}>
      <Modal open={feedback === true}>
        <FeedbackModal type='wa-created' />
      </Modal>
      <Grid item>
        <Grid container alignItems='center' justify='space-between'>
          <ModalTitle>Create Work Area</ModalTitle>
          <EndContainer>
            <SubmitBtn onClick={() => validation(theWA)}>Create Work Area</SubmitBtn>
          </EndContainer>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Grid container direction='column' alignItems='center' spacing={1}>
                  <Grid item>
                    <h5>Work Area Picture</h5>
                  </Grid>
                  <Grid item style={{ marginTop: '10px' }}>
                    <DropzoneInput
                      name='wa-picture'
                      control={control}
                      icon={<PlaceholderIcon />}
                      placeholderText={t('profile.theme.loginPlaceholder')}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='column' alignItems='center' spacing={1}>
                  <Grid item>
                    <h5>Work Area Floor Map</h5>
                  </Grid>
                  <Grid item style={{ marginTop: '10px' }}>
                    <DropzoneInput
                      name='wa-map'
                      control={control}
                      icon={<PlaceholderIcon />}
                      placeholderText={t('profile.theme.loginPlaceholder')}
                      onAccept={e => console.log(e)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={9}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label='Name'
                      variant='outlined'
                      id='wa_name'
                      name='name'
                      onChange={value => setWAData({ ...theWA, name: value.target.value })}
                      style={{ width: '100%' }}
                      error={errors.name !== null}
                    />
                    {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        name='autocomplete_office'
                        options={theOffices !== undefined ? theOffices.offices : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField {...params} label='Office' variant='outlined' error={errors.office !== null} />
                        )}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setWAData({ ...theWA, office: data.slug })
                          else setWAData({ ...theWA, office: null })
                        }}
                        error={errors.office !== null}
                      />
                      {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        disabled={theWA.office === null}
                        name='autocomplete_floor'
                        options={officeFloors !== undefined ? officeFloors[0]?.floors : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Floor'
                            variant='outlined'
                            error={theWA.office !== null && errors.floor !== null}
                          />
                        )}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setWAData({ ...theWA, floor: data.slug })
                          else setWAData({ ...theWA, floor: null })
                        }}
                        error={theWA.office !== null && errors.floor !== null}
                      />
                    </FormControl>
                    {theWA.office !== null && errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='row' justify='space-around' spacing={2}>
                  <Grid item xs={3}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel error={errors.area_type !== null}>Area Type</InputLabel>
                      <Select
                        label='Area Type'
                        onChange={value => setWAData({ ...theWA, area_type: value.target.value })}
                        error={errors.area_type !== null}
                      >
                        <MenuItem value='open_space'>Open Space</MenuItem>
                        <MenuItem value='suite'>Private Office/Suite</MenuItem>
                        <MenuItem value='outdoor'>Outdoor</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.area_type !== null ? <SmallError>{errors.area_type}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel error={errors.status !== null}>Status</InputLabel>
                      <Select
                        label='Status'
                        onChange={value => setWAData({ ...theWA, status: value.target.value })}
                        error={errors.status !== null}
                      >
                        <MenuItem value='active'>Active</MenuItem>
                        <MenuItem value='inactive'>Inactive</MenuItem>
                        <MenuItem value='pending'>Pending</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      label='Size'
                      variant='outlined'
                      id='size'
                      name='size'
                      value={theWA?.size}
                      onChange={e =>
                        e.target.value > 0
                          ? setWAData({ ...theWA, size: e.target.value })
                          : setWAData({ ...theWA, size: 1 })
                      }
                      style={{ width: '100%' }}
                      error={errors.size !== null}
                    />
                    {errors.size !== null ? <SmallError>{errors.size}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      label='Order'
                      variant='outlined'
                      id='order'
                      name='order'
                      value={theWA?.order}
                      onChange={e =>
                        e.target.value >= 0
                          ? setWAData({ ...theWA, order: e.target.value })
                          : setWAData({ ...theWA, order: 0 })
                      }
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label='Description'
                  variant='outlined'
                  id='wa_description'
                  name='description'
                  multiline
                  maxRows={5}
                  rows={5}
                  onChange={value => setWAData({ ...theWA, description: value.target.value })}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={3}>
                    <TextField
                      label='Full Price Day'
                      variant='outlined'
                      id='full_price_day'
                      name='full_price_day'
                      type='number'
                      value={theWA.full_price_day === undefined ? null : theWA.full_price_day}
                      onChange={value =>
                        setWAData({
                          ...theWA,
                          full_price_day: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                        })
                      }
                      style={{ width: '100%' }}
                      error={errors.full_price_day !== null}
                    />
                    {errors.full_price_day !== null ? <SmallError>{errors.full_price_day}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      label='Item Price Day'
                      variant='outlined'
                      id='item_price_day'
                      name='item_price_day'
                      value={theWA.item_price_day === undefined ? null : theWA.item_price_day}
                      onChange={value =>
                        setWAData({
                          ...theWA,
                          item_price_day: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                        })
                      }
                      style={{ width: '100%' }}
                      error={errors.item_price_day !== null}
                    />
                    {errors.item_price_day !== null ? <SmallError>{errors.item_price_day}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      label='Full Price Month'
                      variant='outlined'
                      id='full_price_month'
                      name='full_price_month'
                      value={theWA.full_price_month === undefined ? null : theWA.full_price_month}
                      onChange={value =>
                        setWAData({
                          ...theWA,
                          full_price_month: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                        })
                      }
                      style={{ width: '100%' }}
                      error={errors.full_price_month !== null}
                    />
                    {errors.full_price_month !== null ? <SmallError>{errors.full_price_month}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      type='number'
                      label='Item Price Month'
                      variant='outlined'
                      id='item_price_month'
                      name='item_price_month'
                      value={theWA.item_price_month === undefined ? null : theWA.item_price_month}
                      onChange={value =>
                        setWAData({
                          ...theWA,
                          item_price_month: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                        })
                      }
                      style={{ width: '100%' }}
                      error={errors.item_price_month !== null}
                    />
                    {errors.item_price_month !== null ? <SmallError>{errors.item_price_month}</SmallError> : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='row'>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Text>Bookable</Text>
                      <EndContainer>
                        <Checkbox name='bookable' onChange={e => setChecks(e.target.name)} />
                      </EndContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Text>Visible App</Text>
                      <EndContainer>
                        <Checkbox onChange={e => setChecks(e.target.name)} name='visible_app' />
                      </EndContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Text>Visible Web</Text>
                      <EndContainer>
                        <Checkbox onChange={e => setChecks(e.target.name)} name='visible_web' />
                      </EndContainer>
                    </Grid>
                  </Grid>
                  <Grid item xs={3}>
                    <Grid container direction='row' justify='center' alignItems='center'>
                      <Text>Visible Space</Text>
                      <EndContainer>
                        <Checkbox onChange={e => setChecks(e.target.name)} name='visible_space' />
                      </EndContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResourcesAddWA
