import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Container from './container'

const DateInput = ({ control, rules, name, label, ...props }) => {
  if (control) {
    return (
      <Controller
        render={({ value, onChange, field }) => (
          <Container label={label} onChange={onChange} value={value} {...props} {...field} />
        )}
        name={name}
        control={control}
        rules={rules}
      />
    )
  }
  return <Container label={label} {...props} />
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  name: PropTypes.string,
}

DateInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  rules: {},
  control: null,
  name: null,
}

export default DateInput
