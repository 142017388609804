import * as yup from 'yup'

const validate = yup.object().shape({
  code: yup.string().required(),
  offer_type: yup.string().required(),
  // title: yup.string().required(),
  // subtitle: yup.string().required(),
  // description: yup.string().required(),
  // offices: yup.array().min(1),
  // offer_start_0: yup.string().required(),
  // offer_start_1: yup.string().required(),
  // offer_end_0: yup.string().required(),
  // offer_end_1: yup.string().required(),
})

export default validate
