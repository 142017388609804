import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { useTranslation } from 'react-i18next'
import { createCL, getDashboardOffices, getWorkAreasForOffice, getResourcesForOffice } from 'api'
import { TimePicker, DatePicker } from '@material-ui/pickers'
import { Grid, TextField, FormControl, Select, InputLabel, MenuItem, Modal } from '@material-ui/core'
import { ModalTitle, SubmitBtn } from 'components/organisms/bookings/modalStyles'
import { EndContainer } from 'components/organisms/members/profileStyle'
import { Autocomplete } from '@material-ui/lab'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { SmallError } from './formStyle'

function ResourcesAddCL() {
  const { t } = useTranslation()
  const history = useHistory()
  const { control } = useForm()
  const [theCL, setCLData] = useState({
    name: null,
    code: null,
    status: 'active',
    date_from: null,
    date_to: null,
    list_order: null,
    office: null,
    work_area: null,
    floor: null,
    resource: null,
    time: null,
    dayWeek: [],
    week: [],
    recurrent: true,
    recurrent_time: null,
    recurrency: null,
  })
  const [theOffices, setOffices] = useState()
  const [theWorkAreas, setWorkAreas] = useState([])
  const [theResources, setResources] = useState([])
  const [errors, setErrors] = useState({
    name: null,
    size: null,
    status: null,
    office: null,
    floor: null,
  })

  const daysWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  const recurrency = ['First Week', 'Second Week', 'Third Week', 'Forth Week', 'Last Week']

  const [feedback, setFeedback] = useState(false)

  function createNewCL() {
    setFeedback(true)
    console.log(theCL)
    createCL(theCL).then(setTimeout(() => history.push('../space/checklists'), 1000))
  }

  function validation(data) {
    let canCreate = false
    const textErrors = {
      name: 'Name is required',
      status: 'Status is required',
      office: 'Club is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const statusRes = data?.status === undefined || data?.status === '' ? textErrors.status : null
    const officeRes =
      data?.office === undefined || data?.office === '' || data?.office === null ? textErrors.office : null

    setErrors({
      name: nameRes,
      status: statusRes,
      office: officeRes,
    })

    if (nameRes === null && statusRes === null && officeRes === null) canCreate = true

    if (canCreate === true) createNewCL()
  }

  function getOfficesData() {
    const query = { pageSize: 15, currentPage: 1, filter: {} }
    getDashboardOffices(query).then(r => setOffices(r))
  }

  function getDataWorkAreas() {
    if (theCL.office !== '') {
      console.log('Looking for work area ', theCL.office)
      getWorkAreasForOffice(theCL.office)
        .then(workAreas => setWorkAreas(workAreas))
        .catch(error => console.log(error))
    }
  }

  function getDataResources() {
    if (theCL.office !== '') {
      getResourcesForOffice(theCL.office)
        .then(resources => setResources(resources))
        .catch(error => console.log(error))
    }
  }

  useEffect(() => {
    if (theOffices === undefined) getOfficesData()
    console.log(theCL)
  }, [theCL, errors])

  useEffect(() => {
    if (theCL.office !== null) {
      getDataWorkAreas()
      getDataResources()
    }
  }, [theCL.office])

  const officeFloors = theOffices?.offices?.filter(o => o.slug === theCL.office)
  console.log(control)
  return (
    <Grid container direction='column' spacing={3}>
      <Modal open={feedback === true}>
        <FeedbackModal type='cl-created' />
      </Modal>
      <Grid item>
        <Grid container alignItems='center' justify='space-between'>
          <ModalTitle>Create Check List</ModalTitle>
          <EndContainer>
            <SubmitBtn onClick={() => validation(theCL)}>Create Check List</SubmitBtn>
          </EndContainer>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={4}>
                    <TextField
                      label='Name'
                      variant='outlined'
                      id='cl_name'
                      name='name'
                      onChange={value => setCLData({ ...theCL, name: value.target.value })}
                      style={{ width: '100%' }}
                      error={errors.name !== null}
                    />
                    {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      label='Code'
                      variant='outlined'
                      id='cl_code'
                      name='code'
                      onChange={value => setCLData({ ...theCL, code: value.target.value })}
                      style={{ width: '100%' }}
                      error={errors.name !== null}
                    />
                    {errors.code !== null ? <SmallError>{errors.code}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel error={errors.status !== null}>Status</InputLabel>
                      <Select
                        label='Status'
                        onChange={value => setCLData({ ...theCL, status: value.target.value })}
                        error={errors.status !== null}
                        defaultValue='active'
                      >
                        <MenuItem value='active'>Active</MenuItem>
                        <MenuItem value='inactive'>Inactive</MenuItem>
                        <MenuItem value='pending'>{t('status.pendingC')}</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <Grid container direction='row' justify='space-around' spacing={2}>
                  <Grid item xs={4}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <DatePicker
                        value={theCL?.date_from}
                        name='From'
                        inputVariant='outlined'
                        helperText={null}
                        format='dd-MM-yyyy'
                        onChange={value => setCLData({ ...theCL, date_from: value })}
                        label='From'
                        autoOk
                      />
                      {errors.size !== null ? <SmallError>{errors.size}</SmallError> : ''}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <DatePicker
                        value={theCL?.date_to}
                        name='To'
                        inputVariant='outlined'
                        helperText={null}
                        format='dd-MM-yyyy'
                        onChange={value => setCLData({ ...theCL, date_to: value })}
                        label='To'
                        autoOk
                      />
                      {errors.size !== null ? <SmallError>{errors.size}</SmallError> : ''}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      type='number'
                      label='Order'
                      variant='outlined'
                      id='list_order'
                      name='list_order'
                      value={theCL?.order}
                      onChange={e =>
                        e.target.value >= 0
                          ? setCLData({ ...theCL, list_order: e.target.value })
                          : setCLData({ ...theCL, list_order: 0 })
                      }
                      style={{ width: '100%' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <TextField
                  label='Description'
                  variant='outlined'
                  id='cl_description'
                  name='description'
                  multiline
                  maxRows={5}
                  rows={5}
                  onChange={value => setCLData({ ...theCL, description: value.target.value })}
                  style={{ width: '100%' }}
                />
              </Grid>
              <Grid item>
                <Grid container direction='row' justify='space-around' spacing={2}>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        name='autocomplete_office'
                        options={theOffices !== undefined ? theOffices.offices : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField {...params} label='Office' variant='outlined' error={errors.office !== null} />
                        )}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setCLData({ ...theCL, office: data.slug })
                          else setCLData({ ...theCL, office: null })
                        }}
                        error={errors.office !== null}
                      />
                      {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        disabled={theCL.office === null}
                        name='autocomplete_floor'
                        options={officeFloors !== undefined ? officeFloors[0]?.floors : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => (
                          <TextField
                            {...params}
                            label='Floor'
                            variant='outlined'
                            error={theCL.office !== null && errors.floor !== null}
                          />
                        )}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setCLData({ ...theCL, floor: data.slug })
                          else setCLData({ ...theCL, floor: null })
                        }}
                        error={theCL.office !== null && errors.floor !== null}
                      />
                    </FormControl>
                    {theCL.office !== null && errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        disabled={theCL.office === null}
                        name='autocomplete_work_area'
                        options={theWorkAreas !== undefined ? theWorkAreas : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label='Work Area' variant='outlined' />}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setCLData({ ...theCL, work_area: data.slug })
                          else setCLData({ ...theCL, work_area: null })
                        }}
                      />
                    </FormControl>
                    {theCL.office !== null && errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        disabled={theCL.office === null}
                        name='autocomplete_floor'
                        options={theResources !== undefined ? theResources : [{ name: 'No data' }]}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label='Resource' variant='outlined' />}
                        onChange={(e, data) => {
                          if (e.target.innerText !== undefined) setCLData({ ...theCL, resource: data.slug })
                          else setCLData({ ...theCL, resource: null })
                        }}
                      />
                    </FormControl>
                    {theCL.office !== null && errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                  </Grid>
                </Grid>
              </Grid>
              {/* Recurrency section */}
              <Grid item>
                <Grid container direction='row' spacing={2}>
                  <Grid item xs={3}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel>Recurrent</InputLabel>
                      <Select
                        label='Recurrent'
                        onChange={value => setCLData({ ...theCL, area_type: value.target.value })}
                      >
                        <MenuItem value='true'>Yes</MenuItem>
                        <MenuItem value='false'>No</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel>Day in week</InputLabel>
                      <Select
                        label='Day in week'
                        onChange={value => setCLData({ ...theCL, dayWeek: value.target.value })}
                        multiple
                        value={theCL.dayWeek}
                      >
                        {daysWeek.map(dayName => (
                          <MenuItem key={dayName} value={dayName}>
                            {dayName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl variant='outlined' style={{ width: '100%' }}>
                      <InputLabel>Week in month</InputLabel>
                      <Select
                        label='Week in month'
                        onChange={value => setCLData({ ...theCL, week: value.target.value })}
                        multiple
                        value={theCL.week}
                      >
                        {recurrency.map(recurrentName => (
                          <MenuItem key={recurrentName} value={recurrentName}>
                            {recurrentName}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={3}>
                    <FormControl style={{ width: '100%' }}>
                      <TimePicker
                        label='Time'
                        inputVariant='outlined'
                        name='startTime'
                        minutesStep={5}
                        value={theCL?.time}
                        renderInput={params => <TextField {...params} variant='outlined' />}
                        onChange={value => setCLData({ ...theCL, time: value })}
                      />
                    </FormControl>
                    {errors.full_price_month !== null ? <SmallError>{errors.full_price_month}</SmallError> : ''}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default ResourcesAddCL
