import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { clientSignUp } from 'api'
import bgLogin from 'assets/images/bgLogin.png'
import routes from 'config/routes'
import {
  Button,
  FormControl,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  OutlinedInput,
  InputLabel,
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import slugify from 'react-slugify'
//import Visibility from '@materil-ui/icons/Visibility'
//import VisibilityOff from '@materil-ui/icons/VisibilityOff'
import { SmallError } from '../resourcesAdd/formStyle'
import { Container, Title, Wrapper } from '../login/styled'

function SignUp() {
  const history = useHistory()
  const [info, setInfo] = useState({
    name: '',
    surname: '',
    company: '',
    workspace: '',
    email: '',
    password: '',
  })

  useEffect(() => {}, [info])

  const [errors, setErrors] = useState({
    name: null,
    surname: null,
    company: null,
    workspace: null,
    email: null,
    password: null,
  })

  useEffect(() => {}, [errors])

  function signUpMember() {
    const clientInfo = {
      email: info.email,
      password: info.password,
      full_name: `${info.name} ${info.surname}`,
      first_name: info.name,
      last_name: info.surname,
      company: info.company,
      workspace: info.workspace,
    }
    console.log(clientInfo)
    clientSignUp(clientInfo).then(r => {
      console.log(r)
      if (r.status === 201) history.push(routes.checkMail)
      if (r === 'email_already_registered') console.log('email exists')
    })
  }

  function validate(data) {
    let canSingUp = false
    const textErrors = {
      name: 'Name is required',
      surname: 'Surname is required',
      company: 'Company name is required',
      workspace: 'Workspace name is required',
      email: 'Email is required',
      password: 'Password is required',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const surnameRes = data?.surname === undefined || data?.surname === '' ? textErrors.surname : null
    const companyRes = data?.company === undefined || data?.company === '' ? textErrors.company : null
    const workspaceRes = data?.workspace === undefined || data?.workspace === '' ? textErrors.workspace : null
    const emailRes = data?.email === undefined || data?.email === '' ? textErrors.email : null
    const passwordRes = data?.password === undefined || data?.password === '' ? textErrors.password : null

    setErrors({
      name: nameRes,
      surname: surnameRes,
      company: companyRes,
      workspace: workspaceRes,
      email: emailRes,
      password: passwordRes,
    })
    if (
      nameRes === null &&
      surnameRes === null &&
      companyRes === null &&
      workspaceRes === null &&
      emailRes === null &&
      passwordRes === null
    ) canSingUp = true
    if (canSingUp === true) signUpMember()
  }

  const [values, setValues] = useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    showPassword: false,
  })

  const handleChange = prop => event => {
    setValues({ ...values, [prop]: event.target.value })
  }

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    })
  }

  const handleMouseDownPassword = event => {
    event.preventDefault()
  }

  return (
    <Container style={{ backgroundImage: `url(${bgLogin})` }}>
      <Wrapper elevation={0}>
        <Grid container direction='column' alignItems='center' justify='center' spacing={2}>
          <Grid xs={12} item style={{ textAlign: 'center', marginTop: '100px' }}>
            <Grid container direction='column' spacing={1} style={{ marginBottom: '10px' }}>
              <Grid item>
                <Title variant='h1'>Sign Up</Title>
              </Grid>
              {/*<Grid item>
                <h4>Hey XXXX, YYYY from ZZZZ has invited you to sign up for your very own account.</h4>
              </Grid>*/}
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <Grid container justify='space-between' spacing={2}>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    variant='outlined'
                    label='Name'
                    onChange={e => setInfo({ ...info, name: e.target.value })}
                    error={errors.name !== null}
                  />
                  {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    variant='outlined'
                    label='Surname'
                    onChange={e => setInfo({ ...info, surname: e.target.value })}
                    error={errors.surname !== null}
                  />
                  {errors.surname !== null ? <SmallError>{errors.surname}</SmallError> : ''}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Company'
                onChange={e => setInfo({ ...info, company: e.target.value, workspace: slugify(e.target.value) })}
                error={errors.company !== null}
              />
              {errors.company !== null ? <SmallError>{errors.company}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Work Space'
                onChange={e => setInfo({ ...info, workspace: e.target.value })}
                error={errors.workspace !== null}
                value={info.workspace}
              />
              {errors.workspace !== null ? <SmallError>{errors.workspace}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                autoComplete='off'
                label='E-Mail'
                onChange={e => setInfo({ ...info, email: e.target.value })}
                error={errors.email !== null}
              />
              {errors.email !== null ? <SmallError>{errors.email}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: '100%' }}>
            <FormControl style={{ width: '100%' }} variant='outlined'>
              <InputLabel htmlFor='outlined-adornment-password'>Password</InputLabel>
              <OutlinedInput
                style={{ width: '100%' }}
                id='outlined-adornment-password'
                type={values.showPassword ? 'text' : 'password'}
                value={info.password}
                onChange={e => {
                  handleChange('password')
                  setInfo({ ...info, password: e.target.value })
                }}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label='Password'
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} style={{ width: '100%', margin: '0 auto' }}>
            <Button variant='contained' color='secondary' style={{ width: '100%' }} onClick={() => validate(info)}>
              Sign Up
            </Button>
          </Grid>
          <Grid item xs={12} style={{ width: '100%', margin: '0 auto' }}>
            <Button variant='contained' style={{ backgroundColor: 'black', color: 'white', width: '100%' }}>
              <Link to={routes.login} style={{ textDecoration: 'none', color: 'white' }}>
                Already hace an account? Log in
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default SignUp
