import { OutlinedInput, Select, TextField } from '@material-ui/core'
import { colors } from 'config/colors'
import styled from 'styled-components'

export const MapContainer = styled.div`
  width: 100%;
`
export const FormContainer = styled.div`
  width: 100%;
`
export const FormContainerRow = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const FormContainerStyledRow = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  border-bottom: 1px solid grey;
  flex-wrap: wrap;
`
export const FormContainerStyledColumn = styled.div`
  width: 100%;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-bottom: 1px solid grey;
`

export const FormCard = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  margin: 15px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  font-family: 'lato';
  font-weight: 500;
  font-size: 18px;
`
export const FormCardList = styled.div`
  border: 1px solid grey;
  border-radius: 5px;
  margin: 15px;
  display: flex;
  flex-flow: row wrap;
  font-family: 'lato';
  font-weight: 500;
  font-size: 18px;
  width: 100%;
`

export const ResourceSection = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`

export const ResourceVerticalSection = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
`

export const FormHeader = styled.div`
  display: flex;
  padding-right: 50px;
  flex-direction: row;
  justify-content: space-between;
`

export const FormInput = styled(OutlinedInput)``
export const FormCountInput = styled(OutlinedInput)``
export const FormCountInputSmall = styled(OutlinedInput)`
  height: 35px;
`
export const FormSelect = styled(Select)``
export const FormItem = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
`
export const FormItemColumn = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
`

export const SmallError = styled.small`
  margin: 5px 0 0 2px;
  color: red;
  font-family: 'lato';
`
export const FormSmallInput = styled(OutlinedInput)`
  height: 35px;
`

export const FormItemRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-family: 'lato';
`
export const TextSmallField = styled(TextField)``
export const BookingCard = styled.div`
  width: 150px;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 10px;
  margin: 5px;
`
export const PayButton = styled.button`
  background-color: ${colors.orange};
  padding: 18px;
  font-family: 'lato';
  font-size: 30px;
  font-weight: 700;
  color: white;
  border: 1px solid white;
  border-radius: 5px;
  &:hover {
    background-color: ${colors.gray};
  }
`
export const FormContainerRowStart = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  font-family: 'lato';
  margin-left: 15px;
  font-size: 24px;
  border-bottom: 1px solid ${colors.orange};
  padding: 15px 0;
`
