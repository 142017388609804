import React, { useEffect, useState } from 'react'
import { newGetTeams } from 'api'
import { Button, CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function TeamsList({ layoutOffice }) {
  const [paginate, setPaginate] = useState(true)
  const [theTeams, setTeams] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'name',
    layoutOffice: layoutOffice.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none' }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getDataTeams(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    newGetTeams(query).then(teams => {
      setTeams(teams)
      getPagination(teams)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getDataTeams(query)
    setPaginate(false)
  }

  function setDataFilter(filterData) {
    const letters = filterData.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getDataTeams(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = theTeams?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter, order: theOrder },
    }
    getDataTeams(query)
  }

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getDataTeams(query)
  }

  useEffect(() => {}, [pagination, theFilter])

  useEffect(() => {
    if (theTeams === undefined) getDataTeams()
  }, [theTeams])

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // CALL TIMEOUTS
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  const space = ' '
  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by text...'
          onChange={e => setSearchFilter({ ...searchFilter, filter: e.target.value })}
        />
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Team</strong>
                  </Button>
                  {theTeams?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {theTeams?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Office</strong>
                  </Button>
                  {theTeams?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('members')}>
                    <strong>Members</strong>
                  </Button>
                  {theTeams?.order === 'members' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {theTeams === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            theTeams.teams.map((team, index) => {
              let prettyStatus = '-'
              if (team.status === 'active') prettyStatus = 'Active'
              if (team.status === 'inactive') prettyStatus = 'Inactive'
              return (
                <Link to={`/members/companies/${team.slug}`} key={team.slug} style={noLinkStyle}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{team?.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{prettyStatus}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{team?.office ? team?.office?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{team?.members}</ListItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

TeamsList.propTypes = {
  layoutOffice: PropTypes.object,
}

TeamsList.defaultProps = {
  layoutOffice: null,
}

export default TeamsList
