import React, { useEffect, useState } from 'react'
import { getDashboardResources } from 'api'
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { ScrollView } from 'devextreme-react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import formatWords from 'components/atoms/formatWords/formatWords'
import { Button, CircularProgress, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import {
  FilterSelect,
  ListContainer,
  ListHeader,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function ResourcesList({ layoutOffice }) {
  const [paginate, setPaginate] = useState(true)
  const [resourcesData, setResourcesData] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'name',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none' }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getResourcesData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    console.log('Looking for spaces ', query)
    getDashboardResources(query).then(response => {
      setResourcesData(response)
      getPagination(response)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getResourcesData(query)
    setPaginate(false)
  }
  function setDataFilter(filterData) {
    const letters = filterData.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, name: letters },
    }
    getResourcesData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = resourcesData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getResourcesData(query)
  }

  useEffect(() => {
    console.log('Changing resource data')
    if (resourcesData === undefined) getResourcesData()
  }, [resourcesData])

  useEffect(() => {
    console.log('Changing filter')
    getResourcesData()
  }, [theFilter])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getResourcesData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  const space = ' '

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log(searchFilter)
      if (searchFilter !== undefined) {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 400)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by text...'
            onChange={e => setSearchFilter({ ...searchFilter, filter: e.target.value })}
          />
        </BoxRow>
        <BoxRow>
          <FormControl variant='outlined' style={{ width: '100%', marginRight: '10px' }}>
            <InputLabel>Status</InputLabel>
            <FilterSelect
              label='Status'
              name='status'
              variant='outlined'
              style={{ width: '100%' }}
              onChange={e => setFilter({ ...theFilter, status: e.target.value })}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </FilterSelect>
          </FormControl>
          <FormControl variant='outlined' style={{ width: '100%', marginRight: '10px' }}>
            <InputLabel>Type</InputLabel>
            <FilterSelect
              label='Type'
              name='resource_type'
              variant='outlined'
              style={{ width: '100%' }}
              onChange={e => setFilter({ ...theFilter, resource_type: e.target.value })}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='meeting_room'>Meeting Room</MenuItem>
              <MenuItem value='hot_desk'>Hot Desk</MenuItem>
              <MenuItem value='desk'>Dedicated Desk</MenuItem>
            </FilterSelect>
          </FormControl>
        </BoxRow>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('name')}>
                  <strong>Name</strong>
                </Button>
                {resourcesData?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('resource_type')}>
                  <strong>Type</strong>
                </Button>
                {resourcesData?.order === 'resource_type' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('status')}>
                  <strong>Status</strong>
                </Button>
                {resourcesData?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('price_day')}>
                  <strong>Price hour (VAT in)</strong>
                </Button>
                {resourcesData?.order === 'price_day' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('price_day')}>
                  <strong>Price day (VAT in)</strong>
                </Button>
                {resourcesData?.order === 'price_day' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <Button onClick={() => setOrderData('office__name')}>
                  <strong>Office</strong>
                </Button>
                {resourcesData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('work_area__name')}>
                    <strong>Work Area</strong>
                  </Button>
                  {resourcesData?.order === 'work_area__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('resource_unit__name')}>
                    <strong>Unit</strong>
                  </Button>
                  {resourcesData?.order === 'resource_unit__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {resourcesData === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            resourcesData.resources?.map((resource, index) => (
              <Link to={`/resources/${resource.slug}`} key={index} style={noLinkStyle}>
                <ListRow>
                  <BoxRow>
                    <BoxItem>{resource?.name}</BoxItem>
                    <BoxItem>{formatWords(resource.resource_type)}</BoxItem>
                    <BoxItem>{formatWords(resource.status)}</BoxItem>
                    <BoxItem>{resource?.price_hour}</BoxItem>
                    <BoxItem>{resource?.price_day}</BoxItem>
                    <BoxItem>{resource?.office?.name ? resource?.office?.name : '-'}</BoxItem>
                    <BoxItem>{resource?.work_area?.name ? resource?.work_area?.name : '-'}</BoxItem>
                    <BoxItem>{resource?.resource_unit?.name ? resource?.resource_unit?.name : '-'}</BoxItem>
                  </BoxRow>
                </ListRow>
              </Link>
            ))
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

ResourcesList.propTypes = {
  layoutOffice: PropTypes.object,
}

ResourcesList.defaultProps = {
  layoutOffice: null,
}

export default ResourcesList
