import { CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { getStockList } from 'api'
import { ScrollView } from 'devextreme-react'
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import {
  ListContainer,
  ListHeader,
  ListItem,
  SpinnerContainer,
  ListRow,
  PaginationContainer,
  PaginationNumbers,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function FADStock({ office }) {
  const [stockData, setStockData] = useState()
  const [paginate, setPaginate] = useState(true)
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const [theFilter, setFilter] = useState({
    name: '',
    order: '',
    layoutOffice: office?.slug,
  })

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }
  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: 10,
        currentPage: 1,
        filter: {
          layoutOffice: office.slug,
        },
      }
    } else {
      query = filterData
    }
    getStockList(query).then(o => {
      getPagination(o)
      setStockData(o)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  useEffect(() => {
    if (stockData === undefined) getData()
  }, [stockData])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: office.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [office])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // RENDER

  const slash = '-'
  const space = ' '

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  if (stockData !== undefined) {
    return (
      <ListContainer>
        <BoxRow style={{ marginTop: '25px' }}>
          <SearchInput
            placeholder='Search by member or team...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>Product(s)</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Category</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Location</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ color: 'black' }}>Price</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem style={{ color: 'black' }}>Quantity</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Status</ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {stockData.products.map((p, i) => (
              <ListRow key={i}>
                <BoxRow>
                  <BoxItem>
                    <ListItem>{p.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{p.category?.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{office.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem style={{ color: 'black' }}>{p.price}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem style={{ color: 'black' }}>{p.order_in_list}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{p.status}</ListItem>
                  </BoxItem>
                </BoxRow>
              </ListRow>
            ))}
          </ListContainer>
        </ScrollView>
        {pagination.thePage === undefined || paginate === false ? (
          <PaginationContainer>
            <CircularProgress color='secondary' />
          </PaginationContainer>
        ) : (
          <PaginationContainer>
            <Pagination
              color='secondary'
              size='small'
              onClick={value => setPagePagination(value)}
              count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
              page={parseInt(pagination.thePage, 10)}
            />
            <PaginationNumbers>
              {pagination.resultsByPage * pagination.thePage -
                pagination.resultsByPage +
                slash +
                (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                  ? pagination.resultsByPage * pagination.thePage
                  : pagination.resultsTotal) +
                space}
              of {pagination.resultsTotal}
            </PaginationNumbers>
          </PaginationContainer>
        )}
      </ListContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

FADStock.propTypes = {
  office: PropTypes.object,
}

FADStock.defaultProps = {
  office: {},
}

export default FADStock
