import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import noAvatar from '../../../assets/images/noAvatarDefaultImg.png'
import { ImageTeamMate, ProfileInputSmall, TeamMateCardContainer } from './profileStyle'

function TeamMateCard(props) {
  const { mate } = props
  const space = ' '
  const theName = mate?.user?.first_name + space + mate?.user?.last_name
  const thisLinkStyle = {
    textDecoration: 'none',
    color: 'black',
  }
  return (
    <Link to={{ pathname: `/members/${mate.slug}`, state: mate }} style={thisLinkStyle}>
      <TeamMateCardContainer>
        <ImageTeamMate src={mate?.user?.avatar ? mate.user.avatar : noAvatar} />
        <ProfileInputSmall>{theName}</ProfileInputSmall>
        <ProfileInputSmall>{mate?.job_title ? mate.job_title : '...'}</ProfileInputSmall>
      </TeamMateCardContainer>
    </Link>
  )
}

TeamMateCard.propTypes = {
  mate: PropTypes.object,
}

TeamMateCard.defaultProps = {
  mate: {},
}

export default TeamMateCard
