import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
import { KeyboardTimePicker } from '@material-ui/pickers'

const TimeInputView = ({ value, onChange, label, error, className }) => {
  const handleTimeChange = event => {
    onChange(event)
  }

  return (
    <KeyboardTimePicker
      className={className}
      variant='filled'
      value={value}
      label={label}
      error={error}
      onChange={date => handleTimeChange(date)}
      InputLabelProps={{
        shrink: value ? true : undefined,
      }}
    />
  )
}

TimeInputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
}

TimeInputView.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  error: null,
  className: '',
}

export default TimeInputView
