import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'

export const Container = styled.div``
export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  flex-direction: column;
`
export const Value = styled.div`
  && {
    display: flex;
    margin-top: 5px;
    p {
      font-weight: 700;
      font-size: ${({ isProminent }) => (isProminent ? '32px' : '24px')};
      line-height: ${({ isProminent }) => (isProminent ? '32px' : '24px')};
      letter-spacing: -1.5px;
    }
  }
`
export const Name = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.primary.main[500]};
    font-size: 14px;
    line-height: 16px;
  }
`
export const Magnitude = styled.span``
export const ValueStat = styled(Typography)`
  && p {
    font-weight: 700;
  }
`
