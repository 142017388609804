import { colors } from 'config/colors'
import styled from 'styled-components'

export const ModalConfirm = styled.div`
  margin: 200px;
  padding: 50px;
  height: 200px;
  background-color: white;
  border: 1px solid ${colors.gray};
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'lato';
  font-size: 25px;
  font-weight: 500;
`
export const ModalTitle = styled.h1`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`
export const ModalText = styled.p`
  font-size: 24px;
  line-height: 32px;
  padding: 25px;
`
