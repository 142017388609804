//OFFICES
export const OFFICES = 'OFFICES'

//DASHBOARD
export const DASHBOARD_OCCUPANCY_KEY = 'DASHBOARD_OCCUPANCY_KEY'
export const DASHBOARD_BOOKINGS_KEY = 'DASHBOARD_BOOKINGS_KEY'
export const DASHBOARD_BOOKINGS_PAYMENTS_KEY = 'DASHBOARD_BOOKINGS_PAYMENTS_KEY'
export const DASHBOARD_BOOKINGS_MR_KEY = 'DASHBOARD_BOOKINGS_MR_KEY'
export const DASHBOARD_MEMBERS_KEY = 'DASHBOARD_MEMBERS_KEY'
export const DASHBOARD_FINANCIAL_KEY = 'DASHBOARD_FINANCIAL_KEY'
export const DASHBOARD_INVOICES_KEY = 'DASHBOARD_INVOICES_KEY'
export const DASHBOARD_ORDERS_KEY = 'DASHBOARD_INVOICES_KEY'
export const DASHBOARD_MEMBERSHIPS_KEY = 'DASHBOARD_MEMBERSHIPS_KEY'

//BOOKINGS
export const BOOKINGS_KEY = 'BOOKINGS_KEY'

//MEMBERS
export const MEMBERS_KEY = 'MEMBERS_KEY'
