import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams, useHistory, Link } from 'react-router-dom'
import { getDashboardOffices, getResourceSingle, getWAbyFloor, updateResource } from 'api'
import {
  CircularProgress,
  FormControl,
  TextField,
  Grid,
  Button,
  MenuItem,
  Select,
  InputLabel,
  Modal,
} from '@material-ui/core'
import Image from 'material-ui-image'
import ColorPicker from 'material-ui-color-picker'
import { Autocomplete } from '@material-ui/lab'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import FeedbackModal from 'components/molecules/modals/feedbackModal'

function ResourcesSingle() {
  const [theRes, setResource] = useState()
  const theParams = useParams()
  const { slug } = theParams
  const history = useHistory()

  function getResourceSingleData() {
    getResourceSingle(slug).then(response => setResource(response))
  }

  useEffect(() => {
    if (theRes === undefined) getResourceSingleData()
  }, [theRes])

  const [officesData, setOfficesData] = useState()
  const [floorsData, setFloorsData] = useState()
  const [waData, setWAData] = useState()

  function getOfficesData(data) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: data !== '' || data !== undefined ? data : '',
      },
    }
    getDashboardOffices(query).then(r => {
      console.log(r.offices)
      setOfficesData(r)
    })
  }

  function getWADataFloor(floorSlug) {
    getWAbyFloor(floorSlug).then(r => setWAData(r))
  }

  useEffect(() => {
    if (officesData === undefined) getOfficesData()
  }, [officesData])

  useEffect(() => {
    if (waData === undefined && theRes !== undefined) getWADataFloor(theRes?.floor?.slug)
  }, [waData, theRes])

  const [errors, setErrors] = useState({
    name: null,
    resource_type: null,
    capacity: null,
    status: null,
    price: null,
    office: null,
    work_area: null,
    identifier: null,
  })

  const [feedback, setFeedback] = useState(false)

  function updateRes() {
    setFeedback(true)
    const updatedRes = {
      name: theRes.name,
      resource_type: theRes.resource_type,
      capacity: theRes.capacity,
      office: theRes.office.slug,
      work_area: theRes.work_area?.slug ? theRes.work_area?.slug : null,
      description: theRes.description,
      status: theRes.status,
      identifier: theRes.identifier,
      price_hour: theRes.price_hour,
      price_day: theRes.price_day,
      price_month: theRes.price_month,
      price_credits_hour: theRes.price_credits_hour,
      price_credits_day: theRes.price_credits_day,
      price_credits_month: theRes.price_credits_month,
      no_member_price_hour: theRes.no_member_price_hour,
      no_member_price_day: theRes.no_member_price_day,
      no_member_price_month: theRes.no_member_price_month,
      no_member_price_credits_hour: theRes.no_member_price_credits_hour,
      no_member_price_credits_day: theRes.no_member_price_credits_day,
      no_member_price_credits_month: theRes.no_member_price_credits_month,
      color: theRes.color,
      x: theRes.x,
      y: theRes.y,
      length: theRes.length,
      width: theRes.width,
      height: theRes.height,
    }
    updateResource(updatedRes, theRes.slug).then(setTimeout(() => history.push('/space'), 1000))
  }

  function validate() {
    const data = theRes
    let canUpdate = false
    const textErrors = {
      name: 'Name is required',
      resource_type: 'Resource type is required',
      capacity: 'Capacity is required',
      status: 'Resource status is required',
      price: 'Price is required',
      office: 'Office is required',
      work_area: 'Work Area is required',
      identifier: 'Identifier is required',
    }
    const nameRes = data.name === undefined || data.name === '' ? textErrors.name : null
    const typeRes = data.resource_type === undefined || data.resource_type === '' ? textErrors.resource_type : null
    const capacityRes = data.capacity === undefined || data.capacity === '' ? textErrors.capacity : null
    const statusRes = data.status === undefined || data.status === '' ? textErrors.status : null
    const priceRes = data.price_day === undefined || data.price_day === '' ? textErrors.price_day : null
    const officeRes = data.office === undefined || data.office === '' || data.office === null ? textErrors.office : null
    const waRes =
      (data.work_area === undefined || data.work_area === '' || data.work_area === null) &&
      data.resource_type !== 'meeting_room'
        ? textErrors.work_area
        : null
    const identifierRes = data.identifier === undefined || data.identifier === '' ? textErrors.identifier : null

    setErrors({
      name: nameRes,
      resource_type: typeRes,
      capacity: capacityRes,
      status: statusRes,
      price: priceRes,
      office: officeRes,
      work_area: waRes,
      identifier: identifierRes,
    })
    if (
      nameRes === null &&
      typeRes === null &&
      capacityRes === null &&
      statusRes === null &&
      priceRes === null &&
      officeRes === null &&
      waRes === null &&
      identifierRes === null
    ) canUpdate = true
    if (canUpdate === true) updateRes()
  }

  useEffect(() => {}, [errors])

  /*
  function downloadQR(fileUrl) {
    const qrParse = theRes?.qr.split('/')
    const a = document.createElement('a')
    a.href = fileUrl
    a.setAttribute('download', qrParse[qrParse.length - 1])
    a.click()
  }
  */
  console.log('res', theRes)

  if (theRes !== undefined) {
    return (
      <Grid container direction='column'>
        <Modal open={feedback === true}>
          <FeedbackModal type='resource-update' />
        </Modal>
        <Grid item>
          <Grid container direction='row' spacing={2} justify='space-between'>
            <Grid item xs={4}>
              {theRes?.picture !== null ? (
                <Grid item>
                  <Image src={theRes?.picture} alt={theRes?.name} aspectRatio={1.1} />
                </Grid>
              ) : (
                <Grid item>
                  <Grid container justify='center' style={{ marginTop: '150px' }}>
                    <h5>Image Not Found</h5>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={8}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          value={theRes?.name}
                          name='name'
                          variant='outlined'
                          onChange={e => setResource({ ...theRes, name: e.target.value })}
                          label='Name'
                        />
                        {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          variant='outlined'
                          options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
                          getOptionLabel={option => option.name}
                          value={theRes?.office}
                          onChange={(e, data) => {
                            if (data !== null) {
                              setResource({ ...theRes, office: data, work_area: null, floor: null })
                              setFloorsData(data.floors)
                            } else {
                              setResource({ ...theRes, office: null, work_area: null, floor: null })
                              setFloorsData()
                            }
                          }}
                          renderInput={params => <TextField {...params} variant='outlined' label='Office' />}
                          label='Office'
                        />
                        {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          variant='outlined'
                          options={floorsData !== undefined ? floorsData : [{ name: 'No data' }]}
                          getOptionLabel={option => option.name}
                          onChange={(e, data) => {
                            if (data !== null) {
                              setResource({ ...theRes, floor: data, work_area: null })
                              getWADataFloor(data.slug)
                            } else {
                              setResource({ ...theRes, floor: null, work_area: null })
                              getWADataFloor()
                            }
                          }}
                          renderInput={params => <TextField {...params} variant='outlined' label='Floor ' />}
                          value={theRes?.floor !== undefined ? theRes.floor : [{ name: 'No data' }]}
                        />
                        {errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          variant='outlined'
                          options={waData !== undefined ? waData.work_areas : [{ name: 'No data' }]}
                          getOptionLabel={option => option.name}
                          onChange={(e, data) => {
                            if (data !== null) {
                              setResource({ ...theRes, work_area: data })
                            } else {
                              setResource({ ...theRes, work_area: null })
                            }
                          }}
                          renderInput={params => <TextField {...params} variant='outlined' label='Work Area' />}
                          value={theRes?.work_area !== undefined ? theRes.work_area : [{ name: 'No data' }]}
                        />
                        {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container justify='space-between' spacing={1}>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          value={theRes?.identifier}
                          label='Identifier'
                          name='identifier'
                          variant='outlined'
                          onChange={e => setResource({ ...theRes, identifier: e.target.value })}
                        />
                        {errors.identifier !== null ? <SmallError>{errors.identifier}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel style={{ marginTop: '-8px', marginLeft: '14px' }}>Resource Type</InputLabel>
                        <Select
                          name='resource_type'
                          onChange={data => {
                            console.log(data.target.value)
                            setResource({ ...theRes, resource_type: data?.target?.value })
                          }}
                          value={theRes?.resource_type}
                          variant='outlined'
                          label='Resource Type'
                        >
                          <MenuItem value='hot_desk'>Hot desk</MenuItem>
                          <MenuItem value='meeting_room'>Meeting Room</MenuItem>
                          <MenuItem value='desk'>Desk</MenuItem>
                        </Select>
                        {errors.resource_type !== null ? <SmallError>{errors.resource_type}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel style={{ marginTop: '-8px', marginLeft: '14px' }}>Status</InputLabel>
                        <Select
                          name='status'
                          value={theRes?.status}
                          onChange={value => setResource({ ...theRes, status: value })}
                          variant='outlined'
                          label='Status'
                        >
                          <MenuItem value='active'>Active</MenuItem>
                          <MenuItem value='inactive'>Inactive</MenuItem>
                        </Select>
                        {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          type='number'
                          value={theRes?.capacity !== undefined ? theRes.capacity : 0}
                          variant='outlined'
                          label='Capacity'
                          onChange={e => setResource({ ...theRes, capacity: e.target.value >= 1 ? e.target.value : 1 })}
                        />
                        {errors.capacity !== null ? <SmallError>{errors.capacity}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid container justify='space-between' style={{ marginTop: '14px' }}>
                    <Grid item xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          value={theRes?.description}
                          variant='outlined'
                          label='Description'
                          rows={3}
                          rowsMax={3}
                          multiline
                          onChange={e => setResource({ ...theRes, description: e.target.value })}
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item style={{ marginTop: '15px' }}>
                    <Grid container direction='column' spacing={2}>
                      <Grid item>Physical Description</Grid>
                      <Grid item>
                        <Grid container direction='row' spacing={1}>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                type='number'
                                value={theRes?.length}
                                onChange={e =>
                                  setResource({ ...theRes, length: e.target.value >= 1 ? e.target.value : 0 })
                                }
                                variant='outlined'
                                label='Resource length'
                                name='length'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                type='number'
                                value={theRes?.width}
                                name='width'
                                onChange={e =>
                                  setResource({ ...theRes, width: e.target.value >= 1 ? e.target.value : 0 })
                                }
                                variant='outlined'
                                label='Resource width'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                type='number'
                                value={theRes?.height}
                                name='height'
                                onChange={e =>
                                  setResource({ ...theRes, height: e.target.value >= 1 ? e.target.value : 0 })
                                }
                                variant='outlined'
                                label='Resource height'
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction='row' spacing={1}>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                type='number'
                                value={theRes?.x}
                                onChange={e => setResource({ ...theRes, x: e.target.value })}
                                variant='outlined'
                                label='Resource X coordinate'
                                name='x'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <TextField
                                type='number'
                                value={theRes?.y}
                                name='y'
                                onChange={e => setResource({ ...theRes, y: e.target.value })}
                                variant='outlined'
                                label='Resource Y coordinate'
                              />
                            </FormControl>
                          </Grid>
                          <Grid item xs={4}>
                            <FormControl style={{ width: '100%' }}>
                              <ColorPicker
                                name='color'
                                variant='outlined'
                                label='Resource Color (map)'
                                autoComplete='off'
                                onChange={value => {
                                  console.log(value)
                                  if (value !== undefined) setResource({ ...theRes, color: value })
                                }}
                                style={{ backgroundColor: theRes?.color !== undefined ? theRes.color : '#123456' }}
                              />
                            </FormControl>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container>
            <Grid item xs={4}>
              {theRes.qr !== null ? (
                <Grid container direction='column' alignItems='center'>
                  <Grid item>
                    <img
                      src={theRes.qr}
                      alt={`Resource: ${theRes?.name}`}
                      style={{ width: '200px', border: '1px solid black', marginBottom: '10px' }}
                    />
                  </Grid>
                  <Grid item>
                    <Button variant='outlined'>
                      <Link href={theRes?.qr} download='resource_qr'>
                        Download QR
                      </Link>
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid container justify='center' style={{ marginTop: '25px' }}>
                  <Grid item>
                    <h5>No QR data</h5>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item xs={8}>
              <Grid container direction='column' spacing={2} style={{ marginTop: '10px' }}>
                <Grid item>Member Prices (VAT inc)</Grid>
                <Grid item>
                  <Grid container justify='space-around' spacing={1}>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_hour}
                          onChange={e =>
                            setResource({ ...theRes, price_hour: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_day}
                          onChange={e =>
                            setResource({ ...theRes, price_day: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Day'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_month}
                          onChange={e =>
                            setResource({ ...theRes, price_month: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Month'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_credits_hour}
                          onChange={e =>
                            setResource({ ...theRes, price_credits_hour: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Credit Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_credits_day}
                          onChange={e =>
                            setResource({ ...theRes, price_credits_day: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Credit Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.price_credits_month}
                          onChange={e =>
                            setResource({ ...theRes, price_credits_month: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Credit Month'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container direction='column' spacing={2} style={{ marginTop: '10px' }}>
                <Grid item>Not Member Prices (VAT inc)</Grid>
                <Grid item>
                  <Grid container justify='space-around' spacing={1}>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_hour}
                          onChange={e =>
                            setResource({ ...theRes, no_member_price_hour: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_day}
                          onChange={e =>
                            setResource({ ...theRes, no_member_price_day: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Day'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_month}
                          onChange={e =>
                            setResource({ ...theRes, no_member_price_month: e.target.value >= 0 ? e.target.value : 0 })
                          }
                          variant='outlined'
                          label='Price Month'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_credits_hour}
                          onChange={e =>
                            setResource({
                              ...theRes,
                              no_member_price_credits_hour: e.target.value >= 0 ? e.target.value : 0,
                            })
                          }
                          variant='outlined'
                          label='Price Credit Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_credits_day}
                          onChange={e =>
                            setResource({
                              ...theRes,
                              no_member_price_credits_day: e.target.value >= 0 ? e.target.value : 0,
                            })
                          }
                          variant='outlined'
                          label='Price Credit Hour'
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl>
                        <TextField
                          type='number'
                          value={theRes?.no_member_price_credits_month}
                          onChange={e =>
                            setResource({
                              ...theRes,
                              no_member_price_credits_month: e.target.value >= 0 ? e.target.value : 0,
                            })
                          }
                          variant='outlined'
                          label='Price Credit Month'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ marginTop: '35px' }}>
          <Grid container direction='row' justify='center'>
            <Grid item>
              <Button variant='contained' color='secondary' onClick={() => validate()}>
                Update Resource
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid>
      <CircularProgress color='secondary' />
    </Grid>
  )
}

ResourcesSingle.propTypes = {
  office: PropTypes.object,
}

ResourcesSingle.defaultProps = {
  office: {},
}

export default ResourcesSingle
