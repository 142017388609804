import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import { List } from '@material-ui/core'
import { newGetMembers, newGetTeams } from 'api'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import Checkbox from '@material-ui/core/Checkbox'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { ProfileContainer } from 'components/organisms/members/profileStyle'
import { TransferListContainer } from './transferListStyle'
import SearchInput from '../../atoms/searchInput'

const useStyles = makeStyles(theme => ({
  cardHeader: {
    padding: theme.spacing(1, 2),
  },
  list: {
    width: 300,
    height: 230,
    backgroundColor: theme.palette.background.paper,
    overflow: 'auto',
  },
  button: {
    margin: theme.spacing(0.5, 0),
  },
}))

function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1)
}

function union(a, b) {
  return [...a, ...not(b, a)]
}

export default function TransferList(data) {
  const { users, office, setNumberUsers } = data
  const classes = useStyles()
  const [checked, setChecked] = React.useState([])
  const [left, setLeft] = React.useState(['start'])
  const [right, setRight] = React.useState(users)
  const [teams, setTeams] = useState()

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  function getUsers(filterData) {
    const query = {
      currentPage: 1,
      pageSize: 1000,
      filter: {
        name: filterData !== undefined || filterData !== '' ? filterData : '',
        order: 'user__first_name',
        layoutOffice: office,
      },
    }
    newGetMembers(query).then(response => {
      const usersArr = []
      response.members.map(member => usersArr.push(member.user))
      setLeft(usersArr)
    })
  }

  function getTeams(filterData) {
    const query = {
      currentPage: 1,
      pageSize: 'all',
      filter: {
        team: filterData !== undefined || filterData !== '' ? filterData : '',
        order: 'name',
        layoutOffice: office,
      },
    }
    newGetTeams(query).then(response => setTeams(response.teams))
  }

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
    setNumberUsers(right.concat(leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
    setNumberUsers(not(right, rightChecked))
  }
  function checkFields() {
    if (left[0] === 'start') getUsers()
    if (teams === undefined) getTeams()
  }

  useEffect(() => {
    checkFields()
  }, [teams])

  const customList = (title, items) => (
    <Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component='div' role='list'>
        {items.map(value => {
          const space = ' '
          const labelId = `transfer-list-all-item-${value}-label`
          const theName = value.first_name + space + value.last_name
          return (
            <ListItem key={value} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={theName} />
            </ListItem>
          )
        })}
      </List>
    </Card>
  )

  return (
    <ProfileContainer>
      <SearchInput placeholder='Search user by name...' onChange={value => getUsers(value.target.value)} />

      <TransferListContainer>
        <Grid item>{customList('Users', left)}</Grid>
        <Grid item>
          <Grid container direction='column' alignItems='center'>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label='move selected right'
              style={{ backgroundColor: 'white' }}
            >
              <ArrowForwardIcon />
            </Button>
            <Button
              variant='outlined'
              size='small'
              className={classes.button}
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label='move selected left'
              style={{ backgroundColor: 'white' }}
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item>{customList('Users Selected', right)}</Grid>
      </TransferListContainer>
    </ProfileContainer>
  )
}
