import { useEffect, useState } from 'react'
import { createDashboardMSPlans, getOffices } from 'api'
import { Grid, Button, FormControl, TextField, Modal } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { ModalCard } from '../bookings/modalStyles'

function ModalCreateMSPlan(props) {
  const { toClose } = props
  const [planMS, setPlanMS] = useState({
    name: '',
    description: '',
    base_price: '',
    client: '',
  })
  const [dataOffice, setDataOffice] = useState()
  const [modalFeedback, setModalFeedback] = useState()

  function getDataOffice() {
    getOffices().then(r => setDataOffice(r))
  }

  useEffect(() => {
    if (dataOffice === undefined) getDataOffice()
    console.log(dataOffice)
  }, [dataOffice])

  useEffect(() => {}, [planMS])

  const [errors, setErrors] = useState({
    name: null,
    base_price: null,
  })

  function createMSPlan() {
    const thePlanMS = {
      name: planMS.name,
      base_price: parseInt(planMS.base_price, 10),
      description: planMS.description,
      client: dataOffice.active_client.slug,
    }
    createDashboardMSPlans(thePlanMS).then(() => {
      setModalFeedback(true)
      setTimeout(() => {
        window.location.reload()
      }, 1500)
    })
  }

  function validate() {
    let canCreate = false
    const textErrors = {
      name: 'Name is required',
      base_price: 'Price is required',
    }

    const nameRes = planMS.name === '' || planMS.name === undefined ? textErrors.name : null
    const priceRes = planMS.base_price === '' || planMS.base_price === undefined ? textErrors.base_price : null

    setErrors({
      name: nameRes,
      base_price: priceRes,
    })

    if (nameRes === null && priceRes === null) canCreate = true
    if (canCreate === true) createMSPlan()
  }

  return (
    <ModalCard>
      <Modal open={modalFeedback === true}>
        <FeedbackModal type='planMS-create' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container justify='space-between'>
            <Grid item>
              <h3>Create Membership Plan</h3>
            </Grid>
            <Grid item>
              <Button onClick={() => toClose()} variant='outlined'>
                X
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <TextField
              variant='outlined'
              onChange={e => setPlanMS({ ...planMS, name: e.target.value })}
              value={planMS.name}
              error={errors.name !== null}
              label='Name'
            />
            {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <TextField
              variant='outlined'
              onChange={e => setPlanMS({ ...planMS, description: e.target.value })}
              value={planMS.description}
              multiline
              rows={3}
              rowsMax={3}
              label='Description'
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <TextField
              variant='outlined'
              type='number'
              onScroll={e => e.preventDefault()}
              onChange={e =>
                e.target.value >= 0
                  ? setPlanMS({ ...planMS, base_price: e.target.value })
                  : setPlanMS({ ...planMS, base_price: 0 })
              }
              error={errors.base_price !== null}
              value={planMS.base_price}
              label='Price'
            />
            {errors.base_price !== null ? <SmallError>{errors.base_price}</SmallError> : ''}
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justify='center'>
            <Button color='secondary' variant='contained' onClick={() => validate()}>
              Create Membership Plan
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateMSPlan.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateMSPlan.defaultProps = {
  toClose: () => {},
}

export default ModalCreateMSPlan
