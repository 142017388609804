import React from 'react'
//import PropTypes from 'prop-types'
import OfferUserAddForm from 'components/organisms/offerUserAddForm'
import { Container } from './styled'

const OfferUserAdd = (...props) => (
  <Container>
    <OfferUserAddForm props={props} />
  </Container>
)

OfferUserAdd.propTypes = {
  //office: PropTypes.object
}

OfferUserAdd.defaultProps = {
  //office: {}
}

export default OfferUserAdd
