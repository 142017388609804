import { TextField, Select, MenuItem, InputLabel } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { useTranslation } from 'react-i18next'
import { updateTeam, getDashboardOffices } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { CloseIcon, ModalCard, ModalHeader, ModalItem, ModalTitle, SubmitBtn } from '../bookings/modalStyles'

function ModalUpdateTeam(props) {
  const { toClose, team } = props
  console.log(team)
  const [theTeam, setTeamData] = useState({
    name: team.team?.name,
    status: team.team?.status,
    description: team.team?.description,
    office: team.team?.office,
  })
  const { t } = useTranslation()
  const [errors, setErrors] = useState({
    name: null,
    invoice_name: null,
    invoice_email: null,
    invoice_address: null,
    invoice_post_code: null,
    invoice_city: null,
  })
  const [officesData, setOfficesData] = useState({ offices: [] })

  function updateTeamInfo(localErrors) {
    console.log('')
    if (localErrors.name === null && localErrors.office === null) {
      const filter = { ...theTeam, office: theTeam.office.slug }
      updateTeam(team.team?.slug, filter)
        .then(response => {
          toClose()
          console.log(response)
        })
        .catch(error => console.log(error))
    }
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function validation(data) {
    const textErrors = {
      name: 'Name is required',
      office: 'Office is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const officeRes = data?.office === undefined || data?.office === '' ? textErrors.office : null

    const localErrors = {
      name: nameRes,
      office: officeRes,
    }
    setErrors(localErrors)
    updateTeamInfo(localErrors)
  }

  function checkFields() {}
  useEffect(() => {
    checkFields()
  }, [theTeam, errors])

  useEffect(() => {
    getOfficesData()
  }, [])

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px' }}>{t('members.updateTeam')}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <ModalItem>
        <TextField
          label='Name'
          variant='outlined'
          id='team-name-input-id'
          name='name'
          value={theTeam.name}
          onChange={value => setTeamData({ ...theTeam, name: value.target.value })}
          error={errors.name !== null}
        />
        {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
      </ModalItem>
      <ModalItem>
        <TextField
          label='Description'
          variant='outlined'
          id='team-description-input-id'
          name='description'
          value={theTeam.description}
          onChange={value => setTeamData({ ...theTeam, description: value.target.value })}
        />
      </ModalItem>
      <ModalItem>
        <InputLabel id='filter-label-status'>Status</InputLabel>
        <Select
          name='status'
          label='Status'
          variant='outlined'
          value={theTeam.status}
          onChange={value => {
            setTeamData({ ...theTeam, status: value.target.value })
          }}
        >
          <MenuItem value='active'>Active</MenuItem>
          <MenuItem value='inactive'>Inactive</MenuItem>
        </Select>
      </ModalItem>
      <ModalItem>
        <Autocomplete
          name='office'
          options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
          onChange={(e, data) => {
            console.log(e.target?.value, data)
            setTeamData({ ...theTeam, office: data })
          }}
          loading
          loadingText='Searching...'
          noOptionsText='No Results'
          value={theTeam.office}
        />
      </ModalItem>
      <SubmitBtn
        onClick={() => {
          validation(theTeam)
        }}
      >
        {t('members.updateTeam')}
      </SubmitBtn>
    </ModalCard>
  )
}

ModalUpdateTeam.propTypes = {
  toClose: PropTypes.func,
  team: PropTypes.object,
}

ModalUpdateTeam.defaultProps = {
  toClose: () => {},
  team: null,
}

export default ModalUpdateTeam
