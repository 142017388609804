import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { CircularContainer, TheLetters } from './defaultAvatarStyles'

function DefaultAvatar({ first, last }) {
  const [dataName, setName] = useState()

  let theFirst = first.charAt(0)
  const theLast = last.charAt(0)
  if (last === '') {
    theFirst = first.slice(0, 2)
  }
  useEffect(() => {
    if (dataName === undefined && last !== undefined) {
      setName({
        firstLetter: theFirst,
        lastLetter: theLast,
      })
    }
  }, [dataName])

  if (dataName !== undefined) {
    return (
      <CircularContainer>
        <Grid container direction='row' justify='center' alignItems='center'>
          <Grid item>
            <TheLetters>
              {dataName.firstLetter}
              {dataName.lastLetter}
            </TheLetters>
          </Grid>
        </Grid>
      </CircularContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

DefaultAvatar.propTypes = {
  first: PropTypes.string,
  last: PropTypes.string,
}

DefaultAvatar.defaultProps = {
  first: '',
  last: '',
}

export default DefaultAvatar
