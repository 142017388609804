import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ScrollView } from 'devextreme-react'
import { CircularProgress } from '@material-ui/core'
import formatWords from 'components/atoms/formatWords/formatWords'
import convertDates from 'components/atoms/formatDates/convertDates'
import PropTypes from 'prop-types'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItem, BoxItemTitle, BoxRow } from './scrollListStyles'

function SingleInvoiceLinesList(props) {
  const { invoices, type } = props
  const center = { margin: '0 auto' }
  console.log(invoices)
  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (invoices !== undefined) {
    return (
      <ListContainer>
        {type === 'noHead' ? '' : <SingleTitleSection>Invoices</SingleTitleSection>}
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Invoice</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Concept</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Net Amount (ex VAT)</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Gross Amount (in VAT)</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Status</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Date</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Reference</strong>
                  </ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {invoices.length <= 0 ? (
              <ListRow>
                <ListItem style={center}>
                  <strong>No Invoices data</strong>
                </ListItem>
              </ListRow>
            ) : (
              invoices
                .sort((a, b) => (a.create_date < b.create_date ? 1 : -1))
                .map((invoiceLine, index) => (
                  <Link
                    to={`/members/invoices/${invoiceLine.invoice?.slug}`}
                    key={index}
                    style={{ textDecoration: 'none' }}
                  >
                    <ListRow key={index}>
                      <BoxRow>
                        <BoxItem>
                          <ListItem>{invoiceLine.invoice.name}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>{invoiceLine.name}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>£{invoiceLine.line_gross_price}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>£{invoiceLine.line_price}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>{formatWords(invoiceLine.status)}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>{convertDates(invoiceLine?.create_date, 'date')}</ListItem>
                        </BoxItem>
                        <BoxItem>
                          <ListItem>{invoiceLine?.payment_reference}</ListItem>
                        </BoxItem>
                      </BoxRow>
                    </ListRow>
                  </Link>
                ))
            )}
          </ListContainer>
        </ScrollView>
      </ListContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}
SingleInvoiceLinesList.propTypes = {
  invoices: PropTypes.array,
  type: PropTypes.string,
}
SingleInvoiceLinesList.defaultProps = {
  invoices: [],
  type: '',
}
export default SingleInvoiceLinesList
