import React, { useEffect, useState } from 'react'
import { API_BASE_URL } from 'config/api'
import axios from 'axios'
import { getSingleOffice, updateOffice } from 'api'
import { useHistory, useParams } from 'react-router-dom'
import { CircularProgress, FormControl, Grid, MenuItem, TextField, Select, InputLabel, Button } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import DropzoneInput from 'components/atoms/dropzonetInput/container'
import { SpinnerContainer } from '../members/newListStyle'
import { PlaceholderIcon } from '../themeForm/styled'

function OfficeSingle() {
  const history = useHistory()
  const [officeData, setOffice] = useState()
  const params = useParams()
  const { slug } = params
  const [errors, setErrors] = useState({
    street: null,
    long: null,
    lat: null,
  })
  const [geopointData, setGeoData] = useState({
    long: '',
    lat: '',
  })

  function getData() {
    getSingleOffice(slug).then(r => {
      const theOffice = r.offices.filter(o => o.slug === slug)
      setOffice(theOffice[0])
    })
  }

  useEffect(() => {
    if (officeData === undefined) getData()
    if (officeData !== undefined) {
      const split = officeData?.geopoint?.split(' ')
      setGeoData({ lat: split[2].replace(')', ''), long: split[1].replace('(', '') })
    }
  }, [officeData])

  function uploadImage(image) {
    const form = new FormData()
    form.append('image', {
      uri: image.path,
      name: image.name,
      type: image.type,
    })

    const config = {
      headers: {
        Autorization: `Bearer ${localStorage.token}`,
        'Content-Type': 'multipart/form-data',
        Accept: 'application/json',
      },
    }

    axios
      .put(`${API_BASE_URL}/api/dashboard-offices/${officeData.slug}`, form, config)
      .then(() => {
        history.push('/users/company')
      })
      .catch(e => console.log(e))
  }

  function updateOfficeData() {
    const theOffice = {
      name: officeData.name,
      short_code: officeData.short_code,
      status: officeData.status,
      visibility: officeData.visibility,
      description: officeData.description,
      geopoint: `POINT(${geopointData.long} ${geopointData.lat})`,
      town: officeData.town,
      street: officeData.street,
      postal: officeData.postal,
      google_place: officeData.google_place,
      google_place_url: officeData.google_place_url,
      street_number: officeData.street_number,
      street_name: officeData.street_name,
      floor_number: officeData.floor_number,
      door_name: officeData.door_name,
      phone: officeData.phone,
      list_order: officeData.list_order,
      invoice_prefix: officeData.invoice_prefix,
    }
    updateOffice(officeData.slug, theOffice)
      .then(() => {
        if (theOffice === 'cacafuti') uploadImage(officeData.place_main_img[0])
        setTimeout(() => {
          history.push('/users/company')
        }, 1000)
      })
      .catch(e => console.log(e))
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      address: 'Address is mandatory',
      long: 'Longitude is mandatory',
      lat: 'Latitude is mandatory',
    }

    const addressRes =
      officeData.street === '' || officeData.street === null || officeData.street === undefined
        ? textErrors.address
        : null
    const longRes =
      geopointData.long === '' || geopointData.long === null || geopointData.long === undefined ? textErrors.long : null
    const latRes =
      geopointData.lat === '' || geopointData.lat === null || geopointData.lat === undefined ? textErrors.lat : null

    setErrors({
      street: addressRes,
      long: longRes,
      lat: latRes,
    })

    if (addressRes === null && longRes === null && latRes === null) canUpdate = true
    if (canUpdate === true) updateOfficeData()
  }

  let statusEnabled = false
  if (
    geopointData?.long !== '' &&
    geopointData?.long !== undefined &&
    geopointData?.lat !== '' &&
    geopointData?.lat !== undefined &&
    officeData?.street !== '' &&
    officeData?.street !== undefined
  ) statusEnabled = true

  if (officeData !== undefined) {
    return (
      <Grid container direction='column' spacing={2} style={{ marginTop: '15px' }}>
        <Grid item xs={12}>
          <Grid container justify='flex-end'>
            <Button variant='contained' color='secondary' onClick={() => validate()}>
              Update Office
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Name'
                  variant='outlined'
                  value={officeData?.name}
                  onChange={e => setOffice({ ...officeData, name: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Contact Mail'
                  variant='outlined'
                  value={officeData?.email}
                  onChange={e => setOffice({ ...officeData, email: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Phone'
                  variant='outlined'
                  value={officeData?.phone}
                  onChange={e => setOffice({ ...officeData, phone: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Website'
                  variant='outlined'
                  value={officeData?.website}
                  onChange={e => setOffice({ ...officeData, website: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%' }}>
                <TextField label='Domain' variant='outlined' value={officeData?.domain} />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <DropzoneInput
                  name='login'
                  icon={<PlaceholderIcon />}
                  value={officeData?.place_main_img}
                  onChange={e => {
                    console.log(e)
                    setOffice({ ...officeData, place_main_img: e })
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={9}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      label='Description'
                      variant='outlined'
                      multiline
                      rowsMax={5}
                      rows={5}
                      value={officeData?.description}
                      onChange={e => setOffice({ ...officeData, description: e.target.value })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField label='Short Code' variant='outlined' value={officeData?.short_code} />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Invoice Prefix'
                    variant='outlined'
                    value={officeData?.invoice_prefix}
                    onChange={e => setOffice({ ...officeData, invoice_prefix: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='List Order'
                    variant='outlined'
                    value={officeData?.list_order}
                    onChange={e => setOffice({ ...officeData, list_order: e.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Visibility</InputLabel>
                  <Select label='Visibility' variant='outlined' value={officeData?.visibility}>
                    <MenuItem value='public'>Public</MenuItem>
                    <MenuItem value='private'>Private</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Status</InputLabel>
                  <Select
                    disabled={statusEnabled === false}
                    label='Status'
                    variant='outlined'
                    value={officeData?.status}
                  >
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='inactive'>Inactive</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              label='Address'
              variant='outlined'
              value={officeData?.street}
              onChange={e => setOffice({ ...officeData, street: e.target.value })}
              error={errors.street !== null}
            />
            {errors.street !== null ? <SmallError>{errors.street}</SmallError> : ''}
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Town'
                  variant='outlined'
                  value={officeData?.town}
                  onChange={e => setOffice({ ...officeData, town: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Street'
                  variant='outlined'
                  value={officeData?.street_name}
                  onChange={e => setOffice({ ...officeData, street_name: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Street number'
                  variant='outlined'
                  value={officeData?.street_number}
                  onChange={e => setOffice({ ...officeData, street_number: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Postal'
                  variant='outlined'
                  value={officeData?.postal}
                  onChange={e => setOffice({ ...officeData, postal: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Floor Number'
                  variant='outlined'
                  value={officeData?.floor_number}
                  onChange={e => setOffice({ ...officeData, floor_number: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={2}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Door name'
                  variant='outlined'
                  value={officeData?.door_name}
                  onChange={e => setOffice({ ...officeData, door_name: e.target.value })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Google Place'
                  variant='outlined'
                  value={officeData?.google_place}
                  onChange={e => setOffice({ ...officeData, google_place: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Google Url'
                  variant='outlined'
                  value={officeData?.google_place_url}
                  onChange={e => setOffice({ ...officeData, google_place: e.target.value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Longitude'
                  variant='outlined'
                  value={geopointData.long}
                  onChange={e => {
                    setOffice({ ...officeData, long: e.target.value })
                    setGeoData({ ...geopointData, long: e.target.value })
                  }}
                  error={errors.long !== null}
                  type='number'
                />
                {errors.long !== null ? <SmallError>{errors.long}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Latitude'
                  variant='outlined'
                  value={geopointData.lat}
                  onChange={e => {
                    setOffice({ ...officeData, lat: e.target.value })
                    setGeoData({ ...geopointData, lat: e.target.value })
                  }}
                  error={errors.lat !== null}
                  type='number'
                />
                {errors.lat !== null ? <SmallError>{errors.lat}</SmallError> : ''}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default OfficeSingle
