import React, { useEffect, useState } from 'react'
import { getCampaigns } from 'api'
import { Pagination } from '@material-ui/lab'
import { Button, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import formatWords from 'components/atoms/formatWords/formatWords'
import { ScrollView } from 'devextreme-react'
import { Link } from 'react-router-dom'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from 'components/organisms/members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function CampaignList(props) {
  const { layoutOffice } = props
  const [campaignsData, setCampaignData] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'name',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 15,
    thePage: 1,
    pagesTotal: '',
  })
  const slash = '-'
  const space = ' '
  const noLinkStyle = { textDecoration: 'none' }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getCampaigns(query).then(response => {
      setCampaignData(response.notification_campaigns)
      getPagination(response)
    })
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = campaignsData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getData(query)
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const prevPage = parseInt(pagination.thePage, 10) - 1
      page = prevPage.toString()
    }

    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  useEffect(() => {
    if (campaignsData === undefined) getData()
  }, [campaignsData])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  useEffect(() => {}, [pagination, theFilter])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput placeholder='Search by name...' onChange={value => setDataFilter(value.target.value)} />
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Name</strong>
                  </Button>
                  {campaignsData?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('type__name')}>
                    <strong>Type</strong>
                  </Button>
                  {campaignsData?.order === 'type__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('code')}>
                    <strong>Code</strong>
                  </Button>
                  {campaignsData?.order === 'code' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_created')}>
                    <strong>Created</strong>
                  </Button>
                  {campaignsData?.order === 'date_created' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_planned')}>
                    <strong>Planned</strong>
                  </Button>
                  {campaignsData?.order === 'date_planned' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {campaignsData?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('users')}>
                    <strong>Nº users</strong>
                  </Button>
                  {campaignsData?.order === 'users' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
        </ListContainer>
      </ScrollView>

      {campaignsData === undefined ? (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      ) : (
        campaignsData.map(camp => {
          const dateCreated = camp?.date_created?.split('T')
          const datePlanned = camp?.date_planned?.split('T')
          return (
            <Link to={`/campaigns/${camp?.slug}`} key={camp?.slug} style={noLinkStyle}>
              <ListRow>
                <BoxRow>
                  <BoxItem>
                    <ListItem>{camp?.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{camp?.type?.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{camp?.code}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{dateCreated[0]}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{datePlanned[0]}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{formatWords(camp.status)}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{camp?.users?.length}</ListItem>
                  </BoxItem>
                  ß
                </BoxRow>
              </ListRow>
            </Link>
          )
        })
      )}
      <PaginationContainer>
        <Pagination
          color='secondary'
          size='small'
          onClick={value => setPagePagination(value)}
          count={pagination.pagesTotal}
          page={parseInt(pagination.thePage, 10)}
        />
        <PaginationNumbers>
          {pagination.resultsByPage * pagination.thePage -
            pagination.resultsByPage +
            slash +
            (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
              ? pagination.resultsByPage * pagination.thePage
              : pagination.resultsTotal) +
            space}
          of {pagination.resultsTotal}
        </PaginationNumbers>
      </PaginationContainer>
    </ListContainer>
  )
}

CampaignList.propTypes = { layoutOffice: PropTypes.object }
CampaignList.defaultProps = { layoutOffice: null }

export default CampaignList
