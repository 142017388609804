import React, { useState, useEffect } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import wLogo from 'assets/icons/WLogo.svg'
import { getClientInfo } from 'api'
import psl from 'psl'
import { Logo } from './styled'

const NavBarView = () => {
  const [infoLogo, setInfoLogo] = useState()

  function getLoginInfo() {
    const parsed = psl.parse(window.location.hostname)
    getClientInfo(parsed.subdomain).then(r => setInfoLogo(r))
  }

  useEffect(() => {
    getLoginInfo()
  }, [])

  useEffect(() => {
    console.log(infoLogo)
  }, [infoLogo])

  return (
    <AppBar position='static'>
      <Toolbar>
        <Logo src={infoLogo?.logo !== undefined ? infoLogo.logo : wLogo} />
      </Toolbar>
    </AppBar>
  )
}

export default NavBarView
