import styled from 'styled-components'

export const Container = styled.div``
export const DashboardInfoList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 24px;
  @media (max-width: ${({ theme }) => theme.breakpoints.values.lg}px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    grid-template-columns: 1fr;
  }
`

export const FilterContainer = styled.div`
  display: flex;
  margin-bottom: 10px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 5px 0px #e0e0e0;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  text-transform: capitalize;
  padding: 10px 20px;
  font-weight: bold;
`
