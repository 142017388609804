import styled from 'styled-components'
import { Input } from '@material-ui/core'

export const SearchInputStyled = styled(Input)`
  width: 100%;
  margin: 5px 5px 10px;
  font-size: 15px !important;
  position: relative;
  top: 3px;
`

export const SearchContainerContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`

export const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  padding: 5px;
  border: 1px solid #ededed;
  border-radius: 15px;
  width: 100%;
  .MuiInput-underline:before {
    border: 0;
  }
`
