import { useState } from 'react'
import PropType from 'prop-types'
import { Card, Button, CardActions, CardContent, Typography, Modal } from '@material-ui/core'
import { colors } from 'config/colors'
import ModalUpdateMSPlan from './modalUpdateMSPlan'

function MSPlanCard({ mp }) {
  const [msUpdate, setModalUpdateMS] = useState(false)
  const MSPlanCardStyle = {
    border: `1px solid ${colors.softgray}`,
    width: '400px',
    height: '250px',
    margin: '15px',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'space-between',
  }

  function handleClose() {
    setModalUpdateMS(false)
  }

  return (
    <Card style={MSPlanCardStyle}>
      <Modal open={msUpdate === true}>
        <ModalUpdateMSPlan mp={mp} toClose={handleClose} />
      </Modal>
      <CardContent style={{ minHeight: '150px', maxHeight: '150px' }}>
        <Typography style={{ fontSize: '18px', color: 'black', fontWeight: 900 }}>{mp.name}</Typography>
        <Typography style={{ fontSize: '30px', color: 'black', fontWeight: 900 }}>£{mp.base_price}</Typography>
        <Typography style={{ fontSize: '15px', color: colors.gray }}>{mp.subtitle}</Typography>
        <Typography style={{ fontSize: '15px', color: 'black' }}>{mp.description}</Typography>
      </CardContent>
      <CardActions style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button size='small' variant='outlined' color='secondary'>
          Discount
        </Button>
        <Button size='small' variant='outlined' onClick={() => setModalUpdateMS(true)}>
          Edit
        </Button>
      </CardActions>
    </Card>
  )
}

MSPlanCard.propTypes = {
  mp: PropType.object,
}

MSPlanCard.defaultProps = {
  mp: {},
}

export default MSPlanCard
