import React from 'react'
import MembersAddForm from 'components/organisms/membersAddForm'
import { Container } from './styled'

const MemberAdd = () => (
  <Container>
    <MembersAddForm />
  </Container>
)

MemberAdd.propTypes = {}

export default MemberAdd
