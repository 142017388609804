import styled from 'styled-components'
import ColorPickerInput from 'components/atoms/colorPickerInput'
import DropzonetInput from 'components/atoms/dropzonetInput'
import TextInput from 'components/atoms/textInput'
import SelectInput from 'components/atoms/selectInput'
import ImageIcon from '@material-ui/icons/Image'

export const PlaceholderIcon = styled(ImageIcon)`
  && {
    fill: ${({ theme }) => theme.palette.grey[500]};
  }
`

export const Container = styled.div``

export const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-areas:
    'toolbar      toolbar        toolbar toolbar'
    'favicon      favicon        logo    logo'
    'name         fontInput      logo    logo'
    'primaryColor secondaryColor logo    logo'
    'auto         auto           logo    logo';
  margin-top: 16px;
`

export const Toolbar = styled.div`
  grid-area: toolbar;
  display: flex;
  flex-direction: row-reverse;
`

export const NameInput = styled(TextInput)`
  grid-area: name;
`

export const Favicon = styled(DropzonetInput)`
  grid-area: favicon;
  max-width: 50px;
`

export const FontInput = styled(SelectInput)`
  grid-area: fontInput;
`

export const PrimaryColorInput = styled(ColorPickerInput)`
  grid-area: primaryColor;
`

export const SecondaryColorInput = styled(ColorPickerInput)`
  grid-area: secondaryColor;
`

export const Logo = styled(DropzonetInput)`
  grid-area: logo;
  && .placeholderText {
    color: ${({ theme }) => theme.palette.grey[700]};
  }
`
