import React from 'react'
import OfferAddForm from 'components/organisms/offerAddForm'
import { Container } from './styled'

const OfferAdd = () => (
  <Container>
    <OfferAddForm />
  </Container>
)

OfferAdd.propTypes = {}

export default OfferAdd
