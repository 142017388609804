import styled from 'styled-components'
import TextInput from 'components/atoms/textInput'
import SelectInput from 'components/atoms/selectInput'
import Text from 'components/atoms/text'
import AutocompleteInput from 'components/atoms/autocompleteInput'

export const Container = styled.form``

export const Title = styled(Text)`
  && {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
`

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const BillingSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-areas:
    'subtitle subtitle subtitle subtitle'
    'town     street   street   street';
  margin-bottom: 40px;
  margin-top: 20px;
`

export const MemberSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-areas:
    'firstName lastName email team'
    'role      jobTitle phone auto';
  margin-bottom: 40px;
  margin-top: 20px;
`

export const Subtitle = styled(Text)`
  && {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    grid-area: subtitle;
  }
`

export const FirstNameInput = styled(TextInput)`
  grid-area: firstName;
`

export const SecondNameInput = styled(TextInput)`
  grid-area: lastName;
`

export const EmailInput = styled(TextInput)`
  grid-area: email;
`

export const JobTitleInput = styled(TextInput)`
  grid-area: jobTitle;
`

export const RoleInput = styled(SelectInput)`
  grid-area: role;
`

export const PhoneInput = styled(TextInput)`
  grid-area: phone;
`

export const TownInput = styled(TextInput)`
  grid-area: town;
`

export const StreetInput = styled(TextInput)`
  grid-area: street;
`

export const TeamInput = styled(AutocompleteInput)`
  grid-area: team;
`
