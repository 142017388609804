import React from 'react'
import PropTypes from 'prop-types'
import Placeholder from './placeholder'
import StatCardView from './view'

function StatCard({ data, isLoading, ...props }) {
  if (!data || isLoading) return <Placeholder />
  return <StatCardView data={data} {...props} />
}

StatCard.propTypes = {
  data: PropTypes.object,
  isLoading: PropTypes.bool,
}

StatCard.defaultProps = {
  data: null,
  isLoading: false,
}

export default StatCard
