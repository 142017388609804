import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'

const DateInputView = ({ value, onChange, label, error, className }) => {
  const handleChange = event => {
    onChange(event)
  }

  return (
    <KeyboardDatePicker
      autoOk
      className={className}
      inputVariant='filled'
      format='dd-MM-yyyy'
      label={label}
      value={value}
      error={error}
      helperText={error?.message}
      onChange={date => handleChange(date)}
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      InputLabelProps={{
        shrink: value ? true : undefined,
      }}
    />
  )
}

DateInputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
}

DateInputView.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  error: null,
  className: '',
}

export default DateInputView
