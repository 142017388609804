import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import bgLogin from 'assets/images/bgLogin.png'
import Text from 'components/atoms/text'

//  background-image: url(${bgLogin});
export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${bgLogin});
  flex: 1;
`
export const Wrapper = styled(Paper)`
  padding: 40px;
  max-width: 424px;
  width: 100%;
`
export const Title = styled(Text)`
  && {
    font-size: 64px;
    line-height: 72px;
    font-style: normal;
    font-weight: bold;
    margin-bottom: 12px;
  }
`
export const Subtitle = styled(Text)`
  font-style: normal;
`

export const Header = styled.div`
  && {
    text-align: center;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
  }
`
