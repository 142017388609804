const CHAIR_WIDTH = 50
const CHAIR_HEIGHT = 40

export const mapDeskItem = res => {
  let deskGeometry = {}
  if (res.orientation && res.orientation === 'up') {
    deskGeometry = {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [res.x - res.width / 2, res.y + res.length / 2],
            [res.x + res.width / 2, res.y + res.length / 2],
            [res.x + res.width / 2, res.y - res.length / 2],
            [res.x - res.width / 2, res.y - res.length / 2],
          ],
          [
            [res.x - CHAIR_WIDTH / 2, res.y - res.length / 2],
            [res.x + CHAIR_WIDTH / 2, res.y - res.length / 2],
            [res.x + CHAIR_WIDTH / 2, res.y - CHAIR_HEIGHT / 2 - res.length / 2],
            [res.x - CHAIR_WIDTH / 2, res.y - CHAIR_HEIGHT / 2 - res.length / 2],
          ],
        ],
      ],
    }
  }
  if (res.orientation && res.orientation === 'down') {
    deskGeometry = {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [res.x - res.width / 2, res.y + res.length / 2],
            [res.x + res.width / 2, res.y + res.length / 2],
            [res.x + res.width / 2, res.y - res.length / 2],
            [res.x - res.width / 2, res.y - res.length / 2],
          ],
          [
            [res.x - CHAIR_WIDTH / 2, res.y + res.length / 2],
            [res.x + CHAIR_WIDTH / 2, res.y + res.length / 2],
            [res.x + CHAIR_WIDTH / 2, res.y + CHAIR_HEIGHT / 2 + res.length / 2],
            [res.x - CHAIR_WIDTH / 2, res.y + CHAIR_HEIGHT / 2 + res.length / 2],
          ],
        ],
      ],
    }
  }
  if (res.orientation && res.orientation === 'left') {
    deskGeometry = {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [res.x - res.length / 2, res.y + res.width / 2], // A
            [res.x + res.length / 2, res.y + res.width / 2], // B
            [res.x + res.length / 2, res.y - res.width / 2], // C
            [res.x - res.length / 2, res.y - res.width / 2], // D
          ],
          [
            [res.x + res.length / 2, res.y + CHAIR_HEIGHT / 2], // A
            [res.x + res.length / 2 + CHAIR_HEIGHT / 2, res.y + CHAIR_HEIGHT / 2], // B
            [res.x + res.length / 2 + CHAIR_HEIGHT / 2, res.y - CHAIR_WIDTH / 2],
            [res.x + res.length / 2, res.y - CHAIR_WIDTH / 2],
          ],
        ],
      ],
    }
  }
  if (res.orientation && res.orientation === 'right') {
    deskGeometry = {
      type: 'MultiPolygon',
      coordinates: [
        [
          [
            [res.x - res.length / 2, res.y + res.width / 2], // A
            [res.x + res.length / 2, res.y + res.width / 2], // B
            [res.x + res.length / 2, res.y - res.width / 2], // C
            [res.x - res.length / 2, res.y - res.width / 2], // D
          ],
          [
            [res.x - res.length / 2 - CHAIR_HEIGHT / 2, res.y + CHAIR_HEIGHT / 2], // A
            [res.x - res.length / 2, res.y + CHAIR_HEIGHT / 2], // B
            [res.x - res.length / 2, res.y - CHAIR_WIDTH / 2],
            [res.x - res.length / 2 - CHAIR_HEIGHT / 2, res.y - CHAIR_WIDTH / 2],
          ],
        ],
      ],
    }
  }
  const feature = {
    type: 'Feature',
    properties: {
      name: res.name,
      status: res.status,
      slug: res.slug,
      id: res.id,
      type: 'desk',
    },
    geometry: deskGeometry,
  }
  return feature
}

export const mapMRItem = res => {
  const deskGeometry = {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [res.x - res.width / 2, res.y + res.length / 2],
          [res.x + res.width / 2, res.y + res.length / 2],
          [res.x + res.width / 2, res.y - res.length / 2],
          [res.x - res.width / 2, res.y - res.length / 2],
        ],
      ],
    ],
  }

  const feature = {
    type: 'Feature',
    properties: {
      name: res.name,
      status: res.status,
      slug: res.slug,
      id: res.id,
      type: 'meeting_room',
    },
    geometry: deskGeometry,
  }
  return feature
}

export const mapSuiteItem = res => {
  const suiteGeometry = {
    type: 'MultiPolygon',
    coordinates: [
      [
        [
          [res.x - res.width / 2, res.y + res.length / 2],
          [res.x + res.width / 2, res.y + res.length / 2],
          [res.x + res.width / 2, res.y - res.length / 2],
          [res.x - res.width / 2, res.y - res.length / 2],
        ],
      ],
    ],
  }

  const feature = {
    type: 'Feature',
    properties: {
      name: res.name,
      status: res.status,
      slug: res.slug,
      id: res.id,
      type: 'suite',
    },
    geometry: suiteGeometry,
  }
  return feature
}

export const maxDimensios = floorMap => {
  let maxX = 0
  let maxY = 0
  Object.keys(floorMap).forEach(key => {
    const coordinates = floorMap[key][0].geometry?.coordinates[0]
    coordinates?.map(coordinate => {
      const x = Math.abs(parseInt(coordinate[0], 10))
      const y = Math.abs(parseInt(coordinate[1], 10))
      if (x > maxX) maxX = x
      if (y > maxY) maxY = y
      return { maxX, maxY }
    })
  })
  return { maxX, maxY }
}
