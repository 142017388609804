import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const SingleTitleSection = styled(Grid)`
  border-bottom: 1px solid grey;
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 50px;
  margin-top: 50px;
`
