import styled from 'styled-components'
import Typography from '@material-ui/core/Typography'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

export const Container = styled.div``
export const ValueContainer = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 5px;
`
export const Value = styled.div`
  && {
    display: flex;
    margin-right: 7px;
    p {
      font-weight: 700;
      font-size: ${({ isProminent }) => (isProminent ? '40px' : '24px')};
      line-height: ${({ isProminent }) => (isProminent ? '40px' : '24px')};
      letter-spacing: -1.5px;
    }
  }
`
export const Delta = styled.div`
  color: ${({ isPositive, theme }) => (isPositive ? theme.palette.success.main : theme.palette.error.main)};
`
export const Name = styled(Typography)`
  && {
    color: ${({ theme }) => theme.palette.grey[500]};
    font-size: 14px;
    line-height: 16px;
  }
`
export const Magnitude = styled.span``
export const DeltaStat = styled(Typography)`
  && {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
  }
`
export const ValueStat = styled(Typography)`
  && p {
    font-weight: 700;
  }
`

export const StatArrowUp = styled(ArrowUpwardIcon)`
  && {
    font-size: 14px;
  }
`
export const StatArrowDown = styled(ArrowDownwardIcon)`
  && {
    font-size: 14px;
  }
`
