import styled from 'styled-components'
import Drawer from 'components/organisms/drawer'

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  align-items: stretch;
`

export const Content = styled.div`
  flex-grow: 1;
  padding: 40px 40px 0;
`

export const Sidebar = styled(Drawer)`
  width: 220px;
`

export const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.div`
  margin-bottom: 20px;
`
