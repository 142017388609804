import styled from 'styled-components'
import Drawer from '@material-ui/core/Drawer'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Avatar from '@material-ui/core/Avatar'

export const Container = styled(Drawer)`
  && {
    width: 220;
    .MuiDrawer-paperAnchorLeft {
      width: 220px;
      background-color: ${({ theme }) => theme.palette.primary.main};
    }
  }
`

export const MenuLink = styled(Link)`
  && {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    font-weight: 700;
    position: relative;
    display: block;
    padding: 0;
    text-decoration: none;
    border-left: 4px solid
      ${({ active, theme }) => (active ? theme.palette.secondary.main : theme.palette.primary.main)};
  }
`

export const ItemText = styled(ListItemText)`
  && {
    .MuiTypography-root {
      color: ${({ active }) => (active ? '#fff' : '#9e9e9e')};
      font-weight: 700;
      font-size: 14px;
    }
  }
`

export const HeaderContainer = styled.div`
  padding: 12px 24px;
  margin-bottom: 13px;
`

export const Logo = styled.img``

export const MenuItem = styled(ListItem)`
  && {
    padding: 12px 26px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
`

export const MyAvatar = styled(Avatar)`
  && {
    width: 24px;
    height: 24px;
    object-fit: cover;
  }
`
