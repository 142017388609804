import React, { useEffect, useState } from 'react'
import { getTeamProfile } from 'api'
import { useParams } from 'react-router-dom'
import { Button, CircularProgress, Grid, Modal } from '@material-ui/core'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import DefaultAvatar from 'components/molecules/defaultAvatar/defaultAvatar'
import ModalUpdateTeam from 'components/organisms/teams/modalUpdateTeam'
import {
  AvatarWrapper,
  ProfileContainer,
  ProfileFirstContent,
  ProfileHeaderContent,
  ProfileImgContent,
  ProfileInput,
  ProfileMembershipContent,
  ProfileTeamContent,
  ProfileTitle,
} from '../members/profileStyle'
import ProfileTeamMemberList from '../members/singleTeamMemberList'
import ProfileMembershipList from '../members/singleMembershipList'
import ProfileBookingList from '../bookings/singleBookingsList'
import SingleInvoicesList from '../invoices/singleInvoicesList'
import SingleInvoicesDatasList from '../invoices/singleInvoicesDatasList'

function TeamProfile() {
  const [dataTeam, setDataTeam] = useState(null)
  const params = useParams()
  const { slug } = params
  const [modalTeam, setModalTeam] = useState(false)

  function getDataTeam() {
    setDataTeam(null)
    getTeamProfile(slug).then(response => {
      setDataTeam(response)
    })
  }
  useEffect(() => {
    getDataTeam()
  }, [])

  const noStyle = {
    textDecoration: 'none',
    color: 'white',
  }

  function handleTeamClose() {
    setModalTeam(false)
    getDataTeam()
  }

  // MODAL INVOICE DATA

  const [modalInvoiceData, setModalInvoiceData] = useState({
    show: false,
    invData: null,
  })

  if (dataTeam !== null) {
    return (
      <ProfileContainer>
        <Modal open={modalTeam} onClose={handleTeamClose}>
          <ModalUpdateTeam team={dataTeam} toClose={handleTeamClose} />
        </Modal>
        <ProfileFirstContent>
          <Grid container direction='row' justify='flex-start' alignItems='center' spacing={1}>
            <Grid item xs={2}>
              <AvatarWrapper>
                <ProfileImgContent>
                  {dataTeam?.image ? (
                    <img src={dataTeam?.image} alt={`${dataTeam?.team.name}`} />
                  ) : (
                    <DefaultAvatar first={dataTeam?.team.name} />
                  )}
                </ProfileImgContent>
                <Button onClick={() => setModalTeam(true)} variant='outlined' size='small'>
                  Edit
                </Button>
              </AvatarWrapper>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction='column' justify='flex-start'>
                <Grid item>
                  <ProfileTitle>{dataTeam?.team.name}</ProfileTitle>
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <ProfileInput>{`Status: ${dataTeam?.team.status}`}</ProfileInput>
                    </Grid>
                    <Grid item>
                      <ProfileInput>{`Description: ${dataTeam?.team.description}`}</ProfileInput>
                    </Grid>
                    <Grid item>
                      <ProfileInput>
                        {`Office: ${dataTeam?.team?.office ? dataTeam?.team?.office?.name : '-'}`}
                      </ProfileInput>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ProfileFirstContent>
        <ProfileTeamContent>
          <ProfileInput>Team Members</ProfileInput>
          <ProfileTeamMemberList teamId={dataTeam?.team_mates} />
        </ProfileTeamContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <ProfileInput>Team Memberships</ProfileInput>
            <Button variant='contained' color='secondary' onClick={() => {}}>
              <a href='/members/memberships/add' style={noStyle}>
                Add Membership
              </a>
            </Button>
          </ProfileHeaderContent>
          <ProfileMembershipList memberships={dataTeam?.memberships} />
        </ProfileMembershipContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <ProfileInput>Team Bookings</ProfileInput>
            <Button variant='contained' color='secondary' onClick={() => {}}>
              <a href='/bookings' style={noStyle}>
                Add Booking
              </a>
            </Button>
          </ProfileHeaderContent>
          <ProfileBookingList bookings={dataTeam?.bookings} />
        </ProfileMembershipContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <ProfileInput>Team Invoices</ProfileInput>
            <Button variant='contained' color='secondary' onClick={() => {}}>
              <a href='/members/invoices/add' style={noStyle}>
                Add Invoice
              </a>
            </Button>
          </ProfileHeaderContent>
          <SingleInvoicesList invoices={dataTeam?.invoices} />
        </ProfileMembershipContent>
        <ProfileMembershipContent>
          <ProfileHeaderContent>
            <ProfileInput>Team Invoice Data</ProfileInput>
            <Button
              variant='contained'
              color='secondary'
              onClick={() => setModalInvoiceData({ ...modalInvoiceData, show: true })}
            >
              Add Invoice Data
            </Button>
          </ProfileHeaderContent>
          <SingleInvoicesDatasList
            invoicesData={dataTeam?.invoice_datas}
            team={dataTeam.team.slug}
            modal={modalInvoiceData}
            setModalInvoiceData={setModalInvoiceData}
          />
        </ProfileMembershipContent>
      </ProfileContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default TeamProfile
