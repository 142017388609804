import { useEffect, useState } from 'react'
import { Grid, Button, CircularProgress, Modal } from '@material-ui/core'
import { getOffices } from 'api'
import formatWords from 'components/atoms/formatWords/formatWords'
import DefaultAvatar from 'components/molecules/defaultAvatar/defaultAvatar'
import { SpinnerContainer } from '../members/newListStyle'
import { AvatarWrapper, ProfileImgContent } from '../members/profileStyle'
import ModalUpdateProfile from './modalUpdateProfile'

function ProfileSingle() {
  const [profileData, setProfileData] = useState()
  const [modalUpdate, setModalUpdate] = useState(false)

  function getProfileData() {
    getOffices().then(r => setProfileData(r))
  }

  useEffect(() => {
    if (profileData === undefined) getProfileData()
  }, [profileData])

  const u = profileData?.user
  function toClose() {
    setModalUpdate(false)
  }

  return (
    <Grid>
      <Modal open={modalUpdate === true}>
        <ModalUpdateProfile toClose={toClose} data={profileData} />
      </Modal>
      {profileData !== undefined ? (
        <Grid container direction='column' style={{ marginTop: '25px' }}>
          <Grid item>
            <Grid container spacing={2} style={{ marginLeft: '25px' }}>
              <Grid item>
                <AvatarWrapper>
                  <ProfileImgContent>
                    {u?.avatar !== null ? (
                      <img src={u?.avatar} alt={`${u?.first_name}`} style={{ width: '100px', borderRadius: '50px' }} />
                    ) : (
                      <DefaultAvatar first={u?.first_name} last={u?.last_name} />
                    )}
                  </ProfileImgContent>
                  <Button onClick={() => setModalUpdate(true)} variant='outlined' size='small'>
                    Edit
                  </Button>
                </AvatarWrapper>
              </Grid>
              <Grid item>
                <Grid container direction='column' spacing={1}>
                  <Grid item style={{ fontSize: '20px', color: 'black', fontWeight: 600 }}>
                    {`${u.first_name} ${u.last_name}`}
                  </Grid>
                  <Grid item style={{ color: 'black', fontWeight: 500 }}>
                    {`Email: ${u.email} - Phone: ${u.phone}`}
                  </Grid>
                  <Grid item>{`Role: ${formatWords(profileData.role)}`}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

export default ProfileSingle
