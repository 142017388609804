import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { CircularProgress } from '@material-ui/core'
import { ListContainer, ListHeader, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItemTitle, BoxRow, BoxItem } from '../invoices/scrollListStyles'

function SingleOrderLinesList({ theOrder }) {
  useEffect(() => {}, [theOrder])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>Product</BoxItemTitle>
              <BoxItemTitle>Price</BoxItemTitle>
              <BoxItemTitle>Discount</BoxItemTitle>
              <BoxItemTitle>Qty</BoxItemTitle>
              <BoxItemTitle>Status</BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {theOrder !== undefined ? (
            theOrder.order_lines.map((ol, i) => (
              <ListRow key={i}>
                <BoxRow>
                  <BoxItem>{ol.product.name}</BoxItem>
                  <BoxItem>£{ol.product_price}</BoxItem>
                  <BoxItem>{ol.discount}</BoxItem>
                  <BoxItem>{ol.quantity}</BoxItem>
                  <BoxItem>{formatWords(ol.status)}</BoxItem>
                </BoxRow>
              </ListRow>
            ))
          ) : (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          )}
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

SingleOrderLinesList.propTypes = {
  theOrder: PropTypes.object,
}

SingleOrderLinesList.defaultProps = {
  theOrder: {},
}

export default SingleOrderLinesList
