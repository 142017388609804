import React from 'react'
import PropType from 'prop-types'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { yupResolver } from '@hookform/resolvers/yup'
import { SET_RESOURCE } from 'api/mutations'
import { useForm } from 'react-hook-form'
import validate from './validate'
import ResourcesAddForm from './resourcesAddForm'

function ResourcesAdd({ ...props }) {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })
  const mutateLogin = useMutation(SET_RESOURCE, {
    onSuccess: () => {
      console.log('success')
      enqueueSnackbar(t('login.success'), {
        variant: 'success',
      })
    },
    onError: () => {
      console.log('error')
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
    },
  })

  const { office } = props

  const onSubmit = data => {
    console.log('ONSUBMIT', data)
    mutateLogin.mutate(data)
  }
  return (
    <ResourcesAddForm {...props} control={control} errors={errors} onSubmit={handleSubmit(onSubmit)} office={office} />
  )
}

ResourcesAdd.propTypes = {
  office: PropType.object,
}

ResourcesAdd.defaultProps = {
  office: {},
}

export default ResourcesAdd
