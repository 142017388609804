import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { newGetTeams } from 'api'
import { CircularProgress, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SpinnerContainer } from 'components/template/layout/styled'
import {
  BillingSection,
  Container,
  EmailInput,
  FirstNameInput,
  JobTitleInput,
  MemberSection,
  PhoneInput,
  RoleInput,
  SecondNameInput,
  StreetInput,
  Subtitle,
  Title,
  Toolbar,
  TownInput,
} from './styled'

const LoginFormView = ({ onSubmit, control, errors }) => {
  const { t } = useTranslation()
  const [teamsData, setTeams] = useState()

  function getData(value) {
    const query = {
      pageSize: '100',
      currentPage: '1',
      filter: {
        name: value === undefined ? '' : value,
      },
    }
    newGetTeams(query).then(response => setTeams(response.teams))
  }
  useEffect(() => {
    if (teamsData === undefined) getData()
  }, [teamsData])

  if (teamsData !== undefined) {
    return (
      <Container onSubmit={onSubmit}>
        <Toolbar>
          <Button variant='contained' color='secondary' type='submit'>
            {t('members.addMember')}
          </Button>
        </Toolbar>
        <Title>{t('members.form.addMember')}</Title>
        <MemberSection>
          <FirstNameInput
            label={t('members.form.firstName')}
            name='first_name'
            control={control}
            error={errors?.first_name}
          />
          <SecondNameInput
            label={t('members.form.secondName')}
            name='last_name'
            control={control}
            error={errors?.last_name}
          />
          <EmailInput
            label={t('members.form.contactEmailAddress')}
            name='email'
            control={control}
            error={errors?.email}
          />
          <RoleInput
            label={t('members.form.role')}
            name='role'
            control={control}
            error={errors?.role}
            options={[
              { id: 'team_member', text: t('members.form.teamMember') },
              { id: 'team_admin', text: t('members.form.teamAdmin') },
              { id: 'referral', text: t('members.form.referral') },
            ]}
          />
          <JobTitleInput
            label={t('members.form.jobTitle')}
            name='job_title'
            control={control}
            error={errors?.job_title}
          />
          <PhoneInput
            label={t('members.form.contactPhoneNumber')}
            name='phone'
            control={control}
            error={errors?.phone}
          />
          <Autocomplete
            name='teams'
            options={teamsData !== undefined ? teamsData : ['no data']}
            getOptionLabel={option => option.name}
            style={{ width: 200 }}
            renderInput={params => <TextField {...params} label='Teams' variant='outlined' />}
            onInputChange={(event, value) => {
              getData(value)
            }}
            errors={errors?.work_area}
          />
        </MemberSection>
        <BillingSection>
          <Subtitle>{t('members.form.billingAddress')}</Subtitle>
          <TownInput label={t('members.form.townCity')} name='town' control={control} error={errors?.town} />
          <StreetInput
            label={t('members.form.streetAndNumber')}
            name='street'
            control={control}
            error={errors?.street}
          />
        </BillingSection>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  office: PropTypes.object,
}

LoginFormView.defaultProps = {
  errors: null,
  office: {},
}

export default LoginFormView
