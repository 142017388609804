import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import { newGetMembers, setNewInvoiceLine, updateInvoiceLine, deleteInvoiceLine, getAvailableInvoices } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import React, { useEffect, useRef, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { DatePicker } from '@material-ui/pickers'
import {
  CloseIcon,
  Col2,
  Col3,
  Col4,
  ModalCard,
  ModalHeader,
  ModalItem,
  ModalTitle,
  SubmitBtn,
  DeleteButton,
} from '../bookings/modalStyles'
import { LineDetail } from './invoicesStyles'
// eslint-disable-next-line import/named
import {
  calculateGrossPrice,
  calculateTotalLine,
  calculateGrossTotalLine,
  calculateNetPrice,
} from '../../../utils/utils'

function ModalCreateInvoiceLine(props) {
  // const { t } = useTranslation()
  const { invoice, line, office } = props
  const { toClose } = props
  console.log('Lines is ', line)
  const [lineData, setLineData] = useState({
    invoice: line ? invoice.id : invoice.slug,
    team: `${invoice.team.slug}`,
    name: line?.name,
    date: line ? line.create_date : new Date(),
    units: line?.units,
    vat: line ? line.vat : '20',
    payment_type: line?.payment_type,
    payment_reference: line?.payment_reference,
    unit_price: line?.unit_price,
    net_price: calculateGrossPrice(line?.unit_price, line?.vat),
    status: line?.status,
  })

  const [members, setMembers] = useState([])
  const [invoices, setInvoices] = useState([])
  const [updating, setUpdating] = useState(false)

  const validated = useRef(false)

  const [errors, setErrors] = useState({
    name: null,
    date: null,
    units: null,
    vat: null,
    unit_price: null,
    payment_reference: null,
    status: null,
    reference: null,
  })

  function validation(data) {
    console.log(data)
    const textErrors = {
      name: 'Name is required',
      date: 'Date is required',
      units: 'Units is required',
      status: 'Status is required',
      reference: 'Payment reference is requiered for paid invoices',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const dateRes = data?.date === undefined || data?.date === '' ? textErrors.date : null
    const unitsRes = data?.units === undefined || data?.units === 0 ? textErrors.units : null
    const vatRes = data?.vat === undefined || data?.vat < 0 || data?.vat > 100 ? textErrors.vat : null
    const statusRes = data?.status === undefined || data?.status === 0 ? textErrors.status : null
    const referenceRes =
      data?.status === 'paid' && (data?.payment_reference === undefined || data?.payment_reference === 0)
        ? textErrors.reference
        : null

    setErrors({
      name: nameRes,
      date: dateRes,
      units: unitsRes,
      vat: vatRes,
      status: statusRes,
      reference: referenceRes,
    })

    validated.current =
      nameRes === null &&
      dateRes === null &&
      unitsRes === null &&
      vatRes === null &&
      statusRes === null &&
      referenceRes === null
  }

  const findMember = (id, memberList) => {
    const member = memberList.filter(m => m.id === id)
    return member.length ? member[0] : null
  }

  function getDataMembers(data) {
    newGetMembers({
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: office,
        name: data !== undefined ? data : '',
      },
    }).then(response => {
      if (line) {
        setLineData({ ...lineData, member: findMember(line.member, response.members) })
      }
      setMembers(response.members)
    })
  }

  function getDataInvoices() {
    getAvailableInvoices(invoice.slug)
      .then(results => {
        setInvoices(results)
        console.log(results)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getDataMembers()
    getDataInvoices()
  }, [])

  useEffect(() => {
    const localGrossPrice = calculateGrossPrice(lineData.unit_price, lineData.vat)
    if (localGrossPrice !== lineData.net_price) {
      setLineData({ ...lineData, net_price: localGrossPrice })
    }
  }, [lineData.unit_price])

  useEffect(() => {
    const localPrice = calculateNetPrice(lineData.net_price, lineData.vat)
    if (localPrice !== lineData.unit_price) {
      setLineData({ ...lineData, unit_price: localPrice })
    }
  }, [lineData.net_price])

  useEffect(() => {
    const localPrice = calculateNetPrice(lineData.net_price, lineData.vat)
    if (localPrice !== lineData.unit_price) {
      setLineData({ ...lineData, unit_price: localPrice })
    }
  }, [lineData.vat])

  function createNewLine() {
    setUpdating(true)
    if (validated.current) {
      // eslint-disable-next-line camelcase
      const unit_gross_price = calculateGrossPrice(lineData.unit_price, lineData.vat)
      // eslint-disable-next-line camelcase
      const line_gross_price = calculateGrossTotalLine(lineData.unit_price, lineData.vat, lineData.units)
      // eslint-disable-next-line camelcase
      const line_price = calculateTotalLine(lineData.unit_price, lineData.units)
      const data = {
        ...lineData,
        unit_gross_price,
        line_gross_price,
        line_price,
        member: line ? lineData.member?.id : lineData.member?.slug,
      }
      if (line === null) {
        setNewInvoiceLine(data).then(() => {
          setUpdating(false)
          toClose()
          //window.location.reload()
        })
      } else {
        updateInvoiceLine(data, line.slug).then(() => {
          toClose()
          setUpdating(false)
          //window.location.reload()
        })
      }
    } else {
      setUpdating(false)
    }
  }

  function deleteLine(action) {
    console.log('Calling delete line with action ', action)
    if (action) {
      deleteInvoiceLine(line.slug, action).then(() => {
        toClose()
      })
    }
  }

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{line ? 'Update Line' : 'Add Line'}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col2>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='invoice-line-name-id'
            name='name'
            value={lineData?.name}
            onChange={value => setLineData({ ...lineData, name: value.target.value })}
            error={errors.name !== null}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
        {((members && line && lineData?.member !== undefined) || (members && !line)) && (
          <ModalItem>
            <FormControl>
              <Autocomplete
                disabled={!members}
                name='member'
                defaultValue={lineData?.member}
                options={members}
                getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                renderInput={p => <TextField {...p} label='Member' variant='outlined' />}
                onChange={(e, data) => {
                  setLineData({ ...lineData, member: data })
                }}
                onInputChange={(e, data) => getDataMembers(data)}
                {...props}
              />
            </FormControl>
          </ModalItem>
        )}
      </Col2>
      <Col3>
        <ModalItem>
          <FormControl>
            <Autocomplete
              disabled={!invoices}
              name='Invoice'
              defaultValue={invoice}
              options={invoices}
              getOptionLabel={option =>
                `${option.name} - ${new Date(option.date).getDate()} ${new Date(option.date).toLocaleDateString(
                  'en-GB',
                  { month: 'short' }
                )}`
              }
              renderInput={p => <TextField {...p} label='Invoice' variant='outlined' />}
              onChange={(e, data) => {
                setLineData({ ...lineData, invoice: data?.slug })
              }}
              {...props}
            />
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl>
            <DatePicker
              variant='outlined'
              inputVariant='outlined'
              label='Date'
              name='date'
              format='dd/MM/yyyy'
              error={errors?.date !== null}
              value={lineData?.date}
              onChange={value => setLineData({ ...lineData, date: value })}
            />
            {errors.date === null ? '' : <SmallError>{errors.date}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl error={errors.status !== null}>
            <InputLabel style={{ marginLeft: '15px' }}>Status</InputLabel>
            <Select
              label='Status'
              variant='outlined'
              defaultValue={line?.status}
              value={lineData?.status}
              onChange={value => {
                if (value.target.value) {
                  setLineData({ ...lineData, status: value.target.value })
                }
              }}
            >
              {line?.status !== 'paid' && <MenuItem value='pending_payment'>Pending</MenuItem>}
              <MenuItem value='paid'>Paid</MenuItem>
              {line && line?.status !== 'paid' && (invoice?.status === 'open' || invoice?.status === 'on_review') && (
                <MenuItem value='void'>Void</MenuItem>
              )}
            </Select>
            {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
          </FormControl>
        </ModalItem>
      </Col3>
      <Col4>
        <ModalItem>
          <TextField
            type='number'
            label='Units'
            variant='outlined'
            id='invoice-units-id'
            name='units'
            InputProps={{
              inputProps: { min: 1 },
            }}
            defaultValue={line?.units}
            value={lineData?.units}
            onChange={value => setLineData({ ...lineData, units: value.target.value })}
            error={errors.units !== null}
          />
          {errors.units === null ? '' : <SmallError>{errors.units}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            type='number'
            label='Price ex VAT'
            variant='outlined'
            id='invoice-unit-price-id'
            name='unit_price'
            defaultValue={line?.net_price}
            value={lineData?.net_price}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={value => setLineData({ ...lineData, net_price: value.target.value })}
          />
        </ModalItem>
        <ModalItem>
          <TextField
            type='number'
            label='Price in VAT'
            variant='outlined'
            id='invoice-unit-price-id'
            name='unit_price'
            defaultValue={line?.unit_price}
            value={lineData?.unit_price}
            InputLabelProps={{
              shrink: true,
            }}
            onChange={value => setLineData({ ...lineData, unit_price: value.target.value })}
          />
        </ModalItem>
        <ModalItem>
          <TextField
            type='number'
            label='% Tax rate'
            variant='outlined'
            id='invoice-vat-id'
            name='vat'
            defaultValue={lineData?.vat}
            value={lineData?.vat}
            InputProps={{
              inputProps: { min: 0 },
              shrink: true,
            }}
            onChange={value => {
              setLineData({ ...lineData, vat: value.target.value })
            }}
            error={errors.vat !== null}
          />
          {errors.vat === null ? '' : <SmallError>{errors.vat}</SmallError>}
        </ModalItem>
      </Col4>
      <Col2>
        <ModalItem>
          <TextField
            label='Payment Reference'
            variant='outlined'
            id='invoice-line-reference'
            name='name'
            value={lineData?.payment_reference}
            onChange={value => setLineData({ ...lineData, payment_reference: value.target.value })}
            error={errors.reference !== null}
          />
          {errors.reference === null ? '' : <SmallError>{errors.reference}</SmallError>}
        </ModalItem>
        {lineData?.vat && lineData?.unit_price && lineData?.units ? (
          <ModalItem>
            <LineDetail>
              {`Total: £${calculateTotalLine(lineData.unit_price, lineData.units)} ${
                line?.credit_note ? '(Credited)' : ''
              }`}{' '}
            </LineDetail>
          </ModalItem>
        ) : (
          ''
        )}
      </Col2>

      {updating && <CircularProgress color='secondary' />}
      {!updating && line?.status !== 'paid' && !line?.credit_note && (
        <SubmitBtn
          onClick={() => {
            validation(lineData)
            createNewLine()
          }}
        >
          {line ? 'Update Line' : 'Add Line'}
        </SubmitBtn>
      )}
      {!updating && invoice?.status === 'on_review' && !line?.credit_note && (
        <SubmitBtn
          onClick={() => {
            validation(lineData)
            createNewLine()
          }}
        >
          Update Line
        </SubmitBtn>
      )}
      {line &&
        line?.related_bookings &&
        line.related_bookings.map(booking => (
          <Link to={`/bookings/${booking.code}`} style={{ textDecoration: 'none' }}>
            View booking
          </Link>
        ))}
      {line &&
        line.status === 'paid' &&
        (invoice.status === 'paid' || invoice.status === 'pending_payment') &&
        !line?.credit_note && <DeleteButton onClick={() => deleteLine('void_refund')}>Refund</DeleteButton>}
      {line &&
        line.status === 'pending_payment' &&
        (invoice.status === 'paid' || invoice.status === 'pending_payment') &&
        !line?.credit_note && <DeleteButton onClick={() => deleteLine('void_refund')}>Credit line</DeleteButton>}
      {line && line.status === 'paid' && invoice.status === 'open' && line.credit_none === null && (
        <DeleteButton onClick={() => deleteLine('void_refund')}>Refund</DeleteButton>
      )}
      {line && line.status === 'pending_payment' && invoice.status === 'open' && (
        <DeleteButton onClick={() => deleteLine('void')}>Void</DeleteButton>
      )}
    </ModalCard>
  )
}

ModalCreateInvoiceLine.propTypes = {
  toClose: PropTypes.func,
  invoice: PropTypes.object,
  line: PropTypes.object,
  office: PropTypes.string,
}

ModalCreateInvoiceLine.defaultProps = {
  toClose: () => {},
  invoice: null,
  line: null,
  office: '',
}

export default ModalCreateInvoiceLine
