import { FormControl, InputLabel, MenuItem, Select, TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getTeamsWithInvoiceData, putInvoice } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { format } from 'date-fns'
import { useTranslation } from 'react-i18next'
import {
  CloseIcon,
  Col2,
  Col4,
  ModalCard,
  ModalHeader,
  ModalItem,
  ModalTitle,
  SubmitBtn,
} from '../bookings/modalStyles'
import DateInputView from '../../atoms/dateInput/view'

function ModalUpdateInvoice(props) {
  const { t } = useTranslation()

  const { invoice, office } = props
  const { toClose } = props

  const [invoiceFields, setInvoiceFields] = useState(invoice)
  const [invoiceData, setInvoiceData] = useState(invoice.invoice_data)
  const [updating, setUpdating] = useState(false)
  const validated = useRef(false)

  const [errors, setErrors] = useState({
    name: null,
    team: null,
    invoice_data: null,
    status: null,
    payment_reference: null,
    invoice_type: null,
    date: null,
    date_due: null,
  })

  const [teamsData, setTeams] = useState()

  function validation(data) {
    console.log(data)
    const textErrors = {
      name: 'Name is required',
      team: 'Team is required',
      invoice_data: 'Invoice Data is required',
      status: 'Status is required',
      invoice_type: 'Type is required',
      date: 'Date is required',
      date_due: 'Date Due is required',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const teamRes = data?.team === undefined || data?.team === '' ? textErrors.team : null
    const invoiceDataRes = data?.invoice_data === undefined || data?.invoice_data === '' ? textErrors.name : null
    const statusRes = data?.status === undefined || data?.status === '' ? textErrors.status : null
    const invoiceTypeRes =
      data?.invoice_type === undefined || data?.invoice_type === '' ? textErrors.invoice_type : null
    const dateRes = data?.date === undefined || data?.date === '' ? textErrors.date : null
    const dateDueRes = data?.date_due === undefined || data?.date_due === '' ? textErrors.date_due : null

    setErrors({
      name: nameRes,
      team: teamRes,
      invoice_data: invoiceDataRes,
      status: statusRes,
      invoice_type: invoiceTypeRes,
      date: dateRes,
      date_due: dateDueRes,
      payment_reference: null,
    })

    validated.current =
      nameRes === null &&
      teamRes === null &&
      invoiceDataRes === null &&
      statusRes === null &&
      invoiceTypeRes === null &&
      dateRes === null &&
      dateDueRes === null
  }
  function getData(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: office,
        name: value === undefined ? '' : value,
      },
    }
    getTeamsWithInvoiceData(query)
      .then(response => {
        setTeams(response.teams)
        const team = response.teams.find(item => item.id === invoice.team.id)
        setInvoiceData(team.datas)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getData()
  }, [])

  function updateInvoice() {
    setUpdating(true)
    if (validated.current) {
      let updatedInvoice = {
        ...invoiceFields,
        team: invoiceFields.team.slug,
        invoice_data: invoiceFields.invoice_data?.slug,
      }
      if (invoiceFields.date) updatedInvoice = { ...updatedInvoice, date: format(new Date(invoiceFields.date), 'yyyy-MM-dd hh:ii') }
      if (invoiceFields.date_due) updatedInvoice = { ...updatedInvoice, date_due: format(new Date(invoiceFields.date_due), 'yyyy-MM-dd hh:ii') }
      putInvoice(updatedInvoice, invoice.slug).then(() => {
        toClose()
        setUpdating(false)
      })
    }
  }

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>Update Invoice</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col2>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='invoice-line-name-id'
            name='name'
            disabled
            value={invoiceFields.name}
            // onChange={value => setLineData({ ...lineData, name: value.target.value })}
            error={errors.name !== null}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl>
            <Autocomplete
              name='autocompleteTeams'
              options={teamsData !== undefined ? teamsData : ['no data']}
              getOptionLabel={option => option.name}
              defaultValue={invoice.team}
              value={invoiceFields.team}
              renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
              onChange={(e, data) => {
                setInvoiceData(data?.datas)
                setInvoiceFields({ ...invoiceFields, team: data, invoice_data: null })
              }}
              onInputChange={(e, data) => getData(data)}
              {...props}
            />
            {errors.team === null ? '' : <SmallError>{errors.team}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl>
            <Autocomplete
              name='autocompleteInvoiceData'
              options={invoiceData !== undefined ? invoiceData : ['no data']}
              getOptionLabel={option => option.name}
              defaultValue={invoice.invoice_data}
              value={invoiceFields.invoice_data}
              renderInput={params => <TextField {...params} label='Invoice Data' variant='outlined' />}
              onChange={(e, data) => {
                setInvoiceFields({ ...invoiceFields, invoice_data: data })
              }}
              {...props}
            />
            {errors.invoice_data === null ? '' : <SmallError>{errors.invoice_data}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <TextField
            label='Payment Reference'
            variant='outlined'
            id='invoice-payment-reference-input-id'
            name='payment_reference'
            value={invoice?.payment_reference}
            onChange={value => setInvoiceFields({ ...invoiceFields, payment_reference: value.target.value })}
            error={errors.payment_reference !== null}
          />
          {errors.payment_reference === null ? '' : <SmallError>{errors.payment_reference}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label-type'>Type</InputLabel>
            <Select
              label='Type'
              value={invoiceFields?.invoice_type}
              onChange={value => {
                setInvoiceFields({ ...invoiceFields, invoice_type: value.target.value })
              }}
              error={errors.invoice_type !== null}
            >
              <MenuItem value='work'>Work</MenuItem>
              <MenuItem value='bar'>Bar</MenuItem>
              <MenuItem value='event'>Event</MenuItem>
              <MenuItem value='other'>Other</MenuItem>
            </Select>
            {errors.invoice_type === null ? '' : <SmallError>{errors.invoice_type}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <DateInputView
              variant='outlined'
              inputVariant='outlined'
              label='Date'
              name='date'
              value={invoiceFields?.date}
              onChange={value => {
                setInvoiceFields({ ...invoiceFields, date: value })
              }}
              error={errors?.date !== null}
            />
            {errors.date === null ? '' : <SmallError>{errors.date}</SmallError>}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <DateInputView
            variant='outlined'
            inputVariant='outlined'
            label='Date due'
            name='date_due'
            value={invoiceFields?.date_due}
            onChange={value => {
              setInvoiceFields({ ...invoiceFields, date_due: value })
            }}
            error={errors?.date_due}
          />
        </ModalItem>
        <ModalItem>
          <TextField
            label='Xero Id'
            variant='outlined'
            id='invoice-xero-id'
            name='xero_id'
            disabled
            value={invoiceFields.xero_id}
          />
        </ModalItem>
      </Col2>
      <Col4>
        <ModalItem>
          <TextField
            label='Gross Price'
            variant='outlined'
            id='invoice-gross-price-id'
            name='gross_price'
            disabled
            value={invoiceFields.gross_price}
          />
        </ModalItem>
        <ModalItem>
          <TextField
            label='Vat Type'
            variant='outlined'
            id='invoice-vat-type-id'
            name='vat_type'
            disabled
            value={invoiceFields.vat_type}
          />
        </ModalItem>
        <ModalItem>
          <TextField label='Vat' variant='outlined' id='invoice-vat-id' name='vat' disabled value={invoiceFields.vat} />
        </ModalItem>
        <ModalItem>
          <TextField
            label='Net Price'
            variant='outlined'
            id='invoice-net-id'
            name='net_price'
            disabled
            value={invoiceFields.net_price}
          />
        </ModalItem>
      </Col4>

      {updating ? (
        <CircularProgress color='secondary' />
      ) : (
        <SubmitBtn
          onClick={() => {
            validation(invoiceFields)
            updateInvoice()
          }}
        >
          {t('invoices.form.editInvoice')}
        </SubmitBtn>
      )}
    </ModalCard>
  )
}

ModalUpdateInvoice.propTypes = {
  toClose: PropTypes.func,
  invoice: PropTypes.object,
  office: PropTypes.object,
}

ModalUpdateInvoice.defaultProps = {
  toClose: () => {},
  invoice: null,
  office: null,
}

export default ModalUpdateInvoice
