import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress, Grid, Button } from '@material-ui/core'
import PeopleIcon from '@material-ui/icons/People'
import WifiIcon from '@material-ui/icons/Wifi'
import WarningIcon from '@material-ui/icons/Warning'
import EditIcon from '@material-ui/icons/Edit'
import { colors } from 'config/colors'
import { ButtonCard } from 'components/atoms/styles/commonStyles'
import { SpinnerContainer } from '../members/newListStyle'

function BookingPanelCard({ item, setBookingSelected, updateData, setState, state, profile }) {
  const [overBtn, setOver] = useState(false)
  console.log(item)
  let theName = item.member !== null ? `${item.member?.user.first_name} ${item.member?.user.last_name}` : '-'
  if (item.guest !== null) theName = `${item.guest.name} (${item.member?.user.first_name} ${item.member?.user.last_name})`
  if (item.guest !== null && item.member === null) theName = `${item.guest.name}`
  const start = item.planned_start?.split('T')
  const end = item.planned_end?.split('T')
  const theTimes = `${start[1].slice(0, 5)}-${end[1].slice(0, 5)}`
  const [imMember] = useState(profile?.role === 'team_admin' || profile?.role === 'team_member')
  const [imOfficeManager] = useState(profile?.role === 'office_manager')
  function toHex(str) {
    let result = ''
    for (let i = 0; i < str.length; i += 1) {
      result += str.charCodeAt(i).toString(14)
    }
    return result
  }

  function formatRes() {
    const waData = item.work_area?.name?.split(' ')
    const waLetters = []
    waData?.map(word => {
      if (waData.length > 2) waLetters.push(word.charAt(0))
      if (waData.length <= 2) {
        waLetters.push(word.charAt(0))
        if (word.length > 1) waLetters.push(word.charAt(1))
      }
      return waLetters
    })
    let res = ''
    let color = colors.gray
    switch (item.resource) {
      case null:
        res = waLetters.toString().replace(/,/g, '').toUpperCase()
        break
      case item.real_start !== null:
        color = colors.green
        break
      default:
        res = item.resource.name
    }
    if (item.real_start !== null) color = colors.green
    return <Grid style={{ fontSize: '16px', fontWeight: 700, color, margin: 0 }}>{res}</Grid>
  }

  function formatPrice() {
    let thePrice = ''
    if (item.invoice_line === null) {
      thePrice = (
        <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.red }}>
          {item.membership === null ? `£${item.single_price}` : 'PRE-PAID'}
        </Grid>
      )
    } else {
      switch (item.invoice_line?.status) {
        case 'pending_payment':
          thePrice = (
            <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.red }}>
              {item.membership === null ? `£${item.single_price}` : 'PRE-PAID'}
            </Grid>
          )
          break
        default:
          thePrice = (
            <Grid
              style={{ fontSize: '12px', fontWeight: 900, color: item.origin === 'trial' ? colors.gray : colors.green }}
            >
              {item.membership === null ? `£${item.single_price}` : 'PRE-PAID'}
            </Grid>
          )
      }
    }
    if (item.origin === 'deference') thePrice = <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.gray }}>Complimentary</Grid>
    if (item.origin === 'trial') thePrice = <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.gray }}>Trial</Grid>
    if (item.origin === 'referral') {
      thePrice = (
        <Grid style={{ fontSize: '12px', fontWeight: 900, color: colors.gray }}>
          {`£${item.single_price ? item.single_price : 0}`}
        </Grid>
      )
    }

    return thePrice
  }

  function overOptions() {
    let option = ''
    switch (item.resource) {
      case null:
        option = 'Assign'
        break
      default:
        option = 'Check In'
        if (item.real_start !== null || imMember) option = item.resource.name
    }

    return <Grid style={{ fontSize: '16px', fontWeight: 700, margin: 0, padding: 0 }}>{option}</Grid>
  }

  function updateModalData(e) {
    console.log(item)
    updateData(item)
    if (e.target.innerText !== 'Check In' && e.target.innerText !== 'Assign') console.log(e.target.innerText)
    //e.preventDefault()
  }

  useEffect(() => {}, [overBtn])
  let mainName = ''
  let secondaryName = theName
  let thirdName = ''
  if (imOfficeManager) {
    if (item.origin === 'referral') {
      mainName = item.client?.name.toUpperCase()
      secondaryName = item?.booking_reference ? `${item?.booking_reference}` : ''
      thirdName = item.team ? item.team?.name.toUpperCase() : theName
    } else if (item.client.slug === profile.active_client?.slug) {
      if (item.team) {
        mainName = item.team?.name.toUpperCase()
      } else {
        mainName = theName
      }
      if (item.member) {
        secondaryName = `${item.member?.user?.first_name} ${item.member?.user?.last_name}`
      }
    } else {
      mainName = item.client?.name.toUpperCase()
      secondaryName = item.team ? item.team?.name.toUpperCase() : ''
    }
  } else {
    mainName = theName === '-' ? 'No member assigned' : theName
    secondaryName = item.team ? item.team?.name.toUpperCase() : ''
  }
  let hexColor = colors.softgray
  if (item?.common_reference) {
    hexColor = `#${toHex(item?.common_reference).slice(0, 6)}`
  }

  return (
    <Grid container style={{ boxShadow: '2px 3px 8px rgba(0, 0, 0, 0.2)' }}>
      {item !== undefined ? (
        <ButtonCard style={{ margin: 0, padding: 0 }}>
          <Grid
            container
            direction='column'
            style={{ border: `1px solid ${colors.softgray}`, borderRadius: '5px', padding: '2px 10px' }}
            sx={{ boxShadow: 2 }}
          >
            <Grid item>
              <Grid container direction='row' justify='space-between'>
                <Grid item>
                  <Grid container direction='column' justify='flex-start'>
                    <Grid item style={{ fontSize: '16px', textAlign: 'start' }}>
                      {mainName}
                    </Grid>
                    <Grid item style={{ fontSize: '13px', textAlign: 'start', color: colors.gray }}>
                      {secondaryName}
                      {item.status === 'alert' && <WarningIcon style={{ fontSize: '13px', color: colors.red }} />}
                    </Grid>
                    {thirdName !== '' && (
                      <Grid item style={{ fontSize: '13px', textAlign: 'start', color: colors.gray }}>
                        {thirdName}
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' alignItems='center' spacing={2} style={{ marginRight: '5px' }}>
                    <Grid item>{theTimes}</Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginTop: '10px' }}>
                <Grid item xs={4} style={{ textAlign: 'center' }}>
                  {imMember && item.resource !== null ? (
                    <Grid style={{ fontSize: '16px', fontWeight: 700, margin: 0, padding: 0 }}>
                      {item.resource?.name}
                    </Grid>
                  ) : (
                    <Button
                      style={{ width: '100%', margin: 0, padding: 0, minHeight: 0, minWidth: 0 }}
                      name='btn-resource'
                      variant='text'
                      onMouseOver={() => setOver(true)}
                      onMouseOut={() => setOver(false)}
                      onClick={() => {
                        if (state === 'selecting') setBookingSelected(item.code)
                        setState('assigning')
                      }}
                    >
                      {overBtn === false ? formatRes() : overOptions()}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center', fontSize: '13px' }}>
                  {profile?.role !== 'team_member' && formatPrice()}
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  <EditIcon
                    onClick={e => updateModalData(e)}
                    style={{
                      fontSize: '20px',
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  <WifiIcon
                    style={{
                      fontSize: '20px',
                      color:
                        item.member?.presence === 'network_on' || item.member?.presence === 'checked_in'
                          ? colors.green
                          : colors.gray,
                    }}
                  />
                </Grid>
                <Grid item xs={2} style={{ textAlign: 'center' }}>
                  {!imMember && item?.common_reference && (
                    <Grid item style={{ fontSize: 10, display: 'inline-flex' }}>
                      <PeopleIcon style={{ color: hexColor, fontSize: 18 }} /> {item?.common_reference?.slice(0, 3)}
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ButtonCard>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

BookingPanelCard.propTypes = {
  item: PropTypes.object,
  setBookingSelected: PropTypes.func,
  updateData: PropTypes.func,
  setState: PropTypes.func,
  state: PropTypes.string,
  profile: PropTypes.object,
}

BookingPanelCard.defaultProps = {
  item: () => {},
  setBookingSelected: () => {},
  updateData: () => {},
  setState: () => {},
  state: 'selecting',
  profile: {},
}

export default BookingPanelCard
