import React, { useEffect, useState } from 'react'
import { Pagination } from '@material-ui/lab'
import { getDashboardCheckListExecutions } from 'api'
import { Link, useParams } from 'react-router-dom'
import { Button, CircularProgress, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { SpinnerContainer } from 'components/template/layout/styled'
import {
  FilterSelect,
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
} from '../../organisms/members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxRow, BoxItemTitle, BoxItem, BoxItemTitleLG, BoxItemLG } from '../../organisms/invoices/scrollListStyles'

function ExecutionCLList() {
  const [paginate, setPaginate] = useState(true)
  const params = useParams()
  const { slug } = params
  const [clData, setCL] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: '',
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const noLinkStyle = { textDecoration: 'none' }
  const slash = '-'

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardCheckListExecutions(query, slug).then(response => {
      console.log(response)
      setCL(response)
      getPagination(response)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = clData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getData(query)
  }

  function filterBySelect(value, filterType) {
    let query = {}
    switch (filterType) {
      case 'status':
        setFilter({ ...theFilter, status: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, status: value } }
        return getData(query)
      case 'date_execute':
        setFilter({ ...theFilter, date_execute: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, date_execute: value } }
        return getData(query)
      case 'office':
        setFilter({ ...theFilter, office: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, office: value } }
        return getData(query)
      case 'office-null':
        setFilter({ ...theFilter, office: '' })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, office: '' } }
        return getData(query)
      default:
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter } }
        return getData(query)
    }
  }

  useEffect(() => {
    if (clData === undefined) getData()
  }, [clData])

  useEffect(() => {}, [pagination, theFilter])

  const space = ' '

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by name...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
        <BoxRow>
          <FormControl variant='outlined' style={{ width: '100%', marginRight: '10px' }}>
            <InputLabel>Status</InputLabel>
            <FilterSelect
              label='Status'
              name='status'
              variant='outlined'
              style={{ width: '100%' }}
              onChange={e => filterBySelect(e.target.value, e.target.name)}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </FilterSelect>
          </FormControl>
        </BoxRow>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitleLG>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Name</strong>
                  </Button>
                  {clData?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitleLG>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Office</strong>
                  </Button>
                  {clData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('executiodate_executen_date')}>
                    <strong>Execution</strong>
                  </Button>
                  {clData?.order === 'date_execute' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('item_price_day')}>
                    <strong>Result</strong>
                  </Button>
                  {clData?.order === 'item_price_day' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {clData?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <strong>View executions</strong>
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <strong>Update</strong>
                </ListItem>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {clData === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            clData.check_lists?.map((cl, i) => {
              let result = 'Not started'
              let testPass = 0
              console.log(cl)
              cl.items?.map(execution => {
                console.log(execution)
                if (execution.result_pass) {
                  testPass += 1
                  console.log('Adding test pass')
                }
                return testPass
              })
              if (testPass === cl.items?.length) result = 'Pass'
              else result = 'Pending'
              result = result === 'Error' ? '-' : `${result} ${testPass}/${cl.items?.length}`
              return (
                <ListRow>
                  <BoxRow>
                    <BoxItemLG>
                      <ListItem>{cl.check_list?.name}</ListItem>
                    </BoxItemLG>
                    <BoxItem>
                      <ListItem>{formatWords(cl.check_list?.office.name)}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{cl.date_execution ? new Date(cl.date_execution).toDateString() : '-'}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{result}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{formatWords(cl.status)}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <Link to={`/space/checklists/executions/${cl.slug}`} key={i} style={noLinkStyle}>
                        <ListItem>View</ListItem>
                      </Link>
                    </BoxItem>
                  </BoxRow>
                </ListRow>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

export default ExecutionCLList
