import React, { useEffect, useState } from 'react'
import { Pagination, Autocomplete } from '@material-ui/lab'
import { getDashboardOffices, getDashboardCheckLists } from 'api'
import { Link } from 'react-router-dom'
import { Button, CircularProgress, MenuItem, FormControl, InputLabel, TextField } from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { SpinnerContainer } from 'components/template/layout/styled'
import {
  FilterSelect,
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxRow, BoxItemTitle, BoxItem, BoxItemTitleLG, BoxItemLG } from '../invoices/scrollListStyles'

function CheckListsList({ layoutOffice }) {
  const [paginate, setPaginate] = useState(true)
  const [clData, setCL] = useState()
  const [officesData, setOfficesData] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: '',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const noLinkStyle = { textDecoration: 'none' }
  const slash = '-'

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardCheckLists(query).then(response => {
      console.log(response)
      setCL(response)
      getPagination(response)
      setPaginate(true)
    })
  }

  function getOfficesData() {
    const query = { pageSize: 100, currentPage: 1, filter: theFilter }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = clData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getData(query)
  }

  function filterBySelect(value, filterType) {
    let query = {}
    switch (filterType) {
      case 'status':
        setFilter({ ...theFilter, status: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, status: value } }
        return getData(query)
      case 'area_type':
        setFilter({ ...theFilter, area_type: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, area_type: value } }
        return getData(query)
      case 'office':
        setFilter({ ...theFilter, office: value })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, office: value } }
        return getData(query)
      case 'office-null':
        setFilter({ ...theFilter, office: '' })
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter, office: '' } }
        return getData(query)
      default:
        query = { pageSize: pagination.resultsByPage, currentPage: 1, filter: { ...theFilter } }
        return getData(query)
    }
  }

  useEffect(() => {
    if (clData === undefined) getData()
  }, [clData])

  useEffect(() => {
    if (officesData === undefined) getOfficesData()
  }, [officesData])

  useEffect(() => {}, [pagination, theFilter])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  const space = ' '

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // COUNTER
  const [searchOffice, setSearchOffice] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchOffice !== undefined && searchOffice !== '') {
        if (searchOffice.filter) filterBySelect(searchOffice.filter, 'office')
        if (searchOffice.office) getOfficesData()
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchOffice])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by name...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
        <BoxRow>
          <FormControl variant='outlined' style={{ width: '100%', marginRight: '10px' }}>
            <InputLabel>Status</InputLabel>
            <FilterSelect
              label='Status'
              name='status'
              variant='outlined'
              style={{ width: '100%' }}
              onChange={e => filterBySelect(e.target.value, e.target.name)}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
              <MenuItem value='pending'>Pending</MenuItem>
            </FilterSelect>
          </FormControl>
          <FormControl variant='outlined' style={{ width: '100%', marginRight: '10px' }}>
            <InputLabel>Type</InputLabel>
            <FilterSelect
              label='Type'
              name='area_type'
              variant='outlined'
              style={{ width: '100%' }}
              onChange={e => filterBySelect(e.target.value, e.target.name)}
            >
              <MenuItem value=''>All</MenuItem>
              <MenuItem value='open_space'>Open Space</MenuItem>
              <MenuItem value='office'>Office</MenuItem>
              <MenuItem value='outdoor'>Outdoor</MenuItem>
            </FilterSelect>
          </FormControl>
          <FormControl style={{ width: '100%', marginRight: '10px' }}>
            <Autocomplete
              name='office'
              options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
              onChange={(e, data) => {
                if (data !== null) filterBySelect(data.slug, 'office')
                else filterBySelect(null, 'office-null')
              }}
              onInputChange={(e, value) => {
                setSearchOffice({
                  office: value,
                  filter: e.target.innerText,
                })
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        </BoxRow>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitleLG>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Name</strong>
                  </Button>
                  {clData?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitleLG>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Office</strong>
                  </Button>
                  {clData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('area_type')}>
                    <strong>Last Iteration</strong>
                  </Button>
                  {clData?.order === 'area_type' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('item_price_day')}>
                    <strong>Last result</strong>
                  </Button>
                  {clData?.order === 'item_price_day' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {clData?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <strong>View executions</strong>
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>
                  <strong>Update</strong>
                </ListItem>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {clData === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            clData.check_lists.map((cl, i) => {
              let result = 'Not started'
              let testPass = 0
              cl.last_execution?.items?.map(execution => {
                if (execution.result_pass) {
                  testPass += 1
                }
                return testPass
              })
              if (testPass === cl.last_execution?.items?.length) result = 'Pass'
              else result = 'Error'
              result = result === 'Error' ? '-' : `${result} ${testPass}/${cl.last_execution?.items?.length}`
              return (
                <ListRow>
                  <BoxRow>
                    <BoxItemLG>
                      <ListItem>{cl.name}</ListItem>
                    </BoxItemLG>
                    <BoxItem>
                      <ListItem>{formatWords(cl.office.name)}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>
                        {cl.last_execution ? new Date(cl.last_execution?.date_execution).toDateString() : '-'}
                      </ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{result}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{formatWords(cl.status)}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <Link to={`/space/checklists/executions/${cl.slug}`} key={i} style={noLinkStyle}>
                        <ListItem>View</ListItem>
                      </Link>
                    </BoxItem>
                    <BoxItem>
                      <Link to={`/space/checklists/${cl.slug}`} key={i} style={noLinkStyle}>
                        <ListItem>Update</ListItem>
                      </Link>
                    </BoxItem>
                  </BoxRow>
                </ListRow>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

CheckListsList.propTypes = {
  layoutOffice: PropTypes.object,
}

CheckListsList.defaultProps = {
  layoutOffice: null,
}

export default CheckListsList
