import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { format } from 'date-fns'
import { ListContainer, ListRow, SpinnerContainer, ListHeader } from '../members/newListStyle'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function ChecklistItemList(info) {
  const { checklistItems, editItem } = info

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>Name</BoxItemTitle>
              <BoxItemTitle>Status</BoxItemTitle>
              <BoxItemTitle>Last execution</BoxItemTitle>
              <BoxItemTitle>Last result</BoxItemTitle>
              <BoxItemTitle>Mandatory</BoxItemTitle>
              <BoxItemTitle>Description</BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {info === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            checklistItems?.map(checklistItem => {
              const theDate = checklistItem?.date_last_execution
                ? format(new Date(checklistItem?.date_last_execution), 'dd-MM-yyyy, h:mma')
                : '-'
              return (
                <ListRow
                  onClick={() => {
                    editItem(checklistItem)
                  }}
                >
                  <BoxRow>
                    <BoxItem>{checklistItem.name}</BoxItem>
                    <BoxItem>{formatWords(checklistItem.status)}</BoxItem>
                    <BoxItem>{checklistItem.last_execution}</BoxItem>
                    <BoxItem>{theDate || '-'}</BoxItem>
                    <BoxItem>{checklistItem.mandatory ? 'YES' : 'NO'}</BoxItem>
                    <BoxItem>{checklistItem?.description?.substring(0, 25)}...</BoxItem>
                  </BoxRow>
                </ListRow>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

export default ChecklistItemList
