function formatDates(date, type) {
  if (date !== undefined && date !== null && date !== '') {
    const dateSplit = date.toString().split(' ')
    let t = 'T18:00:00'
    if (type === 'start') t = 'T09:00:00'
    if (type === 'start_day') t = 'T00:00:00'
    if (type === 'end_day') t = 'T23:59:59'
    if (type === 'time') t = `T${dateSplit[4]}`
    const slash = '-'
    if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
    if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
    if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
    if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
    if (dateSplit[1] === 'May') dateSplit[1] = '05'
    if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
    if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
    if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
    if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
    if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
    if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
    if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
    const dateFormatted = `${dateSplit[3] + slash + dateSplit[1] + slash + dateSplit[2] + t}`
    return dateFormatted
  }
  return null
}

export default formatDates
