import React from 'react'
import { useHistory } from 'react-router-dom'
import View from './view'

const Dashboard = ({ ...props }) => {
  const history = useHistory()
  const handleClickOnLink = to => history.push(to)
  return <View {...props} handleClickOnLink={handleClickOnLink} />
}

export default Dashboard
