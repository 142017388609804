import React from 'react'
import InvoiceAddForm from 'components/organisms/invoiceAddForm'
import { Container } from './styled'

const InvoiceAdd = props => (
  <Container>
    <InvoiceAddForm {...props} />
  </Container>
)

InvoiceAdd.propTypes = {}

export default InvoiceAdd
