import { useEffect, useState } from 'react'
import { updateProfile } from 'api'
import { Grid, Button, FormControl, TextField } from '@material-ui/core'
import DropzoneInput from 'components/atoms/dropzonetInput/container'
import PropTypes from 'prop-types'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard } from '../bookings/modalStyles'

function ModalUpdateProfile(props) {
  const { toClose, data } = props
  const [user, setUser] = useState({
    first_name: data.user.first_name,
    last_name: data.user.last_name,
    phone: data.user.phone,
    job_title: data.user.job_title,
  })
  const [errors, setErrors] = useState({
    name: null,
  })

  useEffect(() => {}, [user])

  function updateProfileData() {
    const officesSlugs = []
    const floorsSlugs = []
    data.offices.map(o => officesSlugs.push(o.slug))
    data.floors.map(f => floorsSlugs.push(f.slug))
    const profile = {
      office: data.office?.slug,
      active_client: data.active_client.slug,
      active_office: data.active_office.slug,
      theme: data.theme.slug,
      floors: floorsSlugs,
      offices: officesSlugs,
      web_sections: data.web_sections,
      ticket_types: data.ticket_types,
      role: data.role,
      id: data.id,
      clients: data.clients,
      app_sections: data.app_sections,
      office_skills: data.office_skills,
      user: {
        ...data.user,
        first_name: user.first_name,
        last_name: user.last_name,
        phone: user.phone,
        email: data.user.email,
        job_title: user.job_title,
      },
    }
    updateProfile(profile)
      .then(r => console.log(r))
      .catch(e => console.log(e))
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      name: 'Name is required',
    }
    const nameRes = data.user.first_name === '' || data.user.first_name === undefined ? textErrors.name : null

    setErrors({
      name: nameRes,
    })

    if (nameRes === null) canUpdate = true
    if (canUpdate === true) updateProfileData()
  }

  return (
    <ModalCard>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container justify='space-between'>
            <Grid item>
              <h3>Update Profile</h3>
            </Grid>
            <Grid item>
              <Button onClick={() => toClose()} variant='outlined'>
                X
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl style={{ width: '100%', height: '100%' }}>
                <DropzoneInput />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      variant='outlined'
                      label='Name'
                      value={user.first_name}
                      onChange={e => setUser({ ...user, first_name: e.target.value })}
                      error={errors.name !== null}
                    />
                    {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      variant='outlined'
                      label='Surname'
                      value={user.last_name}
                      onChange={e => setUser({ ...user, last_name: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      variant='outlined'
                      label='Phone'
                      value={user.phone}
                      onChange={e => setUser({ ...user, phone: e.target.value })}
                    />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField variant='outlined' label='Email' value={data.user.email} />
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl style={{ width: '100%' }}>
                    <TextField
                      variant='outlined'
                      label='Job Title'
                      value={user.job_title}
                      onChange={e => setUser({ ...user, job_title: e.target.value })}
                    />
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container justify='center'>
            <Button variant='contained' color='secondary' onClick={() => validate()}>
              Update Profile
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalUpdateProfile.propTypes = {
  toClose: PropTypes.func,
  data: PropTypes.object,
}

ModalUpdateProfile.defaultProps = {
  toClose: () => {},
  data: {},
}

export default ModalUpdateProfile
