import React from 'react'
import TextField from '@material-ui/core/TextField'
import PropTypes from 'prop-types'
import { Area, BlockPickerStyled } from './styled'

const ColorPicker = ({ label, value, onChange, displayColorPicker, handleSelectColor, setDisplayColorPicker }) => (
  <TextField
    label={label}
    variant='outlined'
    value={value}
    onChange={event => {
      onChange(event.target.value)
    }}
    InputProps={{
      endAdornment: (
        <Area
          color={value}
          onClick={() => {
            setDisplayColorPicker(true)
          }}
        >
          {displayColorPicker ? (
            <BlockPickerStyled
              triangle='hide'
              color={value}
              onChangeComplete={color => {
                handleSelectColor(color)
              }}
            />
          ) : null}
        </Area>
      ),
    }}
  />
)
ColorPicker.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  displayColorPicker: PropTypes.bool.isRequired,
  handleSelectColor: PropTypes.func.isRequired,
  setDisplayColorPicker: PropTypes.func.isRequired,
}
ColorPicker.defaultProps = {
  label: '',
  onChange: () => null,
  value: '',
}
export default ColorPicker
