import * as yup from 'yup'

const validate = yup.object().shape({
  name: yup.string().required(),
  identifier: yup.string().required(),
  type: yup.string().required(),
  status: yup.string().required(),
  workArea: yup.string().required(),
  capacity: yup.string().required(),
})

export default validate
