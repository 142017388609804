import routes from 'config/routes'
import { useState, useEffect } from 'react'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import { CircularProgress, Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HeaderToolbar from 'components/organisms/headerToolbar/container'
import { useLocation } from 'react-router'
import FADBar from 'components/organisms/foodAndDrinks/fadBar'
import FADStock from 'components/organisms/foodAndDrinks/fadStock'
import FADOrders from 'components/organisms/foodAndDrinks/fadOrders'
import FADCategories from 'components/organisms/foodAndDrinks/fadCategories'
import ModalCreateProduct from 'components/organisms/foodAndDrinks/modalCreateProduct'
import ModalCreateCategory from 'components/organisms/foodAndDrinks/modalCreateCategory'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { DashBoardContainer } from '../space/dashboardStyles'
import { ContainerEnd } from '../members/styled'

const RightActions = ({ pathname, t }) => {
  const [modalProduct, setProduct] = useState(false)
  function handleProductClose() {
    setProduct(false)
  }
  const [modalCategory, setCategory] = useState(false)
  function handleCategoryClose() {
    setCategory(false)
  }
  switch (pathname) {
    case routes.foodAndDrinksStock:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setProduct(true)}>
            {t('foodAndDrinks.addProduct')}
          </Button>
          <Modal open={modalProduct} onClose={handleProductClose}>
            <ModalCreateProduct toClose={handleProductClose} />
          </Modal>
        </ContainerEnd>
      )
    case routes.foodAndDrinksCategories:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setCategory(true)}>
            {t('foodAndDrinks.addCategory')}
          </Button>
          <Modal open={modalCategory} onClose={handleCategoryClose}>
            <ModalCreateCategory toClose={handleCategoryClose} />
          </Modal>
        </ContainerEnd>
      )
    default:
      return null
  }
}

RightActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

const DownActions = (pathname, office) => {
  if (pathname === routes.foodAndDrinks) return <FADOrders office={office} />
  if (pathname === routes.foodAndDrinksStock) return <FADStock office={office} />
  if (pathname === routes.foodAndDrinksBar) return <FADBar office={office} />
  if (pathname === routes.foodAndDrinksCategories) return <FADCategories office={office} />
  return ''
}

DownActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  office: PropTypes.object,
}

DownActions.defaultProps = {
  pathname: '',
  office: {},
}

function FoodAndDrinksDashboard(props) {
  const { office, handleClickOnLink } = props
  const { pathname } = useLocation()
  const { t } = useTranslation()

  // TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('orders')
      ? { id: routes.foodAndDrinks, to: routes.foodAndDrinks, label: t('foodAndDrinks.order') }
      : '',
    permited.includes('stock')
      ? { id: routes.foodAndDrinksStock, to: routes.foodAndDrinksStock, label: t('foodAndDrinks.stock') }
      : '',
    permited.includes('bar')
      ? { id: routes.foodAndDrinksBar, to: routes.foodAndDrinksBar, label: t('foodAndDrinks.bar') }
      : '',
    permited.includes('categories')
      ? { id: routes.foodAndDrinksCategories, to: routes.foodAndDrinksCategories, label: t('foodAndDrinks.categories') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <DashBoardContainer>
      <HeaderToolbar
        tabValue={pathname}
        tabs={theTabs}
        rightActions={<RightActions pathname={pathname} t={t} handleClickOnLink={handleClickOnLink} />}
      />
      <DashBoardContainer>
        {pathname === routes.foodAndDrinks ||
        pathname === routes.foodAndDrinksStock ||
        pathname === routes.foodAndDrinksCategories ||
        pathname === routes.foodAndDrinksBar ? (
          DownActions(pathname, office)
        ) : (
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        )}
      </DashBoardContainer>
    </DashBoardContainer>
  )
}

FoodAndDrinksDashboard.propTypes = {
  office: PropTypes.object,
  handleClickOnLink: PropTypes.func,
}

FoodAndDrinksDashboard.defaultProps = {
  office: {},
  handleClickOnLink: () => {},
}

export default FoodAndDrinksDashboard
