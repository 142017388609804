import React, { useState, useEffect } from 'react'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import HeaderToolbar from 'components/organisms/headerToolbar'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@material-ui/core'
import routes from 'config/routes'
import { useLocation } from 'react-router-dom'
import TeamsList from 'components/organisms/teams/teamList'
import GuestsList from 'components/organisms/guests/guestsList'
import InvoicesList from 'components/organisms/invoices/invoicesList'
import NewMemerList from 'components/organisms/members/newMemberList'
import NewMembershipsList from 'components/organisms/membershipsList/newMembershipList'
import ModalCreateMember from 'components/organisms/members/modalCreateMember'
import ModalCreateTeam from 'components/organisms/teams/modalCreateTeam'
import { Container, ContainerEnd } from './styled'
import MemberProfile from '../../organisms/members/memberSingle'
import ModalGuest from '../../organisms/guests/modalGuest'

const RigthActions = ({ pathname, t, handleClickOnLink, office }) => {
  const [modalMember, setModalMember] = useState(false)

  function handleMemberClose() {
    setModalMember(false)
  }

  const [modalTeam, setModalTeam] = useState(false)

  function handleTeamClose() {
    setModalTeam(false)
  }

  const [modalGuest, setModalGuest] = useState(false)

  function handleGuestClose() {
    setModalGuest(false)
  }

  switch (pathname) {
    case routes.members:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalMember(true)}>
            {t('members.addMember')}
          </Button>
          <Modal open={modalMember} onClose={handleMemberClose}>
            <ModalCreateMember toClose={handleMemberClose} layoutOffice={office} />
          </Modal>
        </ContainerEnd>
      )
    case routes.membersMembership:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.membersMembershipAdd)}>
          {t('members.addMembership')}
        </Button>
      )
    case routes.membersCompanies:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalTeam(true)}>
            {t('members.addTeam')}
          </Button>
          <Modal open={modalTeam} toClose={handleTeamClose} onClose={handleTeamClose}>
            <ModalCreateTeam toClose={handleTeamClose} />
          </Modal>
        </ContainerEnd>
      )
    case routes.membersGuests:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalGuest(true)}>
            {t('members.addGuest')}
          </Button>
          <Modal open={modalGuest} toClose={handleGuestClose} onClose={handleGuestClose}>
            <ModalGuest toClose={handleGuestClose} office={office} />
          </Modal>
        </ContainerEnd>
      )
    case routes.membersInvoices:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.invoiceAdd)}>
          {t('members.createInvoice')}
        </Button>
      )
    default:
      return null
  }
}

const DownActions = (pathname, office) => {
  if (pathname === routes.members) return <NewMemerList layoutOffice={office} />
  if (pathname === routes.membersCompanies) return <TeamsList layoutOffice={office} />
  if (pathname === routes.membersInvoices) return <InvoicesList layoutOffice={office} />
  if (pathname === routes.memberMemberships) return <NewMembershipsList layoutOffice={office} />
  if (pathname === routes.membersGuests) return <GuestsList layoutOffice={office} />
  return 'otra cosa'
}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleClickOnLink: PropTypes.func.isRequired,
  office: PropTypes.object,
}

RigthActions.defaultProps = {
  office: {},
}

const MemberAddView = ({ handleClickOnLink, office }) => {
  const { t } = useTranslation()
  const { pathname } = useLocation()

  //TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('members') ? { id: routes.members, to: routes.members, label: t('members.tabs.allMembers') } : '',
    permited.includes('membership')
      ? { id: routes.memberMemberships, to: routes.memberMemberships, label: t('members.tabs.memberships') }
      : '',
    permited.includes('teams')
      ? { id: routes.membersCompanies, to: routes.membersCompanies, label: t('members.tabs.companies') }
      : '',
    permited.includes('guests')
      ? { id: routes.membersGuests, to: routes.membersGuests, label: t('members.tabs.guests') }
      : '',
    permited.includes('invoices')
      ? { id: routes.membersInvoices, to: routes.membersInvoices, label: t('members.tabs.invoices') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <Container>
      <HeaderToolbar
        tabValue={pathname}
        tabs={theTabs}
        rightActions={<RigthActions pathname={pathname} t={t} handleClickOnLink={handleClickOnLink} office={office} />}
      />
      <Container>
        {pathname === routes.members ||
        pathname === routes.membersCompanies ||
        pathname === routes.membersGuests ||
        pathname === routes.membersInvoices ||
        pathname === routes.memberMemberships ? (
          DownActions(pathname, office)
        ) : (
          <MemberProfile slug={pathname} />
        )}
      </Container>
    </Container>
  )
}

MemberAddView.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  office: PropTypes.object,
}

MemberAddView.defaultProps = {
  office: {},
}

DownActions.propTypes = {
  pathname: PropTypes.string,
  t: PropTypes.string,
}

DownActions.defaultProps = {
  pathname: '',
  t: '',
}

export default MemberAddView
