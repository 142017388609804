import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import { getDashboardGuests } from 'api'
import { Button, CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import formatWords from '../../atoms/formatWords/formatWords'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxRow, BoxItemTitle, BoxItem, BoxItemTitleLG, BoxItemLG } from '../invoices/scrollListStyles'

function GuestsList({ layoutOffice }) {
  const [paginate, setPaginate] = useState(true)
  const [theGuests, setGuests] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: 'name',
    office: layoutOffice.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 15,
    thePage: 1,
    pagesTotal: '',
  })
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none' }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getDataGuests(filterData) {
    setGuests(null)
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardGuests(query)
      .then(guests => {
        setGuests(guests)
        getPagination(guests)
        setPaginate(true)
      })
      .catch(error => console.log(error))
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 15) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 15) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getDataGuests(query)
    setPaginate(false)
  }

  function setDataFilter(filterData) {
    const letters = filterData.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getDataGuests(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = theGuests?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter, order: theOrder },
    }
    getDataGuests(query)
  }

  useEffect(() => {
    if (theGuests === undefined) getDataGuests()
  }, [theGuests, pagination, theFilter])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getDataGuests(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // CALL TIMEOUTS
  const [searchFilter, setSearchFilter] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  const space = ' '
  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput
          placeholder='Search by text...'
          onChange={e => setSearchFilter({ ...searchFilter, filter: e.target.value })}
        />
      </BoxRow>
      <ScrollView direction='horizontal' style={{ marginTop: '15px' }} width={(windowSize.width * 82) / 100}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Name</strong>
                  </Button>
                  {theGuests?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitleLG>
                <ListTitle>
                  <Button onClick={() => setOrderData('email')}>
                    <strong>Email</strong>
                  </Button>
                  {theGuests?.order === 'email' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitleLG>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('host__user__first_name')}>
                    <strong>Host</strong>
                  </Button>
                  {theGuests?.order === 'host__user__first_name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('team__name')}>
                    <strong>Team</strong>
                  </Button>
                  {theGuests?.order === 'team__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('create_date')}>
                    <strong>Created</strong>
                  </Button>
                  {theGuests?.order === 'create_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Origin</strong>
                  </Button>
                  {theGuests?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('origin')}>
                    <strong>Status</strong>
                  </Button>
                  {theGuests?.order === 'origin' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {theGuests === undefined || theGuests === null ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            theGuests.guests.map((guest, index) => {
              const theDate = guest?.create_date?.split('T')
              return (
                <Link to={`/members/guests/${guest.slug}`} key={guest.slug} style={noLinkStyle}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{guest?.name}</ListItem>
                      </BoxItem>
                      <BoxItemLG>
                        <ListItem>{guest?.email}</ListItem>
                      </BoxItemLG>
                      <BoxItem>
                        <ListItem>
                          {guest.host !== null
                            ? `${guest?.host?.user?.first_name} ${guest?.host?.user?.last_name}`
                            : '-'}
                        </ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{guest.team !== null ? guest?.team?.name : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{`${theDate[0]} ${theDate[1].slice(0, 5)}`}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{formatWords(guest?.origin)}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{formatWords(guest?.status)}</ListItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 15)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

GuestsList.propTypes = {
  layoutOffice: PropTypes.object,
}

GuestsList.defaultProps = {
  layoutOffice: {},
}

export default GuestsList
