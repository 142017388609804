import { useEffect, useState } from 'react'
import { getDashboardMSPlans } from 'api'
import { CircularProgress } from '@material-ui/core'
import MSPlanCard from './MSPlanCard'
import { CardContainer, ListContainer, SpinnerContainer, Title } from '../members/newListStyle'

function ProfileMSPlansList() {
  const [MSPlans, setMSPlans] = useState()

  function getMSPlansData() {
    getDashboardMSPlans().then(r => setMSPlans(r))
  }

  useEffect(() => {
    if (MSPlans === undefined) getMSPlansData()
  }, [MSPlans])

  return (
    <ListContainer>
      <Title>Memberships Plans</Title>
      {MSPlans !== undefined ? (
        <CardContainer>
          {MSPlans.membership_plans.map((mp, i) => (
            <MSPlanCard mp={mp} key={i} />
          ))}
        </CardContainer>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </ListContainer>
  )
}

export default ProfileMSPlansList
