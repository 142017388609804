import React, { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
//import Container from '@material-ui/core/Container'
//import routes from 'config/routes'
import { Link } from 'react-router-dom'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItemTitle, BoxRow, BoxItem } from '../invoices/scrollListStyles'

function SingleBookingList(props) {
  const { bookings /*, showViewAll*/, limit, type } = props
  const center = { margin: '0 auto' }

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (bookings !== undefined) {
    return (
      <ListContainer>
        {type === 'noHead' ? '' : <SingleTitleSection>Latest bookings</SingleTitleSection>}
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Type</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Date</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Member</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Resource</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Status</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Price</strong>
                  </ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>
                    <strong>Credits</strong>
                  </ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {bookings.length > 0 ? (
              bookings
                .sort((a, b) => (a.planned_start < b.planned_start ? 1 : -1))
                .map((booking, index) => {
                  console.log(booking)
                  const dateStart = booking.planned_start !== null ? booking?.planned_start?.split('T') : null
                  const dateEnd = booking.planned_end !== null ? booking?.planned_end?.split('T') : null
                  const startDate =
                    dateStart === null
                      ? '-'
                      : `${dateStart[0]}, ${dateStart[1].slice(0, 5)} - ${dateEnd[1].slice(0, 5)}`
                  let memberName = '-'
                  if (booking.member) {
                    memberName = `${booking.member?.user?.first_name} ${booking.member?.user?.last_name}`
                  }
                  if (booking.guest) {
                    memberName = booking.guest.name
                  }
                  let origin = ''
                  if (booking.origin === 'referral') origin = ' (R)'
                  if (booking.origin === 'deference') origin = ' (C)'
                  if (booking.origin === 'trial') origin = ' (T)'
                  if (index >= limit && limit !== 0) return ''
                  return (
                    <Link to={`/bookings/${booking.code}`} key={index} style={{ textDecoration: 'none' }}>
                      <ListRow>
                        <BoxRow>
                          <BoxItem>
                            <ListItem>
                              <strong>{formatWords(booking?.booking_type)}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{startDate}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{memberName}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{booking?.resource?.name}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>
                                {booking.status}
                                {origin}
                              </strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{booking.single_price}</strong>
                            </ListItem>
                          </BoxItem>
                          <BoxItem>
                            <ListItem>
                              <strong>{booking.credits !== null ? booking.credits : 0}</strong>
                            </ListItem>
                          </BoxItem>
                        </BoxRow>
                      </ListRow>
                    </Link>
                  )
                })
            ) : (
              <ListRow>
                <ListItem style={center}>
                  <strong>No bookings data</strong>
                </ListItem>
              </ListRow>
            )}
            {/*showViewAll && (
          <Container style={{ textAlign: 'right', padding: 10 }}>
            <Link to={routes.bookings} style={{ textDecoration: 'none' }}>
              Vie All
            </Link>
          </Container>
        )*/}
          </ListContainer>
        </ScrollView>
      </ListContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

SingleBookingList.propTypes = {
  bookings: PropTypes.array,
  limit: PropTypes.number,
  //showViewAll: PropTypes.bool,
  type: PropTypes.string,
}

SingleBookingList.defaultProps = {
  bookings: [],
  limit: 15,
  //showViewAll: false,
  type: '',
}

export default SingleBookingList
