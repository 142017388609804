import React, { useEffect, useState } from 'react'
import { createCampaign, getTypesCampaign } from 'api'
import PropTypes from 'prop-types'
import { CircularProgress, FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { DatePicker } from '@material-ui/pickers'
import { CloseIcon, ModalCard, ModalHeader, ModalItem, ModalTitle, SubmitBtn } from '../bookings/modalStyles'
import { ModalText } from './campaignStyles'
import { SpinnerContainer } from '../members/newListStyle'

function CampaignCreateModal(props) {
  const { toClose } = props
  const [theCampaign, setCampaignData] = useState()
  const [types, setTypes] = useState([])
  const [created, setCreated] = useState(false)
  const [errors, setErrors] = useState({
    name: null,
    code: null,
    type: null,
    created: null,
    date_planned: null,
    status: null,
  })

  function getTimeDate(data) {
    const dateSplit = data.toString()?.split(' ')
    const space = '-'
    const t = 'T'
    const time = '00:00:00+01:00'
    if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
    if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
    if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
    if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
    if (dateSplit[1] === 'May') dateSplit[1] = '05'
    if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
    if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
    if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
    if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
    if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
    if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
    if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
    const formattedDate = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + time
    setCampaignData({ ...theCampaign, date_planned: formattedDate })
    return formattedDate
  }

  function validation(data) {
    console.log(data)
    const textErrors = {
      name: 'Name is required',
      code: 'Campaign Code is required',
      type: 'Type is required',
      date_planned: 'Date planned is required',
      status: 'Status is required',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const codeRes = data?.code === undefined || data?.code === '' ? textErrors.code : null
    const typeRes = data?.type === undefined || data?.type === '' ? textErrors.type : null
    const plannedRes = data?.date_planned === undefined || data?.date_planned === '' ? textErrors.date_planned : null
    const statusRes = data?.status === undefined || data?.status === '' ? textErrors.status : null

    setErrors({
      name: nameRes,
      code: codeRes,
      type: typeRes,
      date_planned: plannedRes,
      status: statusRes,
    })
  }

  function createNewCampaign() {
    if (
      errors.name === null &&
      errors.code === null &&
      errors.type === null &&
      errors.date_planned === null &&
      errors.status === null
    ) {
      console.log('sin errores')
      createCampaign(theCampaign)
        .then(setCreated(true))
        .then(
          setTimeout(() => {
            toClose()
          }, 2000)
        )
        .catch(error => console.log(error))
    }
  }

  function setTypesCampaign() {
    getTypesCampaign()
      .then(response => setTypes(response))
      .catch(error => console.log(error))
  }

  useEffect(() => {
    if (types?.length === 0) setTypesCampaign()
  }, [theCampaign, types])

  if (created === false) {
    return (
      <ModalCard>
        <ModalHeader>
          <ModalTitle style={{ margin: '15px' }}>Create Campaign</ModalTitle>
          <CloseIcon onClick={toClose} />
        </ModalHeader>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='team-name-input-id'
            name='name'
            onChange={value => setCampaignData({ ...theCampaign, name: value.target.value })}
            error={errors.name !== null}
          />
          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            label='Code'
            variant='outlined'
            id='code-input-id'
            name='code'
            onChange={value => setCampaignData({ ...theCampaign, code: value.target.value })}
            error={errors.code !== null}
          />
          {errors.code === null ? '' : <SmallError>{errors.code}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label'>Status</InputLabel>
            <Select
              label='Status'
              onChange={value => setCampaignData({ ...theCampaign, status: value.target.value })}
              error={errors.status !== null}
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='finished'>Finished</MenuItem>
              <MenuItem value='cancelled'>Cancel</MenuItem>
              <MenuItem value='pending_payment'>Pending</MenuItem>
            </Select>
          </FormControl>
          {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label'>Type</InputLabel>
            <Select
              label='Type'
              onChange={value => setCampaignData({ ...theCampaign, type: value.target.value })}
              error={errors.type !== null}
            >
              {types?.map((type, i) => (
                <MenuItem value={`${type.id}`} key={i}>
                  {type.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {errors.type === null ? '' : <SmallError>{errors.type}</SmallError>}
        </ModalItem>
        <ModalItem>
          <DatePicker
            value={theCampaign?.date_planned !== undefined ? theCampaign.date_planned : '0000-00-00'}
            onChange={e => setCampaignData({ ...theCampaign, date_planned: getTimeDate(e) })}
            width='100%'
            variant='inline'
            inputVariant='outlined'
            helperText={null}
            format='dd-MM-yyyy'
            error={errors.date_planned !== null}
          />
        </ModalItem>
        <ModalItem>
          <SubmitBtn
            onClick={() => {
              validation(theCampaign)
              createNewCampaign()
            }}
          >
            Create new Campaign
          </SubmitBtn>
        </ModalItem>
      </ModalCard>
    )
  }
  return (
    <ModalCard>
      <ModalTitle>Campaign Created</ModalTitle>
      <ModalText>You will be redirect to Campaigns List...</ModalText>
      <SpinnerContainer>
        <CircularProgress color='secondary' />
      </SpinnerContainer>
    </ModalCard>
  )
}

CampaignCreateModal.propTypes = {
  toClose: PropTypes.func,
}

CampaignCreateModal.defaultProps = {
  toClose: () => {},
}

export default CampaignCreateModal
