import {
  configureToken,
  getUserToken,
  newGetMembers,
  setBooking,
  setMember,
  setMembership,
  setOffer,
  setOfferUser,
  setResource,
  setTheme,
  updateOffer,
  updateOfferUser,
  setInvoice,
} from 'api'

export const DO_LOGIN = async data => {
  const response = await getUserToken(data)
  const token = await response.access
  localStorage.setItem('token', response.access)
  localStorage.setItem('refreshToken', response.refresh)
  configureToken(token)
  return response
}

export const SET_OFFICE = async data => data

export const SET_MEMBER = async data => {
  const response = await setMember(data)
  return response
}

export const SET_MEMBERSHIP = async data => {
  const response = await setMembership(data)
  return response
}

export const SET_THEME = async data => {
  console.log('SET THEME')
  const response = await setTheme(data)
  return response
}

export const SET_BOOKING = async data => {
  const response = await setBooking(data)
  return response
}

export const SET_MEMBERS = async data => {
  const response = await newGetMembers(data)
  return response
}

export const SET_RESOURCE = async data => {
  const response = await setResource(data)
  return response
}

export const SET_OFFER = async data => {
  const response = await setOffer(data)
  return response
}

export const SET_OFFER_USER = async data => {
  const response = await setOfferUser(data)
  return response
}

export const UPDATE_OFFER = async data => {
  const response = await updateOffer(data)
  return response
}
export const UPDATE_OFFER_USER = async data => {
  const response = await updateOfferUser(data)
  return response
}

export const SET_INVOICE = async data => {
  const response = await setInvoice(data)
  return response
}
