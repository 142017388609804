import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import Scheduler, { AppointmentDragging, Resource, View } from 'devextreme-react/scheduler'
import 'devextreme/dist/css/dx.light.css'
import { getBookings, getResourceMR, setBooking, updateBooking } from 'api'
import { CircularProgress, Grid, Modal } from '@material-ui/core'
import { ModalCard, ModalTitle } from 'components/organisms/bookings/modalStyles'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { ModalText } from 'components/organisms/campaigns/campaignStyles'
import ModalSchedulerBooking from 'components/organisms/bookings/modalSchedulerBooking'

function ScheduleSingle({ ...props }) {
  const { office, team, role, activeClient, profile, resource, panelHeight } = props
  console.log('My role is ', role, panelHeight)
  const [rooms, setRooms] = useState(null)
  const [bookings, setBookingData] = useState(null)
  const [appointments, setAppointments] = useState(null)
  const [groups, setGroups] = useState(null)
  const [confirmModal, setConfirm] = useState(false)
  const [modalBooking, setModal] = useState(false)
  const [resourceData, setResourceData] = useState()
  const [lastDate, setLastDate] = useState(new Date())
  const [loading, setLoading] = useState(true)
  const [internalLoading, setInternalLoading] = useState(true)
  const [roomsLoading, setRoomsLoading] = useState(true)

  // Search boundaries defiition, How far are we searching for bookings and how far do we need to move to refresh the search
  const searchDayLimit = 7
  const searchDayRefresh = 5
  // This must be dependant of the office opening time, almost there TODO
  const startDayHour = 8
  const endDayHour = 19

  const history = useHistory()

  function randomHexColorCode() {
    const a = '#'
    const n = (Math.random() * 0xfffff * 1000000).toString(16)
    return a + n.slice(0, 6)
  }
  function getRoomResources() {
    const roomsArr = []
    setRoomsLoading(true)
    getResourceMR(resource.slug).then(response => {
      try {
        response.map(res => {
          if (res.resource_type === 'meeting_room') {
            const room = {
              id: res.slug,
              text: res.name,
              color: res.color === null ? randomHexColorCode() : res.color,
            }
            roomsArr.push(room)
          }
          return ''
        })
        setRooms(roomsArr)
      } catch (ex) {
        console.log('Exception in response.map')
      }
      setRooms(roomsArr)
    })
  }

  function formatDate(data, type) {
    const date = data.toString()
    const dateSplit = date?.split(' ')
    const tab = '-'
    const t = 'T'
    const startTime = '00:00:00'
    const endTime = '23:59:59'
    const realTime = dateSplit[4]
    let time = ''
    switch (type) {
      case 'start':
        time = startTime
        break
      case 'end':
        time = endTime
        break
      default:
        time = realTime
    }
    if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
    if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
    if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
    if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
    if (dateSplit[1] === 'May') dateSplit[1] = '05'
    if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
    if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
    if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
    if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
    if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
    if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
    if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
    const formatData = dateSplit[3] + tab + dateSplit[1] + tab + dateSplit[2] + t + time
    return formatData
  }

  function getBookingTitle(booking) {
    let bookingName = ''
    let teamName = ''
    if (booking?.member !== 'busy') {
      console.log('Booking title ', activeClient, booking?.client)
      if (booking?.client !== null && booking?.client !== undefined && activeClient.slug !== booking?.client?.slug) {
        bookingName += `${booking?.client?.name} - `
      }
      if (booking?.guest !== null && booking?.guest !== undefined) {
        bookingName += `${booking?.guest?.name}`
      }
      if (booking?.team !== null && booking?.team !== undefined) {
        teamName = booking?.team?.name
      }
      if (booking?.member !== null && booking?.member !== undefined && booking?.member?.user !== null) {
        const firstName = booking?.member?.user?.first_name ? booking?.member?.user?.first_name : ''
        const lastName = booking?.member?.user?.last_name ? booking?.member?.user?.last_name : ''
        if (teamName === '') {
          teamName = booking?.member?.team?.name
        }
        if (firstName !== '' || lastName !== '') {
          if (bookingName === '') {
            bookingName = `${firstName} ${lastName}`
          } else {
            bookingName += ` --${firstName} ${firstName}-- `
            console.log(bookingName)
          }
        }
      }
      if (bookingName === '') {
        bookingName = teamName
      } else if (teamName !== '' && teamName !== undefined) {
        bookingName += ` (${teamName})`
      }
      console.log('Bookingname is ', bookingName)
      if (bookingName === '' || bookingName === undefined) {
        if (booking?.client !== null && booking?.client !== undefined) {
          bookingName = booking?.client?.name
        }
      }
      if (booking.single_price) bookingName += ` - £${booking.single_price}`
    } else bookingName = 'Busy'
    return bookingName
  }

  function createAppointments(bookingsArr) {
    const bookedsArr = []
    bookingsArr?.map(b => {
      const booking = {
        roomId: b?.resource?.slug,
        text: getBookingTitle(b),
        startDate: new Date(b.planned_start),
        endDate: new Date(b.planned_end),
        bookingCode: b?.code,
        teamData: b?.team,
        memberData: b?.member,
        guest: b?.guest,
        single_price: b.single_price,
        client: b?.client,
      }
      return bookedsArr.push(booking)
    })
    setAppointments(bookedsArr)
  }

  function getBookingsInfo() {
    setInternalLoading(true)
    setLoading(true)
    const startDate = new Date(lastDate)
    startDate.setDate(startDate.getDate() - searchDayLimit)
    const endDate = new Date(lastDate)
    endDate.setDate(endDate.getDate() + searchDayLimit)
    const query = {
      pageSize: '1000',
      currentPage: 1,
      filter: {
        name: '',
        order: '',
        office: office.slug,
        booking_type: 'meeting_room',
        start: formatDate(startDate, 'start'),
        end: formatDate(endDate, 'end'),
        busy: 'true',
      },
    }
    getBookings(query).then(response => {
      setInternalLoading(false)
      setBookingData(response.bookings)
    })
  }

  function checkFields() {
    //getBookingsInfo()
    getRoomResources()
  }

  function reloadBookings() {
    getBookingsInfo()
    setModal(false)
  }

  function createNewBooking(data) {
    setBookingData(null)
    const newBooking = [
      {
        start: { dateTime: formatDate(data.startDate) },
        end: { dateTime: formatDate(data.endDate) },
        office,
        payNow: false,
        team,
        booking_type: 'meeting_room',
        resource: data.roomId,
        capacity: 1,
        price: data.single_price,
        origin: 'regular',
        comments: data.description,
      },
    ]
    setBooking(newBooking).then(setConfirm(true)).then(history.push('../bookings'))
  }

  function updateExistingBooking(data) {
    console.log(data)
    const existingBooking = {
      code: data.bookingCode,
      start: { dateTime: formatDate(data.startDate) },
      end: { dateTime: formatDate(data.endDate) },
      resource: data.roomId,
      price: data.single_price,
      booking_type: 'meeting_room',
    }
    setConfirm(true)
    console.log(existingBooking)
    updateBooking(data.bookingCode, existingBooking).then(r => {
      if (r?.message === 'busy') {
        reloadBookings()
        setConfirm(false)
      }
      reloadBookings()
      setConfirm(false)
    })
  }

  useEffect(() => {}, [confirmModal])

  useEffect(() => {
    getBookingsInfo()
  }, [lastDate, office])

  useEffect(() => {
    setRooms(null)
    setAppointments(null)
    setRoomsLoading(true)
    setBookingData(null)
    setInternalLoading(true)
    setLoading(true)
    checkFields()
  }, [office])

  useEffect(() => {
    if (appointments !== null) {
      console.log('Appointments loading ', appointments)
      setInternalLoading(false)
      setGroups(['roomId'])
    }
  }, [appointments])

  useEffect(() => {
    if (rooms !== null) setRoomsLoading(false)
  }, [rooms])

  useEffect(() => {
    if (bookings !== null && rooms !== null) {
      console.log('Create appointment loading ', bookings, rooms)
      if (bookings.length >= 0 && rooms.length >= 0) createAppointments(bookings)
      else {
        console.log('Setting internal loading')
        setInternalLoading(false)
      }
    }
  }, [bookings, rooms])

  useEffect(() => {
    console.log('** Internal loading ', internalLoading)
    console.log('Rooms loading ', roomsLoading)
    if (!internalLoading && !roomsLoading) setLoading(false)
  }, [internalLoading, roomsLoading])

  function editedFormScheduler(data) {
    console.log('Preparing upload content 1 ', data.appointmentData)
    const uploadResourceData = {
      resource: data.appointmentData.roomId,
      start: data.appointmentData.startDate,
      end: data.appointmentData.endDate,
      uploadContent: {
        team: data.appointmentData.teamData,
        member: data.appointmentData.memberData,
        code: data.appointmentData.bookingCode,
        guest: data.appointmentData.guest,
        single_price: data.appointmentData.single_price,
        client: data.appointmentData.client,
      },
    }
    console.log('Client selected is ', data.appointmentData.client)
    setResourceData(uploadResourceData)
    if (
      data.appointmentData.client !== 'busy' &&
      (data.appointmentData.startDate > new Date() || data.appointmentData.bookingCode !== undefined)
    ) setModal(true)
  }

  function closeModal() {
    setModal(false)
  }

  function checkNewBookingsNeeded(date) {
    let startLimit = new Date(lastDate)
    let endLimit = new Date(lastDate)
    // Now we check if we haveto show the spnineer because we run our of limits
    startLimit.setDate(startLimit.getDate() - searchDayLimit) // Originally we are looking fro 10 days, when we are 3 days close we go for a new set of bookings
    endLimit.setDate(endLimit.getDate() + searchDayLimit) //Originally we are looking fro 10 days, when we are 3 days close we go for a new set of bookings
    if ((date <= startLimit || date >= endLimit) && internalLoading) setLoading(true)

    startLimit = new Date(lastDate)
    endLimit = new Date(lastDate)
    startLimit.setDate(startLimit.getDate() - searchDayRefresh) // Originally we are looking fro 10 days, when we are 3 days close we go for a new set of bookings
    endLimit.setDate(endLimit.getDate() + searchDayRefresh) //Originally we are looking fro 10 days, when we are 3 days close we go for a new set of bookings
    if (date <= startLimit || date >= endLimit) setLastDate(date)
  }

  return (
    <Grid container direction='column' justify='center'>
      {!loading && groups !== null ? (
        <Grid item xs={12} style={{ height: panelHeight }}>
          <Scheduler
            height={panelHeight}
            dataSource={appointments === undefined ? [] : appointments}
            onAppointmentAdding={appoAdd => createNewBooking(appoAdd.appointmentData)}
            onAppointmentUpdated={appoUp => updateExistingBooking(appoUp.appointmentData)}
            onAppointmentClick={e => {
              e.cancel = true
              editedFormScheduler(e)
            }}
            onAppointmentFormOpening={e => {
              e.cancel = true
              editedFormScheduler(e)
            }}
            onOptionChanged={e => {
              if (e.name === 'currentDate') {
                checkNewBookingsNeeded(e.value)
              }
            }}
            groups={groups}
            defaultCurrentView='Day'
            defaultCurrentDate={lastDate}
            startDayHour={startDayHour}
            endDayHour={endDayHour}
            showAllDayPanel={false}
            editing={{
              allowResizing: role === 'club_manager' || role === 'club_admin',
              allowDragging: role === 'club_manager' || role === 'club_admin',
            }}
            useDropDownViewSwitcher={false}
          >
            <AppointmentDragging
              onAdd={data => {
                console.log(data)
                if (data?.itemData?.client === 'busy') {
                  return false
                }
                return true
              }}
              onDragStart={data => {
                if (data?.itemData?.client === 'busy') {
                  console.log('rejected')
                  return false
                }
                return true
              }}
            />
            <View name='Day' type='day' cellDuration={30} intervalCount={1} />
            <Resource fieldExpr='roomId' allowMultiple={false} dataSource={rooms} label='Room' />
          </Scheduler>
        </Grid>
      ) : (
        <Grid item xs={12}>
          <Grid container direction='column' alignItems='center' spacing={3} style={{ padding: '50px 100px' }}>
            <Grid item>
              <h2>Loading bookings...</h2>
            </Grid>
            <Grid item xs={12}>
              <CircularProgress color='secondary' />
            </Grid>
          </Grid>
        </Grid>
      )}
      <Modal open={modalBooking} onClose={closeModal}>
        <ModalSchedulerBooking
          closeModal={closeModal}
          rooms={rooms}
          office={office}
          resourceData={resourceData}
          reloadBookings={reloadBookings}
          role={role}
          profile={profile}
        />
      </Modal>
      <Modal open={confirmModal}>
        <ModalCard>
          <ModalTitle>Booking created</ModalTitle>
          <ModalText>Hold on a second!</ModalText>
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        </ModalCard>
      </Modal>
    </Grid>
  )
}

ScheduleSingle.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
  team: PropTypes.string,
  role: PropTypes.string,
  profile: PropTypes.object,
  resource: PropTypes.object,
  panelHeight: PropTypes.number,
}

ScheduleSingle.defaultProps = {
  office: {},
  activeClient: {},
  team: '',
  role: '',
  profile: {},
  resource: {},
  panelHeight: 0,
}

export default ScheduleSingle
