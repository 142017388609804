import { useEffect, useState } from 'react'
import { getBarsList } from 'api'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from '../members/newListStyle'
import { BoxItemTitle, BoxRow, BoxItem } from '../invoices/scrollListStyles'

function FADBar({ office }) {
  const [data, setData] = useState()

  function getData() {
    const query = {
      currentPage: 1,
      pageSize: 15,
      filter: {
        layoutOffice: office.slug,
      },
    }
    getBarsList(query).then(r => {
      setData(r)
    })
  }

  useEffect(() => {
    if (data === undefined) getData()
  }, [data])

  function setOfficeFilter() {
    const query = {
      filter: { layoutOffice: office.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [office])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      {data !== undefined ? (
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListContainer>
            <ListHeader>
              <BoxRow>
                <BoxItemTitle>
                  <ListItem>Name</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Open</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Close</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Status</ListItem>
                </BoxItemTitle>
                <BoxItemTitle>
                  <ListItem>Staff</ListItem>
                </BoxItemTitle>
              </BoxRow>
            </ListHeader>
            {data.map(bar => (
              <ListRow>
                <BoxRow>
                  <BoxItem>
                    <ListItem>{bar.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{bar.open_time}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{bar.close_time}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{formatWords(bar.status)}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{bar.staff.length}</ListItem>
                  </BoxItem>
                </BoxRow>
              </ListRow>
            ))}
          </ListContainer>
        </ScrollView>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </ListContainer>
  )
}

FADBar.propTypes = {
  office: PropTypes.object,
}

FADBar.defaultProps = {
  office: {},
}

export default FADBar
