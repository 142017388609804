import { useEffect, useState } from 'react'
import { createOffice } from 'api'
import PropTypes from 'prop-types'
import { Grid, Button, FormControl, TextField, Modal } from '@material-ui/core'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { ModalCard } from '../bookings/modalStyles'

function ModalCreateCompany({ toClose }) {
  const [office, setoffice] = useState({ name: '' })
  const [errors, setErrors] = useState({ name: null })
  const [fbModal, setModal] = useState(false)
  useEffect(() => {}, [office])

  function createofficeData() {
    setModal(true)
    createOffice(office).then(
      setTimeout(() => {
        toClose()
      }, 1500)
    )
  }

  function validate() {
    let canCreate = false
    const textErrors = {
      name: 'Name is required',
    }
    const nameRes = office.name === undefined || office.name === '' ? textErrors.name : null
    setErrors({ name: nameRes })
    if (nameRes === null) canCreate = true
    if (canCreate === true) createofficeData()
  }

  return (
    <ModalCard>
      <Modal open={fbModal === true}>
        <FeedbackModal type='office-create' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container justify='space-between' alignItems='center'>
            <Grid item style={{ fontSize: 25, fontWeight: 500 }}>
              Create office
            </Grid>
            <Grid item>
              <Button onClick={() => toClose()}>X</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              variant='outlined'
              value={office.name}
              onChange={e => setoffice({ ...office, name: e.target.value })}
              label='office Name'
              error={errors.name !== null}
            />
            {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justify='center'>
            <Button variant='contained' color='secondary' onClick={() => validate()}>
              Create Location
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateCompany.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateCompany.defaultProps = {
  toClose: () => {},
}

export default ModalCreateCompany
