import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { useTranslation } from 'react-i18next'
import { getOffices, getStadistics } from 'api'
import { CLUB_SEARCH_RANGE } from 'config/constants'
import OfficeOccupation from 'components/organisms/dashboardInfo/officeOccupation'
import OfficeBookings from 'components/organisms/dashboardInfo/officeBookings'
import OfficeRevenue from 'components/organisms/dashboardInfo/officeRevenue'
import OfficeInvoice from 'components/organisms/dashboardInfo/officeInvoice'
import OfficeReconcile from 'components/organisms/dashboardInfo/officeReconcile'
import DashboardInfo from 'components/organisms/dashboardInfo'
import HeaderToolbar from 'components/organisms/headerToolbar'
import { DashBoardContainer } from '../space/dashboardStyles'

const DownActions = (pathname, office, stats, clubRange, setClubRange, role) => {
  useEffect(() => {}, [office])
  if (pathname === routes.dashboard) return <DashboardInfo office={office} clubRange={clubRange} setClubRange={setClubRange} role={role} />
  if (pathname === routes.dashboardOccupation && role !== 'referral') return <OfficeOccupation stats={stats} />
  if (pathname === routes.dashboardBookings && role !== 'referral') return <OfficeBookings stats={stats} />
  if (pathname === routes.dashboardRevenue && role !== 'referral') return <OfficeRevenue stats={stats} />
  if (pathname === routes.dashboardInvoice && role !== 'referral') return <OfficeInvoice stats={stats} />
  if (pathname === routes.dashboardReconcile && role !== 'referral') return <OfficeReconcile />
  return ''
}

DownActions.propTypes = {
  pathname: PropTypes.string,
  office: PropTypes.object,
  clubRange: PropTypes.object,
  stats: PropTypes.object,
  role: PropTypes.role,
}

DownActions.defaultProps = {
  pathname: '',
  office: {},
  clubRange: {},
  stats: {},
  role: '',
}

function Dashboard({ office, role }) {
  const [clubRange, setClubRange] = useState(CLUB_SEARCH_RANGE.this)
  const [stats, setStats] = useState([])
  const { t } = useTranslation()
  const { pathname } = useLocation()

  function getStats() {
    const query = {
      club: clubRange === CLUB_SEARCH_RANGE.this ? office.slug : CLUB_SEARCH_RANGE.all,
      year: new Date().getFullYear() - 1,
      month: new Date().getMonth() + 1,
      monthLimit: 12,
    }
    getStadistics(query).then(response => setStats(response))
  }
  useEffect(() => {
    console.log(clubRange)
    if (role !== 'referral') getStats()
  }, [office, clubRange])

  // TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => {
      console.log(r)
      setStaffData(r)
    })
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('cards_statistics')
      ? { id: routes.dashboard, to: routes.dashboard, label: t('dashboard.cards') }
      : '',
    permited.includes('bookings_statistics')
      ? { id: routes.dashboardBookings, to: routes.dashboardBookings, label: t('dashboard.bookings') }
      : '',
    permited.includes('occupation_statistics')
      ? { id: routes.dashboardOccupation, to: routes.dashboardOccupation, label: t('dashboard.occupations') }
      : '',
    permited.includes('revenue_statistics')
      ? { id: routes.dashboardRevenue, to: routes.dashboardRevenue, label: t('dashboard.revenue') }
      : '',
    permited.includes('invoice_statistics')
      ? { id: routes.dashboardInvoice, to: routes.dashboardInvoice, label: t('dashboard.invoice') }
      : '',
    permited.includes('invoice_reconcile')
      ? { id: routes.dashboardReconcile, to: routes.dashboardReconcile, label: t('dashboard.reconcile') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <DashBoardContainer>
      <HeaderToolbar tabValue={pathname} tabs={theTabs} style={{ marginBottom: '150px' }} />
      {pathname === routes.dashboard ||
      pathname === routes.dashboardBookings ||
      pathname === routes.dashboardOccupation ||
      pathname === routes.dashboardRevenue ||
      pathname === routes.dashboardInvoice ||
      pathname === routes.dashboardReconcile ? (
        DownActions(pathname, office, stats, clubRange, setClubRange, role)
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </DashBoardContainer>
  )
}

Dashboard.propTypes = {
  office: PropTypes.object.isRequired,
  role: PropTypes.string.isRequired,
}

export default Dashboard
