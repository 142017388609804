import { useEffect, useState } from 'react'
import { getOffices } from 'api'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import { CircularProgress } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import HeaderToolbar from 'components/organisms/headerToolbar/container'
import { useLocation } from 'react-router'
import ComEvents from 'components/organisms/community/comEvents'
import ComNotices from 'components/organisms/community/comNotices'
import ComTickets from 'components/organisms/community/comTickets'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { DashBoardContainer } from '../space/dashboardStyles'

const DownActions = (pathname, office) => {
  useEffect(() => {}, [office])
  if (pathname === routes.community) return <ComTickets layoutOffice={office} />
  if (pathname === routes.communityNotices) return <ComNotices layoutOffice={office} />
  if (pathname === routes.communityEvents) return <ComEvents layoutOffice={office} />
  return ''
}

function CommunityDashboard(props) {
  const { office } = props
  const { pathname } = useLocation()
  const { t } = useTranslation()

  //TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('tickets') ? { id: routes.community, to: routes.community, label: t('community.tickets') } : '',
    permited.includes('board')
      ? { id: routes.communityNotices, to: routes.communityNotices, label: t('community.notices') }
      : '',
    permited.includes('events')
      ? { id: routes.communityEvents, to: routes.communityEvents, label: t('community.events') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <DashBoardContainer>
      <HeaderToolbar tabValue={pathname} tabs={theTabs} />
      <DashBoardContainer>
        {pathname === routes.community ||
        pathname === routes.communityNotices ||
        pathname === routes.communityEvents ? (
          DownActions(pathname, office)
        ) : (
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        )}
      </DashBoardContainer>
    </DashBoardContainer>
  )
}

CommunityDashboard.propTypes = {
  office: PropTypes.object,
}

CommunityDashboard.defaultProps = {
  office: {},
}

export default CommunityDashboard
