import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Button,
  Modal,
} from '@material-ui/core'
import { DateTimePicker } from '@material-ui/pickers'
import formatDates from 'components/atoms/formatDates/formatDates'
import {
  getSingleBooking,
  getMembersByTeam,
  updateBooking,
  getWorkAreasForOffice,
  getResourcesForWorkArea,
  calculateBookingPrice,
  getOffices,
} from 'api'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import SingleBookingsList from 'components/organisms/bookings/singleBookingsList'
import SingleInvoiceLinesList from 'components/organisms/invoices/singleInvoiceLinesList'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import SingleOrdersList from 'components/organisms/members/singleOrdersList'
import { Autocomplete } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { SmallError } from '../resourcesAdd/formStyle'

function BookingSingle() {
  const { t } = useTranslation()
  const params = useParams()
  const [theB, setBooking] = useState(null)

  const [membersData, setMembersData] = useState(null)
  const [msData] = useState(null)
  const [resData, setResData] = useState(null)
  const [waData, setWaData] = useState(null)
  const [officesData, setOfficeData] = useState(null)
  const [guestsData] = useState(null)

  function getOfficeData() {
    getOffices().then(r => setOfficeData(r))
  }

  function getBookingData() {
    getSingleBooking(params.code).then(r => {
      console.log(r)
      setBooking(r)
    })
  }

  function getResourcesData(slug) {
    getResourcesForWorkArea(slug).then(response => {
      console.log('Resources response', response)
      setResData(response)
    })
  }

  function getWorkAreasData(slug) {
    getWorkAreasForOffice(slug).then(response => {
      console.log('work areas response', response)
      setWaData(response)
    })
  }

  function getMembersData(slug) {
    getMembersByTeam(slug).then(r => {
      console.log(r)
      setMembersData(r)
    })
  }

  const [errors, setErrors] = useState({
    booking_type: null,
    origin: null,
    status: null,
    office: null,
    work_area: null,
    planned_start: null,
    planned_end: null,
    member: null,
    guest: null,
    team: null,
  })

  function calculatePrices() {
    if (theB.work_area?.resources?.length === 0 && theB.work_area?.area_type !== 'suite') {
      setErrors({ ...errors, day: 'Work area busy' })
      return
    }
    //if (lists === true) {
    const query = {
      team: theB.team?.slug,
      client: theB?.client?.slug,
      office: theB?.office?.slug,
      workArea: theB?.work_area?.slug,
      resource: theB?.resource?.slug,
      bookingType: theB?.boking_type,
      bookingSize: 1,
      date: formatDates(theB.day)?.split('T')[0],
      fullArea: theB?.work_area?.area_type === 'suite',
    }
    calculateBookingPrice(query).then(r => {
      if (r === 'work_area_busy') {
        setErrors({ ...errors, work_area: 'Work area is busy' })
      } else if (r === 'resource_busy') {
        setErrors({ ...errors, resource: 'Resource is busy' })
      } else if (r?.data !== '') {
        //const bookingsArr = theB.bookings
        console.log(r)
      }
    })
    //}
  }

  useEffect(() => {
    if (theB?.team?.slug) getMembersData(theB.team?.slug)
  }, [theB?.team])

  useEffect(() => {
    if (theB?.office?.slug) getWorkAreasData(theB?.office?.slug)
  }, [theB?.office])

  useEffect(() => {
    if (theB?.work_area?.slug) {
      getResourcesData(theB?.work_area?.slug)
      calculatePrices()
    }
  }, [theB?.work_area])

  useEffect(() => {
    if (theB?.resource?.slug) {
      calculatePrices()
    }
  }, [theB?.resource])

  useEffect(() => {
    getBookingData()
    getOfficeData()
  }, [])

  const [modalFeedback, setModalFeedback] = useState(false)

  function updateTheBooking() {
    setModalFeedback(true)
    console.log(theB)
    const theBooking = {
      office: theB.office?.slug,
      single_price: theB.single_price,
      invoice_line: theB.invoice_line?.slug,
      work_area: theB?.work_area?.slug,
      planned_start: theB.planned_start,
      origin: theB.origin,
      status: theB.status,
      planned_end: theB.planned_end,
      member: theB.member !== null && theB.member !== undefined && theB.member !== '' ? theB.member.slug : null,
      guest: theB.guest !== null && theB.guest !== undefined && theB.guest !== '' ? theB.guest.slug : null,
      resource:
        theB.resource !== null && theB.resource !== undefined && theB.resource !== '' ? theB.resource.slug : null,
    }
    console.log(theBooking)
    updateBooking(theB.code, theBooking).then(() => {
      getBookingData()
      setModalFeedback(false)
    })
  }

  function checkInBooking() {
    setModalFeedback(true)
    const theStart = formatDates(new Date(), 'time')
    const theBooking = {
      ...theB,
      office: theB.office?.slug,
      invoice_line: theB.invoice_line?.slug,
      work_area: theB.work_area?.slug,
      planned_start: theB.planned_start,
      planned_end: theB.planned_end,
      real_start: theStart,
      member: theB.member !== null && theB.member !== undefined && theB.member !== '' ? theB.member?.slug : null,
      guest: theB.guest !== null && theB.guest !== undefined && theB.guest !== '' ? theB.guest?.slug : null,
      team: theB.team !== null && theB.team !== undefined && theB.team !== '' ? theB.team?.slug : null,
      resource:
        theB.resource !== null && theB.resource !== undefined && theB.resource !== '' ? theB.resource?.slug : null,
      membership:
        theB.membership !== null && theB.membership !== undefined && theB.membership !== ''
          ? theB.membership?.slug
          : null,
    }
    updateBooking(theB.code, theBooking).then(() => {
      getBookingData()
      setModalFeedback(false)
    })
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      booking_type: 'Booking type is required',
      origin: 'Origin is required',
      status: 'Status is required',
      office: 'Office is required',
      work_area: 'Work area is required',
      planned_start: 'Planned Start is required',
      planned_end: 'Planned End is required',
      badChoose: 'Select a member, team or guest is mandatory',
      badTime: 'Planned end can not be before planned start',
    }

    const typeRes = theB.booking_type === ('' || undefined || null) ? textErrors.booking_type : null
    const originRes = theB.origin === ('' || undefined || null) ? textErrors.origin : null
    const statusRes = theB.status === ('' || undefined || null) ? textErrors.status : null
    const officeRes = theB.office === ('' || undefined || null) ? textErrors.office : null
    const waRes =
      theB.work_area === ('' || undefined || null) && theB.booking_type !== 'meeting_room' ? textErrors.work_area : null
    const startRes = theB.planned_start === ('' || undefined || null) ? textErrors.planned_start : null
    let endRes = theB.planned_end === ('' || undefined || null) ? textErrors.planned_end : null
    if (theB.planned_end < theB.planned_start) endRes = textErrors.badTime
    let memberRes = null
    let guestRes = null
    let teamRes = null
    let clientRes = null
    if (
      theB.member === ('' || undefined || null) &&
      theB.guest === ('' || undefined || null) &&
      theB.team === ('' || undefined || null) &&
      theB.client === ('' || undefined || null)
    ) {
      memberRes = textErrors.badChoose
      guestRes = textErrors.badChoose
      teamRes = textErrors.badChoose
      clientRes = textErrors.badChoose
    }

    setErrors({
      booking_type: typeRes,
      origin: originRes,
      status: statusRes,
      office: officeRes,
      work_area: waRes,
      planned_start: startRes,
      planned_end: endRes,
      member: memberRes,
      guest: guestRes,
      team: teamRes,
      client: clientRes,
    })

    if (
      typeRes === null &&
      originRes === null &&
      statusRes === null &&
      officeRes === null &&
      waRes === null &&
      startRes === null &&
      endRes === null &&
      memberRes === null &&
      guestRes === null &&
      clientRes === null &&
      teamRes === null
    ) canUpdate = true
    if (canUpdate === true) updateTheBooking()
  }

  useEffect(() => {}, [errors])

  function cancelBooking(type) {
    setModalFeedback(true)
    updateBooking(theB.code, {
      status: type,
    }).then(() => {
      getBookingData()
      setModalFeedback(false)
    })
  }

  let theMS = []
  if (msData !== undefined) theMS = msData?.memberships
  if (theB?.team !== null && theMS !== undefined) theMS = theMS.filter(ms => ms?.team?.slug === theB?.team?.slug)

  return (
    <Grid container>
      <Modal open={modalFeedback === true}>
        <FeedbackModal type='booking-update-single' />
      </Modal>
      {theB !== null ? (
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Grid container justify='space-between' spacing={1}>
              {theB.status === 'finished' || theB.status === 'cancelled' || theB.status === 'pre_paid' ? (
                ''
              ) : (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button
                        variant='contained'
                        onClick={() => cancelBooking('cancelled')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant='contained'
                        onClick={() => cancelBooking('refund')}
                        style={{ color: 'white', backgroundColor: 'red' }}
                      >
                        Cancel & Refund
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
              {theB.status === 'finished' || theB.status === 'cancelled' ? (
                ''
              ) : (
                <Grid item>
                  <Grid container spacing={1}>
                    <Grid item>
                      {theB.real_start === null && theB.status !== 'pre_paid' && (
                        <Button variant='outlined' color='secondary' onClick={() => checkInBooking()}>
                          Check In
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      <Button variant='contained' color='secondary' onClick={() => validate()}>
                        Update Booking
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <SingleTitleSection>General Info</SingleTitleSection>
          <Grid item>
            <Grid container spacing={2} alignItems='center'>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginTop: '-7px', marginLeft: '14px' }}>Booking Type</InputLabel>
                  <Select
                    variant='outlined'
                    label='Booking Type'
                    value={theB.booking_type}
                    onChange={e => setBooking({ ...theB, booking_type: e.target.value })}
                    error={errors.booking_type !== null}
                    disabled
                  >
                    <MenuItem value='hot_desk'>Hot Desk</MenuItem>
                    <MenuItem value='desk'>Desk</MenuItem>
                    <MenuItem value='meeting_room'>Meeting Room</MenuItem>
                    <MenuItem value='suite'>Private office</MenuItem>
                  </Select>
                  {errors.booking_type !== null ? <SmallError>{errors.booking_type}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    label='Create Date'
                    value={theB.create_date}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    variant='outlined'
                    label='Created by'
                    value={`${theB?.user_created?.first_name} ${theB?.user_created?.last_name}`}
                    disabled
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Origin</InputLabel>
                  <Select
                    variant='outlined'
                    value={theB.origin}
                    label='Origin'
                    onChange={e => setBooking({ ...theB, origin: e.target.value })}
                    error={errors.origin !== null}
                  >
                    <MenuItem value='regular'>Regular</MenuItem>
                    <MenuItem value='referral'>Referral</MenuItem>
                    <MenuItem value='trial'>Trial</MenuItem>
                    <MenuItem value='complimentary'>Complimentary</MenuItem>
                  </Select>
                  {errors.origin !== null ? <SmallError>{errors.origin}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Status</InputLabel>
                  <Select
                    variant='outlined'
                    value={theB.status}
                    label='Status'
                    onChange={e => setBooking({ ...theB, status: e.target.value })}
                    error={errors.status !== null}
                  >
                    <MenuItem value='active'>Active</MenuItem>
                    <MenuItem value='inactive'>Inactive</MenuItem>
                    <MenuItem value='alert'>Alert</MenuItem>
                    <MenuItem value='refund'>Refund</MenuItem>
                    <MenuItem value='pre_paid'>Pre Paid</MenuItem>
                    <MenuItem value='finished'>Finished</MenuItem>
                    <MenuItem value='cancelled'>Cancelled</MenuItem>
                  </Select>
                  {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    variant='outlined'
                    label='Single Price'
                    value={theB.single_price}
                    type='number'
                    disabled={theB?.invoice_line?.status !== 'pending_payment'}
                    onChange={e => (e.target.value >= 0 ? setBooking({ ...theB, single_price: e.target.value }) : 0)}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField variant='outlined' label='Credits' value={theB.credits} type='number' disabled />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    disablePast={theB?.planned_start === null}
                    label='Planned Start'
                    value={theB.planned_start}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, planned_start: new Date(value) })}
                    error={errors.planned_start !== null}
                  />
                  {errors.planned_start !== null ? <SmallError>{errors.planned_start}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    disablePast={theB?.planned_end === null}
                    inputVariant='outlined'
                    label='Planned End'
                    value={theB.planned_end}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, planned_end: new Date(value) })}
                    error={errors.planned_end !== null}
                  />
                  {errors.planned_end !== null ? <SmallError>{errors.planned_end}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    label='Real Start'
                    value={theB.real_start}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, real_start: value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <DateTimePicker
                    inputVariant='outlined'
                    label='Real End'
                    value={theB.real_end}
                    format='dd-MM-yyyy HH:mm'
                    autoOk
                    onChange={value => setBooking({ ...theB, real_end: value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Office' error={errors.office !== null} />
                    )}
                    value={theB.office !== (undefined || null) ? theB.office : null}
                    options={officesData !== null ? officesData.offices : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, office: data })
                      else setBooking({ ...theB, office: null })
                    }}
                    error={errors.office !== null}
                  />
                  {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Work Area' error={errors.work_area !== null} />
                    )}
                    value={theB.work_area !== (undefined || null) ? theB.work_area : null}
                    options={waData !== null ? waData : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, work_area: data })
                      else setBooking({ ...theB, work_area: null })
                    }}
                    error={errors.work_area !== null}
                  />
                  {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => <TextField variant='outlined' {...p} label='Resource' />}
                    value={theB.resource !== (undefined || null) ? theB.resource : null}
                    options={
                      resData !== null
                        ? resData.filter(item => item.resource_type === theB.booking_type)
                        : [{ name: 'No data' }]
                    }
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, resource: data })
                      else setBooking({ ...theB, resource: null })
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    variant='outlined'
                    label={t('booking.form.oneTimeCode')}
                    value={theB?.one_time_code ? theB?.one_time_code?.code : '-'}
                    type='text'
                    disabled
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Comments'
                onChange={e => setBooking({ ...theB, comments: e.target.value })}
                multiline
                rows={3}
                rowsMax={3}
              />
            </FormControl>
          </Grid>
          <SingleTitleSection>Member Info</SingleTitleSection>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <TextField variant='outlined' label='Team' value={theB.team?.name} disabled />
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Member' error={errors.member !== null} />
                    )}
                    value={theB.member !== (undefined || null) ? theB.member : null}
                    options={membersData !== null ? membersData : [{ user: { first_name: 'No', last_name: 'data' } }]}
                    getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                    onChange={(e, data) => {
                      console.log(data)
                      if (data !== null) setBooking({ ...theB, member: data })
                      else setBooking({ ...theB, member: null })
                    }}
                    error={errors.member !== null}
                  />
                  {errors.member !== null ? <SmallError>{errors.member}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => (
                      <TextField variant='outlined' {...p} label='Guest' error={errors.guest !== null} />
                    )}
                    value={theB.guest !== (undefined || null) ? theB.guest : null}
                    options={guestsData !== null ? guestsData.guests : [{ name: 'No data' }]}
                    getOptionLabel={option => option.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, guest: data })
                      else setBooking({ ...theB, guest: null })
                    }}
                    error={errors.guest !== null}
                  />
                  {errors.guest !== null ? <SmallError>{errors.guest}</SmallError> : ''}
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    renderInput={p => <TextField variant='outlined' {...p} label='Membership Plan' />}
                    value={theB.membership !== (undefined || null) ? theB.membership : null}
                    options={msData !== null ? theMS : [{ plan_membership: { name: 'no data' } }]}
                    getOptionLabel={option => option.plan_membership.name}
                    onChange={(e, data) => {
                      if (data !== null) setBooking({ ...theB, membership: data })
                      else setBooking({ ...theB, membership: null })
                    }}
                  />
                  {theB?.membership && (
                    <Link to={`/members/memberships/${theB?.membership?.slug}`} style={{ textDecoration: 'none' }}>
                      Check here
                    </Link>
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          {theB.invoice_line !== null ? <SingleInvoiceLinesList invoices={[theB.invoice_line]} /> : ''}
          {theB?.orders !== undefined ? <SingleOrdersList orders={theB?.orders} /> : ''}
          {theB?.related_bookings !== undefined ? (
            <SingleBookingsList bookings={theB.related_bookings.filter(b => b.slug !== theB.slug)} />
          ) : (
            ''
          )}
        </Grid>
      ) : (
        <CircularProgress color='secondary' />
      )}
    </Grid>
  )
}

BookingSingle.propTypes = {
  office: PropTypes.object,
}

BookingSingle.defaultProps = {
  office: {},
}

export default BookingSingle
