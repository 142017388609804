import styled from 'styled-components'
import { BlockPicker } from 'react-color'

export const Area = styled.div`
  background-color: ${({ color }) => color};
  z-index: 2;
  border-radius: 5px;
  height: 22px;
  width: 22px;
  cursor: pointer;
  outline: none;
  border: solid 1px ${({ theme }) => theme.palette.grey[500]};
`
export const BlockPickerStyled = styled(BlockPicker)`
  && {
    position: absolute !important;
    top: 26px;
    right: 0;
  }
`
