import styled from 'styled-components'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'

export const Item = styled(Link)`
  && {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.palette.grey[700]};
  }
`

export const ItemText = styled(Typography)`
  && {
    font-size: 14px;
    font-weight: 500;
    line-height: 30px;
  }
`
