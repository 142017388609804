import { getStaffSingle, getOffices, updateStaffMember } from 'api'
import { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
  CircularProgress,
  FormControl,
  Grid,
  TextField,
  Select,
  MenuItem,
  Button,
  InputLabel,
  Modal,
} from '@material-ui/core'
import formatWords from 'components/atoms/formatWords/formatWords'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import StaffSingleTransferList from './staffSingleTransferList'
import { SpinnerContainer } from '../members/newListStyle'

function StaffSingle() {
  const history = useHistory()
  const params = useParams()
  const [staff, setStaff] = useState()
  const [staffProfile, setStaffProfile] = useState()
  const [staffMember, setStaffMember] = useState({
    first_name: staff ? staff.user.first_name : '',
    last_name: staff ? staff.user.last_name : '',
    email: staff ? staff.user.email : '',
    phone: staff ? staff.user.phone : '',
    location: staff ? staff.user.location : '',
    role: staff ? staff.role : '',
    status: staff ? staff.status : '',
    job_title: staff ? staff.job_title : '',
    web_sections: staff ? staff.web_sections : [],
  })

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    email: null,
  })

  const [fbModal, setModal] = useState(false)

  function getSingleStaffData() {
    getStaffSingle(params.slug).then(r => {
      setStaff(r)
      setStaffMember({
        first_name: r.user.first_name,
        last_name: r.user.last_name,
        email: r.user.email,
        phone: r.user.phone,
        role: r.role,
        status: r.user.status,
      })
    })
  }
  function getStaffData() {
    getOffices().then(r => setStaffProfile(r))
  }

  useEffect(() => {
    if (staff === undefined) getSingleStaffData()
  }, [staff])

  useEffect(() => {
    if (staffProfile === undefined) getStaffData()
  }, [staffProfile])

  useEffect(() => {}, [staffMember])

  let singleStaffWebSections = []
  let profileWebSections = []
  if (staff !== undefined) singleStaffWebSections = staff.web_sections
  if (staffProfile !== undefined) profileWebSections = staffProfile?.active_client.web_sections

  function updateStaffMemberData() {
    updateStaffMember(staff.slug, staffMember)
      .then(setModal(true))
      .then(
        setTimeout(() => {
          history.push('/users/staff')
        }, 1500)
      )
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      first_name: 'First name is required',
      last_name: 'Last name is required',
      email: 'Email is required',
      badMail: '',
    }

    const firstNameRes =
      staffMember.first_name === '' || staffMember.first_name === undefined ? textErrors.first_name : null
    const lastNameRes =
      staffMember.last_name === '' || staffMember.last_name === undefined ? textErrors.last_name : null
    const emailRes = staffMember.email === '' || staffMember.email === undefined ? textErrors.email : null

    setErrors({
      first_name: firstNameRes,
      last_name: lastNameRes,
      email: emailRes,
    })

    if (firstNameRes === null && lastNameRes === null && emailRes === null) canUpdate = true
    if (canUpdate === true) updateStaffMemberData()
  }

  return (
    <Grid container direction='column' spacing={2} style={{ marginTop: '15px' }}>
      <Modal open={fbModal === true}>
        <FeedbackModal type='staff-update' />
      </Modal>
      <Grid item>
        <Grid container justify='flex-end'>
          <Button variant='contained' color='secondary' onClick={() => validate()}>
            Update Staff member
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.first_name}
                variant='outlined'
                label='First Name'
                onChange={e => setStaffMember({ ...staffMember, first_name: e.target.value })}
                error={errors.first_name !== null}
              />
              {errors.first_name !== null ? <SmallError>{errors.first_name}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.last_name}
                variant='outlined'
                label='Last Name'
                onChange={e => setStaffMember({ ...staffMember, last_name: e.target.value })}
                error={errors.last_name !== null}
              />
              {errors.last_name !== null ? <SmallError>{errors.last_name}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.email}
                variant='outlined'
                label='Email'
                onChange={e => setStaffMember({ ...staffMember, email: e.target.value })}
                error={errors.email !== null}
              />
              {errors.email !== null ? <SmallError>{errors.email}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.phone}
                variant='outlined'
                label='Phone'
                onChange={e => setStaffMember({ ...staffMember, phone: e.target.value })}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container direction='row' spacing={2}>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <InputLabel style={{ marginTop: '-8px', marginLeft: '15px' }}>Role</InputLabel>
              <Select
                value={staffMember.role}
                variant='outlined'
                label='Role'
                onChange={e => setStaffMember({ ...staffMember, role: e.target.value })}
              >
                <MenuItem value='club_owner'>Owner</MenuItem>
                <MenuItem value='club_member'>Member</MenuItem>
                <MenuItem value='club_manager'>Manager</MenuItem>
                <MenuItem value='club_staff'>Staff</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.location}
                variant='outlined'
                label='Location'
                onChange={e => setStaffMember({ ...staffMember, location: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <TextField
                value={staffMember.job_title}
                variant='outlined'
                label='Job Title'
                onChange={e => setStaffMember({ ...staffMember, job_title: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl style={{ width: '100% ' }}>
              <InputLabel style={{ marginTop: '-8px', marginLeft: '15px' }}>Status</InputLabel>
              <Select
                value={formatWords(staffMember.status)}
                variant='outlined'
                label='Status'
                onChange={e => setStaffMember({ ...staffMember, status: e.target.value })}
              >
                <MenuItem value='active'>Active</MenuItem>
                <MenuItem value='inactive'>Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        {singleStaffWebSections.lenght === 0 && profileWebSections.lenght === 0 ? (
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        ) : (
          <Grid item>
            <StaffSingleTransferList
              singleStaffWebSections={singleStaffWebSections}
              profileWebSections={profileWebSections}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default StaffSingle
