import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, TextField, Select, Modal } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { DatePicker } from '@material-ui/pickers'
import PropTypes from 'prop-types'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { SubmitBtn } from 'components/organisms/bookings/modalStyles'
import { getDashboardOffices, getInvoiceReconcile, createInvoiceFromExternal } from 'api'
import { ListContainer, ListHeader, ListRow, ListTitle, SpinnerContainer } from '../members/newListStyle'
import ModalPayoutDetail from './modalPayoutDetail'
import { BoxRow } from './scrollListStyles'

const now = new Date()
function OfficeReconcile() {
  let totalDiff = 0
  let totalPlatform = 0
  let totalXero = 0
  let onlyXero = 0
  let idXeroList = 0
  const [invoicingInfo, setInvocingInfo] = useState(null)
  const [payoutInfo, setPayoutInfo] = useState(null)
  const [officesData, setOfficesData] = useState({ offices: [] })
  const [theFilter, setFilter] = useState({
    status: '',
    type: '',
    team: '',
    office: null,
    start: new Date(now.getFullYear(), now.getMonth(), 1),
    end: new Date(now.getFullYear(), now.getMonth() + 1, 0),
  })

  const [modal, setModal] = useState(false)
  const [activePayout, setActivePayout] = useState(null)

  function filterByStatus(event) {
    if (event.target.value !== 'none') {
      setFilter({ ...theFilter, status: event.target.value })
    } else {
      setFilter({ ...theFilter, status: '' })
    }
  }

  function filterByType(event) {
    if (event.target.value !== 'none') {
      setFilter({ ...theFilter, type: event.target.value })
    } else {
      setFilter({ ...theFilter, type: '' })
    }
  }

  function filterByOffice(event) {
    if (event !== 'all') {
      setFilter({ ...theFilter, office: event })
    } else {
      setFilter({ ...theFilter, office: '' })
    }
  }

  function filterByStart(event) {
    if (event !== 'all') {
      setFilter({ ...theFilter, start: new Date(event) })
    } else {
      setFilter({ ...theFilter, start: '' })
    }
  }

  function filterByEnd(event) {
    if (event !== 'all') {
      setFilter({ ...theFilter, end: new Date(event) })
    } else {
      setFilter({ ...theFilter, end: '' })
    }
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function refreshReconcile() {
    setInvocingInfo(null)
    setPayoutInfo(null)
    const data = {
      ...theFilter,
      start: new Date(theFilter.start).toLocaleDateString('en-GB'),
      end: new Date(theFilter.end).toLocaleDateString('en-GB'),
    }
    const query = {
      currentPage: 1,
      pageSize: 10000,
      filter: data,
    }
    getInvoiceReconcile(query).then(r => {
      console.log(r)
      setInvocingInfo(r?.last_month_invoicing)
      setPayoutInfo(r?.month_payout_info)
    })
    console.log('Reconcile refreshong')
  }

  useEffect(() => {
    getOfficesData()
    refreshReconcile()
  }, [])

  useEffect(() => {
    console.log(new Date(theFilter?.start?.year, theFilter?.start.month, theFilter?.start.date))
    console.log()
  }, [theFilter])

  function syncExternalInvoice(externalName) {
    console.log('Press item')
    const data = {
      name: externalName,
    }
    createInvoiceFromExternal(data)
      .then(() => {
        refreshReconcile()
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    console.log(activePayout)
    if (activePayout !== null) setModal(true)
    else setModal(false)
  }, [activePayout])

  const noLinkStyle = { textDecoration: 'none' }

  return (
    <Grid>
      <Modal open={modal} onClose={() => setActivePayout(null)}>
        <ModalPayoutDetail payout={activePayout} toClose={() => setActivePayout(null)} />
      </Modal>
      <ListContainer>
        <BoxRow style={{ marginTop: '25px', marginBottom: '20px' }}>
          <FormControl variant='outlined' style={{ width: '19%', marginRight: '1%' }}>
            <InputLabel id='filter-label'>Status</InputLabel>
            <Select onChange={event => filterByStatus(event)} label='Status filter'>
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='open'>Draft</MenuItem>
              <MenuItem value='pending_payment'>Pending Payment</MenuItem>
              <MenuItem value='paid'>Paid</MenuItem>
              <MenuItem value='on_review'>On Review</MenuItem>
              <MenuItem value='void'>Void</MenuItem>
              <MenuItem value='no_date'>No date</MenuItem>
            </Select>
          </FormControl>
          <FormControl variant='outlined' style={{ width: '19%', marginRight: '1%' }}>
            <InputLabel id='filter-label'>Type Filter</InputLabel>
            <Select onChange={event => filterByType(event)} label='Type filter'>
              <MenuItem value='none'>None</MenuItem>
              <MenuItem value='work'>Work</MenuItem>
              <MenuItem value='bar'>Bar</MenuItem>
            </Select>
          </FormControl>
          <FormControl style={{ width: '19%', marginRight: '1%' }}>
            <Autocomplete
              name='office'
              options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
              onChange={(e, data) => {
                if (data?.slug) {
                  filterByOffice(data?.slug)
                } else {
                  filterByOffice('all')
                }
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
          <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
            <DatePicker
              value={theFilter?.start}
              onChange={value => {
                if (value !== undefined) {
                  filterByStart(value)
                }
              }}
              name='start'
              variant='inline'
              inputVariant='outlined'
              helperText={null}
              format='dd-MM-yyyy'
              label='From'
              autoOk
            />
          </FormControl>
          <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
            <DatePicker
              value={theFilter?.end}
              onChange={value => {
                if (value !== undefined) {
                  filterByEnd(value)
                }
              }}
              name='to'
              variant='inline'
              inputVariant='outlined'
              helperText={null}
              format='dd-MM-yyyy'
              label='To'
              autoOk
            />
          </FormControl>
          <FormControl style={{ width: '9%', marginRight: '1%' }}>
            <SubmitBtn onClick={() => refreshReconcile()}>Filter</SubmitBtn>
          </FormControl>
        </BoxRow>
      </ListContainer>
      {invoicingInfo !== null && payoutInfo != null ? (
        <ListContainer>
          <SingleTitleSection>Platform info</SingleTitleSection>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Club</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Total</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Work space</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>F&B</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Event</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}> </ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Paid</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Pending</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Draft</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Others</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {invoicingInfo?.platform?.clubs?.map((row, i) => (
            <ListRow key={i + 1}>
              <Grid container direction='row' justify='space-between'>
                <Grid item xs={1}>
                  <ListTitle>{row?.club_name === 'empty' ? 'No club' : row?.club_name}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.all).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.work).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.bar).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.event).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}> </ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.paid).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.pending).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.draft).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{`£${parseFloat(row?.others).toFixed(2)}`}</ListTitle>
                </Grid>
              </Grid>
            </ListRow>
          ))}
          <ListRow key='total_platform'>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Total</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ fontWeight: '600' }}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_all).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_work).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_bar).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_event).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}> </ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_paid).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_pending).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_draft).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(invoicingInfo?.platform?.total?.total_others).toFixed(2)}`}</ListTitle>
              </Grid>
            </Grid>
          </ListRow>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle> </ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Total</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Work space</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>F&B</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Event</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}> </ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Paid</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Pending</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Draft</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Others</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          <ListRow key='total_platform'>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Number invoices</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ fontWeight: '600' }}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_all}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_work}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_bar}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_event}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}> </ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_paid}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_pending}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_draft}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{invoicingInfo?.platform?.total?.sum_others}</ListTitle>
              </Grid>
            </Grid>
          </ListRow>
          <SingleTitleSection>Payouts</SingleTitleSection>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Date</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Status</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>£ Total</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>£ Gross</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>£ Fees</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>£ Refunds</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Lines</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>Refunds</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {payoutInfo?.map((row, i) => (
            <ListRow key={i + 1} onClick={() => setActivePayout(row)}>
              <Grid container direction='row' justify='space-between'>
                <Grid item xs={1}>
                  <ListTitle>{row?.date_created?.split('T')[0]}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.status}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${(parseFloat(row?.gross) - parseFloat(row?.fee) - parseFloat(row?.refunds)).toFixed(
                    2
                  )}`}
                  </ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.gross).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.fee).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.refunds).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.num_charges}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.num_refunds}</ListTitle>
                </Grid>
              </Grid>
            </ListRow>
          ))}
          <SingleTitleSection>Invoice reconcile</SingleTitleSection>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>#</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Invoice</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Amount</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Xero</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Status</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Xero</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Date</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Xero</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Result</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Diff</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Acc Diff</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {invoicingInfo?.comparision?.map((row, i) => {
            const datePlatform = row?.platform_date
            const dateXero = row?.xero_date?.split('T')
            let syncFromExternal = false
            if (Number.isNaN(parseFloat(row?.platform_amount))) {
              syncFromExternal = true
            }
            let result =
              parseFloat(row?.platform_amount).toFixed(2) === parseFloat(row?.xero_amount).toFixed(2) ? 'OK' : 'ERROR'
            const platformAmount =
              Number.isNaN(parseFloat(row?.platform_amount)) || syncFromExternal ? 0 : parseFloat(row?.platform_amount)
            const xeroAmount =
              Number.isNaN(parseFloat(row?.xero_amount)) || syncFromExternal ? 0 : parseFloat(row?.xero_amount)
            let diff = platformAmount - xeroAmount
            let platfromSatus = row?.platform_status

            if (row?.xero_status === 'VOIDED' || row?.xero_status === 'DELETED') {
              diff = 0
              result = 'N/A'
              syncFromExternal = false
            } else if (platfromSatus !== 'open') totalDiff += diff

            totalPlatform += platformAmount
            totalXero += xeroAmount

            if (platfromSatus === 'paid') {
              platfromSatus = 'Paid'
              if (row?.xero_status !== 'PAID') result = 'ERROR'
            }
            if (platfromSatus === 'pending_payment') {
              platfromSatus = 'Pending'
              if (row?.xero_status !== 'AUTHORISED') result = 'ERROR'
            }
            if (platfromSatus === 'open') {
              platfromSatus = 'Draft'
              if (row?.xero_status !== 'DRAFT') result = 'ERROR'
              diff = 0
              result = 'N/A'
              syncFromExternal = false
            }
            if (platfromSatus === 'pending_payment') {
              result = 'ERROR'
            }

            if (row?.xero_status === 'DELETED') {
              result = 'OK'
            }

            if (!syncFromExternal) {
              return (
                <ListRow key={i + 1}>
                  <Grid container direction='row' justify='space-between'>
                    <Grid item xs={1}>
                      <ListTitle>
                        {i + 1}{' '}
                        {row?.invoice_slug !== '' && (
                          <Link to={`/members/invoices/${row?.invoice_slug}`} key={row?.slug} style={noLinkStyle}>
                            &nbsp;View
                          </Link>
                        )}
                        {syncFromExternal && (
                          <ListTitle
                            style={{ cursor: 'pointer' }}
                            onClick={() => {
                              syncExternalInvoice(row?.invoice_name)
                            }}
                          >
                            &nbsp;Sync
                          </ListTitle>
                        )}
                      </ListTitle>
                    </Grid>
                    <Grid item xs={1}>
                      <ListTitle>{row?.invoice_name}</ListTitle>
                    </Grid>
                    <Grid item xs={1}>
                      <ListTitle>
                        {Number.isNaN(parseFloat(row?.platform_amount))
                          ? '-'
                          : `£${parseFloat(row?.platform_amount).toFixed(2)}`}
                      </ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>
                        {Number.isNaN(parseFloat(row?.xero_amount))
                          ? '-'
                          : `£${parseFloat(row?.xero_amount).toFixed(2)}`}
                      </ListTitle>
                    </Grid>
                    <Grid item xs={1}>
                      <ListTitle>{platfromSatus}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{row?.xero_status}</ListTitle>
                    </Grid>
                    <Grid item xs={1}>
                      <ListTitle>{datePlatform !== undefined ? datePlatform : '-'}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{dateXero !== undefined ? dateXero[0] : '-'}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: result === 'ERROR' ? '#FF0000' : '' }}>
                      <ListTitle>{result}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{diff.toFixed(2)}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: totalDiff !== 0 ? '#FF0000' : '' }}>
                      <ListTitle>{totalDiff.toFixed(2)}</ListTitle>
                    </Grid>
                  </Grid>
                </ListRow>
              )
            }
            return <></>
          })}
          <ListRow key='result'>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Total</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(totalPlatform).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>{`£${parseFloat(totalXero).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{totalDiff.toFixed(2)}</ListTitle>
              </Grid>
            </Grid>
          </ListRow>
          <SingleTitleSection>Xero invoices</SingleTitleSection>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>#</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Invoice</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Amount</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Status</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>Date</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>Diff</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {invoicingInfo?.comparision?.map(row => {
            const dateXero = row?.xero_date?.split('T')
            const xeroAmount = Number.isNaN(parseFloat(row?.xero_amount)) ? 0 : parseFloat(row?.xero_amount)
            let syncFromExternal = false
            if (Number.isNaN(parseFloat(row?.platform_amount))) {
              syncFromExternal = true
              onlyXero += xeroAmount
              idXeroList += 1
            }

            if (syncFromExternal) {
              return (
                <ListRow key={idXeroList + 1}>
                  <Grid container direction='row' justify='space-between'>
                    <Grid item xs={1}>
                      <ListTitle>{idXeroList + 1}</ListTitle>
                    </Grid>
                    <Grid item xs={1}>
                      <ListTitle>{row?.invoice_name}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>
                        {Number.isNaN(parseFloat(row?.xero_amount))
                          ? '-'
                          : `£${parseFloat(row?.xero_amount).toFixed(2)}`}
                      </ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{row?.xero_status}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{dateXero !== undefined ? dateXero[0] : '-'}</ListTitle>
                    </Grid>
                    <Grid item xs={1} style={{ color: '#05207a' }}>
                      <ListTitle>{onlyXero.toFixed(2)}</ListTitle>
                    </Grid>
                  </Grid>
                </ListRow>
              )
            }
            return <></>
          })}
          <ListRow key='result'>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle>Total</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{`£${parseFloat(totalPlatform).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>{`£${parseFloat(onlyXero).toFixed(2)}`}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1} style={{ color: '#05207a' }}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>-</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle>{onlyXero.toFixed(2)}</ListTitle>
              </Grid>
            </Grid>
          </ListRow>
        </ListContainer>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

OfficeReconcile.propTypes = {
  stats: PropTypes.object,
}

OfficeReconcile.defaultProps = {
  stats: {},
}

export default OfficeReconcile
