import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { getDashboardEvents, getDashboardOffices, getClientsReferral } from 'api'
import PropTypes from 'prop-types'
import { Autocomplete, Pagination } from '@material-ui/lab'
import {
  Button,
  ButtonGroup,
  CircularProgress,
  MenuItem,
  TextField,
  Select,
  FormControl,
  InputLabel,
  Modal,
} from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import { ScrollView } from 'devextreme-react'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
//import formatWords from 'components/atoms/formatWords/formatWords'
import formatDates from 'components/atoms/formatDates/formatDates'
import ModalCreateDesk from 'components/organisms/bookings/modalCreateDesk'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from 'components/organisms/members/newListStyle'
import { BoxRow, BoxItemTitle, BoxItem } from 'components/organisms/invoices/scrollListStyles'
import SearchInput from '../../atoms/searchInput'

function ComEvents({ office, activeClient, role }) {
  const { t } = useTranslation()
  console.log('Office info ', office, role)
  const [paginate, setPaginate] = useState(true)
  const [eventsData, setDataEvents] = useState()
  const [eventDeskModal, setEventDeskModal] = useState(false)
  const [theFilter, setFilter] = useState({
    name: '',
    client: '',
    order: '',
    status: 'active',
    office: office.slug,
    start: null,
    end: null,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 25,
    thePage: 1,
    pagesTotal: '',
  })

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    console.log('Calling to get event in list')
    getDashboardEvents(query).then(response => {
      getPagination(response)
      setDataEvents(response)
      setPaginate(true)
    })
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    console.log('Data from pagination')
    getData(query)
    setPaginate(false)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, name: letters },
    }
    console.log('Data from filter')
    getData(query)
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = eventsData?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    console.log('Data from order')
    getData(query)
  }

  function closeModal() {
    console.log('Closing moda;')
    getData()
    setEventDeskModal(false)
  }
  // ADD FILTERS

  const [clientsData, setClientsData] = useState({ clients: [] })
  const [officesData, setOfficesData] = useState({ offices: [] })
  const [dateFilter, setDateFilter] = useState('')

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 25,
      filter: {
        name: data !== undefined ? data : '',
      },
    }
    console.log(query)
    getClientsReferral(query).then(r => setClientsData(r))
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  useEffect(() => {
    getOfficesData()
    getClientsData()
  }, [])

  useEffect(() => {
    setDataEvents(null)
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: theFilter,
    }
    console.log('Data from use Effect filter ', theFilter)
    getData(query)
  }, [theFilter])
  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // COUNTER
  const [searchClient, setSearchClient] = useState('')

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  const space = ' '
  const slash = '-'

  const today = new Date()
  const yesterday = new Date(today)
  const tomorrow = new Date(today)
  yesterday.setDate(yesterday.getDate() - 1)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by member or team...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
        <BoxRow>
          <ButtonGroup style={{ marginLeft: '15px', width: '100%' }}>
            <Button
              variant='contained'
              color={dateFilter === 'yesterday' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('yesterday')
                setFilter({ ...theFilter, start: formatDates(yesterday, 'start'), end: formatDates(yesterday) })
              }}
            >
              Yesterday
            </Button>
            <Button
              variant='contained'
              color={dateFilter === 'today' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('today')
                setFilter({ ...theFilter, start: formatDates(today, 'start'), end: formatDates(today) })
              }}
            >
              Today
            </Button>
            <Button
              variant='contained'
              color={dateFilter === 'tomorrow' ? 'secondary' : ''}
              onClick={() => {
                setDateFilter('tomorrow')
                setFilter({ ...theFilter, start: formatDates(tomorrow, 'start'), end: formatDates(tomorrow) })
              }}
            >
              Tomorrow
            </Button>
          </ButtonGroup>
          <ButtonGroup style={{ marginLeft: '15px', width: '100%' }}>
            <Button
              variant='contained'
              color='secondary'
              type='submit'
              style={{ marginLeft: '25px' }}
              onClick={() => setEventDeskModal(true)}
            >
              {t('booking.form.bookingBtn')}
            </Button>
          </ButtonGroup>
        </BoxRow>
      </BoxRow>
      <BoxRow style={{ marginTop: '35px' }}>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <Autocomplete
            name='office'
            options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
            onChange={(e, data) => {
              if (data?.slug) {
                setFilter({ ...theFilter, office: data.slug })
              } else {
                setFilter({ ...theFilter, office: 'all' })
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
            defaultValue={office}
          />
        </FormControl>
        {role !== 'referral' && (
          <FormControl style={{ width: '100%', marginRight: '10px' }}>
            <Autocomplete
              name='autocomplete-clients'
              options={clientsData?.clients?.length > 0 ? clientsData?.clients : [{ name: 'Find broker...' }]}
              getOptionLabel={option => option.name}
              renderInput={params => <TextField {...params} label='Broker' variant='outlined' />}
              onInputChange={(e, value) => {
                console.log(e.target, value)
                setSearchClient({
                  client: value,
                  filter: e.target.innerText,
                })
              }}
              onChange={(e, data) => {
                if (data?.slug) {
                  console.log('Selected client ', data)
                  setFilter({ ...theFilter, client: data.slug })
                } else {
                  console.log('Cleaning team ', data)
                  setFilter({ ...theFilter, client: '' })
                }
              }}
              loading
              loadingText='Searching...'
              noOptionsText='No Results'
            />
          </FormControl>
        )}
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <DatePicker
            format='yyyy-MM-dd'
            value={theFilter.start}
            label='Start'
            inputVariant='outlined'
            onChange={value => setFilter({ ...theFilter, start: formatDates(value, 'start_day') })}
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <DatePicker
            clearable
            format='yyyy-MM-dd'
            value={theFilter.end}
            label='End'
            inputVariant='outlined'
            onChange={value => setFilter({ ...theFilter, end: formatDates(value, 'end_day') })}
          />
        </FormControl>
        <FormControl style={{ width: '100%', marginRight: '10px' }}>
          <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Status</InputLabel>
          <Select
            variant='outlined'
            label='Status'
            onChange={e => setFilter({ ...theFilter, status: e.target.value })}
            autoOk
            defaultValue=''
          >
            <MenuItem value=''>None</MenuItem>
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='pending'>Pending</MenuItem>
            <MenuItem value='cancelled'>Cancelled</MenuItem>
            <MenuItem value='finished'>Finished</MenuItem>
          </Select>
        </FormControl>
      </BoxRow>
      <ScrollView direction='horizontal' style={{ marginTop: '15px' }} width={(windowSize.width * 82) / 100}>
        <Modal open={eventDeskModal} toClose={eventDeskModal === false}>
          <ModalCreateDesk
            closeModal={closeModal}
            office={office}
            theDay={new Date()}
            role={role}
            activeClient={activeClient}
          />
        </Modal>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Name</strong>
                  </Button>
                  {eventsData?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              {role !== 'referral' && (
                <BoxItemTitle>
                  <ListTitle>
                    <Button onClick={() => setOrderData('client__name')}>
                      <strong>Broker</strong>
                    </Button>
                    {eventsData?.order === 'client__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListTitle>
                </BoxItemTitle>
              )}
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Club</strong>
                  </Button>
                  {eventsData?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('price')}>
                    <strong>Price (VAT in)</strong>
                  </Button>
                  {eventsData?.order === 'price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('start_date')}>
                    <strong>Start</strong>
                  </Button>
                  {eventsData?.order === 'start_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('end_date')}>
                    <strong>End</strong>
                  </Button>
                  {eventsData?.order === 'end_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('products')}>
                    <strong>Products</strong>
                  </Button>
                  {eventsData?.order === 'products' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('limit_people')}>
                    <strong>Limit</strong>
                  </Button>
                  {eventsData?.order === 'limit_people' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('for_all')}>
                    <strong>For all</strong>
                  </Button>
                  {eventsData?.order === 'for_all' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('ticket_on_sale')}>
                    <strong>Available web</strong>
                  </Button>
                  {eventsData?.order === 'ticket_on_sale' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {eventsData === undefined || eventsData === null ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            eventsData?.events.map((event, index) => {
              let dateStart = ''
              if (event.start_date !== null) {
                dateStart = `${new Date(event.start_date).toLocaleDateString('en-GB')} - ${new Date(
                  event.start_date
                ).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })}`
              }
              let dateEnd = ''
              if (event.end_date !== null) {
                dateEnd = `${new Date(event.end_date).toLocaleDateString('en-GB')} - ${new Date(
                  event.end_date
                ).toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit', hour12: true })}`
              }
              return (
                <Link to={`/events/${event.code}`} style={{ textDecoration: 'none' }}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{event?.name}</ListItem>
                      </BoxItem>
                      {role !== 'referral' && (
                        <BoxItem>
                          <ListItem>{event.client?.name === undefined ? '-' : `${event.client.name}`}</ListItem>
                        </BoxItem>
                      )}
                      <BoxItem>
                        <ListItem>{event.office?.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{event.price ? `£${event.price}` : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dateStart}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dateEnd}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{event.products ? event.products.length : '-'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{event.limit_people}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{event.for_all ? 'YES' : 'NO'}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{event.ticket_on_sale ? 'YES' : 'NO'}</ListItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

ComEvents.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
  role: PropTypes.string,
}

ComEvents.defaultProps = {
  office: {},
  activeClient: {},
  role: '',
}

export default ComEvents
