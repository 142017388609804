import React from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import {
  Container,
  Favicon,
  FontInput,
  Form,
  Logo,
  NameInput,
  PlaceholderIcon,
  PrimaryColorInput,
  SecondaryColorInput,
  Toolbar,
} from './styled'

const FormThemeView = ({ onSubmit, control, errors }) => {
  const { t } = useTranslation()
  return (
    <Container>
      <Form onSubmit={onSubmit}>
        <Toolbar>
          <Button variant='contained' color='secondary' type='submit'>
            {t('profile.theme.save')}
          </Button>
        </Toolbar>
        <Favicon label={t('profile.theme.favicon')} name='favicon' control={control} error={errors?.favicon} />
        <NameInput label={t('profile.theme.name')} name='name' control={control} error={errors?.name} />
        <FontInput
          label={t('profile.theme.font')}
          name='font'
          control={control}
          error={errors?.font}
          options={[
            { id: 1, text: 'Lato' },
            { id: 2, text: 'Comic Sans' },
          ]}
        />
        <PrimaryColorInput
          label={t('profile.theme.primaryColor')}
          name='navigation_color,'
          control={control}
          error={errors?.navigation_color}
        />
        <SecondaryColorInput
          label={t('profile.theme.secondaryColor')}
          name='button_color'
          control={control}
          error={errors?.button_color}
        />
        <Logo
          label={t('profile.theme.loginImage')}
          name='login'
          control={control}
          error={errors?.login}
          icon={<PlaceholderIcon />}
          placeholderText={t('profile.theme.loginPlaceholder')}
        />
      </Form>
    </Container>
  )
}

FormThemeView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
}

FormThemeView.defaultProps = {
  errors: null,
}

export default FormThemeView
