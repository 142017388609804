import { useEffect, useState } from 'react'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import PropTypes from 'prop-types'
import { ListContainer, ListRow, SpinnerContainer, ListHeader, ListItem } from '../members/newListStyle'
import { BoxItem, BoxItemTitle, BoxRow } from '../invoices/scrollListStyles'

function SingleResourcesList({ resourcesData }) {
  useEffect(() => {}, [resourcesData])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const theRes = resourcesData !== undefined ? resourcesData : []

  return (
    <ListContainer>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer style={{ width: '100%' }}>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListItem>Resource</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Type</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Price(Day)</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Price(Hour)</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Status</ListItem>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {theRes.length > 0 ? (
            theRes.map(r => (
              <ListRow>
                <BoxRow>
                  <BoxItem>
                    <ListItem>{r.name}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{formatWords(r.resource_type)}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{r.price_day}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{r.price_hour}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{formatWords(r.status)}</ListItem>
                  </BoxItem>
                </BoxRow>
              </ListRow>
            ))
          ) : (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          )}
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

SingleResourcesList.propTypes = {
  resourcesData: PropTypes.object,
}

SingleResourcesList.defaultProps = {
  resourcesData: {},
}

export default SingleResourcesList
