import styled from 'styled-components'
import Text from 'components/atoms/text'

export const Container = styled.form``

export const Title = styled(Text)`
  && {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
`
export const ModalTitle = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
`
export const ModalText = styled(Text)`
  font-size: 24px;
  line-height: 32px;
  padding: 25px;
`

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const Col2Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const Col4Section = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  grid-template-areas: 'team';
  margin-bottom: 40px;
  margin-top: 20px;
`

export const ModalConfirmCreate = styled.div`
  margin: 200px;
  padding: 50px;
  height: 200px;
  background-color: white;
  border: 1px solid grey;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'lato';
  font-size: 25px;
  font-weight: 500;
`
