import React, { useEffect, useState } from 'react'
import { CircularProgress, Grid, Modal, Button } from '@material-ui/core'
import { getMembershipSingle } from 'api'
import { Link, useParams } from 'react-router-dom'
import ModalUpdateMS from 'components/organisms/members/modalUpdateMS'
import {
  ProfileContainer,
  ProfileDataContent,
  ProfileFirstContent,
  ProfileInput,
  ProfileTitle,
  ProfileHeaderContent,
} from '../members/profileStyle'
import SingleBookingList from '../bookings/singleBookingsList'
import { SpinnerContainer } from '../members/newListStyle'

function MembershipProfile() {
  const [membershipData, setMembershipData] = useState()
  const params = useParams()
  const { slug } = params
  const [modalMS, setMSModal] = useState(false)

  function getMembershipData() {
    getMembershipSingle(slug)
      .then(response => {
        setMembershipData(response)
      })
      .catch(e => console.log(e))
  }

  function handleMSClose() {
    console.log('Membership data ordered to close')
    getMembershipData()
  }

  useEffect(() => {
    setMSModal(false)
    console.log('There was a change in the membership ', membershipData)
  }, [membershipData])

  useEffect(() => {
    getMembershipData()
  }, [])

  if (membershipData !== undefined) {
    const createDate = membershipData?.create_date?.split('T')
    const startDate = membershipData?.start?.split('T')
    const endDate = membershipData?.end?.split('T')
    const renewalDate = membershipData?.renewal_end?.split('T')
    let days = '-'
    if (membershipData?.bookings.length > 0) {
      const pendingDays = membershipData?.bookings.filter(b => b.status === 'pre_paid').length
      const totaDays = membershipData?.bookings.length
      days = `${pendingDays} out of ${totaDays}`
    }
    console.log(membershipData)

    return (
      <ProfileContainer>
        <Modal open={modalMS === true}>
          <ModalUpdateMS toClose={handleMSClose} membershipData={membershipData} />
        </Modal>
        <ProfileHeaderContent>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item>
              <ProfileTitle>{membershipData.plan_membership.name}</ProfileTitle>
            </Grid>
            <Grid item>
              <Grid container spacing={2} direction='row'>
                <Grid item>
                  <Button variant='contained' color='secondary' onClick={() => setMSModal(true)}>
                    Update Membership
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ProfileHeaderContent>
        <ProfileFirstContent>
          <ProfileDataContent>
            <ProfileInput>Name: {membershipData?.plan_membership?.name}</ProfileInput>
            <ProfileInput>Status: {membershipData?.status}</ProfileInput>
            <ProfileInput>Price (VAT in): {membershipData?.base_price}</ProfileInput>
            <ProfileInput>Recurrent: {membershipData?.recurrent === true ? 'Yes' : 'No'}</ProfileInput>
            <ProfileInput>Days: {days}</ProfileInput>
          </ProfileDataContent>
          <ProfileDataContent>
            <ProfileInput>Created Date: {createDate ? createDate[0] : '-'}</ProfileInput>
            <ProfileInput>Start Date: {startDate ? startDate[0] : '-'}</ProfileInput>
            <ProfileInput>End Date: {endDate ? endDate[0] : '-'}</ProfileInput>
            <ProfileInput>Renewal until: {renewalDate ? renewalDate[0] : '-'}</ProfileInput>
            <Link to={`/members/companies/${membershipData?.team?.slug}`} style={{ textDecoration: 'none' }}>
              <ProfileInput>Team: {membershipData?.team ? membershipData?.team.name : ''}</ProfileInput>
            </Link>
          </ProfileDataContent>
          <ProfileDataContent>
            <ProfileInput>
              Member:{' '}
              {membershipData?.member
                ? `${membershipData?.member?.user?.first_name} ${membershipData?.member?.user?.last_name}`
                : '-'}
            </ProfileInput>
            <ProfileInput>Office: {membershipData?.office !== null ? membershipData?.office?.name : '-'}</ProfileInput>
            <ProfileInput>
              Work Area: {membershipData?.work_area !== null ? membershipData?.work_area.name : '-'}
            </ProfileInput>
            <ProfileInput>
              Resource: {membershipData?.resource !== null ? membershipData?.resource.name : '-'}
            </ProfileInput>
          </ProfileDataContent>
        </ProfileFirstContent>
        {membershipData.bookings.length > 0 ? (
          <SingleBookingList
            bookings={membershipData?.bookings}
            limit={0}
            showViewAll={membershipData?.bookings.length > 5}
            type='noHead'
          />
        ) : (
          ''
        )}
      </ProfileContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default MembershipProfile
