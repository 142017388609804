import React from 'react'
import PropTypes from 'prop-types'
import SearchIcon from '@material-ui/icons/Search'
import { SearchContainer, SearchContainerContent, SearchInputStyled } from './styled'

const SearchInput = ({ placeholder, onChange }) => (
  <SearchContainer>
    <SearchContainerContent>
      <SearchIcon style={{ color: '#A3A4A8', fontSize: '20px' }} />
      <SearchInputStyled placeholder={placeholder} onChange={onChange} disableUnderline fullWidth />
    </SearchContainerContent>
  </SearchContainer>
)

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
}

SearchInput.defaultProps = {
  placeholder: 'Search',
  onChange: null,
}

export default SearchInput
