import { useEffect, useState } from 'react'
import { getOffices, updateDashboardMSPlans } from 'api'
import { Grid, Button, FormControl, TextField, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard } from '../bookings/modalStyles'
import { SpinnerContainer } from '../members/newListStyle'

function ModalUpdateMSPlan(props) {
  const { toClose, mp } = props
  const [MSPlan, setMSPlan] = useState(mp)
  const [officeData, setOfficeData] = useState()
  const [errors, setErrors] = useState({
    name: null,
    price: null,
  })

  function updateMSPlan() {
    const theMSPlan = {
      ...MSPlan,
    }
    updateDashboardMSPlans(mp.slug, theMSPlan)
      .then(r => console.log(r))
      .catch(e => console.log(e))
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      name: 'Name is required',
      price: 'Price is required',
    }
    const nameRes = MSPlan.name === '' || MSPlan.name === undefined ? textErrors.name : null
    const priceRes = MSPlan.base_price === '' || MSPlan.base_price === undefined ? textErrors.price : null

    setErrors({
      name: nameRes,
      price: priceRes,
    })
    if (nameRes === null && priceRes === null) canUpdate = true
    if (canUpdate === true) updateMSPlan()
  }

  function getOfficeData() {
    getOffices().then(r => setOfficeData(r))
  }

  useEffect(() => {
    if (officeData === undefined) getOfficeData()
  }, [officeData])

  useEffect(() => {}, [MSPlan])

  return (
    <ModalCard>
      {MSPlan !== undefined ? (
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Grid container justify='space-between'>
              <Grid item>
                <h3>Update MS Plan</h3>
              </Grid>
              <Grid item>
                <Button variant='outlined' onClick={() => toClose()}>
                  X
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Name'
                value={MSPlan.name}
                onChange={e => setMSPlan({ ...MSPlan, name: e.target.value })}
                error={errors.name !== null}
              />
              {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Subtitle'
                value={MSPlan.subtitle}
                onChange={e => setMSPlan({ ...MSPlan, subtitle: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Description'
                value={MSPlan.description}
                multiline
                rows={3}
                rowsMax={3}
                onChange={e => setMSPlan({ ...MSPlan, description: e.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ width: '100%' }}>
              <TextField
                variant='outlined'
                label='Price'
                type='number'
                onScroll={e => e.preventDefault()}
                value={MSPlan.base_price}
                onChange={e =>
                  e.target.value >= 0
                    ? setMSPlan({ ...MSPlan, base_price: e.target.value })
                    : setMSPlan({ ...MSPlan, base_price: 0 })
                }
                error={errors.price !== null}
              />
              {errors.price !== null ? <SmallError>{errors.price}</SmallError> : ''}
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container justify='center'>
              <Button variant='contained' color='secondary' onClick={() => validate()}>
                Update MS Plan
              </Button>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </ModalCard>
  )
}

ModalUpdateMSPlan.propTypes = {
  toClose: PropTypes.func,
  mp: PropTypes.object,
}

ModalUpdateMSPlan.defaultProps = {
  toClose: () => {},
  mp: {},
}

export default ModalUpdateMSPlan
