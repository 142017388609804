import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { newGetMembers, newGetTeams, setNewGuest, updateGuest, getMembersByTeam } from 'api'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import React, { useEffect, useRef, useState } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useSnackbar } from 'notistack'
// import { useHistory } from 'react-router-dom'
import {
  CloseIcon,
  Col2,
  Col,
  ModalCard,
  ModalHeader,
  ModalItem,
  ModalTitle,
  SubmitBtn,
  // DeleteButton,
} from '../bookings/modalStyles'
import { findObjectById, validateEmail, findObjectByPK } from '../../../utils/utils'

function ModalGuest(props) {
  const { guest, toClose, office } = props
  const { enqueueSnackbar } = useSnackbar()
  // const history = useHistory()
  const [guestData, setGuestData] = useState({
    id: guest?.id,
    name: guest?.name,
    phone: guest?.phone,
    email: guest?.email,
    host: null,
    status: guest?.status,
    origin: guest?.origin,
    team: null,
    reference: guest?.reference,
  })

  const [members, setMembers] = useState()
  const [teams, setTeams] = useState()
  const validated = useRef(false)

  const [errors, setErrors] = useState({
    name: null,
    email: null,
  })

  function validation(data) {
    const textErrors = {
      name: 'Name is required',
      email: 'Email is invalid',
    }

    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    const emailRes =
      (data?.email !== undefined || data?.email !== '') && !validateEmail(data?.email) ? textErrors.email : null

    setErrors({
      name: nameRes,
      email: emailRes,
    })

    validated.current = nameRes === null
  }

  function getDataMembers(slug) {
    getMembersByTeam(slug).then(response => {
      setMembers(response.length >= 0 ? response : ['No members'])
      if (guest) {
        const host = findObjectByPK(guest.host, response)
        setGuestData({ ...guestData, host })
      }
    })
  }

  function getTeamsData(data) {
    const query = {
      pageSize: 'all',
      currentPage: '1',
      filter: {
        layoutOffice: office?.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query)
      .then(response => {
        const teamOptions = []
        // eslint-disable-next-line no-restricted-syntax
        for (const team of response.teams) {
          teamOptions.push({
            name: team.name,
            slug: team.slug,
          })
        }

        if (guest) {
          const team = findObjectById(guest.team, response.teams)
          setGuestData({ ...guestData, team })
          getDataMembers(team?.slug)
        }
        setTeams(teamOptions)
      })
      .catch(error => console.log(error))
  }

  useEffect(() => {
    getTeamsData()
  }, [])

  useEffect(() => {
    console.log(members)
  }, [members])

  function submitGuest() {
    if (validated.current) {
      if (guest === null) {
        setNewGuest({ ...guestData, team: guestData?.team?.slug, host: guestData?.host?.slug })
          .then(() => {
            toClose()
            window.location.reload()
          })
          .catch(() => {
            enqueueSnackbar('Email duplicated', {
              variant: 'error',
            })
          })
      } else {
        updateGuest(
          {
            ...guestData,
            team: guestData?.team?.slug,
            host: guestData?.host?.slug,
          },
          guest.slug
        ).then(() => {
          toClose()
          window.location.reload()
        })
      }
    }
  }

  // function deleteGuest() {
  //     removeGuest(guest.slug).then(() => {
  //       toClose()
  //       history.goBack()
  //     })
  // }

  return (
    <ModalCard>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{guest ? 'Update Guest' : 'Add Guest'}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <Col>
        <ModalItem>
          <TextField
            label='Name'
            variant='outlined'
            id='invoice-line-name-id'
            name='name'
            value={guestData?.name}
            onChange={value => setGuestData({ ...guestData, name: value.target.value })}
            error={errors.name !== null}
          />

          {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
        </ModalItem>
      </Col>
      <Col2>
        <ModalItem>
          <FormControl variant='outlined'>
            {teams && (
              <Autocomplete
                disabled={!teams}
                name='team'
                defaultValue={guestData?.team}
                options={teams}
                getOptionLabel={option => `${option.name}`}
                onInputChange={(e, data) => getTeamsData(data)}
                renderInput={p => <TextField {...p} label='Team' variant='outlined' />}
                onChange={(e, data) => {
                  setGuestData({ ...guestData, team: data })
                  getDataMembers(data?.slug)
                }}
                {...props}
              />
            )}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <FormControl>
            {guest && (
              <Autocomplete
                //disabled={!members}
                name='host'
                defaultValue={guestData?.host}
                options={members}
                getOptionLabel={option => `${option?.user?.first_name} ${option?.user?.last_name}`}
                renderInput={p => <TextField {...p} label='Host' variant='outlined' />}
                onChange={(e, data) => {
                  setGuestData({ ...guestData, host: data })
                }}
                {...props}
              />
            )}
          </FormControl>
        </ModalItem>
        <ModalItem>
          <TextField
            label='Email'
            variant='outlined'
            id='member-email-input-id'
            name='email'
            type='email'
            disabled={!!guest}
            value={guestData?.email}
            onChange={event => setGuestData({ ...guestData, email: event.target.value })}
            error={errors.email !== null}
          />
          {errors.email === null ? '' : <SmallError>{errors.email}</SmallError>}
        </ModalItem>
        <ModalItem>
          <TextField
            label='Phone'
            variant='outlined'
            id='member-phone-input-id'
            name='phone'
            value={guestData?.phone}
            onChange={value => setGuestData({ ...guestData, phone: value.target.value })}
          />
        </ModalItem>
        <ModalItem>
          <FormControl variant='outlined'>
            <InputLabel id='filter-label-status'>Status</InputLabel>
            <Select
              label='Status'
              value={guestData?.status}
              defaultValue='active'
              onChange={value => {
                setGuestData({ ...guestData, status: value.target.value })
              }}
            >
              <MenuItem value='active'>Active</MenuItem>
              <MenuItem value='inactive'>Inactive</MenuItem>
              <MenuItem value='former'>Former</MenuItem>
            </Select>
          </FormControl>
        </ModalItem>
        <ModalItem>
          <TextField
            label='Reference'
            variant='outlined'
            id='member-reference-input-id'
            name='reference'
            value={guestData?.reference}
            onChange={value => setGuestData({ ...guestData, reference: value.target.value })}
          />
        </ModalItem>
      </Col2>
      <SubmitBtn
        onClick={() => {
          validation(guestData)
          submitGuest()
        }}
      >
        {guest ? 'Update Guest' : 'Add Guest'}
      </SubmitBtn>
      {/*{guest && <DeleteButton onClick={deleteGuest}>Delete Guest</DeleteButton>}*/}
    </ModalCard>
  )
}

ModalGuest.propTypes = {
  toClose: PropTypes.func,
  guest: PropTypes.object,
  office: PropTypes.object,
}

ModalGuest.defaultProps = {
  toClose: () => {},
  guest: null,
  office: null,
}

export default ModalGuest
