import { CircularProgress, Grid } from '@material-ui/core'
import { ModalCard, ModalHeader, Text } from 'components/organisms/bookings/modalStyles'
import PropTypes from 'prop-types'

function FeedbackModal({ type }) {
  let title = ''
  let text = ''
  switch (type) {
    case 'resource':
      title = 'Resource created!'
      text = 'You will return to resource details in few moments'
      break
    case 'resource-update':
      title = 'Resource updated!'
      text = 'You will return to resource details in few moments'
      break
    case 'booking':
      title = 'Creating booking'
      text = 'You will return to the bookings list in few moments'
      break
    case 'booking-update':
      title = 'Booking updated!'
      text = 'You will return to bookings list in few moments'
      break
    case 'booking-update-single':
      title = 'Booking updated!'
      text = 'You will return to bookings details in few moments'
      break
    case 'member':
      title = 'Member created!'
      text = 'You will return to members list in few moments'
      break
    case 'ms-create-single':
      title = 'Membership created!'
      text = 'You will return to the member single page in few moments'
      break
    case 'wa-created':
      title = 'Work Area created!'
      text = 'You will return to the work areas list in few moments'
      break
    case 'wa-update':
      title = 'Work Area updated!'
      text = 'You will return to the work areas list in few moments'
      break
    case 'staff-create':
      title = 'Staff Member created!'
      text = 'You will return to the staff list in few moments'
      break
    case 'staff-update':
      title = 'Staff Member updated!'
      text = 'You will return to the staff list in few moments'
      break
    case 'office-create':
      title = 'Office Create!'
      text = 'You will return to the offices list in few moments'
      break
    case 'planMS-create':
      title = 'Plan Membership Create!'
      text = 'You will return to the memberships list in few moments'
      break
    default:
      title = 'No title options'
      text = 'Something wrong happens'
  }
  return (
    <ModalCard>
      <Grid container direction='column' alignItems='center' spacing={3}>
        <Grid item>
          <ModalHeader style={{ fontSize: '25px' }}>{title}</ModalHeader>
        </Grid>
        <Grid item>
          <Text>{text}</Text>
        </Grid>
        <Grid item>
          <CircularProgress color='secondary' />
        </Grid>
      </Grid>
    </ModalCard>
  )
}

FeedbackModal.propTypes = {
  type: PropTypes.string,
}

FeedbackModal.defaultProps = {
  type: '',
}

export default FeedbackModal
