import React, { useEffect } from 'react'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import { yupResolver } from '@hookform/resolvers/yup'
import { SET_THEME } from 'api/mutations'
import { useForm } from 'react-hook-form'
import { useTheme } from '@material-ui/core/styles'
//import { useHistory } from 'react-router-dom'
import validate from './validate'

import View from './view'

const ThemeForm = ({ office, ...props }) => {
  const theme = useTheme()
  const queryClient = useQueryClient()
  const data = queryClient.getQueryData('theme')
  const firstTheme = data ? data[0] : {}
  const {
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(validate),
    defaultValues: {
      navigation_color: theme.palette.primary.main,
      button_color: theme.palette.secondary.main,
    },
  })

  useEffect(() => {
    if (firstTheme) {
      Object.keys(firstTheme).forEach(item => setValue(item, firstTheme[item]))
    }
  }, [firstTheme])

  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  //const history = useHistory()
  const mutateTheme = useMutation(SET_THEME, {
    onSuccess: () => {
      //queryClient.invalidateQueries('theme')
      queryClient.invalidateQueries('theme', {
        exact: true,
        refetchInactive: true,
      })
      enqueueSnackbar(t('login.success'), {
        variant: 'success',
      })
    },
    onError: () => {
      enqueueSnackbar(t('login.error'), {
        variant: 'error',
      })
    },
  })

  const onSubmit = newData => {
    mutateTheme.mutate(newData)
  }

  return <View {...props} onSubmit={handleSubmit(onSubmit)} control={control} errors={errors} />
}

ThemeForm.propTypes = {
  office: PropTypes.object.isRequired,
}

export default ThemeForm
