import React, { useEffect, useState, useRef } from 'react'
import { getOffices } from 'api'
import HeaderToolbar from 'components/organisms/headerToolbar'
import PropTypes from 'prop-types'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import routes from 'config/routes'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import CreateBookingMeeting from 'components/organisms/bookings/CreateBookingMeeting'
import CreateBookingDesk from 'components/organisms/bookings/CreateBookingDesk'
import { CircularProgress, Button, Modal } from '@material-ui/core'
import ModalCreateDesk from 'components/organisms/bookings/modalCreateDesk'
import { DashBoardContainer, DashBoardBookingContainer } from '../space/dashboardStyles'
import BookingsList from './bookingsList'
import { ContainerEnd } from '../members/styled'

const RigthActions = ({ pathname, t }) => {
  const [bookingDeskModal, setBookingDeskModal] = useState(false)
  function handleBookingClose() {
    setBookingDeskModal(false)
  }
  switch (pathname) {
    case routes.marketing:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setBookingDeskModal(true)}>
            {t('booking.form.bookingBtn')}
          </Button>
          <Modal open={bookingDeskModal} onClose={handleBookingClose}>
            <ModalCreateDesk closeModal={handleBookingClose} />
          </Modal>
        </ContainerEnd>
      )
    default:
      return null
  }
}

const DownActions = (pathname, office, permited, activeClient, role, profile) => {
  console.log(role)
  useEffect(() => {}, [office, activeClient, role, profile])
  if (permited.includes('map') && pathname === routes.bookings) return <CreateBookingDesk office={office} activeClient={activeClient} role={role} profile={profile} />
  if (permited.includes('list') && pathname === routes.bookingsList) return <BookingsList office={office} activeClient={activeClient} role={role} profile={profile} />
  if (permited.includes('meeting_room') && pathname === routes.bookingsAddMeeting) return <CreateBookingMeeting office={office} activeClient={activeClient} role={role} profile={profile} />
  return ''
}

DownActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

DownActions.defaultProps = {
  pathname: '',
  t: '',
}

function CreateBookingDashboard(props) {
  const { office, activeClient, role, profile } = props
  let { pathname } = useLocation()
  const { t } = useTranslation()
  console.log('In the Create dashboard ', props)
  const headerComponent = useRef()
  const [containerHeight, setContainerHeight] = useState(800)
  //TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  function changeWindowSize() {
    console.log('New header height ', headerComponent.current, headerComponent.current?.clientHeight)
    if (headerComponent.current) setContainerHeight(headerComponent.current.clientHeight)
  }
  useEffect(() => {
    console.log('initial header height ', headerComponent.current, headerComponent.current?.clientHeight)
    if (headerComponent.current) setContainerHeight(headerComponent.current.clientHeight)
  }, [])

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)
    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  let theTabs = [
    permited.includes('map') ? { id: routes.bookings, to: routes.bookings, label: t('booking.addMap') } : '',
    permited.includes('meeting_room')
      ? { id: routes.bookingsAddMeeting, to: routes.bookingsAddMeeting, label: t('booking.addMeetingRoom') }
      : '',
    permited.includes('list') ? { id: routes.bookingsList, to: routes.bookingsList, label: t('booking.list') } : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  if (pathname === routes.bookings) {
    if (!permited.includes('map')) {
      if (permited.includes('meeting_room')) {
        pathname = routes.bookingsAddMeeting
      }
      if (permited.includes('list')) {
        pathname = routes.bookingsList
      }
    }
  }

  return (
    <DashBoardContainer>
      <HeaderToolbar
        ref={headerComponent}
        tabValue={pathname}
        tabs={theTabs}
        rightActions={<RigthActions pathname={pathname} t={t} />}
      />
      <DashBoardBookingContainer containerHeight={containerHeight}>
        {pathname === routes.bookings || pathname === routes.bookingsAddMeeting || pathname === routes.bookingsList ? (
          DownActions(pathname, office, permited, activeClient, role, profile)
        ) : (
          <SpinnerContainer>
            <CircularProgress color='secondary' />
          </SpinnerContainer>
        )}
      </DashBoardBookingContainer>
    </DashBoardContainer>
  )
}

CreateBookingDashboard.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
  role: PropTypes.string,
  profile: PropTypes.object,
}

CreateBookingDashboard.defaultProps = {
  office: {},
  activeClient: {},
  role: '',
  profile: {},
}
RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
}

export default CreateBookingDashboard
