import { Grid, CircularProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
// eslint-disable-next-line no-unused-vars
import { useTranslation } from 'react-i18next'
import { colors } from 'config/colors'
import React, { useState } from 'react'
import { SingleTitleSection } from 'components/molecules/singleTitleSection/singleTitleSection'
import { updatePayout, reconcilePayout } from 'api'
import {
  CloseIcon,
  ModalCardBroad,
  ModalHeader,
  ModalSubHeader,
  ModalTitle,
  ModalSubTitle,
  TitleText,
  TitleDark,
} from '../bookings/modalStyles'
import { ListHeader, ListTitle, ListRow, SmallButton } from '../members/newListStyle'
// eslint-disable-next-line import/named

function ModalPayoutDetail(props) {
  // const { t } = useTranslation()
  const { payout } = props
  const { toClose } = props
  const [loadingPayout, setLoadingPayout] = useState(false)

  const { t } = useTranslation()
  async function sendUpdatePayout() {
    setLoadingPayout(true)
    await updatePayout(payout?.slug, {})
    setLoadingPayout(false)
  }
  async function sendReconcilePayout() {
    setLoadingPayout(true)
    await reconcilePayout(payout?.slug, {})
    setLoadingPayout(false)
  }

  const pendingConfirmation = payout?.items.filter(item => item.status === 'pending_confirmation').length
  const smallStyle = {
    padding: 0,
    margin: 0,
  }

  const smallBtnUpdate = {
    padding: '5px',
    margin: '0px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${colors.orange}`,
    color: 'white',
  }

  return (
    <ModalCardBroad>
      <ModalHeader>
        <ModalTitle style={{ margin: '15px; ' }}>{t('payout.detailTitle')}</ModalTitle>
        <CloseIcon onClick={toClose} />
      </ModalHeader>
      <ModalSubHeader>
        <ModalSubTitle>ID: {payout?.id}</ModalSubTitle>
      </ModalSubHeader>
      <Grid
        container
        justify='space-between'
        spacing={2}
        style={{ borderBottom: '1px solid grey', marginBottom: '5px' }}
      >
        <Grid item md={12}>
          <TitleDark>Payout Info</TitleDark>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item direction='row'>
              <TitleText>Date:&nbsp;{payout?.date_created?.split('T')[0]}</TitleText>
              <TitleText>Lines:&nbsp;{parseInt(payout?.num_charges, 10) + parseInt(payout?.num_refunds, 10)}</TitleText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <TitleText>Charges:&nbsp;{payout?.num_charges}</TitleText>
              <TitleText>Refunds:&nbsp;{payout?.num_refunds}</TitleText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <TitleText style={{ color: payout?.status === 'validated' ? colors.green : colors.red }}>
                Result:&nbsp;{payout?.status}
              </TitleText>
              <TitleText style={{ color: payout?.status === 'validated' ? colors.green : colors.red }}>
                Pending:&nbsp;{pendingConfirmation}
              </TitleText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <TitleDark>Amounts</TitleDark>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item direction='row'>
              <TitleText>
                Total:&nbsp;
                {`£${(parseFloat(payout?.gross) - parseFloat(payout?.fee) + parseFloat(payout?.refunds)).toFixed(2)}`}
              </TitleText>
              <TitleText>Gross:&nbsp;{`£${parseFloat(payout?.gross).toFixed(2)}`}</TitleText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <TitleText>Fees:&nbsp;{`£${parseFloat(payout?.fee).toFixed(2)}`}</TitleText>
              <TitleText>Refunds:&nbsp;{`£${parseFloat(payout?.refunds).toFixed(2)}`}</TitleText>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={4}>
          <Grid container direction='column' spacing={2}>
            <Grid item>
              <TitleText>
                {loadingPayout ? (
                  <CircularProgress color='secondary' />
                ) : (
                  <SmallButton
                    style={smallBtnUpdate}
                    onClick={() => {
                      sendUpdatePayout()
                    }}
                  >
                    <small style={smallStyle}>Update payout</small>
                  </SmallButton>
                )}
              </TitleText>
              <TitleText>
                {loadingPayout ? (
                  <CircularProgress color='secondary' />
                ) : (
                  <SmallButton
                    style={smallBtnUpdate}
                    onClick={() => {
                      sendReconcilePayout()
                    }}
                  >
                    <small style={smallStyle}>Reconcile</small>
                  </SmallButton>
                )}
              </TitleText>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {payout?.items?.length > 0 && (
        <Grid>
          <SingleTitleSection>Items</SingleTitleSection>
          <ListHeader>
            <Grid container direction='row' justify='space-between'>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.date')}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.type')}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.status')}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.amount')}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.fee')}</ListTitle>
              </Grid>
              <Grid item xs={1}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.xero')}</ListTitle>
              </Grid>
              <Grid item xs={2}>
                <ListTitle style={{ fontWeight: '300' }}>{t('payout.reference')}</ListTitle>
              </Grid>
            </Grid>
          </ListHeader>
          {payout?.items?.map((row, i) => (
            <ListRow key={i + 1}>
              <Grid container direction='row' justify='space-between'>
                <Grid item xs={1}>
                  <ListTitle>{row?.date_created?.split('T')[0]}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.charge_type}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.status}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.amount / 100).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle>{`£${parseFloat(row?.fee / 100).toFixed(2)}`}</ListTitle>
                </Grid>
                <Grid item xs={1}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.sync_xero ? 'OK' : 'Error'}</ListTitle>
                </Grid>
                <Grid item xs={2}>
                  <ListTitle style={{ fontWeight: '300' }}>{row?.charge_id}</ListTitle>
                </Grid>
              </Grid>
            </ListRow>
          ))}
        </Grid>
      )}
    </ModalCardBroad>
  )
}

ModalPayoutDetail.propTypes = {
  toClose: PropTypes.func,
  payout: PropTypes.object,
}

ModalPayoutDetail.defaultProps = {
  toClose: () => {},
  payout: null,
}

export default ModalPayoutDetail
