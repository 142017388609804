import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Grid, Modal } from '@material-ui/core'
import {
  getProfileInvoice,
  getValidateInvoice,
  getReviewInvoice,
  getInvoiceSync,
  setInvoiceSyncPayments,
  setInvoiceDeletePayments,
  sendInvoice,
  putInvoice,
  getSyncInvoice,
} from 'api'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import ModalCreateInvoiceLine from 'components/organisms/invoices/modalCreateInvoiceLine'
import PropTypes from 'prop-types'
import { colors } from 'config/colors'
import formatWords from 'components/atoms/formatWords/formatWords'
import InvoicesLinesList from './invoicesLinesList'
import { ProfileContainer, ProfileInfoContent } from '../members/profileStyle'
import { AddressText, DateText, AlertText } from './invoicesStyles'
import { SmallButton, SpinnerContainer } from '../members/newListStyle'
import ModalUpdateInvoice from './modalUpdateInvoice'

function InvoiceProfile({ office }) {
  const params = useParams()
  const [theInvoice, setInvoice] = useState()
  const [externalInvoice, setExternalInvoice] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [selectedLine, setSelectedLine] = useState()
  const { slug } = params
  const { t } = useTranslation()
  const [modal, setModal] = useState(false)
  const [modalInvoice, setModalInvoice] = useState(false)
  const [loadingInvoice, setLoadingInvoice] = useState(false)
  const [invoiceBalance, setInvoiceBalance] = useState({
    paid: 0,
    due: 0,
    payments: 0,
    credited: 0,
  })

  function getDataInvoiceSync() {
    getInvoiceSync(slug)
      .then(data => {
        setExternalInvoice(data.invoice)
      })
      .catch(error => console.log(error))
  }

  function getDataInvoice() {
    getProfileInvoice(slug)
      .then(invoice => {
        setInvoice(invoice)
        setLoadingInvoice(false)
        getDataInvoiceSync()
        let amountPaid = 0
        let amountCredited = 0
        const payments = []
        invoice?.invoice_lines?.map(line => {
          if (line.status === 'paid') {
            amountPaid += parseFloat(line.line_price)
            if (!payments.includes(line.payment_reference)) {
              payments.push(line.payment_reference)
            }
          }
          console.log(line.line_price)
          if ((line.status === 'pending_payment' || line.status === 'credited') && line.line_price < 0) {
            amountCredited += parseFloat(line.line_price)
          }
          return amountPaid
        })
        const amountDue = invoice.net_price - amountPaid + amountCredited
        setInvoiceBalance({
          paid: amountPaid.toFixed(2),
          due: amountDue.toFixed(2),
          payments: payments.length,
          credited: amountCredited.toFixed(2),
        })
      })
      .catch(error => console.log(error))
  }

  function validateInvoice() {
    setLoadingInvoice(true)
    const invoice = {
      invoice: theInvoice.name,
    }
    getValidateInvoice(invoice).then(() => {
      getDataInvoice()
      //setLoadingInvoice(false)
    })
  }

  function reviewInvoice() {
    setLoadingInvoice(true)
    const invoice = {
      invoice: theInvoice.name,
    }
    getReviewInvoice(invoice).then(() => {
      getDataInvoice()
      //setLoadingInvoice(false)
    })
  }

  function voidInvoice() {
    setLoadingInvoice(true)
    const updatedInvoice = {
      status: 'void',
    }
    putInvoice(updatedInvoice, theInvoice.slug).then(() => {
      getDataInvoice()
    })
  }

  useEffect(() => {
    if (theInvoice === undefined) {
      getDataInvoice()
    }
  }, [theInvoice])

  useEffect(() => {
    getDataInvoiceSync()
  }, [])

  function handleClose() {
    setModal(false)
    getDataInvoice()
  }

  function handleCloseInvoice() {
    setModalInvoice(false)
    getDataInvoice()
  }

  function syncExternalPayments() {
    setInvoiceSyncPayments(slug)
      .then(() => {
        getDataInvoice()
      })
      .catch(error => console.log(error))
  }

  function deletePayments() {
    setInvoiceDeletePayments(slug)
      .then(() => {
        getDataInvoice()
      })
      .catch(error => console.log(error))
  }

  function sendExternalInvoice() {
    sendInvoice(slug)
      .then(() => {
        getDataInvoice()
      })
      .catch(error => console.log(error))
  }

  function syncInvoice() {
    const updatedInvoice = {
      slug,
    }
    getSyncInvoice(updatedInvoice)
      .then(() => {
        getDataInvoice()
      })
      .catch(error => console.log(error))
  }

  if (theInvoice !== undefined) {
    const invoiceDate = theInvoice?.date ? theInvoice?.date?.split('T') : ''
    const dateClosed = theInvoice?.date_closed ? theInvoice?.date_closed?.split('T') : ''
    const dateDue = theInvoice?.date_due ? theInvoice?.date_due?.split('T') : ''
    const dateSend = theInvoice?.date_sent ? theInvoice?.date_sent?.split('T') : ''
    let allowValidate = false
    let allowVoid = true
    let allowOpen = false
    let allowPending = false
    let allPaid = true
    if (theInvoice.status === 'open' || theInvoice.status === 'on_review') {
      allowPending = true
      allowOpen = true
    }
    if (theInvoice.status === 'pending_payment') {
      allowPending = true
      allowValidate = true
    }
    if (theInvoice.status === 'new') allowOpen = true

    theInvoice?.invoice_lines?.map(invoiceLine => {
      if (invoiceLine.status !== 'paid') allPaid = false
      if (invoiceLine.status !== 'void') allowVoid = false
      return true
    })

    if (allPaid) {
      allowPending = false
      allowValidate = true
    }

    let syncPayments = false
    if (externalInvoice?.payments < externalInvoice?.num_lines && theInvoice?.invoice_lines?.length > 0) {
      syncPayments = true
    }
    const smallStyle = {
      padding: 0,
      margin: 0,
    }

    const allowDelete = true

    const smallBtnUpdateSpace = {
      padding: '5px',
      margin: '0px 10px 0px 0px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: `${colors.orange}`,
      color: 'white',
    }

    const smallBtnDelete = {
      padding: '5px',
      margin: '0px',
      borderRadius: '5px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: `${colors.red}`,
      color: 'white',
    }

    return (
      <ProfileContainer>
        <Modal open={modal} onClose={handleClose}>
          <ModalCreateInvoiceLine office={office.slug} invoice={theInvoice} line={selectedLine} toClose={handleClose} />
        </Modal>
        <Modal open={modalInvoice} onClose={handleCloseInvoice}>
          <ModalUpdateInvoice
            office={office.slug}
            allowVoid={allowVoid}
            allowValidate={allowValidate}
            allowOpen={allowOpen}
            allowPending={allowPending}
            invoice={theInvoice}
            toClose={handleCloseInvoice}
          />
        </Modal>
        <Grid container direction='column' spacing={2} style={{ borderBottom: '1px solid grey', marginBottom: '25px' }}>
          <Grid item>
            <Grid
              container
              alignItems='center'
              justify='space-between'
              spacing={2}
              style={{ marginBottom: '10px', marginRight: '15px' }}
            >
              <Grid item>
                {loadingInvoice ? (
                  <CircularProgress color='secondary' />
                ) : (
                  <Grid container direction='column'>
                    <Grid
                      item
                      style={{
                        fontSize: '35px',
                        fontWeight: 500,
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                      }}
                    >
                      {theInvoice?.name}
                    </Grid>
                    <Grid item>
                      <AddressText>Status:</AddressText>&nbsp;
                      <DateText>{theInvoice?.status !== 'open' ? formatWords(theInvoice?.status) : 'Draft'}</DateText>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <Grid item>
                {theInvoice.status === 'open' || theInvoice.status === 'on_review' ? (
                  <Grid container>
                    <Button
                      color='secondary'
                      variant='contained'
                      style={{ margin: '10px ' }}
                      onClick={() => validateInvoice()}
                    >
                      Approve
                    </Button>
                    {allowVoid && (
                      <Button
                        color='success'
                        variant='contained'
                        style={{ margin: '10px ' }}
                        onClick={() => voidInvoice()}
                      >
                        {t('invoices.form.voidInvoice')}
                      </Button>
                    )}
                    <Button
                      color='success'
                      variant='contained'
                      style={{ margin: '10px ' }}
                      onClick={() => {
                        setSelectedLine(null)
                        setModal(true)
                      }}
                    >
                      {t('invoices.form.addLine')}
                    </Button>
                    <Button
                      color='success'
                      style={{ margin: '10px ' }}
                      variant='contained'
                      onClick={() => {
                        setModalInvoice(true)
                      }}
                    >
                      {t('invoices.form.editInvoice')}
                    </Button>
                    {theInvoice?.invoice_url && (
                      <Button
                        color='success'
                        style={{ margin: '10px ' }}
                        variant='contained'
                        onClick={() => {
                          window.open(theInvoice?.invoice_url)
                        }}
                      >
                        {t('invoices.form.viewInvoice')}
                      </Button>
                    )}
                  </Grid>
                ) : (
                  <Grid container>
                    <Button
                      color='success'
                      style={{ margin: '10px ' }}
                      variant='contained'
                      onClick={() => {
                        sendExternalInvoice()
                      }}
                    >
                      {t('invoices.form.sendInvoice')}
                    </Button>
                    {theInvoice?.invoice_url && (
                      <Button
                        color='success'
                        style={{ margin: '10px ' }}
                        variant='contained'
                        onClick={() => {
                          window.open(theInvoice?.invoice_url)
                        }}
                      >
                        {t('invoices.form.viewInvoice')}
                      </Button>
                    )}
                    {!theInvoice?.xero_id && (
                      <Button
                        color='success'
                        style={{ margin: '10px ' }}
                        variant='contained'
                        onClick={() => {
                          syncInvoice()
                        }}
                      >
                        {t('invoices.form.syncInvoice')}
                      </Button>
                    )}
                    {theInvoice.status !== 'paid' && (
                      <Button
                        color='success'
                        style={{ margin: '10px ' }}
                        variant='contained'
                        onClick={() => {
                          setModalInvoice(true)
                        }}
                      >
                        {t('invoices.form.editInvoice')}
                      </Button>
                    )}
                    <Button
                      color='secondary'
                      variant='contained'
                      style={{ margin: '10px ' }}
                      onClick={() => reviewInvoice()}
                    >
                      Review
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            justify='space-between'
            spacing={2}
            direction='row'
            style={{ borderBottom: '1px solid grey', marginBottom: '5px' }}
          >
            <Grid item md={4}>
              <Grid container direction='column' spacing={2}>
                <Grid item style={{ fontWeight: 500, color: colors.darkgray }}>
                  <Link to={`/members/companies/${theInvoice?.team?.slug}`} key={theInvoice?.team?.slug}>
                    {theInvoice?.team?.name}
                  </Link>
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {theInvoice?.invoice_data?.address_1 ? theInvoice?.invoice_data?.address_1 : '-'}
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {theInvoice?.invoice_data?.address_2 ? theInvoice?.invoice_data?.address_2 : '-'}
                </Grid>
                <Grid item style={{ fontWeight: 500, color: colors.gray }}>
                  {`${theInvoice?.invoice_data?.town ? theInvoice?.invoice_data?.town : '-'} (${
                    theInvoice?.invoice_data?.country ? theInvoice?.invoice_data?.country : '-'
                  })`}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Date:</AddressText>&nbsp;<DateText>{invoiceDate ? invoiceDate[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Due:</AddressText>&nbsp;<DateText>{dateDue ? dateDue[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Sent:</AddressText>&nbsp;<DateText>{dateSend ? dateSend[0] : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Closed:</AddressText>&nbsp;<DateText>{dateClosed ? dateClosed[0] : '-'}</DateText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={4}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Type:</AddressText>&nbsp;<DateText>{formatWords(theInvoice?.invoice_type)}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Office:</AddressText>&nbsp;<DateText>{formatWords(theInvoice?.office?.name)}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Payment Ref:</AddressText>&nbsp;
                  <DateText>{theInvoice.payment_reference !== null ? theInvoice.payment_reference : '-'}</DateText>
                </Grid>
                <Grid item>
                  <AddressText>Description:</AddressText>&nbsp;<DateText>{theInvoice?.short_description}</DateText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid
            container
            justify='space-between'
            spacing={2}
            direction='row'
            style={{ borderBottom: '1px solid grey', marginBottom: '5px' }}
          >
            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Total Invoice :</AddressText>&nbsp;
                  {externalInvoice && parseFloat(externalInvoice?.total) !== parseFloat(theInvoice.net_price) ? (
                    <AlertText>£{theInvoice.net_price}</AlertText>
                  ) : (
                    <DateText>£{theInvoice.net_price}</DateText>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Paid:</AddressText>&nbsp;<DateText>£{invoiceBalance?.paid}</DateText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Credited:</AddressText>&nbsp;<DateText>£{invoiceBalance?.credited}</DateText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={2}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Due:</AddressText>&nbsp;<DateText>£{invoiceBalance?.due}</DateText>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={3}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <AddressText>Num Payments :</AddressText>&nbsp;<DateText>{invoiceBalance?.payments}</DateText>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {externalInvoice && (
          <Grid item style={{ marginTop: 30 }}>
            <Grid
              container
              justify='space-between'
              spacing={2}
              direction='row'
              style={{ borderBottom: '1px solid grey', marginBottom: '5px' }}
            >
              <Grid item md={12}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <AddressText>Xero ID:</AddressText>&nbsp;<DateText>{theInvoice?.xero_id}</DateText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <AddressText>Status:</AddressText>&nbsp;<DateText>{externalInvoice?.status}</DateText>
                  </Grid>
                  <Grid item>
                    <AddressText>Sent Contact:</AddressText>&nbsp;
                    <DateText>{externalInvoice?.sent_to_contact ? t('general.YES') : t('general.NO')}</DateText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <AddressText>Num lines :</AddressText>&nbsp;<DateText>{externalInvoice?.num_lines}</DateText>
                  </Grid>
                  <Grid item>
                    <AddressText>Num payments:</AddressText>&nbsp;<DateText>{externalInvoice?.payments}</DateText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <AddressText>Amount Due :</AddressText>&nbsp;<DateText>£{externalInvoice?.amount_due}</DateText>
                  </Grid>
                  <Grid item>
                    <AddressText>Amount Paid:</AddressText>&nbsp;<DateText>£{externalInvoice?.amount_paid}</DateText>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Grid container direction='column' spacing={2}>
                  <Grid item>
                    <AddressText>Total Invoice :</AddressText>&nbsp;
                    {externalInvoice && parseFloat(externalInvoice?.total) !== parseFloat(theInvoice.net_price) ? (
                      <AlertText>£{externalInvoice?.total}</AlertText>
                    ) : (
                      <DateText>£{externalInvoice?.total}</DateText>
                    )}
                  </Grid>
                  <Grid item direction='row' style={{ display: 'flex' }}>
                    {syncPayments && externalInvoice?.payments >= 0 && (
                      <SmallButton
                        style={smallBtnUpdateSpace}
                        onClick={() => {
                          syncExternalPayments()
                        }}
                      >
                        <small style={smallStyle}>Sync</small>
                      </SmallButton>
                    )}
                    {allowDelete && (
                      <SmallButton
                        style={smallBtnDelete}
                        onClick={() => {
                          deletePayments()
                        }}
                      >
                        <small style={smallStyle}>Delete payments</small>
                      </SmallButton>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
        <ProfileInfoContent>
          <InvoicesLinesList
            editInvoice={line => {
              setSelectedLine(line)
              setModal(true)
            }}
            invoice={theInvoice}
            invoicesLines={theInvoice?.invoice_lines}
          />
        </ProfileInfoContent>
      </ProfileContainer>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

InvoiceProfile.propTypes = {
  office: PropTypes.object,
}

InvoiceProfile.defaultProps = {
  office: null,
}

export default InvoiceProfile
