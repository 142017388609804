import React, { useEffect, useState } from 'react'
import { getSingleCampaign, updateCampaign } from 'api'
import PropTypes from 'prop-types'
import { CircularProgress, MenuItem, Modal, Select, TextField } from '@material-ui/core'
import { useHistory, useParams } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import {
  ListHeader,
  ListRowNoStyle,
  ListSmallItem,
  ListSmallTitle,
  ListTitle,
  SpinnerContainer,
} from 'components/organisms/members/newListStyle'
import TransferList from 'components/molecules/transferList/transferList'
import { ProfileContainer } from '../members/profileStyle'
import { ModalConfirm, ModalText, ModalTitle } from './campaignStyles'
import { ModalButton } from '../bookings/modalStyles'

function CampaignSingle(props) {
  const history = useHistory()
  const { office } = props
  const [campaign, setCampaign] = useState()
  const params = useParams()
  const { slug } = params
  const [usersList, setUsers] = useState()
  const [modal, setModal] = useState(false)

  function getCampaignData() {
    getSingleCampaign(slug)
      .then(response => setCampaign(response))
      .catch(error => console.log(error))
  }

  function setNumberUsers(data) {
    console.log(data)
    const usersIdArr = []
    data.map(user => usersIdArr.push(user.id))
    console.log(usersIdArr)
    setUsers(usersIdArr)
  }

  function setCampaignUpdate() {
    const userIdArr = []
    campaign.users.map(user => userIdArr.push(user.id))
    const users = usersList === undefined || usersList.length <= 0 ? userIdArr : usersList
    console.log(users)
    const query = {
      campaignSlug: slug,
      userList: users,
      name: campaign.name,
      status: campaign.status,
      code: campaign.code,
      datePlanned: campaign.date_planned,
    }
    console.log(query)
    updateCampaign(query)
    setTimeout(() => history.push('/marketing'), 3000)
    setModal(true)
  }

  function checkFields() {
    if (campaign === undefined) getCampaignData()
  }

  function getTimeDate(data) {
    const dateSplit = data.toString()?.split(' ')
    const space = '-'
    const t = 'T'
    const time = '00:00:00+01:00'
    if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
    if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
    if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
    if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
    if (dateSplit[1] === 'May') dateSplit[1] = '05'
    if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
    if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
    if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
    if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
    if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
    if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
    if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
    const formattedDate = dateSplit[3] + space + dateSplit[1] + space + dateSplit[2] + t + time
    setCampaign({ ...campaign, date_planned: formattedDate })
    return formattedDate
  }

  useEffect(() => {
    checkFields()
    console.log(campaign)
  }, [campaign, modal])

  if (campaign !== undefined) {
    const dateCreated = campaign.date_created?.split('T')
    const datePlanned = campaign.date_planned?.split('T')
    return (
      <ProfileContainer>
        <ListHeader>
          <ListTitle>
            <strong>Name</strong>
          </ListTitle>
          <ListTitle>
            <strong>Code</strong>
          </ListTitle>
          <ListSmallTitle>
            <strong>Type</strong>
          </ListSmallTitle>
          <ListSmallTitle>
            <strong>Created</strong>
          </ListSmallTitle>
          <ListSmallTitle>
            <strong>Planned</strong>
          </ListSmallTitle>
          <ListSmallTitle>
            <strong>Status</strong>
          </ListSmallTitle>
          <ListSmallTitle>
            <strong>Nº users</strong>
          </ListSmallTitle>
        </ListHeader>
        <ListRowNoStyle
          style={{ height: '75px', dislay: 'flex', flexDireciont: 'row', alignItems: 'center', margin: 0 }}
        >
          <TextField
            value={campaign.name}
            variant='outlined'
            onChange={e => setCampaign({ ...campaign, name: e.target.value })}
          />
          <TextField
            value={campaign.code}
            variant='outlined'
            onChange={e => setCampaign({ ...campaign, code: e.target.value })}
          />
          <ListSmallItem>{campaign.type.name}</ListSmallItem>
          <ListSmallItem>{dateCreated[0]}</ListSmallItem>
          <DatePicker
            style={{ width: '150px' }}
            value={datePlanned[0]}
            onChange={e => getTimeDate(e)}
            variant='inline'
            inputVariant='outlined'
            helperText={null}
            format='dd-MM-yyyy'
          />
          <Select
            id='status-select'
            variant='outlined'
            value={campaign.status}
            onChange={value => setCampaign({ ...campaign, status: value.target.value })}
          >
            <MenuItem value='active'>Active</MenuItem>
            <MenuItem value='finished'>Finished</MenuItem>
            <MenuItem value='cancelled'>Cancelled</MenuItem>
            <MenuItem value='pending_payment'>Pending</MenuItem>
          </Select>
          <ListSmallItem>{campaign.users.length}</ListSmallItem>
        </ListRowNoStyle>
        <TransferList users={campaign.users} office={office.slug} setNumberUsers={setNumberUsers} />
        <ProfileContainer style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
          <ModalButton color='secondary' onClick={() => setCampaignUpdate()}>
            Save Changes
          </ModalButton>
        </ProfileContainer>
        <Modal open={modal}>
          <ModalConfirm>
            <ModalTitle>Campaign Update</ModalTitle>
            <ModalText>You will be redirect to Campaigns List...</ModalText>
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          </ModalConfirm>
        </Modal>
      </ProfileContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

CampaignSingle.propTypes = {
  office: PropTypes.object,
}

CampaignSingle.defaultProps = {
  office: null,
}

export default CampaignSingle
