import React from 'react'
import Context from 'context'
import Container from './container'

const Layout = ({ ...props }) => (
  <Context.Consumer>
    {({
      office,
      setOffice,
      sections,
      setSections,
      activeClient,
      setActiveClient,
      role,
      setRole,
      profile,
      setProfile,
    }) => (
      <Container
        {...props}
        office={office}
        setOffice={setOffice}
        sections={sections}
        setSections={setSections}
        activeClient={activeClient}
        setActiveClient={setActiveClient}
        role={role}
        setRole={setRole}
        profile={profile}
        setProfile={setProfile}
      />
    )}
  </Context.Consumer>
)

export default Layout
