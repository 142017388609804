import { useEffect, useState } from 'react'
import { createProductCategory } from 'api'
import PropTypes from 'prop-types'
import { Button, Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'
import { FormInput, SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard, ModalTitle } from '../bookings/modalStyles'

function ModalCreateCategory({ toClose }) {
  const [theCategory, setCategory] = useState({
    status: 'available',
  })
  const [errors, setErrors] = useState({
    name: null,
    state: null,
  })

  function createCategory() {
    createProductCategory(theCategory).then(window.location.reload())
  }

  function validate(data) {
    let canCreate = false
    const textErrors = {
      name: 'Name is required',
      status: 'Status is required',
    }

    const nameRes = data.name === undefined || data.name === '' ? textErrors.name : null
    const statusRes = data.status === undefined || data.status === '' ? textErrors.status : null

    setErrors({
      name: nameRes,
      status: statusRes,
    })

    if (nameRes === null && statusRes === null) canCreate = true

    if (canCreate === true) createCategory()
  }

  useEffect(() => {}, [theCategory])

  return (
    <ModalCard>
      <Grid container direction='column' spacing='2' justify='center'>
        <Grid item>
          <Grid container direciont='row' justify='space-between'>
            <Grid item>
              <ModalTitle>Add Category</ModalTitle>
            </Grid>
            <Grid item>
              <Button variant='contained' onClick={toClose}>
                X
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Name</InputLabel>
            <FormInput
              variant='outlined'
              label='name'
              id='category-name'
              name='name'
              onChange={e => setCategory({ ...theCategory, name: e.target.value })}
              error={errors.name !== null}
            />
            {errors.name === null ? '' : <SmallError>{errors.name}</SmallError>}
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Status</InputLabel>
            <Select
              label='status'
              variant='outlined'
              value={theCategory.status}
              onChange={e => setCategory({ ...theCategory, status: e.target.value })}
            >
              <MenuItem value='available'>Available</MenuItem>
              <MenuItem value='unavailable'>Unavailable</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: '100%' }}>
            <InputLabel style={{ marginLeft: '14px', marginTop: '-8px' }}>Description</InputLabel>
            <FormInput
              label='description'
              variant='outlined'
              value={theCategory?.description}
              onChange={e => setCategory({ ...theCategory, description: e.target.value })}
              multiline
              rows={4}
              rowsMax={6}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='center'>
            <Button variant='contained' color='secondary' onClick={() => validate(theCategory)}>
              Add Category
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalCreateCategory.propTypes = {
  toClose: PropTypes.func,
}

ModalCreateCategory.defaultProps = {
  toClose: () => {},
}
export default ModalCreateCategory
