/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import {
  ArrowGoTo,
  Container,
  Content,
  DeltaItem,
  FirstContent,
  Footer,
  GoToSection,
  GoToSectionLink,
  Header,
  MainContent,
  SecondContent,
  SecondDeltaItem,
  Title,
} from './styled'

function StatCartView({ data, className }) {
  const {
    delta_magnitude,
    first_delta,
    first_name,
    first_value,
    main_delta,
    main_name,
    main_value,
    name,
    second_delta,
    second_name,
    second_value,
    third_delta,
    third_name,
    third_value,
    fourth_delta,
    fourth_name,
    fourth_value,
    value_magnitude,
  } = data

  return (
    <Container className={className} elevation={0}>
      <Header>
        <Title>{name}</Title>
      </Header>
      <Content>
        {main_delta !== '' ? (
          <MainContent
            isProminent
            main={{
              magnitude: value_magnitude !== '' ? value_magnitude : '',
              value: main_value !== '' ? main_value : '',
            }}
            delta={{
              magnitude: delta_magnitude !== '' ? delta_magnitude : '',
              value: main_delta !== '' ? main_delta : '',
            }}
            name={main_name}
          />
        ) : (
          ''
        )}
        {first_delta !== '' ? (
          <FirstContent
            main={{
              magnitude: value_magnitude !== '' ? value_magnitude : '',
              value: first_value !== '' ? first_value : '',
            }}
            delta={{
              magnitude: delta_magnitude !== '' ? delta_magnitude : '',
              value: first_delta !== '' ? first_delta : '',
            }}
            name={first_name}
          />
        ) : (
          ''
        )}
        {second_delta !== '' ? (
          <DeltaItem
            main={{
              magnitude: value_magnitude !== '' ? value_magnitude : '',
              value: second_value !== '' ? second_value : '',
            }}
            delta={{
              magnitude: delta_magnitude !== '' ? delta_magnitude : '',
              value: second_delta !== '' ? second_delta : '',
            }}
            name={second_name}
          />
        ) : (
          ''
        )}
        {third_delta !== '' ? (
          <SecondContent
            main={{
              magnitude: value_magnitude !== '' ? value_magnitude : '',
              value: third_value !== '' ? third_value : '',
            }}
            delta={{
              magnitude: delta_magnitude !== '' ? delta_magnitude : '',
              value: third_delta !== '' ? third_delta : '',
            }}
            name={third_name}
          />
        ) : (
          ''
        )}
        {fourth_delta !== '' ? (
          <SecondDeltaItem
            main={{
              magnitude: value_magnitude !== '' ? value_magnitude : '',
              value: fourth_value !== '' ? fourth_value : '',
            }}
            delta={{
              magnitude: delta_magnitude !== '' ? delta_magnitude : '',
              value: fourth_delta !== '' ? fourth_delta : '',
            }}
            name={fourth_name}
          />
        ) : (
          ''
        )}
      </Content>
      <Footer>
        <GoToSectionLink to=''>
          <GoToSection>
            View payments <ArrowGoTo />
          </GoToSection>
        </GoToSectionLink>
      </Footer>
    </Container>
  )
}

StatCartView.propTypes = {
  data: PropTypes.object,
  className: PropTypes.string,
}

StatCartView.defaultProps = {
  data: {},
  className: '',
}

export default StatCartView
