import styled from 'styled-components'
import Tab from '@material-ui/core/Tab'
import { Link } from 'react-router-dom'

export const TabStyled = styled(Tab)`
  && {
    padding: 0;
    display: flex;
    .MuiTab-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
  }
`

export const LinkStyled = styled(Link)`
  && {
    text-decoration: none;
    line-height: 48px;
    display: inline-block;
    color: ${({ theme, active }) => (active ? theme.palette.common.black : theme.palette.grey[700])};
    font-weight: 700;
    text-transform: capitalize;
    text-align: left;
    letter-spacing: -0.6px;
  }
`
