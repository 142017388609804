import 'date-fns'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { Container } from '@material-ui/core'

function RangedDatePicker(props) {
  const { setDatesToFetch } = props
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())

  useEffect(() => {
    setDatesToFetch(startDate, endDate)
  }, [startDate, endDate])

  const datePickersContainerStyle = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 0,
    marginBottom: 10,
  }

  return (
    <Container style={datePickersContainerStyle}>
      <KeyboardDatePicker
        style={{ marginRight: '40px' }}
        format='dd-MM-yyyy'
        id='date-picker-inline'
        label='Start Date'
        value={startDate}
        onChange={value => {
          console.log(value)
          setStartDate(value)
        }}
      />
      <KeyboardDatePicker
        style={{ marginLeft: '40px' }}
        id='date-picker-dialog'
        label='End Date'
        format='dd-MM-yyyy'
        value={endDate}
        onChange={value => setEndDate(value)}
      />
    </Container>
  )
}

RangedDatePicker.propTypes = {
  setDatesToFetch: PropTypes.func,
}

RangedDatePicker.defaultProps = {
  setDatesToFetch: () => {},
}

export default RangedDatePicker
