import { CircularProgress, Grid } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { getProductCategories } from 'api'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import { useEffect, useState } from 'react'
import formatWords from 'components/atoms/formatWords/formatWords'
import {
  ListContainer,
  ListItem,
  ListHeader,
  ListRow,
  SpinnerContainer,
  PaginationContainer,
  PaginationNumbers,
} from '../members/newListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemLG, BoxItemTitle, BoxItemTitleLG, BoxRow } from '../invoices/scrollListStyles'

function FADCategories({ office }) {
  const [data, setData] = useState()
  const [paginate, setPaginate] = useState(true)
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 15,
    thePage: 1,
    pagesTotal: '',
  })
  const [theFilter, setFilter] = useState({
    name: '',
    order: '',
    status: '',
    layoutOffice: office.slug,
  })

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }
  function getData() {
    const query = {
      currentPage: 1,
      pageSize: 15,
      filter: {
        layoutOffice: office.slug,
      },
    }
    getProductCategories(query).then(r => {
      setData(r)
      getPagination(r)
      setPaginate(true)
    })
  }
  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getData(query)
    setPaginate(false)
  }
  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getData(query)
  }

  useEffect(() => {}, [pagination, paginate, theFilter])

  useEffect(() => {
    if (data === undefined) getData()
  }, [data])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: office.slug },
    }
    getData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [office])

  const slash = '-'
  const space = ' '

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <BoxRow style={{ width: '100%' }}>
          <SearchInput
            placeholder='Search by member or team...'
            onChange={value => setSearchFilter({ ...searchFilter, filter: value.target.value })}
          />
        </BoxRow>
      </BoxRow>
      <ScrollView direction='horizontal' style={{ marginTop: '15px' }} width={(windowSize.width * 82) / 100}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListItem>Id</ListItem>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListItem>Name</ListItem>
              </BoxItemTitle>
              <BoxItemTitleLG>
                <ListItem>Description</ListItem>
              </BoxItemTitleLG>
              <BoxItemTitle>
                <ListItem>Status</ListItem>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {data !== undefined ? (
            data.categories.map(c => (
              <ListRow>
                <BoxRow>
                  <BoxItem>
                    <ListItem>{c.id}</ListItem>
                  </BoxItem>
                  <BoxItem>
                    <ListItem>{c.name}</ListItem>
                  </BoxItem>
                  <BoxItemLG>
                    <ListItem>{c.description === '' ? 'Description of category' : c.description}</ListItem>
                  </BoxItemLG>
                  <BoxItem>
                    <ListItem>{formatWords(c.status)}</ListItem>
                  </BoxItem>
                </BoxRow>
              </ListRow>
            ))
          ) : (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          )}
        </ListContainer>
      </ScrollView>
      <Grid>
        {pagination.thePage === undefined || paginate === false ? (
          <PaginationContainer>
            <CircularProgress color='secondary' />
          </PaginationContainer>
        ) : (
          <PaginationContainer>
            <Pagination
              color='secondary'
              size='small'
              onClick={value => setPagePagination(value)}
              count={pagination.resultsTotal < pagination.resultsByPage ? 1 : pagination.pagesTotal}
              page={parseInt(pagination.thePage, 10)}
            />
            <PaginationNumbers>
              {pagination.resultsByPage * pagination.thePage -
                pagination.resultsByPage +
                slash +
                (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                  ? pagination.resultsByPage * pagination.thePage
                  : pagination.resultsTotal) +
                space}
              of {pagination.resultsTotal}
            </PaginationNumbers>
          </PaginationContainer>
        )}
      </Grid>
    </ListContainer>
  )
}

FADCategories.propTypes = {
  office: PropTypes.object,
}

FADCategories.defaultProps = {
  office: {},
}

export default FADCategories
