import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import SelectInputView from './view'

const SelectInput = ({ control, rules, name, label, ...props }) => (
  <Controller
    render={({ value, onChange, field }) => (
      <SelectInputView label={label} onChange={onChange} value={value} variant='outlined' {...props} {...field} />
    )}
    name={name}
    control={control}
    rules={rules}
    defaultValue={props.value ? props.value : ''}
  />
)

SelectInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
  options: PropTypes.array,
  props: PropTypes.any,
}

SelectInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  rules: {},
  options: [],
  props: {},
}

export default SelectInput
