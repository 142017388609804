import { useEffect, useState } from 'react'
import {
  getMemberships,
  updateMembership,
  getDashboardWorkAreas,
  getDashboardOffices,
  getResourcesForWorkArea,
  getMembersByTeam,
} from 'api'
import PropType from 'prop-types'
import { Button, FormControl, Grid, InputLabel, MenuItem, Modal, Select, TextField } from '@material-ui/core'
import { DatePicker } from '@material-ui/pickers'
import Autocomplete from '@material-ui/lab/Autocomplete'
import formatDates from 'components/atoms/formatDates/formatDates'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard } from '../bookings/modalStyles'

function ModalUpdateMS({ toClose, membershipData }) {
  console.log(membershipData)
  const [theMS, setMSData] = useState({
    member: membershipData?.member,
    team: membershipData?.team,
    base_price: membershipData?.base_price,
    status: membershipData?.status,
    create_date: new Date(membershipData?.create_date),
    start: new Date(membershipData?.start),
    end: new Date(membershipData?.end),
    renewal_end: new Date(membershipData?.renewal_end),
    plan: membershipData?.plan_membership,
    recurrent: membershipData?.recurrent,
    office: membershipData?.office,
    work_area: membershipData?.work_area,
    resource: membershipData?.resource,
  })
  const [officesData, setOffices] = useState([])
  const [membersData, setMembers] = useState([])
  const [waData, setWAData] = useState({ work_areas: [{ name: 'Loading...', resources: [] }] })
  const [resourcesData, setResourcesData] = useState([])

  const [membershipsData, setMembershipsData] = useState()

  function getMembershipsData() {
    getMemberships().then(r => setMembershipsData(r))
  }

  useEffect(() => {
    if (membershipsData === undefined) getMembershipsData()
  }, [membershipsData])

  const [errors, setErrors] = useState({
    member: null,
    team: null,
    plan: null,
  })

  const [feedback, setFeedback] = useState({
    show: false,
    info: 'create-ms',
  })

  function updateMS() {
    setFeedback({ ...feedback, show: true })
    const membership = {
      ...theMS,
      start: formatDates(theMS?.start, 'start'),
      end: formatDates(theMS?.end),
      renewal_end: formatDates(theMS?.renewal_end),
      status: theMS?.status,
      recurrent: theMS?.recurrent,
      team: theMS?.team?.slug,
      member: theMS?.member ? theMS?.member?.slug : '',
      office: theMS?.office ? theMS?.office?.slug : '',
      work_area: theMS?.work_area ? theMS?.work_area?.slug : '',
      resource: theMS?.resource ? theMS?.resource?.slug : '',
    }
    updateMembership(membership, membershipData.slug).then(
      setTimeout(() => {
        toClose()
      }, 200)
    )
  }

  function getMembersData(slug) {
    getMembersByTeam(slug).then(r => setMembers(r))
  }

  function getOffices() {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {},
    }
    getDashboardOffices(query).then(response => {
      setOffices(response.offices)
    })
  }

  function getWAData() {
    console.log('Searching WA ', theMS.office)
    if (theMS.office === null) return
    setWAData({ work_areas: [{ name: 'Loading...', resources: [] }] })
    const query = {
      currentPage: 1,
      pageSize: 1000,
      filter: {
        office: theMS.office.slug,
        area_type: 'open_space,suite',
      },
    }
    getDashboardWorkAreas(query).then(r => {
      if (theMS.work_area === null) {
        const was = r.work_areas.filter(wa => wa.office.slug === theMS.office)
        const firstWA = was[0]
        setMSData({ ...theMS, work_area: firstWA })
      }
      setWAData(r)
      console.log(r)
    })
  }
  function getDataResources() {
    if (theMS.work_area && theMS.work_area !== '') {
      console.log('Looking for resources ', theMS.work_area)
      getResourcesForWorkArea(theMS.work_area?.slug)
        .then(resources => setResourcesData(resources))
        .catch(error => console.log(error))
    }
  }

  function validate() {
    let canUpdate = false
    const textErrors = {
      date: 'Membership cannot end before starts',
    }
    const dateRes = new Date(theMS.start) > new Date(theMS.end) ? textErrors.date : null

    setErrors({
      date: dateRes,
    })

    if (dateRes === null) canUpdate = true

    if (canUpdate === true) updateMS()
  }

  useEffect(() => {
    getOffices()
    getMembersData(theMS.team?.slug)
  }, [])

  useEffect(() => {
    getWAData()
  }, [theMS.office])

  useEffect(() => {
    getDataResources()
  }, [theMS.work_area])

  useEffect(() => {}, [errors])

  return (
    <ModalCard>
      <Modal open={feedback.show === true}>
        <FeedbackModal type='ms-create-single' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between'>
            <Grid item>
              <h3>Update Membership</h3>
            </Grid>
            <Grid item>
              <Button onClick={toClose}>X</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  error={errors.team !== null}
                  value={theMS?.team?.name}
                  variant='outlined'
                  label='Team'
                  disabled
                />
                {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Member'
                  name='member'
                  value={theMS.member !== null ? theMS.member : { name: 'Loading...' }}
                  options={membersData}
                  getOptionLabel={option =>
                    option !== null && option !== undefined && option.user !== undefined
                      ? `${option.user?.first_name} ${option.user?.last_name}`
                      : ''
                  }
                  renderInput={params => <TextField {...params} label='Member' variant='outlined' />}
                  variant='outlined'
                  onChange={(e, data) => {
                    if (data !== null) {
                      setMSData({ ...theMS, member: data })
                    } else {
                      setMSData({ ...theMS, member: '' })
                    }
                  }}
                  //onInputChange={(e, data) => getMembersData(data)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField value={theMS?.plan ? `${theMS?.plan?.name}` : ''} variant='outlined' label='Plan' disabled />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Office'
                  name='office'
                  value={theMS.office !== null ? theMS.office : { name: 'Loading...', resources: [] }}
                  options={officesData}
                  getOptionLabel={option => (option !== null && option !== undefined ? `${option.name}` : '')}
                  renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
                  variant='outlined'
                  onChange={(e, data) => {
                    if (data !== null) {
                      setMSData({ ...theMS, office: data })
                    } else {
                      setMSData({ ...theMS, office: '' })
                    }
                  }}
                  //onInputChange={(e, data) => getMembersData(data)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Work Area'
                  name='work_area'
                  value={theMS.work_area !== null ? theMS.work_area : { name: 'Loading...', resources: [] }}
                  options={waData !== null ? waData?.work_areas : []}
                  getOptionLabel={option => (option !== null && option !== undefined ? `${option.name}` : '')}
                  renderInput={params => <TextField {...params} label='Work Area' variant='outlined' />}
                  disabled={!waData}
                  variant='outlined'
                  onChange={(e, data) => {
                    if (data !== null) {
                      setMSData({ ...theMS, work_area: data })
                    } else {
                      delete theMS.work_area
                      delete theMS.resource
                      getWAData()
                      setResourcesData([])
                    }
                  }}
                  //onInputChange={(e, data) => getMembersData(data)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <Autocomplete
                  label='Resource'
                  name='resource'
                  value={theMS.resource}
                  options={resourcesData}
                  getOptionLabel={option => (option !== null && option !== undefined ? `${option.name}` : '')}
                  renderInput={params => <TextField {...params} label='Resource' variant='outlined' />}
                  variant='outlined'
                  onChange={(e, data) => {
                    if (data !== null) {
                      setMSData({ ...theMS, resource: data })
                    } else {
                      setMSData({ ...theMS, resource: '' })
                    }
                  }}
                  //onInputChange={(e, data) => getMembersData(data)}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theMS.start}
                  label='Date Start'
                  format='yyyy-MM-dd'
                  autoOk
                  inputVariant='outlined'
                  onChange={value => setMSData({ ...theMS, start: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theMS.end}
                  label='Date End'
                  format='yyyy-MM-dd'
                  autoOk
                  inputVariant='outlined'
                  onChange={value => setMSData({ ...theMS, end: value })}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <DatePicker
                  value={theMS.renewal_end}
                  label='Renewal until'
                  format='yyyy-MM-dd'
                  autoOk
                  inputVariant='outlined'
                  onChange={value => setMSData({ ...theMS, renewal_end: value })}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-around' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  value={theMS.base_price}
                  variant='outlined'
                  label='Price (VAT inc)'
                  type='number'
                  min={0}
                  onChange={e => {
                    setMSData({
                      ...theMS,
                      base_price: e.target.value,
                    })
                  }}
                />
                {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ marginLeft: '15px', marginTop: '-7px' }}>Recurrent</InputLabel>
                <Select
                  label='Recurrent'
                  value={theMS.recurrent}
                  variant='outlined'
                  onChange={e => {
                    console.log(e.target.value)
                    setMSData({ ...theMS, recurrent: e.target.value })
                  }}
                >
                  <MenuItem value='true'>Yes</MenuItem>
                  <MenuItem value='false'>No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <InputLabel style={{ marginLeft: '15px', marginTop: '-7px' }}>Status</InputLabel>
                <Select
                  label='Status'
                  value={theMS.status}
                  variant='outlined'
                  onChange={e => setMSData({ ...theMS, status: e.target.value })}
                >
                  <MenuItem value='none'>None</MenuItem>
                  <MenuItem value='active'>Active</MenuItem>
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='cancelled'>Cancel</MenuItem>
                  <MenuItem value='finished'>Finish</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='center'>
            <Grid item style={{ marginTop: '20px' }}>
              <Button variant='contained' color='secondary' onClick={() => validate()}>
                Update Membership
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalUpdateMS.propTypes = {
  toClose: PropType.func,
  membershipData: PropType.object,
}

ModalUpdateMS.defaultProps = {
  toClose: () => {},
  membershipData: {},
}

export default ModalUpdateMS
