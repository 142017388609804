import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import { SpinnerContainer } from './styled'

const LayoutView = () => (
  <SpinnerContainer>
    <CircularProgress color='secondary' />
  </SpinnerContainer>
)

export default LayoutView
