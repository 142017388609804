import React from 'react'
import PropTypes from 'prop-types'
import ContentLoader from 'react-content-loader'
import { withTheme } from 'styled-components'
import { Container } from './styled'

const Placeholder = ({ theme }) => (
  <Container elevation={0}>
    <ContentLoader
      speed={2}
      viewBox='0 0 680 350'
      backgroundColor={theme.palette.common.white}
      foregroundColor={theme.palette.grey[200]}
    >
      <rect x='0' y='16' rx='3' ry='3' width='100%' height='30' />
      <rect x='0' y='70' rx='3' ry='3' width='100%' height='70' />
      <rect x='0' y='180' rx='3' ry='3' width='100%' height='50' />
      <rect x='0' y='300' rx='3' ry='3' width='100%' height='30' />
    </ContentLoader>
  </Container>
)

Placeholder.propTypes = {
  theme: PropTypes.object.isRequired,
}

export default withTheme(Placeholder)
