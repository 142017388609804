import React from 'react'
import map from 'lodash/map'
import PropTypes from 'prop-types'

import Tabs from '@material-ui/core/Tabs'
import { LinkStyled, TabStyled } from './styled'

const TabBarView = ({ tabs, value, onChange }) => (
  <Tabs value={value} onChange={onChange}>
    {map(tabs, ({ id, label, to }) => (
      <TabStyled
        key={id}
        value={id}
        label={
          to ? (
            <LinkStyled active={id === value} to={to}>
              {label}
            </LinkStyled>
          ) : (
            label
          )
        }
      />
    ))}
  </Tabs>
)

TabBarView.propTypes = {
  tabs: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
}

TabBarView.defaultProps = {
  tabs: [],
  value: null,
  onChange: () => null,
}

export default TabBarView
