import React, { useState, useEffect } from 'react'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import formatWords from 'components/atoms/formatWords/formatWords'
import { format } from 'date-fns'
import { ListContainer, ListRow, SpinnerContainer, ListHeader } from '../members/newListStyle'
import { BoxItem, BoxItemTitle, BoxRow } from './scrollListStyles'

function InvoicesLinesList(info) {
  const { invoicesLines, invoice, editInvoice } = info

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>Description</BoxItemTitle>
              <BoxItemTitle>Status</BoxItemTitle>
              <BoxItemTitle>Date</BoxItemTitle>
              <BoxItemTitle>Club</BoxItemTitle>
              <BoxItemTitle>Sync</BoxItemTitle>
              <BoxItemTitle>Public Price</BoxItemTitle>
              <BoxItemTitle>Units</BoxItemTitle>
              <BoxItemTitle>Unit Price</BoxItemTitle>
              <BoxItemTitle>Total</BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {info === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            invoicesLines.map(invoiceLine => {
              const theDate = format(new Date(invoiceLine?.create_date), 'dd-MM-yyyy, h:mma')
              let paymentReference = '-'
              if (invoiceLine.payment_reference) paymentReference = invoiceLine.payment_reference?.substr(0, 12)
              if (invoiceLine.credit_note !== null) paymentReference = 'Credit Note'
              return (
                <ListRow
                  onClick={() => {
                    editInvoice(invoiceLine)
                  }}
                >
                  <BoxRow>
                    <BoxItem>{invoiceLine.name}</BoxItem>
                    <BoxItem>
                      {formatWords(invoiceLine.status)} ({paymentReference})
                    </BoxItem>
                    <BoxItem>{theDate || '-'}</BoxItem>
                    <BoxItem>{invoiceLine.office ? invoiceLine.office?.name : '-'}</BoxItem>
                    <BoxItem>{invoiceLine.paid_xero ? 'YES' : 'NO'}</BoxItem>
                    <BoxItem>£{invoiceLine.unit_price}</BoxItem>
                    <BoxItem>{invoiceLine.units}</BoxItem>
                    <BoxItem>£{invoiceLine.unit_gross_price}</BoxItem>
                    <BoxItem>£{invoiceLine.line_gross_price}</BoxItem>
                  </BoxRow>
                </ListRow>
              )
            })
          )}
          <ListContainer>
            <ListRow>
              <BoxItem style={{ fontSize: '20px', color: 'black', fontWeight: 400 }}>Subtotal</BoxItem>
              <BoxItem style={{ textAlign: 'right', fontSize: '20px', color: 'black', fontWeight: 400 }}>
                £{invoice.gross_price}
              </BoxItem>
            </ListRow>
            <ListRow>
              <BoxItem style={{ fontSize: '20px', color: 'black', fontWeight: 400 }}>
                Total VAT({invoice.vat_type}%)
              </BoxItem>
              <BoxItem style={{ textAlign: 'right', fontSize: '20px', color: 'black', fontWeight: 400 }}>
                £{(invoice.net_price - invoice.gross_price).toFixed(2)}
              </BoxItem>
            </ListRow>
            <ListRow>
              <BoxItem style={{ fontSize: '20px', color: 'black', fontWeight: 400 }}>TOTAL</BoxItem>
              <BoxItem style={{ textAlign: 'right', fontSize: '20px', color: 'black', fontWeight: 400 }}>
                £{invoice.net_price}
              </BoxItem>
            </ListRow>
          </ListContainer>
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

export default InvoicesLinesList
