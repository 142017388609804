import { useEffect, useState } from 'react'
import {
  getMembersByTeam,
  newGetTeams,
  setBooking,
  getMeetingRoomAvailability,
  updateBooking,
  getClientsReferral,
  getResourcesMRForAll,
  getSingleBooking,
} from 'api'
import { colors } from 'config/colors'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { Grid, FormControl, TextField, Button, Checkbox, Modal, Select, MenuItem } from '@material-ui/core'
import { TimePicker } from '@material-ui/pickers'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { Autocomplete } from '@material-ui/lab'
import formatDates from 'components/atoms/formatDates/formatDates'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalSchedulerBooking({ closeModal, office, resourceData, reloadBookings, role, profile }) {
  console.log('Uploaded content', resourceData?.uploadContent)
  const defaultTeam = profile.role === 'team_admin' || profile.role === 'team_member' ? profile.team : null
  const [theBooking, setBookingData] = useState({
    code: resourceData?.uploadContent?.code === undefined ? null : resourceData?.uploadContent?.code,
    client: resourceData?.uploadContent?.client === undefined ? null : resourceData?.uploadContent?.client,
    team: resourceData?.uploadContent?.team === undefined ? defaultTeam : resourceData?.uploadContent?.team,
    member: resourceData?.uploadContent?.member === undefined ? null : resourceData?.uploadContent?.member,
    start: resourceData?.start === undefined ? null : resourceData?.start,
    end: resourceData?.end === undefined ? null : resourceData?.end,
    complimentary: 'NO',
    resource: resourceData.resource,
    use_credits: defaultTeam ? 'YES' : 'NO',
  })
  console.log(profile)
  const [errors, setErrors] = useState({
    start: null,
    end: null,
    client: null,
    team: null,
    member: null,
    bookedStart: null,
    bookedEnd: null,
    badTime: null,
  })
  const imMember = role === 'team_member' || role === 'team_admin'
  const [loadingBooking, setLoadingBooking] = useState(true)
  const [bookingLoaded, setBookingLoaded] = useState(false)
  const [fbModal, setfbModal] = useState(false)
  const [fbModalUpdate, setfbModalUpdate] = useState(false)
  const [confirmCancel, setCancelBtn] = useState(false)
  const [dataTeams, setTeamsData] = useState()
  const [dataClients, setClientData] = useState()
  const [dataMembers, setMembersData] = useState()
  const [dataRooms, setRoomsData] = useState()
  const [originalPrice, setOriginalPrice] = useState(null)
  const [visualPrices, setVisualPrices] = useState({
    vat_inc: 0,
    vat_exc: 0,
  })
  const [firstLoad, setFirstLoad] = useState(true)
  const { t } = useTranslation()

  function getMeetingRoomAvailabilityData(value, type) {
    const query = {
      resource: theBooking.resource ? theBooking.resource : resourceData.resource,
      team: theBooking?.team ? theBooking?.team?.slug : null,
      client: theBooking?.client ? theBooking?.client?.slug : null,
      code: theBooking.code,
      start:
        theBooking.start === undefined
          ? formatDates(resourceData.start, 'time')
          : formatDates(theBooking.start, 'time'),
      end: theBooking.end === undefined ? formatDates(resourceData.end, 'time') : formatDates(theBooking.end, 'time'),
    }
    if (type === 'start') query.start = formatDates(value, 'time')
    if (type === 'end') query.end = formatDates(value, 'time')
    let badTimeStatus = null
    if (new Date(query.start) >= new Date(query.end)) {
      badTimeStatus = 'End time cant be before start time'
      setErrors({ ...errors, badTime: badTimeStatus })
      setBookingLoaded(true)
    }
    if (new Date(query.start) < new Date(query.end)) {
      console.log('Cleaning bad Time')
      getMeetingRoomAvailability(query).then(r => {
        if (r.status === 'busy') {
          setErrors({
            ...errors,
            bookedStart: 'Meeting room busy at that time',
            bookedEnd: 'Meeting room busy at that time',
          })
        }
        if (r.status === 'free') setErrors({ ...errors, bookedStart: null, bookedEnd: null, badTime: badTimeStatus })
        console.log('refresh booking after availability ', r)
        setBookingData({
          ...theBooking,
          price: r.price.toFixed(2),
          price_with_credits: r.price_with_credits.toFixed(2),
          credits: r.credits.toFixed(2),
          available_credits: r.available_credits,
        })
        console.log('Price after return ', r?.price)
        setVisualPrices({
          vat_inc: r?.price,
          vat_exc: (r?.price / 1.2).toFixed(2),
        })
        setBookingLoaded(true)
      })
    }
  }

  function getDataClients(data) {
    const query = {
      pageSize: 100,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => setClientData(r))
  }

  function getDataTeams(data) {
    const query = {
      pageSize: 100,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => setTeamsData(r))
  }

  function getDataMembers(data) {
    if (data !== undefined) getMembersByTeam(data).then(r => setMembersData(r))
  }

  function getDataRooms(data) {
    getResourcesMRForAll(data).then(r => setRoomsData(r))
  }

  function createBooking() {
    setfbModal(true)
    console.log(theBooking)
    console.log('Use credits ', theBooking.use_credits)
    const bookingsArr = []
    const booking = {
      start: { dateTime: formatDates(theBooking.start, 'time') },
      end: { dateTime: formatDates(theBooking.end, 'time') },
      payNow: false,
      booking_type: 'meeting_room',
      member: theBooking?.member?.slug || '',
      team: theBooking?.team?.slug || '',
      resource: resourceData.resource,
      booking_reference: theBooking?.booking_reference,
      price: theBooking.use_credits === 'YES' ? theBooking.price_with_credits : theBooking.price,
      credits: theBooking.credits && theBooking.use_credits === 'YES' ? theBooking.credits.toString() : '0',
      use_credits: theBooking.use_credits,
      client: theBooking?.client?.slug,
    }
    if (theBooking.complimentary === 'YES') booking.deference = true
    if (theBooking.complimentary === 'NO') booking.deference = false
    if (theBooking.use_credits === 'YES') booking.use_credits = true
    if (theBooking.use_credits === 'NO') booking.use_credits = false
    bookingsArr.push(booking)
    console.log(bookingsArr)
    setBooking(bookingsArr)
      .then(() => {
        console.log('Booking success')
        setfbModalUpdate(false)
        reloadBookings()
      })
      .catch(() => {
        console.log('Error')
      })
  }

  function updateTheBooking(data) {
    const booking = {
      start: { dateTime: formatDates(theBooking.start, 'time') },
      end: { dateTime: formatDates(theBooking.end, 'time') },
      payNow: false,
      booking_type: 'meeting_room',
      member: theBooking?.member?.slug || '',
      team: theBooking?.team?.slug || '',
      resource: theBooking.resource ? theBooking.resource : resourceData.resource,
      price: theBooking.use_credits === 'NO' ? theBooking.price : theBooking.price_with_credits,
      credits: theBooking.credits && theBooking.use_credits === 'YES' ? theBooking.credits.toString() : '0',
      use_credits: '',
      client: theBooking.client?.slug,
      code: theBooking.code,
      booking_reference: theBooking?.booking_reference,
    }
    if (theBooking.complimentary === 'YES') booking.deference = true
    if (theBooking.complimentary === 'NO') booking.deference = false
    if (theBooking.use_credits === 'YES') booking.use_credits = true
    if (theBooking.use_credits === 'NO') booking.use_credits = false
    if (data === 'cancel') booking.status = 'cancelled'
    console.log(theBooking)
    updateBooking(resourceData.uploadContent.code, booking)
      .then(() => {
        console.log('Booking updated')
        setfbModal(false)
        reloadBookings()
      })
      .catch(() => {
        console.log('Error')
      })
  }

  function validate(data) {
    let canCreate = false
    const textErrors = {
      start: 'Start time is required',
      end: 'End time is required',
      room: 'Room is required',
      client: 'Client is required',
      team: 'Team is required',
      member: 'Member is required',
      badChoose: 'Broker or team is mandatory',
      badMail: 'Must be a valid email (example@mail.com)',
      price: 'Must have a positive value',
    }

    const startRes = data.start === null || data.start === undefined || data.start === '' ? textErrors.start : null
    const endRes = data.end === null || data.end === undefined || data.end === '' ? textErrors.end : null
    const memberRes = null
    const priceRes = data.price === null || data.price === '' || data.price < 0 ? textErrors.price : null

    let clientRes = data.client === null || data.client === undefined || data.client === '' ? 'empty' : null
    let teamRes = data.team === null || data.team === undefined || data.team === '' ? 'empty' : null

    if (clientRes === 'empty' && teamRes === 'empty') {
      teamRes = textErrors.badChoose
      clientRes = textErrors.badChoose
    } else {
      clientRes = null
      teamRes = null
    }

    if (theBooking.team !== null || theBooking.client !== null) {
      teamRes = null
      clientRes = null
    }

    setErrors({
      start: startRes,
      end: endRes,
      client: clientRes,
      team: teamRes,
      member: memberRes,
      bookedStart: errors.bookedStart,
      bookedEnd: errors.bookedEnd,
      badTime: errors.badTime,
      price: priceRes,
    })

    if (
      startRes === null &&
      endRes === null &&
      teamRes === null &&
      clientRes === null &&
      memberRes === null &&
      errors.bookedStart === null &&
      errors.bookedEnd === null &&
      errors.badTime === null &&
      priceRes === null
    ) canCreate = true

    if (canCreate === true) {
      if (resourceData.uploadContent.code !== undefined) updateTheBooking()
      else createBooking()
    }
  }

  useEffect(() => {
    console.log('refresh availability1 ', firstLoad)
    if (!loadingBooking) {
      if (firstLoad) {
        setFirstLoad(false)
      } else {
        getMeetingRoomAvailabilityData()
      }
    }
  }, [theBooking.resource, theBooking.team, theBooking.client, theBooking.end, theBooking.start])

  useEffect(() => {
    getDataClients()
    getDataTeams()
    if (defaultTeam) getMeetingRoomAvailabilityData()
  }, [])

  useEffect(() => {
    getDataRooms()
    if (resourceData?.uploadContent.code !== undefined) {
      getSingleBooking(resourceData?.uploadContent.code).then(r => {
        console.log('Updatend bookig ', r)
        setBookingData({
          ...theBooking,
          code: r?.code,
          client: r?.client,
          team: r?.team,
          member: r?.member,
          start: new Date(r?.planned_start),
          end: new Date(r?.planned_end),
          booking_reference: r?.booking_reference,
          complimentary: 'NO',
          resource: resourceData.resource,
          work_area: r?.work_area,
          price: r?.single_price ? r?.single_price : 0,
          price_with_credits: r?.single_price ? r?.single_price : 0,
          credits: r?.credits ? r?.credits : 0,
          use_credits: r?.credits && r?.credits > 0 ? 'YES' : 'NO',
        })
        setOriginalPrice({
          credits: r?.credits,
          price: r?.single_price,
        })
        console.log('Price after return ', r?.single_price)
        setVisualPrices({
          vat_inc: r?.single_price,
          vat_exc: (r?.single_price / 1.2).toFixed(2),
        })
        setLoadingBooking(false)
      })
      if (resourceData?.uploadContent.team) {
        getDataMembers(resourceData?.uploadContent.team.slug)
      }
    } else {
      setLoadingBooking(false)
      setFirstLoad(false)
    }
  }, [])

  useEffect(() => {
    if (theBooking.complimentary === 'YES') {
      setVisualPrices({ ...visualPrices, vat_exc: 0 })
      setBookingData({ ...theBooking, price: 0, price_with_credits: 0, use_credits: 'NO', origin: 'complimentary' })
    } else if (theBooking.client) {
      setBookingData({ ...theBooking, origin: 'referral' })
    } else {
      setBookingData({ ...theBooking, origin: 'regular' })
    }
  }, [theBooking.complimentary])

  useEffect(() => {
    console.log(
      'Inc changed ',
      parseFloat(visualPrices.vat_exc).toFixed(2),
      parseFloat(visualPrices.vat_inc / 1.2),
      parseFloat(visualPrices.vat_exc).toFixed(2) !== parseFloat(visualPrices.vat_inc / 1.2).toFixed(2)
    )
    if (parseFloat(visualPrices.vat_exc).toFixed(2) !== parseFloat(visualPrices.vat_inc / 1.2).toFixed(2)) {
      const newPrice = parseFloat(visualPrices.vat_inc)
      console.log(newPrice)
      //theBooking.use_credits === 'YES' ? theBooking.price_with_credits / 1.20 : theBooking.price / 1.20
      setVisualPrices({ ...visualPrices, vat_exc: (newPrice / 1.2).toFixed(2) })
      setBookingData({ ...theBooking, price: newPrice >= 0 ? newPrice.toFixed(2) : 0 })
    }
  }, [visualPrices.vat_inc])

  useEffect(() => {
    console.log(
      'Exc changed ',
      parseFloat(visualPrices.vat_exc).toFixed(2),
      parseFloat(visualPrices.vat_inc / 1.2),
      parseFloat(visualPrices.vat_exc).toFixed(2) !== parseFloat(visualPrices.vat_inc / 1.2).toFixed(2)
    )
    if (parseFloat(visualPrices.vat_exc).toFixed(2) !== parseFloat(visualPrices.vat_inc / 1.2).toFixed(2)) {
      const newPrice = parseFloat(visualPrices.vat_exc * 1.2)
      console.log(newPrice)
      //theBooking.use_credits === 'YES' ? theBooking.price_with_credits / 1.20 : theBooking.price / 1.20
      setVisualPrices({ ...visualPrices, vat_inc: newPrice.toFixed(2) })
      setBookingData({ ...theBooking, price: newPrice >= 0 ? newPrice.toFixed(2) : 0 })
    }
  }, [visualPrices.vat_exc])

  useEffect(() => {}, [errors])

  return (
    <ModalCard>
      <Modal open={fbModal}>
        <FeedbackModal type='booking' />
      </Modal>
      <Modal open={fbModalUpdate}>
        <FeedbackModal type='booking-update' />
      </Modal>
      {bookingLoaded}
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Grid container direction='row' justify='space-between' alignItems='center' style={{ marginBottom: '15px' }}>
            <Grid item>
              <ModalTitle>
                {resourceData.uploadContent?.member === undefined
                  ? t('booking.form.addBooking')
                  : t('booking.form.updateBooking')}
              </ModalTitle>
            </Grid>
            <Grid item>
              <Grid container direction='row' spacing={2}>
                <Grid item>
                  <Button variant='outlined' onClick={() => closeModal()}>
                    X
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <FormControl style={{ width: '100%' }}>
            <TextField
              value={theBooking?.booking_reference}
              label='Reference'
              variant='outlined'
              InputLabelProps={{ shrink: true }}
              onChange={e => setBookingData({ ...theBooking, booking_reference: e.target.value })}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2}>
            {role !== 'referral' && !imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    name='autocomplete-clients'
                    value={
                      resourceData.uploadContent?.client === undefined
                        ? theBooking.client
                        : resourceData.uploadContent?.client
                    }
                    options={dataClients !== undefined ? dataClients.clients : [{ name: 'Type something...' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label='Broker' variant='outlined' error={errors.client !== null} />
                    )}
                    onChange={(e, data) => {
                      if (e !== undefined && data !== undefined) {
                        getDataClients(e.target.value)
                        setBookingData({ ...theBooking, client: data })
                      } else {
                        getDataClients()
                        setBookingData({ ...theBooking, client: null })
                      }
                      return ''
                    }}
                    onInputChange={(e, data) => getDataClients(data)}
                    error={errors.client !== null}
                  />
                  {errors.client !== null ? <SmallError>{errors.client}</SmallError> : ''}
                </FormControl>
              </Grid>
            )}
            {!imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    name='autocomplete-teams'
                    value={theBooking.team}
                    options={dataTeams !== undefined ? dataTeams.teams : [{ name: 'No data team' }]}
                    getOptionLabel={option => option.name}
                    renderInput={params => (
                      <TextField {...params} label='Team' variant='outlined' error={errors.team !== null} />
                    )}
                    onChange={(e, data) => {
                      console.log('refresh input')
                      if (e !== undefined && data !== undefined) {
                        //getDataTeams(e.target.value)
                        getDataMembers(data?.slug)
                        setBookingData({ ...theBooking, team: data })
                      } else {
                        getDataTeams()
                        setBookingData({ ...theBooking, team: null })
                      }
                      return ''
                    }}
                    onInputChange={(e, data) => getDataTeams(data)}
                    error={errors.team !== null}
                    disabled={resourceData?.uploadContent?.code !== undefined}
                  />
                  {errors.team !== null ? <SmallError>{errors.team}</SmallError> : ''}
                </FormControl>
              </Grid>
            )}
            {!imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Autocomplete
                    disabled={theBooking.team === null}
                    value={
                      resourceData.uploadContent?.member === undefined
                        ? theBooking.member
                        : resourceData.uploadContent?.member
                    }
                    name='autocomplete-members'
                    error={errors.member !== null}
                    options={dataMembers !== undefined ? dataMembers : [{ name: 'No data Member' }]}
                    getOptionLabel={option =>
                      option?.user?.first_name !== undefined
                        ? `${option?.user?.first_name} ${option?.user?.last_name}`
                        : ''
                    }
                    renderInput={params => (
                      <TextField {...params} label='Member' variant='outlined' error={errors.member !== null} />
                    )}
                    onChange={(e, data) => {
                      if (e !== undefined && data !== undefined) {
                        setBookingData({ ...theBooking, member: data })
                      } else {
                        setBookingData({ ...theBooking, member: null })
                      }
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                  {errors.member !== null ? <SmallError>{errors.member}</SmallError> : ''}
                </FormControl>
              </Grid>
            )}
            {theBooking.code != null && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <Select
                    label='Room'
                    variant='outlined'
                    value={theBooking?.resource}
                    onChange={value => setBookingData({ ...theBooking, resource: value.target.value })}
                  >
                    {dataRooms?.map((o, i) => (
                      <MenuItem key={i} value={o.slug}>
                        {`${o.office.name} - ${o.name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' justify='space-between' spacing={2}>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  label='Start'
                  inputVariant='outlined'
                  minutesStep={5}
                  onChange={value => {
                    setBookingData({ ...theBooking, start: value })
                  }}
                  value={theBooking?.start === undefined ? resourceData.start : theBooking.start}
                  renderInput={params => <TextField {...params} variant='outlined' />}
                  error={errors.start !== null || errors.bookedStart !== null}
                />
                {errors.start !== null ? <SmallError>{errors.start}</SmallError> : ''}
                {errors.bookedStart !== null ? <SmallError>{errors.bookedStart}</SmallError> : ''}
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TimePicker
                  inputVariant='outlined'
                  label='End'
                  minutesStep={5}
                  renderInput={params => (
                    <TextField
                      {...params}
                      variant='outlined'
                      error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                    />
                  )}
                  onChange={value => {
                    setBookingData({ ...theBooking, end: value })
                  }}
                  value={theBooking?.end === undefined ? resourceData.end : theBooking.end}
                  error={errors.end !== null || errors.bookedEnd !== null || errors.badTime !== null}
                />
                {errors.end !== null ? <SmallError>{errors.end}</SmallError> : ''}
                {errors.bookedEnd !== null ? <SmallError>{errors.bookedEnd}</SmallError> : ''}
                {errors.badTime !== null ? <SmallError>{errors.badTime}</SmallError> : ''}
              </FormControl>
            </Grid>
            {!imMember && (
              <Grid item xs={4}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Price (VAT exc)'
                    readOnly={false}
                    defaultValue={visualPrices.vat_exc}
                    value={visualPrices.vat_exc}
                    type='number'
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    onChange={e => {
                      console.log(e.target.value)
                      setVisualPrices({ ...visualPrices, vat_exc: e.target.value > 0 ? e.target.value : 0 })
                    }}
                  >
                    Price (VAT exc)
                  </TextField>
                </FormControl>
              </Grid>
            )}
            <Grid item xs={4}>
              <FormControl style={{ width: '100%' }}>
                <TextField
                  label='Price (VAT inc)'
                  readOnly={imMember}
                  disabled={imMember}
                  defaultValue={visualPrices.vat_inc}
                  value={visualPrices.vat_inc}
                  type='number'
                  variant='outlined'
                  InputLabelProps={{ shrink: true }}
                  onChange={e => {
                    setVisualPrices({ ...visualPrices, vat_inc: e.target.value > 0 ? e.target.value : 0 })
                  }}
                >
                  Price (VAT inc)
                </TextField>
                {!imMember && (
                  <Grid container direction='row' alignItems='center'>
                    <Grid item>
                      <FormControl>
                        <Checkbox
                          size='small'
                          checked={theBooking.complimentary === 'YES'}
                          onChange={value =>
                            setBookingData({ ...theBooking, complimentary: value.target.checked ? 'YES' : 'NO' })
                          }
                          name='complimentary'
                          disabled={resourceData?.uploadContent?.code !== undefined}
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>Complimentary</Grid>
                  </Grid>
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Grid>
        {theBooking.team !== null ? (
          <Grid item>
            <Grid container direction='column'>
              <Grid item>
                <Grid container direction='row' justify='space-around'>
                  {originalPrice === null && (
                    <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      Credits: {theBooking.credits}
                    </Grid>
                  )}
                  {originalPrice && (
                    <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                      Credits: {originalPrice.credits}
                      {theBooking.credits > 0 && theBooking.credits !== originalPrice.credits
                        ? ` + (${theBooking.credits - originalPrice.credits})`
                        : ''}
                    </Grid>
                  )}
                  <Grid item style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    Credits available: {theBooking.available_credits}
                  </Grid>
                  {theBooking.available_credits > 0 ? (
                    <Grid item>
                      <Grid container direction='row' alignItems='center'>
                        <Grid item>
                          <FormControl>
                            <Checkbox
                              size='small'
                              defaultChecked={
                                (resourceData?.uploadContent?.code !== undefined && theBooking.credits > 0) ||
                                defaultTeam
                              }
                              onChange={value =>
                                setBookingData({ ...theBooking, use_credits: value.target.checked ? 'YES' : 'NO' })
                              }
                              name='credits'
                              disabled={resourceData?.uploadContent?.code !== undefined}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item>Use Credits</Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ''
        )}
        <Grid item justify='center'>
          {(theBooking?.end === undefined || new Date(theBooking?.end) > new Date()) && (
            <Grid container direction='row' justify='space-between' style={{ marginTop: '15px' }}>
              <Grid item>
                {resourceData.uploadContent?.member === undefined ? (
                  ''
                ) : (
                  <Grid container direction='row' spacing={1}>
                    <Grid item>
                      <Button
                        variant='contained'
                        style={{ backgroundColor: colors.red, color: 'white' }}
                        onClick={() => (confirmCancel === false ? setCancelBtn(true) : setCancelBtn(false))}
                      >
                        Cancel Booking
                      </Button>
                    </Grid>
                    {confirmCancel === true ? (
                      <Grid item>
                        <Button
                          variant='contained'
                          style={{ backgroundColor: colors.red, color: 'white' }}
                          onClick={() => updateTheBooking('cancel')}
                        >
                          Confirm Cancel
                        </Button>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Button variant='contained' color='secondary' onClick={() => validate(theBooking)}>
                  {resourceData.uploadContent?.code === undefined ? 'Create Booking' : 'Update Booking'}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </ModalCard>
  )
}

ModalSchedulerBooking.propTypes = {
  closeModal: PropTypes.func,
  rooms: PropTypes.array,
  office: PropTypes.object,
  resourceData: PropTypes.object,
  reloadBookings: PropTypes.func,
  role: PropTypes.string,
  profile: PropTypes.object,
}

ModalSchedulerBooking.defaultProps = {
  closeModal: () => {},
  rooms: [],
  office: {},
  resourceData: {},
  reloadBookings: () => {},
  role: '',
  profile: {},
}

export default ModalSchedulerBooking
