import React from 'react'
import { useLocation } from 'react-router-dom'
import compact from 'lodash/compact'
import split from 'lodash/split'
import { map } from 'lodash'
import NavBarView from './view'

const Breadcrumb = ({ ...props }) => {
  const { pathname } = useLocation()
  const splitPathname = compact(split(pathname, '/'))
  let path = ''
  const routes = map(splitPathname, item => {
    path = `${path}/${item}`
    return {
      path,
      label: item,
      id: item,
    }
  })
  return <NavBarView {...props} routes={routes} />
}

export default Breadcrumb
