import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { colors } from 'config/colors'
import { useTranslation } from 'react-i18next'
import { Autocomplete } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { getDashboardOffices, getDashboardWorkAreas, setResource } from 'api'
import { Title } from 'components/organisms/membersAddForm/styled'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import { Button, FormControl, InputLabel, MenuItem, TextField, Grid, Select, Modal } from '@material-ui/core'
import ColorPicker from 'material-ui-color-picker'
import { PlaceholderIcon, Logo } from 'components/organisms/themeForm/styled'
import { FormContainer, FormCountInput, FormInput, SmallError } from './formStyle'
import { Subtitle } from '../login/styled'

function ResourcesAddForm({ control, office }) {
  const history = useHistory()
  const [modal, setModal] = useState(false)
  const { t } = useTranslation()
  const [workAreasData, setWorkAreas] = useState()
  const [theResource, setResourceData] = useState({
    name: '',
    identifier: '',
    resource_type: '',
    status: 'active',
    work_area: '',
    capacity: 1,
    price_hour: 0.0,
    price_day: 0.0,
    price_month: 0.0,
    price_credits_hour: 0.0,
    price_credits_day: 0.0,
    price_credits_month: 0.0,
    no_member_price_hour: 0.0,
    no_member_price_day: 0.0,
    no_member_price_month: 0.0,
    no_member_price_credits_hour: 0.0,
    no_member_price_credits_day: 0.0,
    no_member_price_credits_month: 0.0,
    office: '',
    floor: '',
    description: '',
    length: 0,
    width: 0,
    height: 0,
    x: 0,
    y: 0,
    color: '#ffffff',
  })
  const [errors, setErrors] = useState({
    name: null,
    identifier: null,
    resource_type: null,
    capacity: null,
    office: null,
    floor: null,
    work_area: null,
  })

  function getDataAreas() {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
      },
    }
    getDashboardWorkAreas(query)
      .then(r => setWorkAreas(r))
      .catch(error => console.log(error))
  }

  function sendResource() {
    setModal(true)
    const theRes = {
      ...theResource,
      office: theResource.office.slug,
      work_area: theResource.work_area.slug,
      floor: theResource.floor.slug,
    }
    setResource(theRes).then(setTimeout(() => history.push('/space'), 1000))
  }

  function validateResource(data) {
    let validate = false
    const textErrors = {
      name: 'Name is required',
      identifier: 'Identifier is required',
      resource_type: 'Resource type is required',
      status: 'Status is required',
      capacity: 'Capacity must be a positive number',
      office: 'Office  is required',
      floor: 'Floor is required',
      work_area: 'Work Area is required',
    }
    const nameRes = data.name === undefined || data.name === '' ? textErrors.name : null
    const idRes = data.identifier === undefined || data.identifier === '' ? textErrors.identifier : null
    const typeRes = data.resource_type === undefined || data.resource_type === '' ? textErrors.resource_type : null
    const capacityRes = data.capacity === undefined || data.capacity === '' ? textErrors.capacity : null
    const officeRes = data.office === undefined || data.office === '' ? textErrors.office : null
    const floorRes = data.floor === undefined || data.floor === '' ? textErrors.floor : null
    const waRes = data.work_area === undefined || data.work_area === '' ? textErrors.work_area : null

    setErrors({
      name: nameRes,
      identifier: idRes,
      resource_type: typeRes,
      capacity: capacityRes,
      office: officeRes,
      floor: floorRes,
      work_area: waRes,
    })

    if (
      nameRes === null &&
      idRes === null &&
      typeRes === null &&
      capacityRes === null &&
      officeRes === null &&
      floorRes === null &&
      waRes === null
    ) validate = true
    if (validate === true) sendResource()
  }

  useEffect(() => {
    if (workAreasData === undefined) getDataAreas()
  }, [workAreasData, theResource, errors])

  const [officesData, setOfficesData] = useState()

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 1000,
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  useEffect(() => {
    if (officesData === undefined) getOfficesData()
  }, [officesData])

  let theWAs = [{ name: 'No data' }]
  if (workAreasData !== undefined) theWAs = workAreasData.work_areas
  if (theResource?.floor !== '') theWAs = theWAs.filter(wa => wa.floor?.name === theResource.floor?.name)

  console.log(workAreasData)

  return (
    <FormContainer>
      <Modal open={modal === true}>
        <FeedbackModal type='resource' />
      </Modal>
      <Grid container direction='column' spacing={2}>
        <Grid item name='Header'>
          <Grid container direction='row' justify='space-between' alignItems='center'>
            <Grid item>
              <Title>Add Resource</Title>
            </Grid>
            <Grid item>
              <Button
                variant='contained'
                color='secondary'
                onClick={() => {
                  validateResource(theResource)
                }}
              >
                {t('space.addResource')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item name='Body'>
          <Grid container direction='row' justify='space-between'>
            <Grid item name='col-der' md={4} xs={12}>
              <Grid item style={{ marginLeft: '7px' }}>
                <Grid container direction='column' spacing={1} alignItems='center'>
                  <Grid item>
                    <Subtitle>Resource Picture</Subtitle>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '95%' }}>
                      <Logo
                        name='res-picture'
                        icon={<PlaceholderIcon />}
                        placeholderText={t('profile.theme.loginPlaceholder')}
                        onInputChange={e => setResourceData({ ...theResource, picture: e })}
                        control={control}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item name='col-izq' md={8} xs={12}>
              <Grid container direction='column' spacing={1}>
                <Grid item name='1row'>
                  <Grid container direction='row' spacing={1}>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          placeholder='Name'
                          name='name'
                          variant='outlined'
                          onChange={e => {
                            setResourceData({ ...theResource, name: e.target.value })
                          }}
                          style={{ width: '100%' }}
                          label='Name'
                          error={errors.name !== null}
                        />
                        {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          options={officesData !== undefined ? officesData.offices : [{ name: 'no name' }]}
                          getOptionLabel={option => option.name}
                          renderInput={theParams => (
                            <TextField
                              {...theParams}
                              label='Office'
                              variant='outlined'
                              error={errors.office !== null}
                            />
                          )}
                          onChange={(e, data) => {
                            if (data !== null) setResourceData({ ...theResource, office: data })
                            else setResourceData({ ...theResource, office: '' })
                          }}
                          error={errors.office !== null}
                        />
                      </FormControl>
                      {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          renderInput={theParams => (
                            <TextField {...theParams} variant='outlined' label='Floor' error={errors.floor !== null} />
                          )}
                          options={
                            theResource.office.floors !== undefined ? theResource.office?.floors : [{ name: 'No data' }]
                          }
                          getOptionLabel={option => option.name}
                          onChange={(e, data) => {
                            if (data !== null) setResourceData({ ...theResource, floor: data })
                            else setResourceData({ ...theResource, floor: '' })
                          }}
                          error={errors.floor !== null}
                        />
                        {errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <Autocomplete
                        name='work_area'
                        options={theWAs}
                        getOptionLabel={option => option.name}
                        style={{ width: '100%' }}
                        renderInput={theParams => (
                          <TextField
                            {...theParams}
                            label='Work Area'
                            variant='outlined'
                            error={errors.work_area !== null}
                          />
                        )}
                        onChange={(e, data) => {
                          if (data !== null) setResourceData({ ...theResource, work_area: data })
                          else setResourceData({ ...theResource, work_area: '' })
                        }}
                      />
                      {errors.work_area !== null ? <SmallError>{errors.work_area}</SmallError> : ''}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item name='2row'>
                  <Grid container spacing={1}>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel style={{ marginTop: '-7px', marginLeft: '14px' }}>Id (Internal)</InputLabel>
                        <FormInput
                          placeholder='Id (Internal)'
                          name='identifier'
                          variant='outlined'
                          onChange={e => {
                            setResourceData({ ...theResource, identifier: e.target.value })
                          }}
                          errors={errors?.identifier}
                          label='Id (Internal)'
                          error={errors.identifier}
                        />
                        {errors.identifier === null ? '' : <SmallError>{errors.identifier}</SmallError>}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl variant='outlined' style={{ width: '100%' }}>
                        <InputLabel id='filter-label'>{t('resource.form.type')}</InputLabel>
                        <Select
                          label='Type'
                          name='resource_type'
                          errors={errors?.resource_type}
                          onChange={e => setResourceData({ ...theResource, resource_type: e.target.value })}
                          color={colors.softgray}
                          error={errors.resource_type}
                        >
                          <MenuItem value='hot_desk'>Hot Desk</MenuItem>
                          <MenuItem value='desk'>Desk</MenuItem>
                          <MenuItem value='meeting_room'>Meeting Room</MenuItem>
                        </Select>
                        {errors.resource_type === null ? '' : <SmallError>{errors.resource_type}</SmallError>}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl variant='outlined' style={{ width: '100%' }}>
                        <InputLabel id='filter-label'>{t('resource.form.status')}</InputLabel>
                        <Select
                          label='Status'
                          name='status'
                          value={theResource.status}
                          color={colors.softgray}
                          errors={errors?.status}
                          onChange={e => setResourceData({ ...theResource, status: e.target.value })}
                        >
                          <MenuItem value='inactive'>Inactive</MenuItem>
                          <MenuItem value='active'>Active</MenuItem>
                        </Select>
                        {errors.status === null ? '' : <SmallError>{errors.status}</SmallError>}
                      </FormControl>
                    </Grid>
                    <Grid item md={3} sm={6} xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Capacity</InputLabel>
                        <FormInput
                          variant='outlined'
                          label='capacity'
                          placeholder='Capacity'
                          name='capacity'
                          control={control}
                          type='number'
                          min='0'
                          value={theResource.capacity}
                          errors={errors?.capacity}
                          onChange={e =>
                            setResourceData({
                              ...theResource,
                              capacity: e.target.value >= 1 ? parseInt(e.target.value, 10) : 1,
                            })
                          }
                        />
                        {errors.capacity === null ? '' : <SmallError>{errors.capacity}</SmallError>}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item name='description'>
                  <Grid container>
                    <Grid item xs={12}>
                      <FormControl style={{ width: '100%' }}>
                        <TextField
                          placeholder='Description'
                          name='description'
                          variant='outlined'
                          onChange={e => {
                            setResourceData({ ...theResource, description: e.target.value })
                          }}
                          rows={3}
                          rowsMax={3}
                          multiline
                          label='Description'
                        />
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item name='physical'>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>Physical Description</Grid>
                    <Grid item>
                      <Grid container direction='row' spacing={1}>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Lenght</InputLabel>
                            <FormInput
                              variant='outlined'
                              label='Lenght'
                              placeholder='Resource Lenght in cm'
                              name='length'
                              type='number'
                              min='0'
                              value={theResource.length}
                              errors={errors?.length}
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  length: e.target.value >= 1 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                            />
                            {errors.length === null ? '' : <SmallError>{errors.length}</SmallError>}
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Width</InputLabel>
                            <FormInput
                              variant='outlined'
                              label='Width'
                              placeholder='Resource width in cm'
                              name='width'
                              type='number'
                              min='0'
                              value={theResource.width}
                              errors={errors?.width}
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  width: e.target.value >= 1 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                            />
                            {errors.width === null ? '' : <SmallError>{errors.width}</SmallError>}
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Height</InputLabel>
                            <FormInput
                              variant='outlined'
                              label='Height'
                              placeholder='Resource height in cm'
                              name='height'
                              type='number'
                              min='0'
                              value={theResource.height}
                              errors={errors?.height}
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  height: e.target.value >= 1 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                            />
                            {errors.height === null ? '' : <SmallError>{errors.height}</SmallError>}
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container direction='row' spacing={1}>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>X</InputLabel>
                            <FormInput
                              variant='outlined'
                              label='x'
                              placeholder='X coordinate'
                              name='x'
                              type='number'
                              value={theResource.x}
                              errors={errors?.x}
                              onChange={e => setResourceData({ ...theResource, x: parseInt(e.target.value, 10) })}
                            />
                            {errors.x === null ? '' : <SmallError>{errors.x}</SmallError>}
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <InputLabel style={{ marginLeft: '12px', marginTop: '-8px' }}>Y</InputLabel>
                            <FormInput
                              variant='outlined'
                              label='Y'
                              placeholder='y coordinate'
                              name='y'
                              type='number'
                              value={theResource.y}
                              errors={errors?.y}
                              onChange={e => setResourceData({ ...theResource, y: parseInt(e.target.value, 10) })}
                            />
                            {errors.y === null ? '' : <SmallError>{errors.y}</SmallError>}
                          </FormControl>
                        </Grid>
                        <Grid item md={4} sm={12}>
                          <FormControl style={{ width: '100%' }}>
                            <ColorPicker
                              name='color'
                              variant='outlined'
                              label={theResource.color}
                              autoComplete='off'
                              onChange={value => {
                                if (value !== undefined) setResourceData({ ...theResource, color: value })
                              }}
                              style={{ backgroundColor: theResource?.color }}
                              value={theResource.color}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item name='prices'>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>Prices Members</Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price hour</InputLabel>
                            <FormCountInput
                              label='Price hour'
                              name='price_hour'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_hour: parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_hour}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price Day</InputLabel>
                            <FormCountInput
                              label='Price Day'
                              name='price_day'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_day: parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_day}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price Month</InputLabel>
                            <FormCountInput
                              label='Price Month'
                              name='price_month'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_month: parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_month}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Hour</InputLabel>
                            <FormCountInput
                              label='Credits Hour'
                              name='price_credits_hour'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_credits_hour:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_credits_hour}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Day</InputLabel>
                            <FormCountInput
                              label='Credits Day'
                              name='price_credits_day'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_credits_day:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_credits_day}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Month</InputLabel>
                            <FormCountInput
                              label='Credits Month'
                              name='price_credits_month'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  price_credits_month:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.price_credits_month}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item name='prices-no-members'>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>Prices No Members</Grid>
                    <Grid item>
                      <Grid container spacing={1}>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price Hour</InputLabel>
                            <FormCountInput
                              label='Price hour'
                              name='no_member_price_hour'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_hour:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_hour}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price Day</InputLabel>
                            <FormCountInput
                              label='Price Day'
                              name='no_member_price_day'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_day:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_day}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Price Month</InputLabel>
                            <FormCountInput
                              label='Price Month'
                              name='no_member_price_month'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_month:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_month}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Hour</InputLabel>
                            <FormCountInput
                              label='Credits Hour'
                              name='no_member_price_credits_hour'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_credits_hour:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_credits_hour}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Day</InputLabel>
                            <FormCountInput
                              label='Credits Day'
                              name='no_member_price_credits_day'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_credits_day:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_credits_day}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                        <Grid item lg={2} md={4} sm={6} xs={12}>
                          <FormControl>
                            <InputLabel style={{ marginLeft: '14px', marginTop: '-7px' }}>Credits Month</InputLabel>
                            <FormCountInput
                              label='Credits Month'
                              name='no_member_price_credits_month'
                              control={control}
                              type='number'
                              min='0'
                              onChange={e =>
                                setResourceData({
                                  ...theResource,
                                  no_member_price_credits_month:
                                    parseInt(e.target.value, 10) >= 0 ? parseInt(e.target.value, 10) : 0,
                                })
                              }
                              value={theResource.no_member_price_credits_month}
                              style={{ width: '100%' }}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </FormContainer>
  )
}

ResourcesAddForm.propTypes = {
  control: PropTypes.object,
  office: PropTypes.object,
}

ResourcesAddForm.defaultProps = {
  control: {},
  office: {},
}

export default ResourcesAddForm
