import styled from 'styled-components'
import Text from 'components/atoms/text'
import TimePickerInput from 'components/atoms/timePickerInput'

export const Container = styled.form``

export const Title = styled(Text)`
  && {
    font-size: 24px;
    line-height: 32px;
    font-weight: 700;
  }
`

export const Toolbar = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export const Col3 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
export const Col2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const Col3to1 = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const Col4 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`
export const Col5 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const Col = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 16px;
  column-gap: 16px;
  margin-bottom: 40px;
  margin-top: 20px;
`

export const TimerInput = styled(TimePickerInput)`
  border: 1px solid #8a8b90;
  border-radius: 5px;
  padding: 10px 0;
`
