import React from 'react'
import 'date-fns'
import PropTypes from 'prop-types'
//import get from 'lodash/get'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'

const DateInputView = ({
  value,
  onChange,
  label,
  isLoading,
  error,
  className,
  options,
  inputValue,
  setInputValue,
  getOptionLabel,
  ...props
}) => (
  <Autocomplete
    className={className}
    value={value}
    inputValue={inputValue}
    onInputChange={(event, newInputValue) => {
      setInputValue(newInputValue)
    }}
    onChange={onChange}
    getOptionLabel={option => (option !== undefined ? option?.name : null)}
    options={options}
    {...props}
    renderInput={params => (
      <TextField
        {...params}
        value={inputValue}
        label={label}
        variant='filled'
        error={error}
        helperText={error?.message}
        InputProps={{
          ...params.InputProps,
          endAdornment: (
            <>
              {isLoading ? <CircularProgress color='inherit' size={20} /> : null}
              {params.InputProps.endAdornment}
            </>
          ),
        }}
      />
    )}
  />
)

DateInputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  options: PropTypes.array,
  inputValue: PropTypes.string,
  setInputValue: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  getOptionLabel: PropTypes.string,
}

DateInputView.defaultProps = {
  label: '',
  value: '' || {},
  onChange: () => null,
  error: null,
  className: '',
  options: [],
  inputValue: '',
  isLoading: false,
  getOptionLabel: 'name',
}

export default DateInputView
