import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useQuery } from 'react-query'
import debounce from 'lodash/debounce'
import DateInputView from './view'

const getQuery = (query, text, searchProp) => query({ [searchProp]: text })

const DateInput = ({ control, rules, name, label, onChange, value, query, searchProp, ...props }) => {
  const [inputValue, setInputValue] = useState('')
  const [inputSearchValue, setInputSearchValue] = useState('')

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const delayedQuery = useCallback(
    debounce(q => setInputSearchValue(q), 500),
    []
  )
  const { data, isLoading } = useQuery([name, inputSearchValue], () => getQuery(query, inputSearchValue, searchProp))
  return (
    <DateInputView
      {...props}
      inputValue={inputValue}
      setInputValue={text => {
        setInputValue(text)
        delayedQuery(text)
      }}
      label={label}
      isLoading={isLoading}
      onChange={(event, newValue) => {
        onChange(newValue)
      }}
      value={value}
      options={data}
    />
  )
}

DateInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  control: PropTypes.object,
  rules: PropTypes.object,
  name: PropTypes.string,
  query: PropTypes.func,
  searchProp: PropTypes.string,
}

DateInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => {},
  rules: {},
  query: null,
  control: null,
  name: null,
  searchProp: 'text',
}

export default DateInput
