import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Grid from '@mui/material/Grid'
import List from '@mui/material/List'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import Checkbox from '@mui/material/Checkbox'
import Button from '@mui/material/Button'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import Divider from '@mui/material/Divider'
import { CircularProgress } from '@material-ui/core'
import { SpinnerContainer } from '../members/newListStyle'

export default function TransferList(props) {
  const { singleStaffWebSections, profileWebSections } = props
  useEffect(() => {}, [profileWebSections])
  useEffect(() => {}, [singleStaffWebSections])

  function not(a, b) {
    return a.filter(value => b.indexOf(value) === -1)
  }

  function intersection(a, b) {
    return a.filter(value => b.indexOf(value) !== -1)
  }

  function union(a, b) {
    return [...a, ...not(b, a)]
  }

  const permitedSections = []
  let noPermitedSections = []

  singleStaffWebSections?.map(p => permitedSections.push(p.code))
  profileWebSections?.map(ap => noPermitedSections.push(ap.code))

  noPermitedSections = noPermitedSections.filter(np => !permitedSections.includes(np))

  const [checked, setChecked] = useState([])
  const [left, setLeft] = useState([])
  const [right, setRight] = useState([])

  useEffect(() => {
    setLeft(noPermitedSections)
    setRight(permitedSections)
  }, [])

  const leftChecked = intersection(checked, left)
  const rightChecked = intersection(checked, right)

  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]
    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleCheckedRight = () => {
    setRight(right.concat(leftChecked))
    setLeft(not(left, leftChecked))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked))
    setRight(not(right, rightChecked))
    setChecked(not(checked, rightChecked))
  }
  console.log('permited', permitedSections)
  console.log('no permited', noPermitedSections)

  const customList = (title, items) => (
    <Card>
      <CardHeader
        sx={{ px: 2, py: 1 }}
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{
              'aria-label': 'all items selected',
            }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List
        sx={{
          width: 200,
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component='div'
        role='list'
      >
        {items.map(value => {
          const labelId = `transfer-list-all-item-${value}-label`
          return (
            <ListItem key={value} role='listitem' button onClick={handleToggle(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={`${value}`} />
            </ListItem>
          )
        })}
        <ListItem />
      </List>
    </Card>
  )

  TransferList.propTypes = {
    singleStaffWebSections: PropTypes.array,
    profileWebSections: PropTypes.array,
  }

  TransferList.defaultProps = {
    singleStaffWebSections: [],
    profileWebSections: [],
  }

  if (permitedSections.length !== 0 && noPermitedSections.length !== 0) {
    return (
      <Grid container spacing={2} justifyContent='center' alignItems='center' style={{ marginTop: '15px' }}>
        <Grid item xs={5}>
          {customList('Not Permited', left)}
        </Grid>
        <Grid item xs={2}>
          <Grid container direction='column' alignItems='center'>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleCheckedRight}
              disabled={leftChecked.length === 0}
              aria-label='move selected right'
            >
              <ArrowForwardIcon />
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant='outlined'
              size='small'
              onClick={handleCheckedLeft}
              disabled={rightChecked.length === 0}
              aria-label='move selected left'
            >
              <ArrowBackIcon />
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={5}>
          {customList('Permited', right)}
        </Grid>
      </Grid>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}
