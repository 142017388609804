import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { getOffers, newGetMembers } from 'api'
import { Checkbox, FormControl, FormControlLabel, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { Controller } from 'react-hook-form'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import SelectInput from 'components/atoms/selectInput'
import TextInputHidden from 'components/atoms/textInputHidden'
import { useLocation } from 'react-router-dom'
import { Col, Col2, Container, Title, Toolbar } from './styled'

const LoginFormView = ({ onSubmit, control, errors, officeData }) => {
  const params = useLocation()
  const offer = params.state
  const { t } = useTranslation()

  const [members, setMembers] = useState([])
  const [offers, setOffers] = useState([])

  function getDataMembers(data) {
    newGetMembers({
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: officeData.props[0].office.slug,
        name: data !== undefined ? data : '',
      },
    }).then(response => setMembers(response.members))
  }

  function getDataOffers() {
    getOffers({
      pageSize: 1000,
      currentPage: 1,
      filter: '',
    }).then(response => setOffers(response.offers))
  }

  useEffect(() => {
    getDataMembers()
    getDataOffers()
  }, [])

  return (
    <Container onSubmit={onSubmit}>
      <Toolbar>
        <Button variant='contained' color='secondary' type='submit'>
          {t(offer ? 'marketing.updateOfferUser' : 'marketing.addOfferUser')}
        </Button>
      </Toolbar>
      <Title>{t(offer ? 'marketing.updateOfferUser' : 'marketing.addOfferUser')}</Title>
      <Col>
        <TextInputHidden value={offer?.slug} name='slug' control={control} />
      </Col>
      <Col2>
        {members && (
          <Controller
            name='user'
            control={control}
            defaultValue={offer?.user.id}
            render={({ onChange, ...props }) => (
              <FormControl>
                <Autocomplete
                  disabled={!members}
                  name='autocompleteMembers'
                  defaultValue={offer?.user ? { user: offer?.user } : null}
                  options={members}
                  getOptionLabel={option => `${option.user.first_name} ${option.user.last_name}`}
                  renderInput={p => <TextField {...p} label='Member' variant='outlined' />}
                  onChange={(e, data) => {
                    // eslint-disable-next-line react/prop-types
                    props.field.onChange(data?.user.id)
                  }}
                  onInputChange={(e, data) => getDataMembers(data)}
                  {...props}
                />
                {errors.member === null ? '' : <SmallError>{errors.member?.message}</SmallError>}
              </FormControl>
            )}
          />
        )}
        <Controller
          name='offer'
          control={control}
          defaultValue={offer?.offer.id}
          render={({ onChange, ...props }) => (
            <FormControl>
              <Autocomplete
                disabled={!offers}
                name='autocompleteMembers'
                options={offers}
                defaultValue={offer?.offer}
                getOptionLabel={option => `${option.code} ${option.title}`}
                renderInput={p => <TextField {...p} label='Offer' variant='outlined' />}
                onChange={(e, data) => {
                  // eslint-disable-next-line react/prop-types
                  props.field.onChange(data?.id)
                }}
                {...props}
              />
              {errors.offer === null ? '' : <SmallError>{errors.offer?.message}</SmallError>}
            </FormControl>
          )}
        />
      </Col2>
      <Col2>
        <SelectInput
          label={t('marketing.form.status')}
          name='status'
          control={control}
          value={offer?.status}
          error={errors?.status}
          options={[
            { id: 'new', text: 'New' },
            { id: 'pending', text: 'Pending' },
            { id: 'accepted', text: 'Accepted' },
            { id: 'rejected', text: 'Rejected' },
            { id: 'cancelled', text: 'Cancelled' },
          ]}
        />
        <Controller
          control={control}
          render={({ onChange, ...props }) => (
            <FormControlLabel
              {...props}
              onChange={(e, data) => {
                // eslint-disable-next-line react/prop-types
                props.field.onChange(data)
              }}
              control={<Checkbox name='send_notification' />}
              label={t('marketing.form.sendNotification')}
            />
          )}
          name='send_notification'
        />
      </Col2>
    </Container>
  )
}

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  office: PropTypes.object,
  officeData: PropTypes.object,
}

LoginFormView.defaultProps = {
  errors: null,
  office: {},
  officeData: {},
}

export default LoginFormView
