import React from 'react'
import PropTypes from 'prop-types'
import TabBar from 'components/molecules/tabBar'
import { Container } from './styled'

export const HeaderToolber = ({ className, tabValue, tabs, rightActions }) => (
  <Container variant='permanent' anchor='left' className={className}>
    <TabBar value={tabValue} tabs={tabs} />
    {rightActions}
  </Container>
)

HeaderToolber.propTypes = {
  className: PropTypes.string,
  tabValue: PropTypes.string,
  tabs: PropTypes.array,
  rightActions: PropTypes.node,
}

HeaderToolber.defaultProps = {
  className: '',
  tabValue: '',
  tabs: [],
  rightActions: null,
}

export default HeaderToolber
