import React from 'react'
import PropTypes from 'prop-types'
import MembershipsAddForm from 'components/organisms/membershipsAddForm'
import { Container } from './styled'

const MembershipsAdd = props => {
  const { office } = props
  return (
    <Container>
      <MembershipsAddForm office={office} />
    </Container>
  )
}

MembershipsAdd.propTypes = {
  office: PropTypes.object,
}

MembershipsAdd.defaultProps = {
  office: {},
}
export default MembershipsAdd
