import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, Modal, Grid } from '@material-ui/core'
import PropTypes from 'prop-types'
import { getGuestSingle, getTeamProfile } from 'api'
import { useParams } from 'react-router-dom'
import DefaultAvatar from 'components/molecules/defaultAvatar/defaultAvatar'
import {
  ProfileContainer,
  ProfileFirstContent,
  ProfileInput,
  AvatarWrapper,
  ProfileImgContent,
  ProfileInputBold,
  ProfileTitle,
} from '../members/profileStyle'
import { SpinnerContainer } from '../members/newListStyle'
import ModalGuest from './modalGuest'
import ProfileTeamMemberList from '../members/singleTeamMemberList'

function GuestSingle(props) {
  const { office } = props
  const [guestData, setGuestData] = useState()
  const [teamData, setTeamData] = useState()
  const params = useParams()
  const { slug } = params

  function getGuestData() {
    getGuestSingle(slug).then(response => setGuestData(response))
  }

  function getTeamData() {
    if (guestData?.team !== null && guestData?.team !== undefined) getTeamProfile(guestData?.team.slug).then(r => setTeamData(r))
    else setTeamData(null)
  }

  useEffect(() => {
    getGuestData()
  }, [])

  useEffect(() => {
    if (teamData === undefined) getTeamData()
  }, [guestData, teamData])

  const [modalGuest, setModalGuest] = useState(false)

  function handleGuestClose() {
    setModalGuest(false)
  }

  if (guestData !== undefined && guestData !== null) {
    const createDate = guestData.create_date?.split('T')
    return (
      <ProfileContainer>
        <Modal open={modalGuest} toClose={handleGuestClose} onClose={handleGuestClose}>
          <ModalGuest guest={guestData} toClose={handleGuestClose} office={office} />
        </Modal>
        <ProfileFirstContent>
          <Grid container direction='row' justify='flex-start' spacing={1}>
            <Grid item xs={2}>
              <AvatarWrapper>
                <ProfileImgContent>
                  {guestData?.image ? (
                    <img src={guestData?.image} alt={`${guestData?.name}`} />
                  ) : (
                    <DefaultAvatar first={guestData.name} />
                  )}
                </ProfileImgContent>
                <Button
                  style={{ width: 80, marginTop: '10px' }}
                  onClick={() => setModalGuest(true)}
                  variant='outlined'
                  size='small'
                >
                  Edit
                </Button>
              </AvatarWrapper>
            </Grid>
            <Grid item xs={10}>
              <Grid container direction='column' justify='flex-start'>
                <Grid item>
                  <ProfileTitle>{guestData?.name}</ProfileTitle>
                </Grid>
                <Grid item>
                  <Grid container direction='column' spacing={1}>
                    <Grid item>
                      <ProfileInputBold>{`${guestData?.email} - ${guestData?.phone} - Origin: ${guestData?.origin}`}</ProfileInputBold>
                    </Grid>
                    <Grid item>
                      <ProfileInput>
                        {guestData?.host !== null
                          ? `Host: ${guestData?.host?.user?.first_name} ${guestData?.host?.user?.last_name} - ${guestData?.host?.user?.phone}`
                          : ''}
                      </ProfileInput>
                    </Grid>
                    <Grid item>
                      <ProfileInput>{`Created Date: ${createDate ? createDate[0] : ''}`}</ProfileInput>
                    </Grid>
                    <Grid item>
                      <ProfileInput>
                        Consent Marketing: {guestData?.consent_marketing === true ? 'Yes' : 'No'}
                      </ProfileInput>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </ProfileFirstContent>
        {teamData !== null ? (
          <Grid container direction='column'>
            <Grid item>
              <h3>Team: {guestData.team?.name}</h3>
            </Grid>
            <Grid item>
              <ProfileTeamMemberList teamId={teamData?.team_mates} />
            </Grid>
          </Grid>
        ) : (
          ''
        )}
      </ProfileContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

GuestSingle.propTypes = { office: PropTypes.object }
GuestSingle.defaultProps = { office: {} }

export default GuestSingle
