import React, { useEffect, useState } from 'react'
import { CircularProgress, Box } from '@material-ui/core'
import PropTypes from 'prop-types'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { ScrollView } from 'devextreme-react'
import TeamMateCard from './teamMateCard'

function ProfileTeamMemberList(props) {
  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  if (props !== undefined) {
    return (
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <Box style={{ display: 'flex', flexDirection: 'row' }}>
          {props.teamId.map((mate, index) => (
            <TeamMateCard mate={mate} key={index} />
          ))}
        </Box>
      </ScrollView>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

ProfileTeamMemberList.propTypes = {
  teamId: PropTypes.array,
}

ProfileTeamMemberList.defaultProps = {
  teamId: [],
}

export default ProfileTeamMemberList
