import React from 'react'
import { Route } from 'react-router'
import PropTypes from 'prop-types'
import Layout from 'components/template/layout'
import NoLoggedLayout from 'components/template/noLoggedLayout'
import FullScreenLayout from 'components/template/fullScreenLayout'

const SelectedLayout = ({ layoutType, children, ...rest }) => {
  console.log(rest)
  switch (layoutType) {
    case 'noLogin':
      return <NoLoggedLayout {...rest}>{children}</NoLoggedLayout>
    case 'logged':
      return <Layout {...rest}>{children}</Layout>
    default:
      return <FullScreenLayout {...rest}>{children}</FullScreenLayout>
  }
}

SelectedLayout.defaultProps = {
  layoutType: '',
  children: <></>,
}

SelectedLayout.propTypes = {
  layoutType: PropTypes.string,
  children: PropTypes.node,
}

const RouteWrapper = ({ component: Component, layoutType, selectOffice, breadcrumb, selectActiveClient, ...rest }) => (
  <Route {...rest}>
    {console.log(breadcrumb)}
    {console.log(rest)}
    {console.log(Component)}
    {layoutType ? (
      <SelectedLayout
        layoutType={layoutType}
        breadcrumb={breadcrumb}
        selectOffice={selectOffice}
        selectActiveClient={selectActiveClient}
      >
        <Component />
      </SelectedLayout>
    ) : (
      <Component />
    )}
  </Route>
)

RouteWrapper.defaultProps = {
  component: () => {},
  layoutType: '',
  selectOffice: true,
  breadcrumb: true,
  selectActiveClient: true,
}

RouteWrapper.propTypes = {
  component: PropTypes.func,
  layoutType: PropTypes.string,
  selectOffice: PropTypes.bool,
  breadcrumb: PropTypes.bool,
  selectActiveClient: PropTypes.bool,
}

export default RouteWrapper
