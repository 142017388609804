import { useState, useEffect } from 'react'
import { useHistory } from 'react-router'
import { getPlansBoarding, updateClient } from 'api'
import bgLogin from 'assets/images/bgLogin.png'
import { Grid, Button, FormControl, RadioGroup, FormControlLabel, Radio, TextField } from '@material-ui/core'
import formatWords from 'components/atoms/formatWords/formatWords'
import { colors } from 'config/colors'
import { SmallError } from '../resourcesAdd/formStyle'
import { Container, Wrapper } from '../login/styled'

function OnBoarding() {
  const history = useHistory()
  const [data, setData] = useState({
    role: null,
    club: null,
    visibility: null,
    email: null,
    employees: 0,
    plan: null,
  })
  const [errors, setErrors] = useState({
    club: null,
    email: null,
  })
  const [finish, setFinish] = useState(false)
  const [privatePlans, setPrivatePlans] = useState(false)
  const [plansData, setPlansData] = useState()

  useEffect(() => {}, [finish])

  useEffect(() => {}, [errors])

  useEffect(() => {}, [data])

  const pStyle = {
    marginBottom: '5px',
    marginLeft: '35px',
    color: colors.gray,
    fontWeight: 700,
  }

  function getPlansData() {
    getPlansBoarding().then(r => setPlansData(r))
  }

  useEffect(() => {
    if (plansData === undefined) getPlansData()
    console.log(plansData)
  }, [plansData])

  function canFinishOnBoarding() {
    const textErrors = {
      club: 'Office name is mandatory',
    }
    let validName = true
    if (data.club === '' || data.club === null) {
      validName = false
      setErrors({ ...errors, club: textErrors.club })
    } else {
      setErrors({ ...errors, club: null })
    }

    if (data.role === 'coworking' && validName === true && data.visibility === 'public') setFinish(true)
    else if (data.role === 'coworking' && validName === true && data.visibility === 'private') setFinish(true)
    else if (data.role === 'other' && validName === true && data.visibility === 'public') setFinish(true)
    else if (data.role === 'private' && validName === true && data.visibility === 'private') setPrivatePlans(true)
    else if (data.role === 'private' && validName === true && data.visibility === 'protected') setPrivatePlans(true)
  }

  function finishOnBoarding() {
    console.log(data)
    const textErrors = {
      email: 'Must be a valid mail',
    }

    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const testMail = regex.test(data.email)

    if (data.email === null || data.email === '' || testMail === false) {
      setErrors({ ...errors, email: textErrors.email })
    } else {
      setErrors({ ...errors, email: null })
      updateClient(data).then(history.push('/login'))
    }
  }

  let typeCompany = 'Private'
  if (data.role === 'coworking') typeCompany = 'Coworking'
  if (data.role === 'other') typeCompany = 'Other'

  let typeSpace = 'Private'
  if (data.visibility === 'public') typeSpace = 'Public'
  if (data.visibility === 'protected') typeSpace = 'Hybrid'

  return (
    <Container style={{ backgroundImage: `url(${bgLogin})` }}>
      <Wrapper elevation={0}>
        <Grid container direction='column' justify='center' alignItems='center' spacing={1}>
          <Grid item>
            <Grid container direction='column' justify='center' alignItems='center'>
              {finish === false ? (
                <Grid item>
                  <Grid container direction='column' style={{ marginBottom: '25px' }}>
                    <Grid item style={{ textAlign: 'center', fontSize: '50px', fontWeight: 700 }}>
                      Your company
                    </Grid>
                    <Grid item style={{ textAlign: 'center', fontSize: '18px' }}>
                      Let us know something more about you
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {data.role === null && finish === false ? (
                <Grid container justify='center'>
                  <Grid item>
                    <FormControl>
                      <RadioGroup
                        onChange={e => {
                          if (e.target.value === 'other') setData({ ...data, role: e.target.value, visibility: 'public' })
                          else setData({ ...data, role: e.target.value })
                        }}
                      >
                        <FormControlLabel value='private' control={<Radio />} label='Private Company' />
                        <p style={pStyle}>
                          Select this option if you are a private company and you just need a platform to manage your
                          space and employees, like hot desking, meeting rooms bookings, presence control...
                        </p>
                        <FormControlLabel value='coworking' control={<Radio />} label='Coworking Company' />
                        <p style={pStyle}>
                          Select this option if you are a co-working space and you also need to handle payments,
                          invoices, plans and day passes
                        </p>
                        <FormControlLabel value='other' control={<Radio />} label='Other spaces' />
                        <p style={pStyle}>
                          Select this option if you are a restaurant, pub, library or any other venue that is open to
                          offer space to work, study or focus
                        </p>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {data.role === 'private' && finish === false && privatePlans === false ? (
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        variant='outlined'
                        label='Main office name'
                        value={data.club ? data.club : ''}
                        onChange={e =>
                          e !== '' ? setData({ ...data, club: e.target.value }) : setData({ ...data, club: null })
                        }
                        error={errors.club !== null}
                      />
                      {errors.club !== null ? <SmallError>{errors.club}</SmallError> : ''}
                    </FormControl>
                    <FormControl style={{ marginTop: '15px' }}>
                      <RadioGroup
                        onChange={e => {
                          setData({ ...data, visibility: e.target.value })
                        }}
                      >
                        <FormControlLabel value='private' control={<Radio />} label='Private Space' />
                        <p style={pStyle}>
                          Nobody outside your organization will see your office in the app. Your employees will only see
                          your offices
                        </p>
                        <FormControlLabel value='protected' control={<Radio />} label='Hybrid Space' />
                        <p style={pStyle}>
                          Nobody outside your organization will see your office in the app, but your employees will be
                          able to book working spaces in other clubs. You can select a tailored list of spaces that
                          fullfil your requirements
                        </p>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {data.role === 'coworking' && finish === false ? (
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        variant='outlined'
                        label='Main office name'
                        value={data.club ? data.club : ''}
                        onChange={e => setData({ ...data, club: e.target.value })}
                        error={errors.club !== null}
                      />
                      {errors.club !== null ? <SmallError>{errors.club}</SmallError> : ''}
                    </FormControl>
                    <FormControl style={{ marginTop: '15px' }}>
                      <RadioGroup
                        onChange={e => {
                          setData({ ...data, visibility: e.target.value })
                        }}
                      >
                        <FormControlLabel value='private' control={<Radio />} label='Private Space' />
                        <p style={pStyle}>
                          Nobody outside your coworking will see your office in the app. Your members will only see your
                          offices
                        </p>
                        <p style={pStyle}>With this option, there is a £2 per member and month</p>
                        <FormControlLabel value='public' control={<Radio />} label='Community' />
                        <p style={pStyle}>
                          Your spaces will be visible to the whole community. There is a fee per sucesful booking, but
                          also a reward if you introduce a new member to the community
                        </p>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {data.role === 'other' && finish === false ? (
                <Grid container>
                  <Grid item xs={12}>
                    <FormControl style={{ width: '100%' }}>
                      <TextField
                        variant='outlined'
                        label='Main office name'
                        value={data.club ? data.club : ''}
                        onChange={e => setData({ ...data, club: e.target.value })}
                        error={errors.club !== null}
                      />
                      {errors.club !== null ? <SmallError>{errors.club}</SmallError> : ''}
                    </FormControl>
                    <FormControl style={{ marginTop: '15px' }}>
                      <RadioGroup
                        defaultValue='public'
                        onChange={e => {
                          setData({ ...data, visibility: e.target.value })
                        }}
                      >
                        <FormControlLabel value='public' control={<Radio />} label='Community' />
                        <p style={pStyle}>
                          Your spaces will be visible to the whole community. There is a fee per sucesful booking, but
                          also a reward if you introduce a new member to the community
                        </p>
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {finish === true ? (
                <Grid container>
                  <Grid item xs={12}>
                    <Grid item style={{ fontSize: '50px', fontWeight: 800 }}>
                      Lets get started
                    </Grid>
                    <Grid item style={{ fontWeight: 800, textAlign: 'center', marginBottom: '15px', fontSize: '18px' }}>
                      Your account its almost ready, we just need few more seconds of your attention
                    </Grid>
                    <Grid item xs={12}>
                      <p style={{ marginBottom: '10px', color: colors.gray, fontWeight: 700 }}>
                        You have selected a {typeCompany} membership and a {typeSpace} Management. We will contact you
                        shortly and the onboarding process will start. Meanwhile, can you start collecting
                      </p>
                      <p style={pStyle}>- List of users</p>
                      <p style={pStyle}>- A map of your offices</p>
                      <p style={pStyle}>- Confirm your contact email address</p>
                    </Grid>
                    <Grid item>
                      <FormControl style={{ width: '100%', marginTop: '25px' }}>
                        <TextField
                          variant='outlined'
                          label='Email address'
                          onChange={e => setData({ ...data, email: e.target.value })}
                          error={errors.email !== null}
                        />
                        {errors.email !== null ? <SmallError>{errors.email}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ''
              )}
              {privatePlans === true
                ? plansData.map((p, i) => (
                  <Button
                    style={{ width: '100%', textAlign: 'start' }}
                    key={i}
                    variant='outlined'
                    onClick={() => {
                        setData({ ...data, role: 'private', plan: p.slug })
                        setPrivatePlans(false)
                        setFinish(true)
                      }}
                  >
                    <Grid
                      container
                      direction='column'
                      style={{ padding: '15px 0 15px 25px' }}
                      spacing={1}
                      justify='flex-start'
                    >
                      <Grid item style={{ fontWeight: 900 }}>
                        {formatWords(p.type_client)}
                      </Grid>
                      <Grid item style={{ fontWeight: 900, fontSize: '35px' }}>
                        {p.text_price}
                      </Grid>
                      <Grid item style={{ color: colors.gray, fontWeight: 900 }}>
                        Valid for {p.invoicing_period_in_days} days
                      </Grid>
                      <Grid item style={{ fontWeight: 900 }}>
                        {p.subtitle}
                      </Grid>
                    </Grid>
                  </Button>
                  ))
                : ''}
            </Grid>
            <Grid item>
              {data.role === 'private' && finish === false ? (
                <Grid container justify='space-around' style={{ marginTop: '25px' }}>
                  <Button
                    variant='contained'
                    style={{ color: 'white', backgroundColor: 'black' }}
                    onClick={() => {
                      setData({ ...data, role: null, visibility: null, club: null })
                      setPrivatePlans(false)
                    }}
                  >
                    Back
                  </Button>
                  <Button variant='contained' color='secondary' onClick={() => canFinishOnBoarding()}>
                    Next
                  </Button>
                </Grid>
              ) : (
                ''
              )}
              {data.role === 'coworking' && finish === false ? (
                <Grid container justify='space-around' style={{ marginTop: '25px' }}>
                  <Button
                    variant='contained'
                    style={{ color: 'white', backgroundColor: 'black' }}
                    onClick={() => {
                      setData({ ...data, role: null, visibility: null, club: null })
                      setPrivatePlans(false)
                    }}
                  >
                    Back
                  </Button>
                  <Button variant='contained' color='secondary' onClick={() => canFinishOnBoarding()}>
                    Next
                  </Button>
                </Grid>
              ) : (
                ''
              )}
              {data.role === 'other' && finish === false ? (
                <Grid container justify='space-around' style={{ marginTop: '25px' }}>
                  <Button
                    variant='contained'
                    style={{ color: 'white', backgroundColor: 'black' }}
                    onClick={() => {
                      setData({ ...data, role: null, visibility: null, club: null })
                      setPrivatePlans(false)
                    }}
                  >
                    Back
                  </Button>
                  <Button variant='contained' color='secondary' onClick={() => canFinishOnBoarding()}>
                    Next
                  </Button>
                </Grid>
              ) : (
                ''
              )}
              {finish === true ? (
                <Grid container justify='space-around' style={{ marginTop: '25px' }}>
                  <Button
                    variant='contained'
                    style={{ color: 'white', backgroundColor: 'black' }}
                    onClick={() => {
                      setData({ ...data, role: null, visibility: null, club: null })
                      setPrivatePlans(false)
                      setFinish(false)
                    }}
                  >
                    Back
                  </Button>
                  <Button variant='contained' color='secondary' onClick={() => finishOnBoarding()}>
                    Finish
                  </Button>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
      </Wrapper>
    </Container>
  )
}

export default OnBoarding
