import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { getSingleOrder, updateOrder } from 'api'
import { colors } from 'config/colors'
import { CircularProgress, Grid, Button } from '@material-ui/core'
import { TextStyled } from 'components/atoms/text/styled'
import SingleOrderLinesList from './singleOrderLinesList'
import { SpinnerContainer } from '../members/newListStyle'

function FADOrderSingle() {
  const params = useParams()
  const { slug } = params
  const [theOrder, setOrder] = useState()

  function getOrderData() {
    getSingleOrder(slug).then(r => setOrder(r))
  }

  function setOrderStatus(status) {
    let theStatus = status.toLowerCase()
    if (theStatus === 'cancel') theStatus = 'cancelled'
    setOrder({ ...theOrder, status: theStatus })
    updateOrder(theOrder.slug, { ...theOrder, status: theStatus })
  }

  useEffect(() => {
    if (theOrder === undefined) getOrderData()
  }, [theOrder])

  const theDate = theOrder?.init_date?.split('T')
  const lorem =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean semper sed lorem sit amet accumsan. Suspendisse non pharetra diam, vel sagittis felis. Nullam fringilla fringilla malesuada.'
  return (
    <Grid>
      {theOrder !== undefined ? (
        <Grid container direction='column' spacing={3}>
          <Grid item>
            <Grid container direction='row' justify='space-around' alignItems='center'>
              <Grid item xs={8}>
                <Grid container direction='column' spacing={4}>
                  <Grid item>
                    <Grid container direction='row' justify='space-around'>
                      <Grid item>
                        <Grid container direction='column' spacing={2}>
                          <Grid item>
                            <TextStyled>
                              Member: {`${theOrder?.member.user.first_name} ${theOrder?.member.user.last_name}`}
                            </TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>Team: {theOrder?.member.team.name}</TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>Init Date: {theDate[0]}</TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>Staff: {theOrder.staff === null ? '-' : theOrder.staff.name}</TextStyled>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item>
                        <Grid container direction='column' spacing={2}>
                          <Grid item>
                            <TextStyled>Code: {theOrder?.code}</TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>
                              Status: {theOrder.status.charAt(0).toUpperCase() + theOrder.status.slice(1)}
                            </TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>Total Price: £{theOrder?.price}</TextStyled>
                          </Grid>
                          <Grid item>
                            <TextStyled>
                              Payment Ref: {theOrder?.payment_reference === null ? '-' : theOrder.payment_reference}
                            </TextStyled>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{ border: `1px solid ${colors.softgray}`, margin: '25px 100px', borderRadius: '5px' }}
                  >
                    <TextStyled>{theOrder.comments === '' ? lorem : theOrder.comments}</TextStyled>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={e => setOrderStatus(e.target.innerText)}
                      color={theOrder.status === 'preparing' ? 'secondary' : ''}
                      disabled={
                        theOrder.status === 'ready' ||
                        theOrder.status === 'preparing' ||
                        theOrder.status === 'cancelled'
                      }
                    >
                      Preparing
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={e => setOrderStatus(e.target.innerText)}
                      color={theOrder.status === 'ready' ? 'secondary' : ''}
                      disabled={theOrder.status === 'ready' || theOrder.status === 'cancelled'}
                    >
                      Ready
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={e => setOrderStatus(e.target.innerText)}
                      color={theOrder.status === 'delivered' ? 'secondary' : ''}
                      disabled={theOrder.status === 'cancelled' || theOrder.status === 'delivered'}
                    >
                      Delivered
                    </Button>
                  </Grid>
                  <Grid item />
                  <Grid item />
                  <Grid item>
                    <Button
                      variant='contained'
                      onClick={e => setOrderStatus(e.target.innerText)}
                      color={theOrder.status === 'cancelled' ? 'secondary' : ''}
                    >
                      Cancel
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SingleOrderLinesList theOrder={theOrder} />
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

export default FADOrderSingle
