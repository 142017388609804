function convertDates(date, type) {
  if (date !== undefined && date !== null && date !== '') {
    const dateSplit = date.toString().split('T')
    const day = dateSplit[0].toString().split('-')
    let time = null
    if (dateSplit.length > 1) {
      time = dateSplit[1].substring(1)
    }
    if (type === 'date') time = ''
    const slash = '-'
    const dateFormatted = `${day[2] + slash + day[1] + slash + day[0] + time}`
    return dateFormatted
  }
  return null
}

export default convertDates
