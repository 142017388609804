import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core'

function Error404() {
  function navigateTo() {
    window.location.href = '/'
  }
  return (
    <Grid container direction='column' style={{ margin: '100px' }} spacing={3}>
      <Grid item style={{ textDecoration: 'underline' }}>
        <h1>Error 404</h1>
      </Grid>
      <Grid item>
        <h4>You can not be here</h4>
      </Grid>
      <Grid item>
        <Link to='/' onClick={() => navigateTo()}>
          Back home
        </Link>
      </Grid>
    </Grid>
  )
}

export default Error404
