import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
  width: 100%;
`
export const ContainerEnd = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  position: relative;
  top: -10px;
  & > button {
    border-radius: 10px;
  }
`
