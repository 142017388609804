import { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types'
import { Grid, CircularProgress, Modal } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import AddIcon from '@material-ui/icons/Add'
import { ScrollView } from 'devextreme-react'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import ModalCreateDesk from './modalCreateDesk'
import ModalUpdateDesk from './modalUpdateDesk'
import BookingPanelCard from './bookingPanelCard'

function PanelInfo({
  info,
  spaces,
  setSpacesData,
  asignBookingToResource,
  setBookingSelected,
  filter,
  office,
  day,
  getPanelInfo,
  setState,
  state,
  updateList,
  panelHeight,
  theBooking,
  showUpdate,
  profile,
}) {
  const [bookingDeskModal, setBookingDeskModal] = useState(false)
  const [updateModal, setUpdateModal] = useState(false)
  const [bookingCode, setBookingCode] = useState(null)
  //const [imMember] = useState(profile?.role === 'team_admin' || profile?.role === 'team_member')
  console.log(theBooking)
  function onDragStart(e) {
    const data = e.target.outerHTML
    const code = data?.split('"')
    asignBookingToResource(code[5])
  }

  useEffect(() => {}, [filter, bookingDeskModal, updateModal])

  useEffect(() => {
    document.removeEventListener('onDragStart', onDragStart)
    document.addEventListener('onDragStart', onDragStart)
  }, [info])

  useEffect(() => {
    if (showUpdate !== '') setUpdateModal(!updateModal)
  }, [showUpdate])

  function updateData(item) {
    console.log('Updateing data ', item)
    setUpdateModal(true)
    getPanelInfo(item.code, 'bookingInfo')
    setBookingCode(item.code)
  }

  function closeModal() {
    console.log('Clodign moda;')
    setSpacesData([])
    updateList()
    setBookingDeskModal(false)
    setUpdateModal('')
    getPanelInfo()
  }

  function styleListTitle() {
    return {
      fontSize: '18px',
      textAlign: 'left',
      marginTop: '20px',
      marginBottom: '10px',
    }
  }

  function styleListItem() {
    return {
      fontSize: '14px',
      textAlign: 'left',
      paddingLeft: '10px',
      marginTop: '5px',
    }
  }

  function howToInfo() {
    return (
      <Grid container direction='column' style={{ flex: 1, alignContent: 'center' }}>
        <Grid item style={styleListTitle()}>
          How to book desks from this screen?
        </Grid>
        <Grid item style={styleListItem()}>
          Step 1: Select a date just above the map
        </Grid>
        <Grid item style={styleListItem()}>
          Step 2: Select as many desks as you need
        </Grid>
        <Grid item style={styleListItem()}>
          Step 3: You can specify which member is using which desk. This step is optional
        </Grid>
        <Grid item style={styleListItem()}>
          Step 4: Confirm your booking by pressing the button that will appear above these lines
        </Grid>
      </Grid>
    )
  }

  let lista = []
  if (spaces && Object.keys(spaces).length > 0) {
    if (filter === 'unassigned') lista = spaces.bookings.filter(b => b.resource === null)
    if (filter === 'all' || filter === undefined) lista = spaces.bookings
    if (filter === 'assigned') lista = spaces.bookings.filter(b => b.resource !== null)
    return (
      <ScrollView showScrollbar='onScroll' height={panelHeight}>
        <Modal open={bookingDeskModal} toClose={bookingDeskModal === false}>
          <ModalCreateDesk
            closeModal={closeModal}
            office={office}
            theDay={day}
            activeClient={theBooking.client !== '' ? theBooking.client : profile.active_client}
            activeTeam={theBooking.team}
            role={profile.role}
          />
        </Modal>
        <Modal open={updateModal === true} toClose={closeModal}>
          <ModalUpdateDesk infoBooking={bookingCode} office={office} closeModal={closeModal} />
        </Modal>
        {lista.length === 0 && howToInfo()}
        <Grid container direction='column' justify='center'>
          <Grid item>
            <Grid container direction='column'>
              {profile?.role === 'office_manager' && (
                <Grid item>
                  <Button
                    style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }}
                    variant='outlined'
                    onClick={() => setBookingDeskModal(true)}
                  >
                    <AddIcon />
                  </Button>
                </Grid>
              )}
              {lista?.map((item, i) => (
                <Grid
                  key={i}
                  item
                  draggable
                  onDragStart={e => onDragStart(e)}
                  code={item.code}
                  style={{ margin: '6px 10px 8px 5px' }}
                >
                  <BookingPanelCard
                    item={item}
                    updateData={updateData}
                    setBookingSelected={setBookingSelected}
                    setState={setState}
                    state={state}
                    profile={profile}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ScrollView>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

PanelInfo.propTypes = {
  info: PropTypes.object,
  spaces: PropTypes.object,
  setSpacesData: PropTypes.func,
  asignBookingToResource: PropTypes.func,
  setBookingSelected: PropTypes.func,
  filter: PropTypes.string,
  office: PropTypes.object,
  day: PropTypes.string,
  getPanelInfo: PropTypes.func,
  setState: PropTypes.string,
  state: PropTypes.string,
  updateList: PropTypes.func,
  panelHeight: PropTypes.number,
  theBooking: PropTypes.object,
  showUpdate: PropTypes.bool,
  profile: PropTypes.object,
}

PanelInfo.defaultProps = {
  info: { default: true },
  spaces: {},
  setSpacesData: () => {},
  asignBookingToResource: () => {},
  setBookingSelected: () => {},
  filter: '',
  office: {},
  day: '',
  getPanelInfo: () => {},
  setState: '',
  state: 'selecting',
  updateList: () => {},
  panelHeight: 0,
  theBooking: {},
  showUpdate: false,
  profile: {},
}

export default PanelInfo
