import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import { useTranslation } from 'react-i18next'
import ModalDialog from 'components/molecules/modalDialog'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import PassInput from 'components/atoms/passInput'
import TextInput from 'components/atoms/textInput'
import { Container, ForgotButton } from './styled'
import validate from './validate'

const LoginFormView = ({ onSubmit }) => {
  const { t } = useTranslation()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validate),
  })

  const [modal, setModal] = useState(false)

  const handleClickForgot = () => {
    setModal(true)
  }

  return (
    <>
      <Container onSubmit={handleSubmit(onSubmit)}>
        <TextInput label={t('login.emailLabel')} name='email' control={control} error={errors?.email} />
        <PassInput label={t('login.passwordLabel')} name='password' control={control} error={errors?.password} />
        <ForgotButton onClick={handleClickForgot}>{t('login.forgot')}</ForgotButton>
        <Button variant='contained' color='secondary' type='submit'>
          {t('login.logIn')}
        </Button>
        <Button variant='contained' color='primary'>
          <Link to={routes.signUp} style={{ textDecoration: 'none', color: 'white' }}>
            {t('login.join')}
          </Link>
        </Button>
      </Container>
      <ModalDialog
        title='Recover password'
        subtitle='Working on that...'
        onClose={() => setModal(false)}
        open={modal}
        actions={[
          { id: 1, label: 'Confirm & add', onClick: () => setModal(false), color: 'primary' },
          { id: 2, label: 'Confirm & add more', onClick: () => setModal(false), color: 'secondary' },
        ]}
      >
        <div>Pending development</div>
      </ModalDialog>
    </>
  )
}

LoginFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
}

export default LoginFormView
