import React from 'react'
import PropTypes from 'prop-types'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { map } from 'lodash-es'
import { OutlineLabel } from './styled'

const SelectInputView = ({ value, onChange, label, error, options, className, ...props }) => {
  const handleChange = event => {
    onChange(event)
  }

  return (
    <FormControl variant='outlined' error={error} className={className}>
      <OutlineLabel>{label}</OutlineLabel>
      <Select value={value} onChange={event => handleChange(event)} {...props}>
        {map(options, item => (
          <MenuItem key={item.id} value={item.id}>
            {item.text}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  )
}

SelectInputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  options: PropTypes.array,
  className: PropTypes.string,
}

SelectInputView.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  error: null,
  options: [],
  className: '',
}

export default SelectInputView
