import React from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  Delta,
  DeltaStat,
  Magnitude,
  Name,
  StatArrowDown,
  StatArrowUp,
  Value,
  ValueContainer,
  ValueStat,
} from './styled'

const StatItemView = ({ main, delta, name, isProminent, className }) => (
  <Container className={className}>
    <ValueContainer>
      <Value isProminent={isProminent}>
        <ValueStat>
          {main.value} <Magnitude>{main.magnitude}</Magnitude>
        </ValueStat>
      </Value>
      <Delta isPositive={delta.value > 0}>
        <DeltaStat>
          {delta.value < 0 && <StatArrowDown />}
          {delta.value > 0 && <StatArrowUp />}
          {Math.abs(delta.value)} <Magnitude>{delta.magnitude}</Magnitude>
        </DeltaStat>
      </Delta>
    </ValueContainer>
    <Name>{name}</Name>
  </Container>
)

StatItemView.propTypes = {
  main: PropTypes.object,
  delta: PropTypes.object,
  name: PropTypes.string,
  isProminent: PropTypes.bool,
  className: PropTypes.string,
}

StatItemView.defaultProps = {
  main: null,
  delta: null,
  name: '',
  isProminent: false,
  className: '',
}

export default StatItemView
