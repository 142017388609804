import * as yup from 'yup'

const validate = yup.object().shape({
  // base_price: yup.string().required(),
  // start: yup.string().required(),
  // end: yup.string().required(),
  // free_days: yup.string().required(),
  // free_credits: yup.string().required(),
})

export default validate
