import React, { useEffect, useState } from 'react'
import { getResourcesForWorkArea, getSingleWA } from 'api'
import { useParams, useHistory } from 'react-router-dom'
import { CircularProgress, Grid, Button } from '@material-ui/core'
import SingleResourcesList from '../resources/singleResourcesList'
import { SpinnerContainer } from '../members/newListStyle'

function WorkAreaSingle() {
  const history = useHistory()
  const [waData, setWAData] = useState()
  const [resourcesData, setResourcesData] = useState()
  const params = useParams()
  const { slug } = params

  function getWAData() {
    getSingleWA(slug).then(response => setWAData(response))
  }

  function getResourcesData() {
    getResourcesForWorkArea(slug).then(r => {
      setResourcesData(r)
    })
  }

  useEffect(() => {
    if (waData === undefined) getWAData()
  }, [waData])

  useEffect(() => {
    if (resourcesData === undefined) getResourcesData()
  }, [resourcesData])

  function updateWorkArea() {
    history.push(`/space/work_areas/update/${waData.slug}`)
  }

  function formatWords(value) {
    let word = ''
    if (value === 'open_space') word = 'Open Space'
    if (value === 'office') word = 'Office'
    if (value === 'outodoor') word = 'Outdoor'
    if (value === 'hot_desk') word = 'Hot Desk'
    if (value === 'meeting_room') word = 'Meeting Room'
    if (value === 'active') word = 'Active'
    if (value === 'desk') word = 'Desk'
    if (value === 'inactive') word = 'Inactive'
    if (value === 'suite') word = 'Suite'
    return word
  }

  return (
    <Grid container>
      {waData !== undefined ? (
        <Grid name='main' container direction='column' spacing={3}>
          <Grid name='haeder' item>
            <Grid container direction='row' justify='space-between'>
              <Grid item>
                <h1>{waData.name} Details</h1>
              </Grid>
              <Grid item>
                <Button variant='contained' color='secondary' onClick={() => updateWorkArea()}>
                  Modify Work Area
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid name='waData' item>
            <Grid container direction='row' justify='space-around' spacing={4}>
              <Grid name='images' item xs={4}>
                <img src={waData.picture} style={{ width: '100%' }} alt={waData.name} />
              </Grid>
              <Grid item xs={8} name='info'>
                <Grid container direction='column' spacing={1}>
                  <Grid item>
                    <Grid container direction='row'>
                      <Grid item xs={6}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Name:{' '}
                          </Grid>
                          <Grid item>{waData.name}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Office:{' '}
                          </Grid>
                          <Grid item>{waData.office.name}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Floor:{' '}
                          </Grid>
                          <Grid item>{waData.floor.name}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction='row'>
                      <Grid item xs={11}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Description:{' '}
                          </Grid>
                          <Grid item>{waData.description === '' ? 'No description Data' : waData.description}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction='row'>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Type:{' '}
                          </Grid>
                          <Grid item>{formatWords(waData.area_type)}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Capacity:{' '}
                          </Grid>
                          <Grid item>{waData.size}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Status:{' '}
                          </Grid>
                          <Grid item>{waData.status}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        {waData.bookable ? 'Bookable' : 'Not Bookable'}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction='row'>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Price Day (item):{' '}
                          </Grid>
                          <Grid item>£{waData.item_price_day}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Price Day:{' '}
                          </Grid>
                          <Grid item>£{waData.full_price_day}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Price Month (item):{' '}
                          </Grid>
                          <Grid item>£{waData.item_price_month}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Price Month:{' '}
                          </Grid>
                          <Grid item>£{waData.full_price_month}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item>
                    <Grid container direction='row'>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Visible on App:{' '}
                          </Grid>
                          <Grid item>{waData.visible_app === true ? 'Visible' : 'No visible'}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Visible on web:{' '}
                          </Grid>
                          <Grid item>{waData.visible_web === true ? 'Visible' : 'No visible'}</Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={3}>
                        <Grid container direction='row' spacing={1} alignItems='center'>
                          <Grid item style={{ fontWeight: 700 }}>
                            Visible Space:{' '}
                          </Grid>
                          <Grid item>{waData.visible_space === true ? 'Visible' : 'No visible'}</Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <SingleResourcesList resourcesData={resourcesData !== undefined ? resourcesData : []} />
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' secondary />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

export default WorkAreaSingle
