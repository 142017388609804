import React, { useEffect, useState } from 'react'
import { Button, CircularProgress, TextField, Select } from '@material-ui/core'
import { Autocomplete, Pagination } from '@material-ui/lab'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { DatePicker } from '@material-ui/pickers'
import { getDashboardInvoices, newGetTeams, getDashboardOffices } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import { ScrollView } from 'devextreme-react'
import { CSVLink } from 'react-csv'
import formatWords from '../../atoms/formatWords/formatWords'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListSmallItem,
  ListSmallTitle,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from './invoicesStyles'
import SearchInput from '../../atoms/searchInput'
import { BoxItem, BoxItemTitle, BoxRow } from './scrollListStyles'

const now = new Date()
function InvoicesList({ layoutOffice }) {
  const [dataInvoices, setDataInvoices] = useState([])
  const [downloadInvoices, setDownloadInvoices] = useState([])
  const [downloadInvoicesFull, setDownloadInvoicesFull] = useState([])
  const [prepareDownload, setPrepareDownload] = useState(false)
  const [theFilter, setFilter] = useState({
    name: '',
    status: '',
    type: '',
    sent: 'all',
    team: '',
    order: '-create_date',
    layoutOffice: layoutOffice?.slug,
    office: layoutOffice?.slug,
    start: new Date(now.getFullYear(), now.getMonth(), 1).toISOString(),
    end: new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString(),
  })
  const [paginate, setPaginate] = useState(true)
  const [theTeams, setTeams] = useState()
  const [officesData, setOfficesData] = useState({ offices: [] })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: '15',
    thePage: '1',
    pagesTotal: '',
  })
  const slash = '-'
  const noLinkStyle = { textDecoration: 'none' }

  function formatCSV(rawDataInvoice) {
    const proccessedData = []
    rawDataInvoice.map(item => {
      proccessedData.push({
        invoice: item.name,
        concept: item.short_description,
        team: item.team?.name,
        status: item.status,
        date: new Date(item.date).toLocaleDateString('en-GB'),
        date_due: new Date(item.date_due).toLocaleDateString('en-GB'),
        date_sent: new Date(item.date_sent).toLocaleDateString('en-GB'),
        price_exc_vat: item.gross_price,
        price_inc_vat: item.net_price,
        vat: item.vat,
        type: item.type,
        url: item.url,
        office: item.office?.name,
        xero: item.xero_id ? 'ok' : '-',
      })
      return true
    })
    return proccessedData
  }

  function formatLinesCSV(rawDataInvoice) {
    const proccessedData = []
    rawDataInvoice.map(item => {
      proccessedData.push(item)
      return true
    })
    return proccessedData
  }

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.num_results <= info.page_size ? '1' : info.current_page,
      pagesTotal: info.num_pages,
    })
  }
  const space = ' '

  function getDataInvoices(filterData) {
    setDataInvoices([])
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getDashboardInvoices(query)
      .then(response => {
        setDataInvoices(response)
        getPagination(response)
        setPaginate(true)
        setDownloadInvoices([])
      })
      .catch(error => console.log(error))
  }

  function downloadDataInvoices(filterData) {
    console.log('Enering download Data invoices')
    setPrepareDownload(true)
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: 0,
        currentPage: 1,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    console.log('Preparing download data')
    getDashboardInvoices(query)
      .then(response => {
        console.log('Getting all the invoices for download', response.invoices)
        setDownloadInvoices(formatCSV(response.invoices))
      })
      .catch(error => console.log(error))
  }

  function downloadFullDataInvoices(filterData) {
    console.log('Enering download Data invoices')
    setPrepareDownload(true)
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: -1,
        currentPage: 1,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    console.log('Preparing download data full')
    getDashboardInvoices(query)
      .then(response => {
        console.log('Getting all the invoices full for download', response.invoices)
        setDownloadInvoicesFull(formatLinesCSV(response.invoices))
      })
      .catch(error => console.log(error))
  }

  function setDataFilter(filterData) {
    const letters = filterData.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: '1',
      filter: { ...theFilter, name: letters },
    }
    getDataInvoices(query)
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const nextPage = parseInt(pagination.thePage, 10) - 1
      page = nextPage.toString()
    }
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getDataInvoices(query)
    setPaginate(false)
  }

  function filterByStatus(event) {
    const statusValue = event.target.value
    if (statusValue !== 'none') {
      setFilter({ ...theFilter, status: statusValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, status: statusValue },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, status: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, status: '' },
      }
      getDataInvoices(query)
    }
  }

  function filterByType(event) {
    const typeValue = event.target.value
    if (typeValue !== 'none') {
      setFilter({ ...theFilter, type: typeValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, type: typeValue },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, type: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, type: '' },
      }
      getDataInvoices(query)
    }
  }

  function filterBySent(event) {
    const sentValue = event.target.value
    if (sentValue !== 'none') {
      setFilter({ ...theFilter, sent: sentValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, sent: sentValue },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, sent: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, sent: '' },
      }
      getDataInvoices(query)
    }
  }

  function filterByStart(date) {
    const startValue = new Date(date)
    console.log(startValue.toISOString())
    if (startValue !== '') {
      setFilter({ ...theFilter, start: startValue.toISOString() })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, start: startValue.toISOString() },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, start: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, start: '' },
      }
      getDataInvoices(query)
    }
  }

  function filterByEnd(date) {
    const endValue = new Date(date)
    console.log(endValue.toISOString())
    if (endValue !== '') {
      setFilter({ ...theFilter, end: endValue.toISOString() })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, end: endValue.toISOString() },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, end: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, end: '' },
      }
      getDataInvoices(query)
    }
  }

  function filterByOffice(event) {
    const typeValue = event
    if (typeValue !== 'all') {
      setFilter({ ...theFilter, office: typeValue })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, office: typeValue },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, office: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, office: '' },
      }
      getDataInvoices(query)
    }
  }

  function setTeamFilter(value) {
    let queryTeam = ''
    theTeams.teams.map(team => {
      if (value !== undefined && value !== '' && value === team.name) {
        queryTeam = team.slug
      }
      return ''
    })
    if (queryTeam !== undefined && queryTeam !== '') {
      setFilter({ ...theFilter, team: queryTeam })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: queryTeam },
      }
      getDataInvoices(query)
    } else {
      setFilter({ ...theFilter, team: '' })
      const query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: { ...theFilter, team: '' },
      }
      getDataInvoices(query)
    }
  }

  function getDataTeams(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: value === undefined ? '' : value,
        layoutOffice: layoutOffice.slug,
      },
    }
    newGetTeams(query)
      .then(response => setTeams(response))
      .catch(error => console.log(error))
  }

  function getOfficesData() {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {},
    }
    getDashboardOffices(query).then(r => setOfficesData(r))
  }

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = dataInvoices?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter, order: theOrder },
    }
    getDataInvoices(query)
  }

  useEffect(() => {}, [pagination, theFilter])

  useEffect(() => {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: { ...theFilter },
    }
    getDataInvoices(query)
    getOfficesData()
  }, [])

  useEffect(() => {
    if (theTeams === undefined) getDataTeams()
  }, [theTeams])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getDataInvoices(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  useEffect(() => {
    console.log('Prepare download set to ', prepareDownload)
  }, [prepareDownload])

  useEffect(() => {
    console.log('Download invoices changed ')
    if (downloadInvoices.length > 0) setPrepareDownload(false)
  }, [downloadInvoices])

  useEffect(() => {
    console.log('Download full invoices changed ')
    if (downloadInvoicesFull.length > 0) setPrepareDownload(false)
  }, [downloadInvoicesFull])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // CALL TIMEOUTS
  const [searchTeam, setSearchTeam] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getDataTeams(searchTeam.team)
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  const [searchFilter, setSearchFilter] = useState('')
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchFilter !== undefined && searchFilter !== '') {
        if (searchFilter.filter) {
          setDataFilter(searchFilter.filter)
        }
      }
    }, 1000)
    return () => clearTimeout(delayDebounceFn)
  }, [searchFilter])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px', marginBottom: '20px' }}>
        <FormControl variant='outlined' style={{ width: '29%', marginRight: '1%' }}>
          <SearchInput
            placeholder='Search by text...'
            onChange={e => setSearchFilter({ ...searchFilter, filter: e.target.value })}
          />
        </FormControl>
        <FormControl variant='outlined' style={{ width: '19%', marginRight: '1%' }}>
          <InputLabel id='filter-label'>Status</InputLabel>
          <Select onChange={event => filterByStatus(event)} label='Status filter'>
            <MenuItem value='none'>None</MenuItem>
            <MenuItem value='open'>Draft</MenuItem>
            <MenuItem value='pending_payment'>Pending Payment</MenuItem>
            <MenuItem value='paid'>Paid</MenuItem>
            <MenuItem value='on_review'>On Review</MenuItem>
            <MenuItem value='void'>Void</MenuItem>
            <MenuItem value='no_date'>No date</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <InputLabel id='filter-label'>Type Filter</InputLabel>
          <Select onChange={event => filterByType(event)} label='Type filter'>
            <MenuItem value='none'>None</MenuItem>
            <MenuItem value='work'>Work</MenuItem>
            <MenuItem value='bar'>Bar</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <InputLabel id='filter-label'>Email sent</InputLabel>
          <Select onChange={event => filterBySent(event)} label='Email sent filter'>
            <MenuItem value='all' selected>
              All
            </MenuItem>
            <MenuItem value='yes'>Yes</MenuItem>
            <MenuItem value='no'>No</MenuItem>
          </Select>
        </FormControl>
        <FormControl style={{ width: '19%', marginRight: '1%' }}>
          <Autocomplete
            name='office'
            options={officesData !== undefined ? officesData.offices : [{ name: 'No data' }]}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Office' variant='outlined' />}
            onChange={(e, data) => {
              if (data?.slug) {
                filterByOffice(data?.slug)
              } else {
                filterByOffice('all')
              }
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
            defaultValue={layoutOffice}
          />
        </FormControl>
      </BoxRow>
      <BoxRow style={{ marginTop: 50 }}>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <DatePicker
            value={theFilter?.start}
            onChange={value => {
              if (value !== undefined) {
                filterByStart(value)
              }
            }}
            name='start'
            variant='inline'
            inputVariant='outlined'
            helperText={null}
            format='dd-MM-yyyy'
            label='From'
            autoOk
          />
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <DatePicker
            value={theFilter?.end}
            onChange={value => {
              if (value !== undefined) {
                filterByEnd(value)
              }
            }}
            name='to'
            variant='inline'
            inputVariant='outlined'
            helperText={null}
            format='dd-MM-yyyy'
            label='To'
            autoOk
          />
        </FormControl>
        <FormControl style={{ width: '24%', marginRight: '1%' }}>
          <Autocomplete
            id='autocomplete-teams-filter'
            options={theTeams?.teams}
            getOptionLabel={option => option.name}
            renderInput={params => <TextField {...params} label='Team Filter' variant='outlined' />}
            onInputChange={(e, value) => {
              setSearchTeam({
                team: value,
                filter: e.target.innerText,
              })
            }}
            loading
            loadingText='Searching...'
            noOptionsText='No Results'
          />
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <TextField
            type='text'
            label='Total Exc VAT'
            value={dataInvoices.sum_gross ? `£${dataInvoices.sum_gross}` : '-'}
            variant='outlined'
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <TextField
            type='text'
            label='Total Inc VAT'
            value={dataInvoices.sum_net ? `£${dataInvoices.sum_net}` : '-'}
            variant='outlined'
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
        <FormControl variant='outlined' style={{ width: '14%', marginRight: '1%' }}>
          <TextField
            type='text'
            label='VAT'
            value={dataInvoices.sum_vat ? `£${dataInvoices.sum_vat}` : '-'}
            variant='outlined'
            disabled
            InputLabelProps={{ shrink: true }}
          />
        </FormControl>
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('name')}>
                    <strong>Invoice</strong>
                  </Button>
                  {dataInvoices?.order === 'name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {dataInvoices?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <Button onClick={() => setOrderData('net_price')}>
                    <strong>Amount</strong>
                  </Button>
                  {dataInvoices?.order === 'net_price' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('team__name')}>
                    <strong>Team</strong>
                  </Button>
                  {dataInvoices?.order === 'team__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('office__name')}>
                    <strong>Office</strong>
                  </Button>
                  {dataInvoices?.order === 'office__name' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('create_date')}>
                    <strong>Date</strong>
                  </Button>
                  {dataInvoices?.order === 'create_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_due')}>
                    <strong>Due Date</strong>
                  </Button>
                  {dataInvoices?.order === 'date_due' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date_sent')}>
                    <strong>Sent Date</strong>
                  </Button>
                  {dataInvoices?.order === 'date_sent' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('create_date')}>
                    <strong>Created on</strong>
                  </Button>
                  {dataInvoices?.order === 'create_date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListSmallTitle>
                  <ListTitle>
                    <Button onClick={() => setOrderData('lines')}>
                      <strong>Lines</strong>
                    </Button>
                    {dataInvoices?.order === 'lines' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListTitle>
                </ListSmallTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('xero')}>
                    <strong>Xero</strong>
                  </Button>
                  {dataInvoices?.order === 'xero' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {dataInvoices && dataInvoices?.length === 0 ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            dataInvoices.invoices?.map((invoice, index) => {
              const dateStart = invoice.date ? new Date(invoice.date?.split('T')[0]).toLocaleDateString('en-GB') : ['-']
              const createdDate = new Date(invoice.create_date?.split('T')[0]).toLocaleDateString('en-GB')
              const dueDate = invoice.date_due
                ? new Date(invoice.date_due?.split('T')[0]).toLocaleDateString('en-GB')
                : ['-']
              const sentDate = invoice.date_sent
                ? new Date(invoice.date_sent?.split('T')[0]).toLocaleDateString('en-GB')
                : ['-']
              return (
                <Link to={`/members/invoices/${invoice.slug}`} key={invoice.slug} style={noLinkStyle}>
                  <ListRow key={index}>
                    <BoxRow>
                      <BoxItem>
                        <ListItem>{invoice.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{formatWords(invoice.status)}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.net_price}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.team?.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{invoice.office?.name}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dateStart}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{dueDate}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{sentDate}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListItem>{createdDate}</ListItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.invoice_lines.length}</ListSmallItem>
                      </BoxItem>
                      <BoxItem>
                        <ListSmallItem>{invoice.xero_id ? 'ok' : '-'}</ListSmallItem>
                      </BoxItem>
                    </BoxRow>
                  </ListRow>
                </Link>
              )
            })
          )}
        </ListContainer>
      </ScrollView>
      {pagination.thePage === undefined || paginate === false ? (
        <PaginationContainer>
          <CircularProgress color='secondary' />
        </PaginationContainer>
      ) : (
        <PaginationContainer>
          <Pagination
            color='secondary'
            size='small'
            onClick={value => setPagePagination(value)}
            count={pagination.resultsTotal < pagination.resultsByPage ? '1' : pagination.pagesTotal}
            page={parseInt(pagination.thePage, 10)}
          />
          <PaginationNumbers>
            {pagination.resultsByPage * pagination.thePage -
              pagination.resultsByPage +
              slash +
              (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                ? pagination.resultsByPage * pagination.thePage
                : pagination.resultsTotal) +
              space}
            of {pagination.resultsTotal}
          </PaginationNumbers>
          {!prepareDownload && downloadInvoices.length === 0 && (
            <Button onClick={() => downloadDataInvoices()}>Export CSV</Button>
          )}
          {!prepareDownload && downloadInvoicesFull.length === 0 && (
            <Button onClick={() => downloadFullDataInvoices()}>Export Lines CSV</Button>
          )}
          {prepareDownload && <CircularProgress color='secondary' />}
          {!prepareDownload && downloadInvoices.length > 0 && <CSVLink data={downloadInvoices}>Download CSV</CSVLink>}
          {!prepareDownload && downloadInvoicesFull.length > 0 && (
            <CSVLink data={downloadInvoicesFull}>Download CSV</CSVLink>
          )}
        </PaginationContainer>
      )}
    </ListContainer>
  )
}

InvoicesList.propTypes = {
  layoutOffice: PropTypes.object,
}

InvoicesList.defaultProps = {
  layoutOffice: null,
}

export default InvoicesList
