import React, { useCallback, useEffect, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import { getOffersUsers } from 'api'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import PropTypes from 'prop-types'
import { ScrollView } from 'devextreme-react'
import { Link } from 'react-router-dom'
import debounce from 'lodash/debounce'
import {
  ListContainer,
  ListHeader,
  ListItem,
  ListRow,
  ListTitle,
  PaginationContainer,
  PaginationNumbers,
  SpinnerContainer,
} from './offerUserListStyle'
import SearchInput from '../../atoms/searchInput'
import { BoxItemTitle, BoxRow, BoxItem } from '../invoices/scrollListStyles'

function OfferUserList(props) {
  const { layoutOffice } = props
  const [offersUsers, setOffersUsers] = useState()
  const [theFilter, setFilter] = useState({
    name: '',
    order: '',
    layoutOffice: layoutOffice?.slug,
  })
  const [pagination, setPagination] = useState({
    resultsTotal: ' ... ',
    resultsByPage: 10,
    thePage: 1,
    pagesTotal: '',
  })
  const noLinkStyle = { textDecoration: 'none' }
  const slash = '-'
  const space = ' '

  function getPagination(info) {
    setPagination({
      resultsTotal: info.num_results,
      resultsByPage: info.page_size,
      thePage: info.current_page,
      pagesTotal: info.num_pages,
    })
  }

  function getOffersUserData(filterData) {
    let query = {}
    if (filterData === undefined) {
      query = {
        pageSize: pagination.resultsByPage,
        currentPage: pagination.thePage,
        filter: theFilter,
      }
    } else {
      query = filterData
    }
    getOffersUsers(query)
      .then(info => {
        setOffersUsers(info)
        getPagination(info)
      })
      .catch(error => console.log(error))
  }

  function setPagePagination(pageInfo) {
    let page = pageInfo?.target?.innerText
    if (pageInfo.target.parentElement.ariaLabel === 'Go to next page') {
      const nextPage = parseInt(pagination.thePage, 10) + 1
      page = nextPage.toString()
    }
    if (pageInfo.target.parentElement.ariaLabel === 'Go to previous page') {
      const prevPage = parseInt(pagination.thePage, 10) - 1
      page = prevPage.toString()
    }

    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: page,
      filter: theFilter,
    }
    getOffersUserData(query)
  }

  function setDataFilter(textFilter) {
    const letters = textFilter.replace(/\s/g, '')
    setFilter({ ...theFilter, name: letters })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, name: letters },
    }
    getOffersUserData(query)
  }

  const delayedQuery = useCallback(
    debounce(q => setDataFilter(q), 300),
    []
  )

  function setOrderData(value) {
    const formatValue = value.toLowerCase()
    const minus = '-'
    const theOrder = offersUsers?.order === theFilter?.order?.replace('-', '') ? minus + formatValue : formatValue
    setFilter({ ...theFilter, order: theOrder })
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: pagination.thePage,
      filter: {
        ...theFilter,
        order: theOrder,
      },
    }
    getOffersUserData(query)
  }

  useEffect(() => {}, [theFilter, pagination])

  useEffect(() => {
    if (offersUsers === undefined) getOffersUserData()
  }, [offersUsers])

  function setOfficeFilter() {
    const query = {
      pageSize: pagination.resultsByPage,
      currentPage: 1,
      filter: { ...theFilter, layoutOffice: layoutOffice.slug },
    }
    getOffersUserData(query)
  }

  useEffect(() => {
    setOfficeFilter()
  }, [layoutOffice])

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return (
    <ListContainer>
      <BoxRow style={{ marginTop: '25px' }}>
        <SearchInput placeholder='Search by name ...' onChange={value => delayedQuery(value.target.value)} />
      </BoxRow>
      <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
        <ListContainer>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('user')}>
                    <strong>User</strong>
                  </Button>
                  {offersUsers?.order === 'user' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('offer')}>
                    <strong>Offer name</strong>
                  </Button>
                  {offersUsers?.order === 'offer' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('offer')}>
                    <strong>Offer code</strong>
                  </Button>
                  {offersUsers?.order === 'offer' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('date')}>
                    <strong>Date</strong>
                  </Button>
                  {offersUsers?.order === 'date' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
              <BoxItemTitle>
                <ListTitle>
                  <Button onClick={() => setOrderData('status')}>
                    <strong>Status</strong>
                  </Button>
                  {offersUsers?.order === 'status' ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListTitle>
              </BoxItemTitle>
            </BoxRow>
          </ListHeader>
          {offersUsers === undefined ? (
            <SpinnerContainer>
              <CircularProgress color='secondary' />
            </SpinnerContainer>
          ) : (
            offersUsers.offer_users.map(offer => (
              <Link
                to={{ pathname: `/marketing/offer_user/${offer.slug}`, state: offer }}
                key={offer.slug}
                style={noLinkStyle}
              >
                <ListRow>
                  <BoxRow>
                    <BoxItem>
                      <ListItem>{`${offer.user.first_name}  ${offer.user.last_name}`}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.offer.title}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.offer.code}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.date ? `${offer.date?.split('.')[0].replace('T', ' ')}` : '-'}</ListItem>
                    </BoxItem>
                    <BoxItem>
                      <ListItem>{offer.status}</ListItem>
                    </BoxItem>
                  </BoxRow>
                </ListRow>
              </Link>
            ))
          )}
          <PaginationContainer>
            <Pagination
              color='secondary'
              size='small'
              onClick={value => setPagePagination(value)}
              count={pagination.pagesTotal}
              page={parseInt(pagination.thePage, 10)}
            />
            <PaginationNumbers>
              {pagination.resultsByPage * pagination.thePage -
                pagination.resultsByPage +
                slash +
                (pagination.resultsByPage * pagination.thePage < pagination.resultsTotal
                  ? pagination.resultsByPage * pagination.thePage
                  : pagination.resultsTotal) +
                space}
              of {pagination.resultsTotal}
            </PaginationNumbers>
          </PaginationContainer>
        </ListContainer>
      </ScrollView>
    </ListContainer>
  )
}

OfferUserList.propTypes = {
  layoutOffice: PropTypes.object,
}

OfferUserList.defaultProps = {
  layoutOffice: null,
}

export default OfferUserList
