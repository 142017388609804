import React from 'react'
import { Controller } from 'react-hook-form'
import PropTypes from 'prop-types'
import Container from './container'

const PassInput = ({ control, rules, name, ...props }) => (
  <Controller
    render={({ value, onChange, field }) => <Container onChange={onChange} value={value} {...props} {...field} />}
    name={name}
    control={control}
    rules={rules}
  />
)

PassInput.propTypes = {
  control: PropTypes.object.isRequired,
  rules: PropTypes.object,
  name: PropTypes.string.isRequired,
}

PassInput.defaultProps = {
  rules: {},
}

export default PassInput
