import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import { useTranslation } from 'react-i18next'
import FolderSharedOutlinedIcon from '@material-ui/icons/FolderSharedOutlined'
import PeopleOutlineOutlinedIcon from '@material-ui/icons/PeopleOutlineOutlined'
import MeetingRoomOutlinedIcon from '@material-ui/icons/MeetingRoomOutlined'
import TodayOutlinedIcon from '@material-ui/icons/TodayOutlined'
import FreeBreakfastOutlinedIcon from '@material-ui/icons/FreeBreakfastOutlined'
import LocalOfferIcon from '@material-ui/icons/LocalOfferOutlined'
import routes from 'config/routes'
import DrawerView from './view'

const Drawer = ({ ...props }) => {
  const { t } = useTranslation()
  const menuSections = [
    { id: 1, label: t('drawer.sections.dashboard'), Icon: HomeIcon, to: routes.dashboard },
    { id: 2, label: t('drawer.sections.members'), Icon: FolderSharedOutlinedIcon, to: routes.members },
    { id: 3, label: t('drawer.sections.community'), Icon: PeopleOutlineOutlinedIcon, to: routes.community },
    { id: 4, label: t('drawer.sections.space'), Icon: MeetingRoomOutlinedIcon, to: routes.space },
    { id: 5, label: t('drawer.sections.bookings'), Icon: TodayOutlinedIcon, to: routes.bookings },
    { id: 6, label: t('drawer.sections.foodAndDrinks'), Icon: FreeBreakfastOutlinedIcon, to: routes.foodAndDrinks },
    { id: 7, label: t('drawer.sections.marketing'), Icon: LocalOfferIcon, to: routes.marketing },
  ]

  return <DrawerView {...props} options={menuSections} />
}

export default Drawer
