import { useEffect } from 'react'
import PropTypes from 'prop-types'
import Schedule from 'components/molecules/schedule/schedule'
import { CircularProgress, Grid } from '@material-ui/core'
import { SpinnerContainer } from '../members/newListStyle'

function CreateBookingMeeting({ office, activeClient, role, profile }) {
  useEffect(() => {}, [office, activeClient, role, profile])
  console.log('CReate booking Meetong', activeClient, role)
  return (
    <Grid container>
      {office !== undefined && activeClient !== undefined && role !== undefined ? (
        <Grid item>
          {' '}
          <Schedule office={office} activeClient={activeClient} role={role} profile={profile} />
        </Grid>
      ) : (
        <SpinnerContainer>
          <CircularProgress color='secondary' />
        </SpinnerContainer>
      )}
    </Grid>
  )
}

CreateBookingMeeting.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
  role: PropTypes.string,
  profile: PropTypes.object,
}

CreateBookingMeeting.defaultProps = {
  office: {},
  activeClient: {},
  role: '',
  profile: {},
}

export default CreateBookingMeeting
