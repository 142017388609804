import { FormControl, TextField, Button, Grid, Checkbox, Modal, Drawer, CircularProgress } from '@material-ui/core'
import { useEffect, useState, useRef } from 'react'
import { useSnackbar } from 'notistack'
import { PropTypes } from 'prop-types'
import { useTranslation } from 'react-i18next'
import { DatePicker } from '@material-ui/pickers'
import MapHeadItem from 'components/atoms/mapHeadItem'
import { MapContainer } from 'components/pages/resourcesAdd/formStyle'
import ScheduleSingle from 'components/molecules/schedule/scheduleSingle'
import { Autocomplete } from '@material-ui/lab'
import RebootIcon from '@material-ui/icons/Autorenew'
import { colors } from 'config/colors'
import {
  calculateBookingPrice,
  getFloorResources,
  getMembersByTeam,
  getSpaces,
  newGetTeams,
  setBooking,
  updateBooking,
  getClientsReferral,
  getAvailableBookings,
  getWAbyFloor,
  getDoors,
  remoteOpenDoor,
} from 'api'
import FloorMap from 'components/molecules/floorMap/floorMap'
import { SmallButton, SpinnerContainer } from '../members/newListStyle'
import PanelInfo from './PanelInfoDer'
import { ModalCard } from './modalStyles'
import ModalUpdateSpaces from './modalUpdateSpaces'

function CreateBookingDesk({ office, activeClient, role, profile }) {
  const { enqueueSnackbar } = useSnackbar()
  const [imMember] = useState(role === 'team_admin' || role === 'team_member')
  const [imOfficeManager] = useState(role === 'office_manager')
  const [teamsData, setTeamsData] = useState()
  const [theBooking, setBookingData] = useState({
    start: new Date(),
    team: imMember ? profile.team : '',
    client: '',
  })
  const [spaces, setSpacesData] = useState(null)
  const [selectedDesk, setSelectedData] = useState([])
  const [resourcesData, setResourcesData] = useState()
  const [numberHotDesks, setNumberHotDesk] = useState(0)
  const [workAreasData, setWorkAreasData] = useState()
  const [updateSpaces, setUpdateSpaces] = useState(false)
  const [updateMap, setUpdateMap] = useState(false)

  const [membersRows, setMembersRows] = useState([])
  const [guestsRows, setGuestsRows] = useState()
  const [prepaidsRows, setPrepaidsRows] = useState()
  const [priceInfo, setPrice] = useState()
  const [totalPrice, setTotalPrice] = useState(0)

  const [finalBookings, setFinal] = useState([])

  const [infoPanel, setPanel] = useState()
  const [showUpdate, setShowUpdate] = useState('')

  const [dragResource, setDragResource] = useState(null)
  const [modalBooking, setModalBooking] = useState(false)

  const [bookingSelected, setBookingSelected] = useState(null)
  const [floorMap, setFloorMap] = useState({})
  const [availableBookings, setAvailableBookings] = useState(0)

  const [state, setState] = useState('selecting')

  const [theClients, setClients] = useState()
  const [theTeams, setTeams] = useState()
  const [doors, setDoors] = useState([])

  const [emptySearch] = useState([{ name: 'Type something' }])
  const [searchClient, setSearchClient] = useState('')
  const [searchTeam, setSearchTeam] = useState('')
  const [mapDimension, setMapDimensions] = useState({
    height: 600,
    width: 600,
    menu: 0,
    add: 0,
  })

  const mapComponent = useRef()
  const mapHeader = useRef()
  const staffUtilsHeader = useRef()
  const addUtilsHeader = useRef()

  const hardcodedMargin = 150
  const { t } = useTranslation()

  useEffect(() => {}, [state])

  function getResourcesData() {
    const query = {
      filter: {
        floor:
          theBooking?.floor === undefined || theBooking?.floor === '' ? office?.floors[0]?.slug : theBooking?.floor,
      },
    }
    getFloorResources(query).then(response => {
      setResourcesData(response)
    })
  }

  function getWorkAreasData() {
    const floorSlug =
      theBooking?.floor === undefined || theBooking?.floor === '' ? office?.floors[0]?.slug : theBooking?.floor
    console.log('Floor slug ', floorSlug)
    getWAbyFloor(floorSlug).then(response => {
      console.log('work areas response', response)
      setWorkAreasData(response)
    })
  }

  function getTeamData(info) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        name: info !== undefined ? info : '',
        order: 'name',
        layoutOffice: office.slug,
      },
    }
    newGetTeams(query).then(response => setTeamsData(response))
  }

  function formatDates(date, type) {
    if (date !== undefined && date !== null && date !== '') {
      const dateSplit = date.toString().split(' ')
      let time = 'T18:00:00'
      if (type === 'start') time = 'T09:00:00'
      if (type === 'start_day') time = 'T00:00:00'
      if (type === 'end_day') time = 'T23:59:59'
      const slash = '-'
      if (dateSplit[1] === 'Jan') dateSplit[1] = '01'
      if (dateSplit[1] === 'Feb') dateSplit[1] = '02'
      if (dateSplit[1] === 'Mar') dateSplit[1] = '03'
      if (dateSplit[1] === 'Apr') dateSplit[1] = '04'
      if (dateSplit[1] === 'May') dateSplit[1] = '05'
      if (dateSplit[1] === 'Jun') dateSplit[1] = '06'
      if (dateSplit[1] === 'Jul') dateSplit[1] = '07'
      if (dateSplit[1] === 'Aug') dateSplit[1] = '08'
      if (dateSplit[1] === 'Sep') dateSplit[1] = '09'
      if (dateSplit[1] === 'Oct') dateSplit[1] = '10'
      if (dateSplit[1] === 'Nov') dateSplit[1] = '11'
      if (dateSplit[1] === 'Dec') dateSplit[1] = '12'
      const dateFormatted = `${dateSplit[3] + slash + dateSplit[1] + slash + dateSplit[2] + time}`
      return dateFormatted
    }
    return ''
  }

  function getFreeBookigs() {
    getAvailableBookings().then(r => {
      setAvailableBookings(r ? r.length : 0)
      console.log('Number of free bookings ', r)
    })
  }

  function getDataSpaces(data) {
    console.log('Data looking fos spaces', data)
    console.log('Date start', theBooking)
    const queryClient = theBooking?.client?.slug
    if (office?.floors === undefined) return
    const query = {
      start:
        data?.start === undefined ? formatDates(theBooking?.start, 'start_day') : formatDates(data?.start, 'start_day'),
      end: data?.start === undefined ? formatDates(theBooking?.start, 'end_day') : formatDates(data?.start, 'end_day'),
      office: office.slug,
      bookingType: 'hot_desk,suite',
      team: data?.team === undefined ? theBooking?.team?.slug : data?.team?.slug,
      client: data?.client === undefined ? queryClient : data?.client?.slug,
      floor: data?.floor === undefined || data?.floor === '' ? office?.floors[0]?.slug : data?.floor,
    }
    getSpaces(query).then(r => {
      console.log('duite bookings', r?.bookings?.filter(item => item.booking_type === 'suite').length)
      setSpacesData(r)
      console.log('Space info', r)
      setNumberHotDesk(r?.bookings?.filter(item => item.booking_type === 'hot_desk').length)
      setModalBooking(false)
      setShowUpdate('')
      if (imMember) getFreeBookigs()
    })
  }

  function formatResRows(slugsArr) {
    if (slugsArr !== undefined && resourcesData !== undefined) {
      const resourcesArr = slugsArr
      const finalArr = []
      resourcesData.resources?.map(res => {
        resourcesArr.map(slug => {
          if (slug === res.slug) finalArr.push(res)
          return null
        })
        return null
      })
      return finalArr
    }
    return null
  }

  function createBookingsArr(arr) {
    const newBooks = []
    arr.map((b, i) => {
      let bookingPrice = 0
      if (priceInfo && Object.keys(priceInfo).length > i && !finalBookings[i]?.pre_paid) {
        console.log(priceInfo)
        bookingPrice = priceInfo.pending_payment[i]
      }
      const book = {
        id: i,
        start: { dateTime: formatDates(theBooking?.start !== undefined ? theBooking.start : new Date(), 'start') },
        end: { dateTime: formatDates(theBooking?.start !== undefined ? theBooking.start : new Date()) },
        office: office.slug,
        price: bookingPrice,
        payNow: false,
        booking_type: 'hot_desk',
        member: finalBookings[i]?.member || '',
        resource: b,
        floor: finalBookings[i]?.floor || '',
        team: finalBookings[i]?.team?.slug || theBooking?.team?.slug,
        client: finalBookings[i]?.client?.slug || theBooking?.client?.slug,
        work_area: '',
        pre_paid: finalBookings[i]?.pre_paid || '',
        paid: finalBookings[i]?.paid || false,
        complimentary: finalBookings[i]?.complimentary || false,
        guest: finalBookings[i]?.guest || '',
      }
      newBooks.push(book)
      return ''
    })
    console.log(newBooks)
    setFinal(newBooks)
  }

  function asignResToBooking(resSlug) {
    console.log('se asigna el res al booking ', resSlug)
    if (bookingSelected !== undefined) {
      setDragResource(resSlug)
    }
  }
  function asignBookingToResource() {
    console.log('Assigning but doing notghins')
  }

  function getSelectedDesk(data, type) {
    console.log('get seleceted desk')
    const arr = selectedDesk
    if (data !== undefined && type === 'drop' && arr.length < 1) {
      arr.push(data)
      return ''
    }
    if (data !== undefined && type === true) arr.push(data)
    if (data !== undefined && type === false) {
      arr.map((slug, i) => {
        if (data === slug) arr.splice(i, 1)
        return ''
      })
    }
    const newArr = []
    arr.map(slug => {
      newArr.push(slug)
      return ''
    })
    const filterArr = newArr.filter((item, index) => newArr.indexOf(item) === index)
    if (state === 'selecting') setSelectedData(filterArr)
    createBookingsArr(filterArr)
    formatResRows()
    return filterArr
  }

  function getMembersData(slug) {
    getMembersByTeam(slug).then(r => setMembersRows(r))
  }

  function createDataBookings(index, data, field) {
    const bookings = finalBookings
    if (field === 'member') {
      bookings[index].member = data
      let members = []
      membersRows.map(m => {
        if (m.slug === data) {
          members = membersRows.filter(mem => !(mem.slug === m.slug && mem.role !== 'referral'))
          setMembersRows(members)
        }
        return setMembersRows(members)
      })
    }
    if (field === 'pre_paid') {
      bookings[index].pre_paid = data
      let prepaidsArr = []
      prepaidsRows.map(pre => {
        if (pre.code === data) {
          prepaidsArr = prepaidsRows.filter(p => p.code !== pre.code)
          setPrepaidsRows(prepaidsArr)
        }
        return ''
      })
    }
    if (field === 'guest') {
      bookings[index].guest = data
      let guestsArr = []
      guestsRows?.map(g => {
        if (g.slug === data) {
          guestsArr = guestsRows?.filter(p => p.slug !== g.slug)
          setGuestsRows(guestsArr)
        }
        return ''
      })
    }
    if (field === 'already_paid') bookings[index].paid = data
    if (field === 'complimentary') bookings[index].complimentary = data
    if (bookings[index].team === undefined || bookings[index].team === '') bookings[index].team = theBooking.team
    setFinal(bookings)
  }

  function sendBooking() {
    console.log(finalBookings)
    setBooking(finalBookings).then(() => {
      setSelectedData([])
      setSpacesData(null)
    })
  }

  function getPanelInfo(data, type) {
    console.log('get panel info pedro ', data, type, state)
    let bookingInfo = { default: true }
    if (data !== undefined && type === 'resourceInfo') {
      spaces.bookings.map(booking => {
        if (booking.resource?.slug === data) {
          bookingInfo = booking
        }
        return null
      })
      setPanel(bookingInfo)
    }
    if (data !== undefined && type === 'bookingInfo' && state !== 'assigning' && state !== 'booked') {
      spaces.bookings.map(booking => {
        if (booking.code === data) {
          bookingInfo = booking
        }
        return null
      })
      setPanel(bookingInfo)
    }
  }

  function updateBookingRes() {
    setModalBooking(true)
    updateBooking(bookingSelected, { resource: dragResource }).then(r => {
      if (r.status === 200) {
        setPanel({ default: true })
        setState('selecting')
        setSelectedData([])
        getResourcesData()
        getWorkAreasData()
        setBookingSelected(null)
        setDragResource(null)
      } else {
        setModalBooking(false)
      }
    })
  }

  function checkFields() {
    if (teamsData === undefined) getTeamData()
    if (spaces === undefined) getDataSpaces()
  }

  function getPrices() {
    if (theBooking?.team !== undefined && theBooking?.team !== '') {
      const infoPrices = {
        team: theBooking?.team !== undefined ? theBooking.team?.slug : '',
        office: office?.slug !== undefined ? office.slug : '',
        bookingType: 'hot_desk',
        bookingSize: selectedDesk?.length !== undefined ? selectedDesk.length : '',
      }
      calculateBookingPrice(infoPrices).then(r => {
        console.log(r)
        if (imMember) {
          setTotalPrice(r.pre_paid_price)
        } else {
          setTotalPrice(r.full_price)
        }
        setPrice(r)
        setPrepaidsRows(r?.selected_prepaids)
      })
    }
  }

  function compareDates() {
    let response = ''
    const today = new Date()
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    if (formatDates(new Date(), 'start') === formatDates(theBooking?.start, 'start')) response = 'today'
    if (formatDates(tomorrow, 'start') === formatDates(theBooking?.start, 'start')) response = 'tomorrow'
    return response
  }

  function getFloor() {
    let theFloor = office.floors[0]
    if (theBooking?.floor !== undefined) {
      office.floors.map(f => {
        if (f.slug === theBooking.floor) theFloor = f
        return ''
      })
    }
    if (theFloor !== undefined) setFloorMap(JSON.parse(theFloor.floor_map))
  }

  function getClientsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        layoutOffice: office.slug,
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    getClientsReferral(query).then(r => {
      setClients(r)
    })
  }

  function getTeamsData(data) {
    const query = {
      currentPage: 1,
      pageSize: 100,
      filter: {
        office: office.slug,
        name: data !== undefined ? data : '',
      },
    }
    newGetTeams(query).then(r => {
      //getMembersData(firstTeam?.slug)
      setTeams(r)
    })
  }

  function changeWindowSize() {
    const mapHeatherHeight = mapHeader.current ? mapHeader.current?.clientHeight : 0
    const mapCurrentWidth = mapComponent.current ? mapComponent.current.clientWidth : 0
    const staffUtilsHeaderHeight = staffUtilsHeader.current ? staffUtilsHeader.current.clientHeight : 0
    const addUtilsHeaderHeight = addUtilsHeader?.current ? addUtilsHeader?.current?.clientHeight : 0
    const calculatedHeight = window.innerHeight - mapHeatherHeight - hardcodedMargin
    console.log(window.innerHeight, mapHeatherHeight, hardcodedMargin)
    setMapDimensions({
      height: calculatedHeight,
      width: mapCurrentWidth,
      menu: staffUtilsHeaderHeight,
      add: addUtilsHeaderHeight,
    })
    console.log({
      height: calculatedHeight,
      width: mapCurrentWidth,
      menu: staffUtilsHeaderHeight,
      add: addUtilsHeaderHeight,
    })
  }

  function getOfficeDoors() {
    getDoors().then(r => {
      console.log(r)
      setDoors(r)
    })
  }

  function openDoor(reboot) {
    const floorSlug =
      theBooking?.floor === undefined || theBooking?.floor === '' ? office?.floors[0]?.slug : theBooking?.floor
    console.log(doors, floorSlug)
    const selectedDoors = doors.filter(item => item?.floor?.slug === floorSlug)
    if (selectedDoors.length === 0) {
      return
    }
    const data = {
      door: selectedDoors[0]?.code,
      reboot,
    }
    remoteOpenDoor(data)
      .then(response => {
        if (response?.status === 201) {
          enqueueSnackbar('Door opening success', {
            variant: 'success',
          })
        } else {
          enqueueSnackbar('Door opening error', {
            variant: 'error',
          })
        }
      })
      .catch(error => {
        console.log(error)
        enqueueSnackbar('Door opening error', {
          variant: 'error',
        })
      })
  }

  // Use effects
  useEffect(() => {
    getClientsData()
    changeWindowSize()
  }, [])

  useEffect(() => {
    if (role === 'team_admin') {
      getMembersData(profile.team?.slug)
    }
  }, [imMember])

  useEffect(() => {
    getResourcesData()
    getWorkAreasData()
  }, [theBooking?.floor])

  useEffect(() => {
    if (bookingSelected !== null && dragResource !== null) updateBookingRes()
  }, [bookingSelected, dragResource])

  useEffect(() => {
    checkFields()
  }, [theBooking, spaces, selectedDesk, finalBookings, office])

  useEffect(() => {
    getPrices()
  }, [theBooking, office, selectedDesk, infoPanel])

  useEffect(() => {
    setSpacesData(null)
    getFloor()
    getOfficeDoors()
  }, [theBooking, office])

  useEffect(() => {
    createBookingsArr(selectedDesk)
  }, [theBooking, priceInfo])

  useEffect(() => {
    getResourcesData()
    getWorkAreasData()
    getDataSpaces()
  }, [office, selectedDesk])

  useEffect(() => {
    if (updateMap === true) {
      getResourcesData()
      setUpdateMap(false)
    }
  }, [updateMap])

  useEffect(() => {
    //cleanErrors()
    const delayDebounceFn = setTimeout(() => {
      if (searchClient !== undefined && searchClient !== '') {
        //if (searchClient.filter) setClientFilter(searchClient.filter)
        if (searchClient.client) getClientsData(searchClient.client)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchClient])

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (searchTeam !== undefined && searchTeam !== '') {
        //if (searchTeam.filter) setTeamFilter(searchTeam.filter)
        if (searchTeam.team) getTeamsData(searchTeam.team)
      }
    }, 600)
    return () => clearTimeout(delayDebounceFn)
  }, [searchTeam])

  useEffect(() => {
    window.addEventListener('resize', changeWindowSize)
    return () => {
      window.removeEventListener('resize', changeWindowSize)
    }
  }, [])

  const smallStyle = {
    padding: 0,
    margin: 0,
  }

  const smallBtnUpdateSpace = {
    padding: '5px',
    margin: '0px 10px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: `${colors.orange}`,
    color: 'white',
  }

  const smallBtnToday = {
    width: '100%',
    maxHeight: '24px',
    padding: '5px',
    margin: '1px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: compareDates() === 'today' ? `${colors.orange}` : `${colors.softgray}`,
    color: compareDates() === 'today' ? 'white' : 'black',
  }

  const smallBtnTomorrow = {
    width: '100%',
    maxHeight: '24px',
    padding: '5px',
    margin: '1px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: compareDates() === 'tomorrow' ? `${colors.orange}` : `${colors.softgray}`,
    color: compareDates() === 'tomorrow' ? 'white' : 'black',
  }

  const card = {
    border: `1px solid ${colors.softgray}`,
    borderRadius: '5px',
    margin: '5px',
    padding: '5px 10px',
    boxShadow: 2,
  }

  const cardTitle = {
    fontSize: '16px',
    fontWeight: '600',
    margin: '5px 0',
  }

  return (
    <MapContainer>
      <Grid container direction='row' justify='space-between'>
        <Drawer open={updateSpaces} anchor='right'>
          <ModalUpdateSpaces
            resources={resourcesData}
            setUpdateMap={setUpdateMap}
            closeModal={() => setUpdateSpaces(false)}
          />
        </Drawer>
        <Grid item xs={8} ref={mapComponent}>
          <Grid direction='column'>
            <Grid item ref={mapHeader}>
              <Grid container direction='row' justify='space-between' alignItems='center'>
                <Grid item>
                  <Grid container direction='row'>
                    {office &&
                      office.floors?.map(floor => (
                        <Grid>
                          <Button
                            variant='contained'
                            onClick={() => {
                              setBookingData({ ...theBooking, floor: floor.slug })
                              getDataSpaces({ ...theBooking, floor: floor.slug })
                            }}
                            style={{ margin: '10px 10px 10px 0' }}
                            color={
                              theBooking?.floor === floor.slug ||
                              office.floors.length === 1 ||
                              ((theBooking?.floor === undefined || theBooking?.floor === '') &&
                                office?.floors[0]?.slug === floor.slug)
                                ? 'secondary'
                                : ''
                            }
                          >
                            {floor.name}
                          </Button>
                        </Grid>
                      ))}
                    {doors?.length > 0 && (
                      <Grid>
                        <Button
                          variant='contained'
                          onClick={() => {
                            console.log('Opening door ', theBooking?.floor)
                            openDoor(false)
                          }}
                          style={{ margin: '10px 10px 10px 0' }}
                          color='secondary'
                        >
                          {t('space.openDoor')}
                        </Button>
                        <RebootIcon style={{ color: colors.red, fontSize: 14 }} onClick={() => openDoor(true)} />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' justify='center' style={{ marginRight: '25px', padding: '10px 0' }}>
                    {!imMember && (
                      <Grid item>
                        <SmallButton
                          style={smallBtnUpdateSpace}
                          onClick={() => {
                            setUpdateSpaces(!updateSpaces)
                          }}
                        >
                          <small style={smallStyle}>{t('space.updateSpaces')}</small>
                        </SmallButton>
                      </Grid>
                    )}
                    <Grid item>
                      <FormControl>
                        <DatePicker
                          value={theBooking?.start}
                          onChange={value => {
                            if (value !== undefined) {
                              setBookingData({ ...theBooking, start: value })
                              getDataSpaces({ ...theBooking, start: value })
                            }
                          }}
                          name='start'
                          variant='inline'
                          inputVariant='outlined'
                          helperText={null}
                          format='yyyy-MM-dd'
                          label='Day'
                          autoOk
                        />
                      </FormControl>
                    </Grid>
                    <Grid item>
                      <Grid container direction='column' justify='center' style={{ marginLeft: '10px' }}>
                        <SmallButton
                          style={smallBtnToday}
                          onClick={() => {
                            setBookingData({ ...theBooking, start: new Date() })
                            getDataSpaces({ ...theBooking, start: new Date() })
                          }}
                        >
                          <small style={smallStyle}>Today</small>
                        </SmallButton>
                        <SmallButton
                          style={smallBtnTomorrow}
                          onClick={() => {
                            const today = new Date()
                            const tomorrow = new Date(today)
                            tomorrow.setDate(tomorrow.getDate() + 1)
                            setBookingData({ ...theBooking, start: tomorrow })
                            getDataSpaces({ ...theBooking, start: tomorrow })
                            return ''
                          }}
                        >
                          <small style={smallStyle}>Tomorrow</small>
                        </SmallButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item>
                {spaces && resourcesData ? (
                  <FloorMap
                    spaces={spaces}
                    resources={resourcesData}
                    workAreas={workAreasData}
                    setSelectedDesk={setSelectedData}
                    getSelectedDesk={getSelectedDesk}
                    selectedDesk={selectedDesk}
                    getPanelInfo={getPanelInfo}
                    asignResToBooking={asignResToBooking}
                    setModalBooking={setModalBooking}
                    floorMap={floorMap}
                    state={state}
                    mapDimension={mapDimension}
                    setUpdateModal={setShowUpdate}
                  />
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4}>
          <Grid container direction='column' style={{ marginLeft: '10px' }}>
            {role !== 'referral' && (
              <Grid item xs={12} style={{ margin: '12px 0' }}>
                <Grid
                  container
                  direction='row'
                  justify='space-around'
                  style={{ borderBottom: `1px solid ${colors.softgray}` }}
                  alignItems='center'
                >
                  <MapHeadItem name='Num bookings' value={numberHotDesks} magnitude='' className='head_bookings' />
                  <MapHeadItem
                    name='Available'
                    value={spaces?.num_resources ? spaces?.num_resources - numberHotDesks : '-'}
                    magnitude=''
                    className='head_bookings'
                  />
                  <MapHeadItem
                    name='Occupancy'
                    value={Math.floor((spaces?.occupancy ? spaces?.occupancy : 0) * 100)}
                    magnitude='%'
                    className='head_occupancy'
                  />
                  {imMember && (
                    <MapHeadItem name='Available days' value={availableBookings} magnitude='' className='head_days' />
                  )}
                </Grid>
              </Grid>
            )}
            {role !== 'referral' && !imMember && activeClient.role === 'coworking' && (
              <Grid item xs={12}>
                <Grid
                  container
                  direction='row'
                  alignItems='center'
                  justify='space-around'
                  ref={staffUtilsHeader}
                  spacing={1}
                >
                  <Grid item xs={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        options={theClients ? theClients?.clients : emptySearch}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label='Brokers' variant='outlined' />}
                        onInputChange={(e, value) => {
                          //cleanErrors()
                          setSearchClient({
                            client: value,
                            filter: e.target.innerText,
                          })
                        }}
                        onChange={(e, data) => {
                          console.log(data)
                          if (data !== null) {
                            setBookingData({ ...theBooking, client: data })
                            getDataSpaces({ ...theBooking, client: data })
                            getPrices()
                          } else {
                            setBookingData({ ...theBooking, client: '' })
                            getDataSpaces({ ...theBooking, client: '' })
                            setClients(null)
                          }
                        }}
                        loading
                        loadingText='Searching...'
                        noOptionsText='No Results'
                        //error={errors.client !== null}
                      />
                      {/*errors.team === null ? '' : <SmallError>{errors.team}</SmallError>*/}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl style={{ width: '100%' }}>
                      <Autocomplete
                        options={theTeams ? theTeams?.teams : emptySearch}
                        getOptionLabel={option => option.name}
                        renderInput={params => <TextField {...params} label='Team' variant='outlined' />}
                        onInputChange={(e, value) => {
                          setSearchTeam({
                            team: value,
                            filter: e.target.innerText,
                          })
                        }}
                        onChange={(e, data) => {
                          if (data !== null) {
                            setBookingData({ ...theBooking, team: data })
                            getDataSpaces({ ...theBooking, team: data })
                            getMembersData(data.slug)
                            getPrices()
                            //validateBooking({ ...theBooking, team: team.slug })
                          } else {
                            setBookingData({ ...theBooking, team: '' })
                            getDataSpaces({ ...theBooking, team: '' })
                            setTeams(null)
                            //validateBooking({...theBooking, team: '' })
                          }
                        }}
                      />
                      {/*errors.team === null ? '' : <SmallError>{errors.team}</SmallError>*/}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container direciont='row' justify='space-around'>
                <Grid item xs={12}>
                  {spaces !== undefined ? (
                    <Grid container direction='row' justify='center'>
                      <Grid item xs={12}>
                        {selectedDesk?.length > 0 ? (
                          <Grid container spacing={1}>
                            <Grid item xs={6} ref={addUtilsHeader}>
                              <Button
                                variant='outlined'
                                style={{ width: '100%', marginTop: '7px' }}
                                onClick={() => {
                                  setSelectedData([])
                                  setBookingSelected(null)
                                  setDragResource(null)
                                }}
                              >
                                Clear
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                disabled={selectedDesk.length === 0}
                                variant='contained'
                                color='secondary'
                                onClick={() => sendBooking()}
                                style={{ width: '100%', marginTop: '7px' }}
                              >
                                {priceInfo?.full_price !== undefined && profile?.role !== 'team_member'
                                  ? `£ ${totalPrice}.00`
                                  : 'Book'}
                              </Button>
                            </Grid>
                            {console.log(mapDimension.height - mapDimension.menu - mapDimension.add)}
                            <Grid
                              container
                              style={{
                                height: mapDimension.height - mapDimension.menu - mapDimension.add,
                                overflow: 'scroll',
                                display: 'block',
                              }}
                            >
                              {formatResRows(selectedDesk).map((res, i) => {
                                if (
                                  res.resource_type === 'hot_desk' &&
                                  formatResRows(selectedDesk).filter(item => item.resource_type === 'meeting_room')
                                    .length === 0
                                ) {
                                  return (
                                    <Grid item xs={12} direction='column' key={i} style={card}>
                                      <Grid item xs={12} style={cardTitle}>
                                        Desk {res.name}
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Grid container direction='row' spacing={1}>
                                          {role !== 'team_member' && (
                                            <Grid item xs={6}>
                                              <Autocomplete
                                                disabled={
                                                  theBooking?.team === undefined || theBooking?.team === '' ? true : ''
                                                }
                                                name='autocompleteMember'
                                                id='autocomplete_members'
                                                options={membersRows}
                                                getOptionLabel={option =>
                                                  `${option?.user?.first_name} ${option?.user?.last_name}`
                                                }
                                                renderInput={params => (
                                                  <TextField {...params} label='Member' variant='outlined' />
                                                )}
                                                onChange={(event, option) => {
                                                  if (event !== undefined && option != null) {
                                                    createDataBookings(i, option?.slug, 'member')
                                                  } else {
                                                    createDataBookings(i, null, 'member')
                                                  }
                                                }}
                                              />
                                            </Grid>
                                          )}
                                          {console.log(prepaidsRows)}
                                          {imOfficeManager && activeClient.role === 'coworking' && (
                                            <Grid item xs={6}>
                                              <Autocomplete
                                                disabled={prepaidsRows === undefined ? true : ''}
                                                name='autocompletePrepaids'
                                                id='autocomplete_prepaids'
                                                options={prepaidsRows}
                                                getOptionLabel={option => `${option?.single_price} ${option?.code}`}
                                                renderOption={option => {
                                                  const theDate = option.create_date?.split('T')
                                                  return <h5>{`Hot Desk - ${theDate[0]} - £${option.single_price}`}</h5>
                                                }}
                                                renderInput={params => (
                                                  <TextField {...params} label='Pre-Paid' variant='outlined' />
                                                )}
                                                onChange={(event, option) => {
                                                  if (event !== undefined && option !== undefined && option !== null) {
                                                    createDataBookings(i, option.code, 'pre_paid')
                                                  } else {
                                                    createDataBookings(i, null, 'pre_paid')
                                                  }
                                                  return ''
                                                }}
                                              />
                                            </Grid>
                                          )}
                                        </Grid>
                                      </Grid>
                                      {role !== 'referral' && !imMember && (
                                        <Grid item xs={12}>
                                          <Grid container direction='row' justify='space-evenly'>
                                            <Grid item>
                                              <Grid container direction='row' alignItems='center'>
                                                <Grid item>
                                                  <Checkbox
                                                    name='already_paid'
                                                    onChange={event => {
                                                      createDataBookings(i, event.target.checked, event.target.name)
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item>
                                                  <p>Already Paid</p>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                            <Grid item>
                                              <Grid container direction='row' alignItems='center'>
                                                <Grid item>
                                                  <Checkbox
                                                    name='complimentary'
                                                    onChange={event => {
                                                      createDataBookings(i, event.target.checked, event.target.name)
                                                    }}
                                                  />
                                                </Grid>
                                                <Grid item>
                                                  <p>Complimentary</p>
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Grid>
                                  )
                                }
                                if (res.resource_type === 'meeting_room') {
                                  return (
                                    <ScheduleSingle
                                      office={office}
                                      activeClient={activeClient}
                                      role={role}
                                      profile={profile}
                                      resource={res}
                                      panelHeight={mapDimension.height - mapDimension.menu}
                                    />
                                  )
                                }
                                return ''
                              })}
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid>
                            <PanelInfo
                              info={infoPanel}
                              spaces={spaces}
                              setSpacesData={setSpacesData}
                              office={office}
                              activeClient={theBooking.cllient}
                              asignBookingToResource={asignBookingToResource}
                              setBookingSelected={setBookingSelected}
                              filter='all'
                              day={theBooking?.start === undefined ? new Date() : theBooking.start}
                              getPanelInfo={getPanelInfo}
                              setState={setState}
                              state={state}
                              updateList={getDataSpaces}
                              panelHeight={mapDimension.height - mapDimension.menu}
                              theBooking={theBooking}
                              showUpdate={showUpdate}
                              profile={profile}
                            />
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container direction='row' justify='center'>
                      <Grid item xs={6}>
                        This is a empty element
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Modal open={modalBooking === true}>
        <ModalCard>
          <Grid container>
            <Grid item>
              <h1>Updating Booking...</h1>
            </Grid>
            <Grid item>
              <SpinnerContainer>
                <CircularProgress color='secondary' />
              </SpinnerContainer>
            </Grid>
          </Grid>
        </ModalCard>
      </Modal>
    </MapContainer>
  )
}

CreateBookingDesk.propTypes = {
  office: PropTypes.object,
  activeClient: PropTypes.object,
  role: PropTypes.object,
  profile: PropTypes.object,
}

CreateBookingDesk.defaultProps = {
  office: '',
  activeClient: '',
  role: '',
  profile: '',
}

export default CreateBookingDesk
