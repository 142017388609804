import React from 'react'
import PropTypes from 'prop-types'
import { Input } from './styled'

const TextAreaInputView = ({ value, onChange, label, error, className, type, ...props }) => {
  const handleChange = event => {
    onChange(event)
  }

  return (
    <Input
      label={label}
      value={value}
      error={error}
      type='hidden'
      onChange={event => handleChange(event)}
      helperText={error?.message}
      className={className}
      {...props}
    />
  )
}

TextAreaInputView.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  label: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
}

TextAreaInputView.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  error: null,
  className: '',
  type: 'text',
}

export default TextAreaInputView
