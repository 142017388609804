import { colors } from 'config/colors'
import styled from 'styled-components'

export const ListContainer = styled.div``

export const ListHeader = styled.div`
  font-family: 'Lato';
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  color: black;
  border-bottom: 1px solid grey;
  font-weight: 700;
`

export const ListRow = styled.div`
  font-family: 'Lato';
  font-size: 15px;
  height: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid ${colors.gray};
  &:hover {
    color: white;
    background-color: #d7d7d7;
  }
`

export const PaginationNumbers = styled.span`
  font-family: 'lato';
`

export const PaginationContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 25px;
`

export const ListIndex = styled.div`
  color: ${colors.gray};
  width: 25px;
`

export const ListItem = styled.div`
  color: ${colors.gray};
  width: 200px;
`
export const ListItemBold = styled.div`
  color: black;
  width: 200px;
  font-weight: 700;
`

export const ListTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 200px;
  align-items: center;
  justify-content: start;
`
export const ListSmallTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100px;
  align-items: center;
`

export const ListSmallItem = styled.div`
  color: ${colors.gray};
  width: 100px;
`

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 150px 0;
`

export const FilterContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin: 0 15px;
`
