import { useState, useEffect } from 'react'
import { updateResource } from 'api'
import PropTypes from 'prop-types'
import { Grid, Button, FormControl, TextField, MenuItem, Select, InputLabel, CircularProgress } from '@material-ui/core'
//import { colors } from 'config/colors'
import { useTranslation } from 'react-i18next'
import { ModalCard, ModalTitle } from './modalStyles'

function ModalUpdateSpaces({ resources, setUpdateMap, closeModal }) {
  const [selectedSpace, setSelectedSpace] = useState(resources?.resources[0])
  const { t } = useTranslation()

  function updateSpace() {
    const data = { ...selectedSpace, office: selectedSpace.office?.slug, floor: selectedSpace.floor?.slug }
    updateResource(data, selectedSpace.slug).then(() => {
      setUpdateMap(true)
    })
  }

  useEffect(() => {
    console.log('Spaces changed ', resources)
  }, [resources])

  useEffect(() => {
    console.log('Selected space was ', selectedSpace, resources?.resources)
  }, [selectedSpace])

  return (
    <ModalCard style={{ width: '300px' }}>
      {resources !== null ? (
        <Grid container direction='column' spacing={2}>
          <Grid item>
            <Grid
              container
              direction='row'
              justify='space-between'
              alignItems='center'
              style={{ marginBottom: '18px' }}
            >
              <Grid item>
                <ModalTitle>Update Space</ModalTitle>
              </Grid>
              <Grid item>
                <Button onClick={() => closeModal()}>x</Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row'>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '15px' }}>Resource</InputLabel>
                  <Select
                    label='Resource'
                    variant='outlined'
                    name='resource'
                    value={resources?.resources?.filter(item => item.slug === selectedSpace.slug)[0]}
                    onChange={event => setSelectedSpace(event.target.value)}
                  >
                    {resources &&
                      resources?.resources?.map((item, index) => (
                        <MenuItem value={item} key={`space${index}`}>
                          {item.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-around' spacing={2} alignItems='flex-start'>
              <Grid item xs={12}>
                <FormControl style={{ width: '100%' }}>
                  <InputLabel style={{ marginLeft: '15px', marginTop: '-8px' }}>Orientation</InputLabel>
                  <Select
                    label='Orientation'
                    variant='outlined'
                    name='orientation'
                    value={selectedSpace.orientation}
                    onChange={event => setSelectedSpace({ ...selectedSpace, orientation: event.target.value })}
                  >
                    <MenuItem value='up'>Up</MenuItem>
                    <MenuItem value='down'>Down</MenuItem>
                    <MenuItem value='left'>Left</MenuItem>
                    <MenuItem value='right'>Right</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Length'
                    variant='outlined'
                    type='number'
                    value={selectedSpace !== undefined ? selectedSpace?.length : ''}
                    name='length'
                    onChange={event => setSelectedSpace({ ...selectedSpace, length: event.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Width'
                    variant='outlined'
                    type='number'
                    value={selectedSpace !== undefined ? selectedSpace?.width : ''}
                    name='length'
                    onChange={event => setSelectedSpace({ ...selectedSpace, width: event.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='X'
                    variant='outlined'
                    type='number'
                    value={selectedSpace !== undefined ? selectedSpace?.x : ''}
                    name='x'
                    onChange={event => setSelectedSpace({ ...selectedSpace, x: event.target.value })}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl style={{ width: '100%' }}>
                  <TextField
                    label='Y'
                    variant='outlined'
                    type='number'
                    value={selectedSpace !== undefined ? selectedSpace?.y : ''}
                    name='y'
                    onChange={event => setSelectedSpace({ ...selectedSpace, y: event.target.value })}
                  />
                </FormControl>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction='row' justify='space-between'>
              <Grid item>
                <Button variant='contained' color='secondary' onClick={() => updateSpace(true)}>
                  {t('resource.updateResource')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Grid container direction='column' justify='space-between' spacing={3} alignItems='center'>
          <Grid item xs={12}>
            <h3>Loading data...</h3>
          </Grid>
          <Grid item xs={12}>
            <CircularProgress color='secondary' />
          </Grid>
        </Grid>
      )}
    </ModalCard>
  )
}

ModalUpdateSpaces.propTypes = {
  resources: PropTypes.array,
  setUpdateMap: PropTypes.func,
  closeModal: PropTypes.func,
}

ModalUpdateSpaces.defaultProps = {
  resources: [],
  setUpdateMap: () => {},
  closeModal: () => {},
}

export default ModalUpdateSpaces
