import { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { getSingleWA, getDashboardOffices, updateWA } from 'api/index'
import { useTranslation } from 'react-i18next'
import {
  Grid,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Checkbox,
  CircularProgress,
  Modal,
} from '@material-ui/core'
import { SubmitBtn } from 'components/organisms/bookings/modalStyles'
import { EndContainer } from 'components/organisms/members/profileStyle'
import { Autocomplete } from '@material-ui/lab'
import FeedbackModal from 'components/molecules/modals/feedbackModal'
import Text from 'components/atoms/text/view'
import { SpinnerContainer } from 'components/organisms/members/newListStyle'
import { PlaceholderIcon, Logo } from 'components/organisms/themeForm/styled'
import { SmallError } from './formStyle'

function UpdateWA() {
  const { control } = useForm()
  const history = useHistory()
  const { t } = useTranslation()
  const params = useParams()
  const { slug } = params
  const [theWA, setWAData] = useState()
  const [theOffices, setOffices] = useState()
  const [theFloors, setFloors] = useState()
  const [errors, setErrors] = useState({
    name: null,
    size: null,
    status: null,
    office: null,
    floor: null,
    area_type: null,
    item_price_day: null,
    full_price_day: null,
    item_price_month: null,
    full_price_month: null,
  })

  function getData() {
    getSingleWA(slug).then(response => setWAData(response))
  }

  const [feedback, setFeedback] = useState(false)

  function updateWorkArea() {
    setFeedback(true)
    const updateObject = {
      name: theWA.name,
      area_type: theWA.area_type,
      bookable: theWA.bookable,
      description: theWA.description,
      floor: theWA.floor.slug,
      full_price_day: theWA.full_price_day,
      full_price_month: theWA.full_price_month,
      item_price_day: theWA.item_price_day,
      item_price_month: theWA.item_price_month,
      office: theWA.office.slug,
      order: theWA.order,
      size: theWA.size,
      status: theWA.status,
      visible_app: theWA.visible_app,
      visible_web: theWA.visible_web,
      visible_space: theWA.visible_space,
    }
    updateWA(theWA.slug, updateObject)
      .then(() =>
        setTimeout(() => {
          history.push('/space/work_areas')
        }, 1500)
      )
      .catch(e => console.log(e))
  }

  function validation(data) {
    let canUpdate = false
    const textErrors = {
      name: 'Work Area name is required',
      size: 'Work Area size is required',
      positive: 'It must be a positive number',
      status: 'Work Area status is required',
      office: 'Work Area office is required',
      floor: 'Work Area floor is required',
      area_type: 'Work Area type is required',
      item_price_day: 'Work Area item price for day is required',
      full_price_day: 'Work Area full price for day is required',
      item_price_month: 'Work Area item price for month is required',
      full_price_month: 'Work Area full price for month is required',
    }
    const nameRes = data?.name === undefined || data?.name === '' ? textErrors.name : null
    let sizeRes = data?.size === undefined || data?.size === '' ? textErrors.size : null
    if (data?.size < 0) sizeRes = textErrors.positive
    const statusRes = data?.status === undefined || data?.status === '' ? textErrors.status : null
    const officeRes =
      data?.office === undefined || data?.office === '' || data?.office === null ? textErrors.office : null
    const floorRes = data?.floor === undefined || data?.floor === '' ? textErrors.floor : null
    const typeRes = data?.area_type === undefined || data?.area_type === '' ? textErrors.area_type : null
    let ipdRes = data?.item_price_day === undefined || data?.item_price_day === '' ? textErrors.item_price_day : null
    if (data?.item_price_day < 0) ipdRes = textErrors.positive
    let fpdRes = data?.full_price_day === undefined || data?.full_price_day === '' ? textErrors.full_price_day : null
    if (data?.full_price_day < 0) fpdRes = textErrors.positive
    let ipmRes =
      data?.item_price_month === undefined || data?.item_price_month === '' ? textErrors.item_price_month : null
    if (data?.item_price_month < 0) ipmRes = textErrors.positive
    let fpmRes =
      data?.full_price_month === undefined || data?.full_price_month === '' ? textErrors.full_price_month : null
    if (data?.full_price_month < 0) fpmRes = textErrors.positive

    setErrors({
      name: nameRes,
      size: sizeRes,
      status: statusRes,
      office: officeRes,
      floor: floorRes,
      area_type: typeRes,
      item_price_day: ipdRes,
      full_price_day: fpdRes,
      item_price_month: ipmRes,
      full_price_month: fpmRes,
    })

    if (
      nameRes === null &&
      sizeRes === null &&
      statusRes === null &&
      officeRes === null &&
      floorRes === null &&
      typeRes === null &&
      fpdRes === null &&
      fpmRes === null &&
      ipdRes === null &&
      ipmRes === null
    ) canUpdate = true

    if (canUpdate === true) updateWorkArea()
  }

  function setChecks(value) {
    switch (value) {
      case 'bookable':
        return theWA.bookable === false
          ? setWAData({ ...theWA, bookable: true })
          : setWAData({ ...theWA, bookable: false })
      case 'visible_app':
        return theWA.visible_app === false
          ? setWAData({ ...theWA, visible_app: true })
          : setWAData({ ...theWA, visible_app: false })
      case 'visible_web':
        return theWA.visible_web === false
          ? setWAData({ ...theWA, visible_web: true })
          : setWAData({ ...theWA, visible_web: false })
      case 'visible_space':
        return theWA.visible_space === false
          ? setWAData({ ...theWA, visible_space: true })
          : setWAData({ ...theWA, visible_space: false })
      default:
        return ''
    }
  }

  function getOfficesData() {
    const query = { pageSize: 15, currentPage: 1, filter: {} }
    getDashboardOffices(query).then(r => {
      const floorsArr = []
      r.offices.map(o => o.floors.map(f => floorsArr.push(f)))
      setFloors(floorsArr)
      setOffices(r.offices)
    })
  }

  useEffect(() => {
    if (theWA === undefined) getData()
  }, [theWA])

  useEffect(() => {
    if (theOffices === undefined) getOfficesData()
  }, [theOffices])

  useEffect(() => {}, [errors])

  const floors = []
  if (theOffices !== undefined && theWA !== undefined) {
    theOffices.map(o => {
      if (o.slug === theWA.office?.slug) {
        o.floors.map(f => floors.push(f))
      }
      return ''
    })
  }

  console.log('floors', floors)
  console.log('offices', theOffices)

  if (theWA !== undefined) {
    return (
      <Grid container direction='column' spacing={2}>
        <Modal open={feedback === true}>
          <FeedbackModal type='wa-update' />
        </Modal>
        <Grid item>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <h3>Update Work Area</h3>
            </Grid>
            <Grid item>
              {' '}
              <SubmitBtn onClick={() => validation(theWA)}>Save Changes</SubmitBtn>{' '}
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container direction='row' spacing={2}>
            <Grid item xs={4}>
              <Grid container direction='column' alignItems='center'>
                <Grid item xs={12}>
                  {theWA.picture !== null ? (
                    <img src={theWA.picture} alt={theWA.name} />
                  ) : (
                    <FormControl style={{ width: '95%' }}>
                      <Logo
                        name='res-picture'
                        icon={<PlaceholderIcon />}
                        placeholderText={t('profile.theme.loginPlaceholder')}
                        onChange={e => setWAData({ ...theWA, picture: e })}
                        control={control}
                      />
                    </FormControl>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid container direction='column' spacing={2}>
                <Grid item>
                  <Grid container direction='row' spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        label='Name'
                        value={theWA?.name}
                        variant='outlined'
                        id='wa_name'
                        name='name'
                        onChange={value => setWAData({ ...theWA, name: value.target.value })}
                        style={{ width: '100%' }}
                        error={errors.name !== null}
                      />
                      {errors.name !== null ? <SmallError>{errors.name}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        value={theWA?.description}
                        label='Description'
                        variant='outlined'
                        id='wa_description'
                        name='description'
                        multiline
                        rows={3}
                        rowsMax={3}
                        onChange={value => setWAData({ ...theWA, description: value.target.value })}
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' justify='space-around' spacing={2}>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          name='autocomplete_office'
                          value={theWA.office !== null ? theWA.office : [{ name: 'no data' }]}
                          options={theOffices !== undefined ? theOffices : [{ name: 'No data' }]}
                          getOptionLabel={option => option.name}
                          renderInput={p => (
                            <TextField {...p} label='Office' variant='outlined' error={errors.office !== null} />
                          )}
                          onChange={(e, data) => {
                            if (data !== null) setWAData({ ...theWA, office: data })
                            else setWAData({ ...theWA, office: null })
                          }}
                          error={errors.office !== null}
                        />
                        {errors.office !== null ? <SmallError>{errors.office}</SmallError> : ''}
                      </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl style={{ width: '100%' }}>
                        <Autocomplete
                          name='autocomplete_floor'
                          value={
                            theWA?.floor !== null && theWA.floor !== undefined ? theWA?.floor : [{ name: 'no data' }]
                          }
                          options={theFloors !== undefined ? theFloors : [{ name: 'No data' }]}
                          getOptionLabel={option => option.name}
                          renderInput={p => (
                            <TextField
                              {...p}
                              label='Floor'
                              variant='outlined'
                              error={theWA.office !== null && errors.floor !== null}
                            />
                          )}
                          onChange={(e, data) => {
                            if (e.target.innerText !== undefined) setWAData({ ...theWA, floor: data })
                            else setWAData({ ...theWA, floor: null })
                          }}
                          error={theWA.office !== null && errors.floor !== null}
                        />
                      </FormControl>
                      {theWA.office !== null && errors.floor !== null ? <SmallError>{errors.floor}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant='outlined' style={{ width: '100%' }}>
                        <InputLabel error={errors.area_type !== null}>Area Type</InputLabel>
                        <Select
                          label='Area Type'
                          onChange={value => setWAData({ ...theWA, area_type: value.target.value })}
                          error={errors.area_type !== null}
                          value={theWA.area_type}
                        >
                          <MenuItem value='open_space' selected>
                            Open Space
                          </MenuItem>
                          <MenuItem value='suite'>Private office</MenuItem>
                          <MenuItem value='outdoor'>Outdoor</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.area_type !== null ? <SmallError>{errors.area_type}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={4}>
                      <FormControl variant='outlined' style={{ width: '100%' }}>
                        <InputLabel error={errors.status !== null}>Status</InputLabel>
                        <Select
                          label='Status'
                          value={theWA.status}
                          onChange={value => setWAData({ ...theWA, status: value.target.value })}
                          error={errors.status !== null}
                        >
                          <MenuItem value='active'>Active</MenuItem>
                          <MenuItem value='inactive'>Inactive</MenuItem>
                          <MenuItem value='pending'>Pending</MenuItem>
                        </Select>
                      </FormControl>
                      {errors.status !== null ? <SmallError>{errors.status}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type='number'
                        label='Size'
                        variant='outlined'
                        id='size'
                        name='size'
                        value={theWA.size}
                        onChange={value =>
                          setWAData({ ...theWA, size: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0 })
                        }
                        style={{ width: '100%' }}
                        error={errors.size !== null}
                      />
                      {errors.size !== null ? <SmallError>{errors.size}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={4}>
                      <TextField
                        type='number'
                        label='Order'
                        variant='outlined'
                        id='order'
                        name='order'
                        value={theWA.order}
                        onChange={value =>
                          setWAData({ ...theWA, order: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0 })
                        }
                        style={{ width: '100%' }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row' spacing={2}>
                    <Grid item xs={3}>
                      <TextField
                        label='Full Price Day'
                        variant='outlined'
                        id='full_price_day'
                        name='full_price_day'
                        type='number'
                        value={theWA.full_price_day === undefined ? null : theWA.full_price_day}
                        onChange={value =>
                          setWAData({
                            ...theWA,
                            full_price_day: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                          })
                        }
                        style={{ width: '100%' }}
                        error={errors.full_price_day !== null}
                      />
                      {errors.full_price_day !== null ? <SmallError>{errors.full_price_day}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type='number'
                        label='Item Price Day'
                        variant='outlined'
                        id='item_price_day'
                        name='item_price_day'
                        value={theWA.item_price_day === undefined ? null : theWA.item_price_day}
                        onChange={value =>
                          setWAData({
                            ...theWA,
                            item_price_day: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                          })
                        }
                        style={{ width: '100%' }}
                        error={errors.item_price_day !== null}
                      />
                      {errors.item_price_day !== null ? <SmallError>{errors.item_price_day}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type='number'
                        label='Full Price Month'
                        variant='outlined'
                        id='full_price_month'
                        name='full_price_month'
                        value={theWA.full_price_month === undefined ? null : theWA.full_price_month}
                        onChange={value =>
                          setWAData({
                            ...theWA,
                            full_price_month: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                          })
                        }
                        style={{ width: '100%' }}
                        error={errors.full_price_month !== null}
                      />
                      {errors.full_price_month !== null ? <SmallError>{errors.full_price_month}</SmallError> : ''}
                    </Grid>
                    <Grid item xs={3}>
                      <TextField
                        type='number'
                        label='Item Price Month'
                        variant='outlined'
                        id='item_price_month'
                        name='item_price_month'
                        value={theWA.item_price_month === undefined ? null : theWA.item_price_month}
                        onChange={value =>
                          setWAData({
                            ...theWA,
                            item_price_month: value.target.value >= 0 ? parseInt(value.target.value, 10) : 0,
                          })
                        }
                        style={{ width: '100%' }}
                        error={errors.item_price_month !== null}
                      />
                      {errors.item_price_month !== null ? <SmallError>{errors.item_price_month}</SmallError> : ''}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction='row'>
                    <Grid item xs={3}>
                      <Grid container direction='row' justify='center' alignItems='center'>
                        <Text>Bookable</Text>
                        <EndContainer>
                          <Checkbox
                            name='bookable'
                            onChange={e => setChecks(e.target.name)}
                            checked={theWA.bookable === true ? true : ''}
                          />
                        </EndContainer>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container direction='row' justify='center' alignItems='center'>
                        <Text>Visible App</Text>
                        <EndContainer>
                          <Checkbox
                            onChange={e => setChecks(e.target.name)}
                            name='visible_app'
                            checked={theWA.visible_app === true ? true : ''}
                          />
                        </EndContainer>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container direction='row' justify='center' alignItems='center'>
                        <Text>Visible Web</Text>
                        <EndContainer>
                          <Checkbox
                            onChange={e => setChecks(e.target.name)}
                            name='visible_web'
                            checked={theWA.visible_web === true ? true : ''}
                          />
                        </EndContainer>
                      </Grid>
                    </Grid>
                    <Grid item xs={3}>
                      <Grid container direction='row' justify='center' alignItems='center'>
                        <Text>Visible Space</Text>
                        <EndContainer>
                          <Checkbox
                            onChange={e => setChecks(e.target.name)}
                            name='visible_space'
                            checked={theWA.visible_space === true ? true : ''}
                          />
                        </EndContainer>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

export default UpdateWA
