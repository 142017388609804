import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import Button from '@material-ui/core/Button'
import { getTeamsWithInvoiceData, getDashboardOffices } from 'api'
import { CircularProgress, FormControl, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { SpinnerContainer } from 'components/template/layout/styled'
import { Controller } from 'react-hook-form'
import { SmallError } from 'components/pages/resourcesAdd/formStyle'
import { Container, Title, Toolbar } from './styled'
import { Col2 } from '../bookings/modalStyles'
import DateInput from '../../atoms/dateInput'
import SelectInput from '../../atoms/selectInput'
import TextInput from '../../atoms/textInput'

const InvoiceFormView = ({ onSubmit, control, errors, office }) => {
  const { t } = useTranslation()
  const [teamsData, setTeams] = useState()
  const [officesData, setOffices] = useState([])
  const [invoiceData, setInvoiceData] = useState()
  const [selectedData, setSelectedData] = useState({})

  function getData(value) {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {
        layoutOffice: office.slug,
        name: value === undefined ? '' : value,
      },
    }
    getTeamsWithInvoiceData(query).then(response => setTeams(response.teams))
  }

  function getOffices() {
    const query = {
      pageSize: 1000,
      currentPage: 1,
      filter: {},
    }
    getDashboardOffices(query).then(response => {
      const offices = []
      response.offices?.map(item => {
        offices.push({ id: item.slug, text: item.name })
        return offices
      })
      setOffices(offices)
    })
  }

  useEffect(() => {
    if (teamsData === undefined) getData()
  }, [teamsData])

  useEffect(() => {
    getOffices()
  }, [])

  if (teamsData !== undefined) {
    return (
      <Container onSubmit={onSubmit}>
        <Toolbar>
          <Button variant='contained' color='secondary' type='submit'>
            {t('invoices.form.addInvoice')}
          </Button>
        </Toolbar>
        <Title>{t('invoices.form.addInvoice')}</Title>
        <Col2>
          <Controller
            name='team_slug'
            control={control}
            render={({ onChange, ...props }) => (
              <FormControl>
                <Autocomplete
                  name='autocompleteTeams'
                  options={teamsData !== undefined ? teamsData : ['no data']}
                  getOptionLabel={option => option.name}
                  renderInput={params => (
                    <TextField error={errors.team_slug} {...params} label='Team' variant='outlined' />
                  )}
                  onChange={(e, data) => {
                    console.log('_onTeam selected ', data?.datas)
                    setInvoiceData(data?.datas)
                    const defaultDatas = data?.datas?.filter(inv => inv.default === true && inv.name !== null)
                    if (defaultDatas?.length === 0 || data?.datas === undefined) {
                      console.log('_onTeam empty delected data ', defaultDatas)
                      setSelectedData({})
                    } else {
                      console.log('_onTeam filling selected data ', defaultDatas, props)
                      setSelectedData(defaultDatas[0])
                    }
                    // eslint-disable-next-line react/prop-types
                    props.field.onChange(data?.slug)
                  }}
                  onInputChange={(e, data) => {
                    getData(data)
                  }}
                  {...props}
                />
                {errors.team === null ? '' : <SmallError>{errors.team}</SmallError>}
              </FormControl>
            )}
          />
          <DateInput
            control={control}
            variant='outlined'
            inputVariant='outlined'
            label='Date'
            name='date'
            error={errors?.date}
          />
        </Col2>
        <Col2>
          <Controller
            name='invoice_data'
            control={control}
            render={({ onChange, ...props }) => (
              <FormControl>
                <Autocomplete
                  name='autocompleteInvoiceData'
                  options={invoiceData !== undefined ? invoiceData : ['no data']}
                  //defaultValue={invoiceData ? invoiceData?.filter(inv => inv.default === true && inv.name !== null)[0].slug : null}
                  // eslint-disable-next-line react/prop-types
                  value={selectedData}
                  getOptionLabel={option =>
                    option.name ? `${option.name} ${option.default === true ? ' (default)' : ''}` : ''
                  }
                  renderInput={params => (
                    <TextField error={errors.invoice_data} {...params} label='Invoice Data' variant='outlined' />
                  )}
                  onChange={(e, data) => {
                    // eslint-disable-next-line react/prop-types
                    console.log('_onChange inoivce data', props.field)
                    // eslint-disable-next-line react/prop-types
                    props.field.onChange(data?.slug)
                    setSelectedData(data)
                  }}
                  onInputChange={() => {
                    // eslint-disable-next-line react/prop-types
                    console.log('_onInput change inoivce data', props.field)
                    // eslint-disable-next-line react/prop-types
                    props.field.onChange(selectedData?.slug)
                  }}
                  {...props}
                />
              </FormControl>
            )}
          />
          <DateInput
            control={control}
            variant='outlined'
            inputVariant='outlined'
            label='Date due'
            name='date_due'
            error={errors?.date_due}
          />
        </Col2>
        <Col2>
          <SelectInput
            searchProp='text'
            label='Type'
            name='type'
            control={control}
            error={errors?.type}
            options={[
              { id: 'work', text: 'Work' },
              { id: 'bar', text: 'Bar' },
              { id: 'events', text: 'Events' },
              { id: 'other', text: 'Other' },
            ]}
          />
          <SelectInput
            searchProp='text'
            label='Office'
            name='office'
            control={control}
            error={errors?.office}
            options={officesData}
          />
        </Col2>
        <Col2>
          <TextInput
            label='Payment Reference'
            name='payment_reference'
            control={control}
            error={errors?.payment_reference}
          />
          <TextInput label='Xero Id' name='xero_id' control={control} error={errors?.xero_id} />
        </Col2>
      </Container>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

InvoiceFormView.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object,
  control: PropTypes.object.isRequired,
  office: PropTypes.object,
}

InvoiceFormView.defaultProps = {
  errors: null,
  office: {},
}

export default InvoiceFormView
