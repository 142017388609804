import React from 'react'
import PropTypes from 'prop-types'
import { Container, Magnitude, Name, Value, ValueContainer, ValueStat } from './styled'

const MapHeadItem = ({ name, value, magnitude, className }) => (
  <Container className={className}>
    <ValueContainer>
      <Name>{name}</Name>
      <Value isProminent>
        <ValueStat>
          {value} <Magnitude>{magnitude}</Magnitude>
        </ValueStat>
      </Value>
    </ValueContainer>
  </Container>
)

MapHeadItem.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  magnitude: PropTypes.string,
  className: PropTypes.string,
}

MapHeadItem.defaultProps = {
  name: '',
  value: '',
  magnitude: '',
  className: '',
}

export default MapHeadItem
