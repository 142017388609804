import React, { useState } from 'react'
import PropTypes from 'prop-types'
import View from './view'

const Container = ({ onChange, ...props }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false)
  const handleSelectColor = color => {
    onChange(color.hex)
    setDisplayColorPicker(false)
  }
  return (
    <View
      {...props}
      displayColorPicker={displayColorPicker}
      onChange={onChange}
      handleSelectColor={handleSelectColor}
      setDisplayColorPicker={setDisplayColorPicker}
    />
  )
}
Container.propTypes = { onChange: PropTypes.func }
Container.defaultProps = { onChange: () => null }
export default Container
