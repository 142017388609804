import React, { useEffect, useState } from 'react'
import { getOffices } from 'api'
import PropTypes from 'prop-types'
import HeaderToolbar from 'components/organisms/headerToolbar'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '@material-ui/core'
import routes from 'config/routes'
import { useLocation } from 'react-router-dom'
import OfferList from 'components/organisms/offerList/offerList'
import CampaignList from 'components/organisms/campaigns/campaignList'
import CampaignCreateModal from 'components/organisms/campaigns/campaignCreateModal'
import { Container } from './styled'
import OfferUserList from '../../organisms/offerUserList/offerUserList'
import { ContainerEnd } from '../members/styled'

const RigthActions = ({ pathname, t, handleClickOnLink }) => {
  const [modalCampaign, setModalCampaign] = useState(false)
  function handleCampaignClose() {
    setModalCampaign(false)
  }

  switch (pathname) {
    case routes.marketingOffer:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.marketingOffer)}>
          {t('marketing.addOfferUser')}
        </Button>
      )
    case routes.marketingOfferUser:
      return (
        <Button variant='contained' color='secondary' onClick={() => handleClickOnLink(routes.offerUserAdd)}>
          {t('marketing.addOfferUser')}
        </Button>
      )
    case routes.marketing:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalCampaign(true)}>
            {t('marketing.addCampaign')}
          </Button>
          <Modal open={modalCampaign} onClose={handleCampaignClose}>
            <CampaignCreateModal toClose={handleCampaignClose} />
          </Modal>
        </ContainerEnd>
      )
    default:
      return null
  }
}

const DownActions = (pathname, office) => {
  useEffect(() => {}, [office])
  if (pathname === routes.marketingOffer) return <OfferList layoutOffice={office} />
  if (pathname === routes.marketingOfferUser) return <OfferUserList layoutOffice={office} />
  if (pathname === routes.marketing) return <CampaignList layoutOffice={office} />
  return ''
}

const OfferView = props => {
  const { handleClickOnLink, office } = props
  const { t } = useTranslation()
  const { pathname } = useLocation()

  // TABS

  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }

  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  let theTabs = [
    permited.includes('campaigns')
      ? { id: routes.marketing, to: routes.marketing, label: t('marketing.campaigns') }
      : '',
    permited.includes('offers')
      ? { id: routes.marketingOffer, to: routes.marketingOffer, label: t('marketing.tabs.offers') }
      : '',
    permited.includes('offer_user')
      ? { id: routes.marketingOfferUser, to: routes.marketingOfferUser, label: t('marketing.tabs.offerUser') }
      : '',
  ]

  theTabs = theTabs.filter(tab => tab !== '')

  return (
    <Container>
      <HeaderToolbar
        tabValue={pathname}
        tabs={theTabs}
        rightActions={<RigthActions pathname={pathname} t={t} handleClickOnLink={handleClickOnLink} />}
      />
      <Container>{DownActions(pathname, office)}</Container>
    </Container>
  )
}

OfferView.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  office: PropTypes.object,
}

OfferView.defaultProps = {
  office: {},
}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  handleClickOnLink: PropTypes.func.isRequired,
}

DownActions.propTypes = {
  pathname: PropTypes.string,
  t: PropTypes.string,
}

DownActions.defaultProps = {
  pathname: '',
  t: '',
}

export default OfferView
