import React, { useState, useEffect } from 'react'
import { Route, Switch } from 'react-router'
import { getOffices } from 'api'
import routes from 'config/routes'
import PropTypes from 'prop-types'
import HeaderToolbar from 'components/organisms/headerToolbar'
import { Button, Modal } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import ThemeForm from 'components/organisms/themeForm'
import { useLocation } from 'react-router-dom'
import OfficesList from 'components/organisms/offices/officesList'
import ProfileSingle from 'components/organisms/account/ProfileSingle'
import ProfileMSPlansList from 'components/organisms/account/ProfileMSPlansList'
import ProfileStaffList from 'components/organisms/account/ProfileStaffList'
import ModalCreateStaff from 'components/organisms/account/ModalCreateStaff'
import StaffSingle from 'components/organisms/account/staffSingle'
import OfficeSingle from 'components/organisms/offices/officeSingle'
import ModalCreateMSPlan from 'components/organisms/account/modalCreateMSPlan'
import { Container } from './styled'
import { ContainerEnd } from '../members/styled'

const RigthActions = ({ pathname, t, reloadData }) => {
  const [modalStaff, setModalStaff] = useState(false)
  const [modalMSPlan, setModalMSPlan] = useState(false)

  function handleStaffClose() {
    setModalStaff(false)
    reloadData(true)
  }

  function handleMSClose() {
    setModalMSPlan(false)
  }

  switch (pathname) {
    case routes.profileStaff:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalStaff(true)}>
            {t('profile.addStaff')}
          </Button>
          <Modal open={modalStaff} onClose={handleStaffClose}>
            <ModalCreateStaff toClose={handleStaffClose} />
          </Modal>
        </ContainerEnd>
      )
    case routes.profileMembership:
      return (
        <ContainerEnd>
          <Button variant='contained' color='secondary' onClick={() => setModalMSPlan(true)}>
            {t('profile.addMS')}
          </Button>
          <Modal open={modalMSPlan} onClose={handleMSClose}>
            <ModalCreateMSPlan toClose={handleMSClose} />
          </Modal>
        </ContainerEnd>
      )
    default:
      return null
  }
}

RigthActions.propTypes = {
  pathname: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  reloadData: PropTypes.func.isRequired,
  //handleClickOnLink: PropTypes.func.isRequired,
}

const ProfileView = ({ permitedUrls, ...props }) => {
  console.log('Vamos para alla ', permitedUrls)
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const [reloadData, setReloadData] = useState(false)

  // tabs
  const [staffData, setStaffData] = useState(null)
  const permited = []

  function getStaffData() {
    getOffices().then(r => setStaffData(r))
  }
  useEffect(() => {
    if (staffData === null) getStaffData()
  }, [staffData])

  if (staffData !== null) {
    staffData?.web_sections?.map(p => permited.push(p.code))
  }

  const tabs = []
  tabs.push({ id: routes.profile, to: routes.profile, label: t('profile.tabs.profile') })
  if (permited.includes('company')) tabs.push({ id: routes.profileCompany, to: routes.profileCompany, label: t('profile.tabs.company') })
  if (permited.includes('plan')) tabs.push({ id: routes.profileMembership, to: routes.profileMembership, label: t('profile.tabs.membership') })
  if (permited.includes('staff')) tabs.push({ id: routes.profileStaff, to: routes.profileStaff, label: t('profile.tabs.staff') })
  if (permited.includes('referral')) tabs.push({ id: routes.profileReferral, to: routes.profileReferral, label: t('profile.tabs.referral') })
  if (permited.includes('theme')) tabs.push({ id: routes.profileTheme, to: routes.profileTheme, label: t('profile.tabs.theme') })
  return (
    <Container>
      <HeaderToolbar
        tabValue={pathname}
        tabs={tabs}
        rightActions={<RigthActions pathname={pathname} t={t} reloadData={setReloadData} />}
      />
      <Switch>
        <Route exact path={routes.profileTheme}>
          <ThemeForm {...props} />
        </Route>
        <Route exact path={routes.profile}>
          <ProfileSingle />
        </Route>
        <Route exact path={routes.profileCompany}>
          <OfficesList />
        </Route>
        <Route exact path={routes.profileMembership}>
          <ProfileMSPlansList />
        </Route>
        <Route exact path={routes.profileStaff}>
          <ProfileStaffList reloadData={reloadData} />
        </Route>
        <Route exact path={routes.staffSingle}>
          <StaffSingle />
        </Route>
        <Route exact path={routes.officeSingle}>
          <OfficeSingle />
        </Route>
      </Switch>
    </Container>
  )
}

ProfileView.propTypes = {
  handleClickOnLink: PropTypes.func.isRequired,
  permitedUrls: PropTypes.object,
}

ProfileView.defaultProps = {
  permitedUrls: {},
}

export default ProfileView
