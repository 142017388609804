import React from 'react'
import Menu from '@material-ui/core/Menu'
import PropTypes from 'prop-types'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp'
import find from 'lodash/find'
import map from 'lodash/map'
import Text from 'components/atoms/text'
import { Container, InputSelector, SelectedText, SelectorMenuItem } from './styled'

const SelectorView = ({ options, value, className, type, color, onChange }) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const selectedItem = find(options, { id: value }) || {}

  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleChange = item => {
    onChange(item)
    handleClose()
  }

  const open = Boolean(anchorEl)
  return (
    <Container className={className}>
      <InputSelector onClick={handleClick} color={color} type={type}>
        {' '}
        <SelectedText type={type} color={color}>
          {selectedItem.text}
        </SelectedText>
        {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </InputSelector>
      <Menu anchorEl={anchorEl} keepMounted open={open} onClose={handleClose}>
        {map(options, item => (
          <SelectorMenuItem key={item.id} onClick={() => handleChange(item.id)} type={type} color={color}>
            <Text>{item.text}</Text>
          </SelectorMenuItem>
        ))}
      </Menu>
    </Container>
  )
}

SelectorView.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  type: PropTypes.string,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
}

SelectorView.defaultProps = {
  className: '',
  options: [],
  value: null,
  type: '',
  color: null,
}

export default SelectorView
