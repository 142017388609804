import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { CircularProgress } from '@material-ui/core'
import { ScrollView } from 'devextreme-react'
import PropTypes from 'prop-types'
import Container from '@material-ui/core/Container'
import routes from 'config/routes'
import formatWords from 'components/atoms/formatWords/formatWords'
import { ListContainer, ListHeader, ListItem, ListRow, SpinnerContainer } from './newListStyle'
import { BoxRow, BoxItemTitleSM, BoxItemSM, BoxItemTitle, BoxItem } from '../invoices/scrollListStyles'

function ProfileMembershipList(props) {
  const { memberships, showViewAll } = props
  const center = { margin: '0 auto' }

  // WINDOW RESIZER

  const [windowSize, setWindowSize] = useState({
    width: null,
    height: null,
  })

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      })
    }
    window.addEventListener('resize', handleResize)
    handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  if (props !== undefined) {
    return (
      <ListContainer>
        <ScrollView direction='horizontal' width={(windowSize.width * 82) / 100} style={{ marginTop: '15px' }}>
          <ListHeader>
            <BoxRow>
              <BoxItemTitle>
                <ListItem>
                  <strong>Name</strong>
                </ListItem>
              </BoxItemTitle>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Status</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Start</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>End</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Bookings</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Used Bookings</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Credits</strong>
                </ListItem>
              </BoxItemTitleSM>
              <BoxItemTitleSM>
                <ListItem>
                  <strong>Used credits</strong>
                </ListItem>
              </BoxItemTitleSM>
            </BoxRow>
          </ListHeader>
          {memberships.length > 0 ? (
            memberships
              .sort((a, b) => (a.create_date < b.create_date ? 1 : -1))
              .map((membership, index) => {
                const startDate = membership?.start?.split('T')
                const endDate = membership?.end?.split('T')
                if (index >= 10) return ''
                return (
                  <Link to={`/members/memberships/${membership.slug}`} key={index} style={{ textDecoration: 'none' }}>
                    <ListRow>
                      <BoxRow>
                        <BoxItem>
                          <ListItem>
                            <strong>{membership.plan_membership?.name}</strong>
                          </ListItem>
                        </BoxItem>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{formatWords(membership?.status)}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{startDate ? startDate[0] : '-'}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{endDate ? endDate[0] : '-'}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{membership.pre_bookings}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{membership.bookings}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{membership.credits}</strong>
                          </ListItem>
                        </BoxItemSM>
                        <BoxItemSM>
                          <ListItem>
                            <strong>{membership.used_credits}</strong>
                          </ListItem>
                        </BoxItemSM>
                      </BoxRow>
                    </ListRow>
                  </Link>
                )
              })
          ) : (
            <ListRow>
              <ListItem style={center}>
                <strong>No memberships data</strong>
              </ListItem>
            </ListRow>
          )}
          {showViewAll && (
            <Container style={{ textAlign: 'right', padding: 10 }}>
              <Link to={routes.membersMembership} style={{ textDecoration: 'none' }}>
                Vie All
              </Link>
            </Container>
          )}
        </ScrollView>
      </ListContainer>
    )
  }

  return (
    <SpinnerContainer>
      <CircularProgress color='secondary' />
    </SpinnerContainer>
  )
}

ProfileMembershipList.propTypes = {
  memberships: PropTypes.array,
  showViewAll: PropTypes.bool,
}

ProfileMembershipList.defaultProps = {
  memberships: [],
  showViewAll: false,
}

export default ProfileMembershipList
